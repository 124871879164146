import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import Loader from "react-loader-spinner";
import { Elements, StripeProvider } from "react-stripe-elements";

import { currentUser } from "../../auth/reducers/selectors";
import {
  removeCardFromUser,
  addCardAction,
  addCardReset
} from "../actions/actions";
import {
  getRemoveCardLoading,
  cardHasFailed,
  cardError
} from "../reducers/selectors";

import StripeComponent from "../../../components/StripeComponent";
import config from "../../../config/config";

class AddEditCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notComplete: false,
      isUpdating: false,
      stripeError: ""
    };
  }

  componentWillUnmount() {
    this.props.resetAddCard();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.removeLoader !== this.props.removeLoader)
      if (
        this.props.removeLoader === false &&
        this.props.cardHasFailed === false
      )
        this.props.closeModal();
  }

  static defaultProps() {
    return {
      inline: false
    };
  }

  setStripeError = stripeError => this.setState({ stripeError });

  setNotComplete = notComplete => this.setState({ notComplete });

  getToken = token => {
    const { addCardAction } = this.props;

    addCardAction({ stripeToken: token.id });
  };

  renderEditDetails = () => {
    const { notComplete, invalidDate, stripeError } = this.state;
    const { cardError, cardHasFailed } = this.props;

    return (
      <div>
        <div className="modal-title white booking">{this.props.name}</div>
        <img
          src={require("../../../assets/images/ic_close.svg")}
          className="close-button  booking-close-btn white"
          alt=""
          onClick={this.props.closeModal}
        />
        <StripeProvider apiKey={config.stripeApiKey}>
          <div className="flex-center direction-column">
            <div>
              <Elements>
                <StripeComponent
                  token={this.getToken}
                  setNotComplete={this.setNotComplete}
                  setStripeError={this.setStripeError}
                />
              </Elements>
            </div>
            {notComplete && (
              <span className="validation-message">
                Please fill in all Payment Information.
              </span>
            )}
            {cardHasFailed && cardError && (
              <span className="validation-message">{cardError}</span>
            )}
            {invalidDate && (
              <span className="validation-message">
                Invalid Expiration Date. Please try again.
              </span>
            )}
            {stripeError && (
              <span className="validation-message">{stripeError}</span>
            )}
          </div>
        </StripeProvider>
      </div>
    );
  };

  inlineOrModal = children => {
    const { modalIsOpen, closeModal, inline } = this.props;

    return inline ? (
      children
    ) : (
      <Modal
        isOpen={modalIsOpen}
        className="payment-modal"
        onRequestClose={closeModal}
        ariaHideApp={false}
      >
        {children}
      </Modal>
    );
  };

  render() {
    const { removeLoader } = this.props;
    const { isUpdating } = this.state;

    if (isUpdating || removeLoader) {
      return this.inlineOrModal(
        <Fragment>
          <div className="modal-title white booking">Please wait...</div>
          <div className="flex-center direction-column">
            <div className="payment-modal-height flex-center direction-column">
              <Loader
                type="Ball-Triangle"
                height="100"
                width="100"
                color="#0EA800"
              />
            </div>
          </div>
        </Fragment>
      );
    }

    const editDetails = this.renderEditDetails();

    return this.inlineOrModal(editDetails);
  }
}

AddEditCard.propTypes = {
  removeCardFromUser: PropTypes.func.isRequired,
  addCardAction: PropTypes.func.isRequired,
  resetAddCard: PropTypes.func.isRequired,
  cardHasFailed: PropTypes.bool.isRequired,
  cardError: PropTypes.string,
  inline: PropTypes.bool
};

const mapDispatchToProps = {
  removeCardFromUser,
  addCardAction,
  resetAddCard: addCardReset
};

const mapStateToProps = state => ({
  currentUser: currentUser(state),
  removeLoader: getRemoveCardLoading(state),
  cardHasFailed: cardHasFailed(state),
  cardError: cardError(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditCard);
