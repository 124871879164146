import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import Loader from "react-loader-spinner";
import { get } from "lodash-es";

import { currentUser } from "../../auth/reducers/selectors";
import ProfileSwitch from "../components/ProfileSwitch";
import ReviewComponent from "../../../components/ReviewComponent";
import Input from "../../../components/Input";
import NumberInput from "../../../components/NumberInput";
import PrimaryButton from "../../../components/PrimaryButton";
import SecondaryButton from "../../../components/SecondaryButton";
import AddCard from "../components/AddCard";
import {
  Regex,
  ResendWaitingTime,
  MaxPictureSize,
  PasswordLength
} from "../../../settings";
import history from "../../../history";

import {
  sendSmsCode,
  verifySmsCode,
  changeProfilePassword,
  getLoggedUser,
  updateUser
} from "../../auth/actions/actions";
import config from "../../../config/config";
import ViewCard from "../components/ViewCard";

class ProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidMount() {
    this.props.getLoggedUser();
    document.title = "Deski";
  }

  getInitialState = () => {
    const initialState = {
      type: "renter",
      preview: null,
      firstName: this.props.currentUser.firstName,
      lastName: this.props.currentUser.lastName,
      email: this.props.currentUser.email,
      phone: this.props.currentUser.phone.substr(
        2,
        this.props.currentUser.phone.length
      ),
      prefix: this.props.currentUser.phone.substr(0, 2),
      editMode: false,
      passwordVisible: false,
      password: "placeholder",
      editModalOpen: false,
      addCardModal: false,
      isCodeVerified: true,
      isPhoneValid: true,
      isPasswordValid: true,
      invalidEmail: false,
      EmailOrPhoneAlreadyExists: false,
      deleteModalOpen: false,
      isUpdating: false
    };
    return initialState;
  };

  getSelected = selected => {
    this.setState({
      type: selected
    });
    if (selected === "host") {
      history.push("/profile/host");
    }
  };

  readURL = event => {
    const input = event.target;

    if (input.files && input.files[0]) {
      let reader = new FileReader();
      const sizeInMB = input.files[0].size / 1024 / 1024;

      if (sizeInMB > MaxPictureSize) {
        this.setState({ invalidFileSize: true });
      } else {
        reader.onload = e => {
          this.setState({
            profilePictureBase64: e.target.result,
            preview: e.target.result,
            invalidFileSize: false
          });
        };

        reader.readAsDataURL(input.files[0]);
      }
    }
  };

  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  editCard = () => {
    this.setState({ editModalOpen: true });
  };

  addCard = () => {
    this.setState({ addCardModal: true });
  };

  sendCode = async () => {
    const { phone, resendEnabled, prefix } = this.state;

    try {
      await this.props.sendSmsCode({
        phone: (prefix + phone).replace(/\+|-/g, ""),
        forgot: false
      });
      this.setState({
        modalIsOpen: true,
        resendEnabled: resendEnabled === null ? true : false,
        AccountAlreadyConnected: null,
        Unknown: null
      });
    } catch (e) {
      let error = JSON.parse(e.message);
      if (error.error.code === "AccountAlreadyConnected")
        this.setState({
          [error.error.code]:
            "The phone number you entered is already registred. Please try again."
        });
      else
        this.setState({
          [error.error.code]: "Please enter a valid phone number."
        });
    }

    setTimeout(() => {
      this.setState({ resendEnabled: true });
    }, ResendWaitingTime);
  };

  verifySmsCode = async () => {
    const { verifyCode, phone, prefix } = this.state;

    try {
      const response = await this.props.verifySmsCode({
        code: verifyCode,
        phone: (prefix + phone).replace(/\+|-/g, "")
      });
      if (response.isCodeValid) {
        this.setState({
          modalIsOpen: false,
          isCodeVerified: true,
          isPhoneValid: true
        });
      }
    } catch (e) {
      this.setState({ isCodeVerified: false });
    }
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      editModalOpen: false,
      addCardModal: false,
      deleteModalOpen: false
    });
  };

  getUpdatedFields = () => {
    const { firstName, lastName, email, phone, preview, prefix } = this.state;
    const { currentUser } = this.props;
    let updatedFields = {};

    if (firstName !== currentUser.firstName) {
      updatedFields["firstName"] = firstName;
    }
    if (lastName !== currentUser.lastName) {
      updatedFields["lastName"] = lastName;
    }
    if (email !== currentUser.email) {
      updatedFields["email"] = email;
    }
    if (prefix.replace(/\+|-/g, "") + phone !== currentUser.phone) {
      updatedFields["phone"] = prefix + phone;
    }
    if (preview) {
      updatedFields["pictureBase64"] = preview;
    }

    return updatedFields;
  };

  //TO DO: review function  - too many ifs !!!!!!

  saveChanges = async () => {
    const { email, password } = this.state;

    this.setState({
      passwordVisible: false,
      invalidEmail: false,
      isPasswordValid: true
    });

    let updatedFields = this.getUpdatedFields();
    let emptyFields = 0;
    Object.keys(updatedFields).forEach(field => {
      if (updatedFields[field] === "") emptyFields++;
    });

    if (emptyFields > 0) {
      this.setState({ notComplete: true });
    } else {
      this.setState({ notComplete: false });
      if (Object.keys(updatedFields).length) {
        if (!Regex.emailRegex.test(email)) {
          this.setState({ invalidEmail: true });
        } else {
          try {
            this.setState({ isUpdating: true });
            await this.props.updateUser(updatedFields);
            this.setState({
              editMode: false,
              invalidEmail: false,
              password: "placeholder",
              isUpdating: false
            });
          } catch (e) {
            let error = JSON.parse(e.message);
            if (error.error.code === "EmailOrPhoneAlreadyExists")
              this.setState({
                isUpdating: false,
                [error.error.code]:
                  "This email is already registred. Please try again."
              });
            else
              this.setState({
                isUpdating: false,
                [error.error.code]: "An error appeared. Please try again."
              });
          }
        }
      } else if (password !== "") {
        if (!(password.trim().length > PasswordLength))
          this.setState({
            isPasswordValid: false
          });
        else {
          await this.props.changeProfilePassword({ newPassword: password });
          this.setState({ editMode: false, password: "placeholder" });
        }
      } else {
        this.setState({
          editMode: false,
          EmailOrPhoneAlreadyExists: false,
          Unknown: false,
          password: "placeholder"
        });
      }
    }
  };

  editProfile = () => {
    this.setState({ editMode: true, password: "" });
  };

  toggleVisibility = () => {
    this.setState({ passwordVisible: !this.state.passwordVisible });
  };

  openEditCardModal = type => {
    const { editModalOpen, addCardModal } = this.state;
    if (type === "edit") {
      return (
        <ViewCard
          modalIsOpen={type === "edit" ? editModalOpen : addCardModal}
          closeModal={this.closeModal}
          name="Edit Payment Details"
        />
      );
    } else
      return (
        <AddCard
          modalIsOpen={type === "edit" ? editModalOpen : addCardModal}
          closeModal={this.closeModal}
          name="Add Payment Details"
        />
      );
  };

  getPrefix = prefix => {
    this.setState({ prefix });
  };

  render() {
    const {
      isUpdating,
      prefix,
      deleteModalOpen,
      notComplete,
      type,
      isPasswordValid,
      EmailOrPhoneAlreadyExists,
      invalidFileSize,
      invalidEmail,
      preview,
      editMode,
      passwordVisible,
      editModalOpen,
      addCardModal,
      isCodeVerified,
      isPhoneValid,
      modalIsOpen,
      phone,
      resendEnabled,
      AccountAlreadyConnected,
      Unknown,
      firstName,
      lastName,
      email
    } = this.state;
    const { currentUser } = this.props;
    const last4 = get(currentUser, "stripe.last4");
    const savedCard = last4 ? `Card ending in ${last4}` : `Saved Card`;

    let paymentDetails = (
      <Input
        className={`input-style add-payment-btn flex-center payment-btn`}
        readOnly="readonly"
        placeholder="Card Details"
        value=""
        onClick={this.addCard}
        icon={require("../../../assets/images/ic_add_card.svg")}
      />
    );

    if (currentUser.saveCreditCard) {
      paymentDetails = (
        <Input
          className={`input-style payment-btn`}
          id="payment-input"
          name="cardNumber"
          readOnly="readonly"
          value={savedCard}
          onClick={this.editCard}
        />
      );
    }

    let bottomBarButton = (
      <div className="sticky-buttons">
        <PrimaryButton
          text="Edit Profile"
          className="footer-button booking gray-bg report-btn"
          onClick={this.editProfile}
        />
        {/* <PrimaryButton
          text="Delete Account"
          className="footer-button booking red-bg report-btn"
          icon={'ic_delete.svg'}
          onClick={() => this.setState({ deleteModalOpen: true })}
        /> */}
      </div>
    );

    if (editMode) {
      bottomBarButton = (
        <div className="sticky-buttons">
          <PrimaryButton
            text="Cancel"
            className="footer-button booking red-bg report-btn"
            onClick={() => this.setState(this.getInitialState())}
          />
          <PrimaryButton
            text="Save Changes"
            className="footer-button booking green-bg report-btn"
            onClick={() => this.saveChanges()}
            disabled={!isPhoneValid}
          />
        </div>
      );
    }

    let passwordIcon = "ic_hide_password.svg";
    if (passwordVisible) {
      passwordIcon = "ic_show_password.svg";
    }

    let passwordType = "password";
    if (passwordVisible) {
      passwordType = "text";
    }

    let status = (
      <SecondaryButton
        disabled={false}
        text="Send code"
        className="send-code-button"
        onClick={this.sendCode}
      />
    );

    if (isPhoneValid) {
      status = (
        <div className="verified-button">
          <img src={require("../../../assets/images/ic_check.svg")} alt="" />
          <span className="button-text">Verified</span>
        </div>
      );
    }

    const profileSrc =
      currentUser &&
      currentUser.socialLogin &&
      Object.keys(currentUser.socialLogin).length
        ? currentUser.profilePictureUrl
        : `${config.api}/files/${currentUser.profilePictureUrl}`;

    let passwordField = (
      <div className="field-wrapper">
        <div className="field-name">PASSWORD</div>
        <Input
          id="password"
          name="password"
          type={passwordType}
          className="input-style"
          disabled={!editMode}
          onChange={e => {
            this.handleInputChange(e);
            e.target.value = this.state.name;
          }}
          value={this.state.password}
          passwordIcon={
            editMode && require(`../../../assets/images/${passwordIcon}`)
          }
          onIconClick={this.toggleVisibility}
        />
      </div>
    );

    if (currentUser.socialLogin) {
      passwordField = null;
    }

    let editCard = null;

    if (editModalOpen) {
      editCard = this.openEditCardModal("edit");
    } else if (addCardModal) {
      editCard = this.openEditCardModal("add");
    }

    if (isUpdating) {
      return (
        <div className="loader-wrapper">
          <Loader
            type="Ball-Triangle"
            height="100"
            width="100"
            color="#0EA800"
          />
        </div>
      );
    }

    return (
      <div className="page-template profile-page-renter profile">
        <Modal
          ariaHideApp={false}
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          className="insert-code-modal"
          overlayClassName="overlay"
        >
          <div className="code-modal-title">Insert the code</div>
          <div className="code-modal-text">
            We sent the code to <br /> <b>{prefix + phone}</b>
          </div>
          <input
            name="verifyCode"
            className={`code-modal-input ${
              isCodeVerified === false ? "invalid" : ""
            }`}
            onChange={e => {
              this.handleInputChange(e);
            }}
          />
          <div className="buttons-wrapper">
            <button
              className="resend-button"
              disabled={!resendEnabled}
              onClick={this.sendCode}
            >
              Resend
            </button>
            <SecondaryButton text="Submit" onClick={this.verifySmsCode} />
          </div>
        </Modal>
        <Modal
          ariaHideApp={false}
          isOpen={deleteModalOpen}
          // onRequestClose={this.closeModal}
          className="delete-account-modal"
          overlayClassName="overlay"
        >
          <img
            src={require("../../../assets/images/ic_close.svg")}
            className="close-button filter-close-button"
            alt=""
            onClick={this.closeModal}
          />
          <div className="flex-center direction-column">
            <div className="delete-wrapper red-bg flex-center">
              <img
                src={require("../../../assets/images/ic_delete.svg")}
                style={{ width: "22px" }}
                alt=""
              />
            </div>
            <div className="code-modal-title delete">
              Delete your Deski Account
            </div>
            <div className="delete-text flex-center direction-column">
              You can delete your Deski Account at any time. <br />
              If you change your mind, you will not be able to reactivate.{" "}
              <br /> <br />
              Learn what permanently closing and deleting your account means!
              <ul>
                <li>
                  Please note that both your general and your host accounts will
                  be permanently deleted.
                </li>
                <li>
                  You can’t reactivate, recover any data, or regain access to
                  your account once it’s deleted.
                </li>
                <li>
                  Any reservations you currently have as a host or a guest will
                  automatically be cancelled.
                </li>
                <li>Your listings will be deleted.</li>
                <li>
                  Some of your data may remain in our systems if we are legally
                  required, or while we are legally permitted, to retain it.
                </li>
                <li>
                  Some information, such as your reviews, may still be visible
                  to others.
                </li>
              </ul>
              Need more info, please contact us.
              <br />
            </div>
            <PrimaryButton
              text="Delete Account"
              className="top-margin red-bg"
              onClick={this.removeCard}
            />
            <PrimaryButton
              text="Cancel"
              className="no-bg gray-border"
              textClass="gray"
              onClick={this.closeModal}
            />
          </div>
        </Modal>
        {editCard}
        {/*
        <AddCard
          modalIsOpen={addCardModal}
          closeModal={this.closeModal}
          name="Add card"
          updateButtonName="Add"
        /> */}

        <div className="page-wrapper">
          <div className="page-header">
            <h1 className="page-title">Profile</h1>
            <ProfileSwitch getSelected={this.getSelected} selectedType={type} />
          </div>
          <div className="page-content">
            <div className="main-content">
              <div className="img-uploader-wrapper">
                <div className="img-uploader flex-center direction-column">
                  <div
                    className="uploader-img-profile"
                    style={{
                      backgroundImage: `url(${preview ||
                        (currentUser.profilePictureUrl
                          ? profileSrc
                          : require("../../../assets/images/placeholder_profile.svg"))})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center"
                    }}
                  ></div>
                  <input
                    type="file"
                    disabled={!editMode}
                    onChange={this.readURL}
                    className="photo-uploader"
                    style={{ height: "130px" }}
                    accept="image/*"
                  />
                  <button className={`text-button ${editMode ? "" : "hidden"}`}>
                    Upload Photo
                  </button>
                </div>
                {invalidFileSize && (
                  <span className="validation-message validation-message-avatar">
                    Invalid file size
                  </span>
                )}
              </div>

              <div className="row">
                <div className="field-wrapper">
                  <div className="field-name">FIRST NAME</div>
                  <Input
                    id="firstName"
                    name="firstName"
                    className="input-style"
                    disabled={!editMode}
                    onChange={e => this.handleInputChange(e)}
                    value={firstName}
                    maxLength={25}
                  />
                </div>

                <div className="field-wrapper">
                  <div className="field-name">LAST NAME</div>
                  <Input
                    id="lastName"
                    name="lastName"
                    className="input-style"
                    disabled={!editMode}
                    onChange={e => this.handleInputChange(e)}
                    value={lastName}
                    maxLength={25}
                  />
                </div>
              </div>

              <div className={`row ${!passwordField && "single-child"}`}>
                <div className="field-wrapper">
                  <div className="field-name">EMAIL ADDRESS</div>
                  <Input
                    name="email"
                    className="input-style"
                    disabled={!editMode}
                    onChange={e => this.handleInputChange(e)}
                    value={email}
                  />
                </div>
                {passwordField}
              </div>

              {EmailOrPhoneAlreadyExists && (
                <span className="validation-message">
                  {EmailOrPhoneAlreadyExists}
                </span>
              )}
              {!isPasswordValid && (
                <span className="validation-message">
                  The password you have entered needs to have at least minimum 6
                  characters. Please try again.
                </span>
              )}
              {invalidEmail && (
                <span className="validation-message">
                  Please enter an email address with the format email@email.com.
                </span>
              )}
              {notComplete && (
                <span className="validation-message">
                  Please fill in all information.
                </span>
              )}

              <div className="row single-child">
                <div className="field-wrapper">
                  <div className="field-name">MOBILE NUMBER {status}</div>
                  <NumberInput
                    id="phone"
                    name="phone"
                    className="input-style"
                    disabled={!editMode}
                    onChange={e => {
                      this.handleInputChange(e);
                      this.setState({ isPhoneValid: false });
                    }}
                    defaultValue={phone}
                    getPrefix={this.getPrefix}
                    prefix={prefix}
                    onPrefixChange={() =>
                      this.setState({ isPhoneValid: false })
                    }
                  />
                  {AccountAlreadyConnected && (
                    <span className="validation-message">
                      {AccountAlreadyConnected}
                    </span>
                  )}
                  {Unknown && (
                    <span className="validation-message">{Unknown}</span>
                  )}
                </div>
              </div>
              <div className="separation-line"></div>
              <div className="row single-child">
                <div className="field-wrapper">
                  <div className="field-name">PAYMENT DETAILS</div>
                  {paymentDetails}
                </div>
              </div>
            </div>
            <div className="sidebar-content">
              <ReviewComponent
                title="REVIEWS"
                rating={currentUser.rating}
                ratings={currentUser.ratings}
                nrOfRaters={currentUser.nrOfRaters}
              />
            </div>
          </div>
        </div>

        <div className="space-footer">
          <div className="section-wrapper">{bottomBarButton}</div>
        </div>
      </div>
    );
  }
}

ProfileScreen.propTypes = {
  sendSmsCode: PropTypes.func.isRequired,
  verifySmsCode: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  getLoggedUser: PropTypes.func.isRequired,
  changeProfilePassword: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  sendSmsCode,
  verifySmsCode,
  updateUser,
  changeProfilePassword,
  getLoggedUser
};

const mapStateToProps = state => ({
  currentUser: currentUser(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileScreen);
