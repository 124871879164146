import { GET_FEATURED_PRICE, GET_FEATURED_PRICE_SUCCESS, SET_FEATURED_SUCCESS, SET_FEATURED, SET_FEATURED_FAIL } from './actionTypes';

import { Search } from '../../../api/endpoints';

export function fetchPrice(payload) {
  return {
    type: GET_FEATURED_PRICE,
    payload,
  };
}

export function fetchPriceSuccess(payload) {
  return {
    type: GET_FEATURED_PRICE_SUCCESS,
    payload,
  };
}

export function setFeatured(payload) {
  return {
    type: SET_FEATURED,
    payload,
  };
}

export function setFeaturedSuccess(payload) {
  return {
    type: SET_FEATURED_SUCCESS,
    payload,
  };
}

export function setFeaturedFail(payload) {
  return {
    type: SET_FEATURED_FAIL,
    payload,
  };
}

export function getPriceFeatured(payload) {
  return async (dispatch) => {
    dispatch(fetchPrice(payload));

    const response = await Search.getFeaturedPrice(payload);

    dispatch(fetchPriceSuccess(response));
  };
}

export function setFeaturedSpace(payload) {
  return async (dispatch) => {
    dispatch(setFeatured(payload));

    try {
      const response = await Search.boostSpace(payload);

      dispatch(setFeaturedSuccess(response));
    }

    catch (e) {
      dispatch(setFeaturedFail(e));
    }
  };
}