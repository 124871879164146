import React, { Component } from 'react';
import Input from '../../../components/Input';
import NumberInput from '../../../components/NumberInput';

class PersonalHost extends Component {

    getPrefix = (prefix) => {
        this.props.getPrefix(prefix)
    }

    render() {
        const { emptyFields, prefix, handleInputChange, editMode, defaultAddress, status, phone, defaultCity, defaultState, defaultZip, defaultCountry, defaultDob, defaultGovNumber, AccountAlreadyConnected, Unknown } = this.props;

        return (
            <div className="user-details-personal">
                <div className="row">
                    <div className="field-wrapper">
                        <div className="field-name">Address <span className="red">{emptyFields.includes('address') && '*'}</span></div>
                        <Input
                            id="hostAddress"
                            name="hostAddress"
                            className="input-style"
                            disabled={!editMode}
                            onChange={(e) => handleInputChange(e)}
                            value={defaultAddress}
                        />
                    </div>

                    <div className="field-wrapper">
                        <div className="field-name">
                            <span>Phone Number<span className="red">{emptyFields.includes('businessPhone') && '*'}</span></span>
                            {status}</div>
                        <NumberInput
                            id="hostPhone"
                            name="phone"
                            className="input-style"
                            disabled={!editMode}
                            onChange={(e) => {
                                handleInputChange(e)
                            }}
                            defaultValue={phone}
                            getPrefix={this.getPrefix}
                            prefix={prefix}
                            onPrefixChange={this.props.prefixChanged}
                        />
                        {AccountAlreadyConnected && <span className="validation-message">{AccountAlreadyConnected}</span>}
                        {Unknown && <span className="validation-message">{Unknown}</span>}
                    </div>
                </div>

                <div className="row">
                    <div className="field-wrapper">
                        <div className="field-name">City <span className="red">{emptyFields.includes('city') && '*'}</span></div>
                        <Input
                            id="hostCity"
                            name="hostCity"
                            className="input-style"
                            disabled={!editMode}
                            onChange={(e) => handleInputChange(e)}
                            value={defaultCity}
                        />
                    </div>

                    <div className="field-wrapper">
                        <div className="field-name">State <span className="red">{emptyFields.includes('state') && '*'}</span></div>
                        <Input
                            id="hostState"
                            name="hostState"
                            className="input-style"
                            disabled={!editMode}
                            onChange={(e) => handleInputChange(e)}
                            value={defaultState}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="field-wrapper">
                        <div className="field-name">POSTAL CODE <span className="red">{emptyFields.includes('zip') && '*'}</span></div>
                        <Input
                            id="hostZip"
                            name="hostZip"
                            className="input-style"
                            disabled={!editMode}
                            onChange={(e) => handleInputChange(e)}
                            value={defaultZip}
                            maxLength={12}
                        />
                    </div>

                    <div className="field-wrapper">
                        <div className="field-name">Country <span className="red">{emptyFields.includes('country') && '*'}</span></div>
                        <Input
                            id="hostCountry"
                            name="hostCountry"
                            className="input-style"
                            disabled={true}
                            onChange={(e) => handleInputChange(e)}
                            value={defaultCountry}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="field-wrapper">
                        <div className="field-name">Date of Birth <span className="red">{emptyFields.includes('dob') && '*'}</span></div>
                        <Input
                            placeholder="dd/mm/yyyy"
                            id="hostBirthday"
                            name="hostBirthday"
                            className="input-style"
                            disabled={!editMode}
                            onChange={(e) => handleInputChange(e)}
                            value={defaultDob}
                        />
                    </div>

                    <div className="field-wrapper">
                        <div className="field-name">Government Number <span className="red">{emptyFields.includes('governmentNumber') && '*'}</span></div>
                        <Input
                            id="hostGovNumber"
                            name="hostGovNumber"
                            className="input-style"
                            disabled={!editMode}
                            onChange={(e) => handleInputChange(e)}
                            value={defaultGovNumber}
                        />
                        <div className="gst-text gray">*Australia: ABN, passport ID, drivers licence ID or TFN </div>
                        <div className="gst-text gray">*New Zealand: IRD, NZBN, Passport ID or drivers licence ID</div>
                    </div>
                </div>
            </div>

        )
    }
}

export default PersonalHost;