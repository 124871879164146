import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, padStart } from 'lodash-es';
import Modal from 'react-modal';
import Loader from 'react-loader-spinner';

import { currentUser } from '../../auth/reducers/selectors';
import { removeCardFromUser, getCardDetailsAction } from '../actions/actions';
import { getRemoveCardLoading, cardDetails, cardLoading } from '../reducers/selectors';

import PrimaryButton from '../../../components/PrimaryButton';
import AddCard from './AddCard';


class AddEditCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAddOpen: false
        }
    }

    componentDidMount() {
        const { getCardDetailsAction } = this.props;
        getCardDetailsAction();
    }

    goToAddCard = () => {
        setTimeout(() => {
            this.setState({ isAddOpen: true })
        }, 0);
    }

    removeCard = () => {
        const lastCardId = this.props.cardDetails.paymentMethod.id;
        const { removeCardFromUser } = this.props;

        removeCardFromUser(lastCardId)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.removeLoader !== this.props.removeLoader)
            if (this.props.removeLoader === false)
                this.props.closeModal();
    }

    renderAddDetails = () => {
        return <AddCard
            modalIsOpen={true}
            closeModal={this.props.closeModal}
            name="Add Payment Details"
        />
    }

    renderEditDetails = () => {
        const { cardDetails } = this.props;
        const cardName = get(cardDetails, 'paymentMethod.name', '-')
        const cardNumber = `XXXX-XXXX-XXXX-${get(cardDetails, 'paymentMethod.last4', 'XXXX')}`
        const cardExpMonth = padStart(get(cardDetails, 'paymentMethod.exp_month', 'XX'), 2, '0')
        const cardExpYear = String(get(cardDetails, 'paymentMethod.exp_year', 'XXXX')).slice(2)
        const cardExp = `${cardExpMonth} / ${cardExpYear}`

        return (
            <div>
                <div className="modal-title white booking">{this.props.name}</div>
                <img src={require('../../../assets/images/ic_close.svg')} className="close-button  booking-close-btn white" alt=""
                    onClick={this.props.closeModal} />
                <div className="flex-center direction-column">
                    <div style={{ paddingTop: '10px' }}>
                        <div className="justify-between card-name">
                            <div >
                                <div className="card-info">
                                    Name</div>
                                <div className="credit-card">{cardName}</div>
                            </div>
                        </div>

                        <div>
                            <div className="card-info">
                                Card Number</div>
                            <div className="card-data payment">
                                {cardNumber}
                            </div>
                            <div className="card-info">
                                Expiration Date</div>
                            <div className="card-data payment">
                                {cardExp}
                            </div>
                        </div>
                    </div>
                    <PrimaryButton text="Replace Card" className={`top-margin absolute-button-top `} onClick={this.goToAddCard} />
                    <PrimaryButton text="Remove Card" className="top-margin absolute-button gray-bg white-border" onClick={this.removeCard} />
                </div >
            </div >
        )
    }


    render() {
        const { modalIsOpen, closeModal, removeLoader, cardLoading } = this.props;
        const { isAddOpen } = this.state;
        let editModal = null;
        editModal = isAddOpen ? this.renderAddDetails() : this.renderEditDetails();


        if (removeLoader || cardLoading) {
            return (
                <Modal
                    isOpen={modalIsOpen}
                    className="payment-modal"
                    onRequestClose={closeModal}
                    ariaHideApp={false}
                >
                    <div className="modal-title white booking">Please wait...</div>
                    <div className="flex-center direction-column">
                        <div className="payment-modal-height flex-center direction-column">
                            <Loader
                                type="Ball-Triangle"
                                height="100"
                                width="100"
                                color="#0EA800"
                            />
                        </div>
                    </div>
                </Modal>
            )
        }


        return (
            <Modal
                isOpen={modalIsOpen}
                className="payment-modal"
                onRequestClose={closeModal}
                ariaHideApp={false}
            >
                {editModal}

            </Modal>
        );
    }
}

AddEditCard.propTypes = {
    removeCardFromUser: PropTypes.func.isRequired,
    getCardDetailsAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    removeCardFromUser,
    getCardDetailsAction
};

const mapStateToProps = (state) => ({
    currentUser: currentUser(state),
    removeLoader: getRemoveCardLoading(state),
    cardDetails: cardDetails(state),
    cardLoading: cardLoading(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCard);
