import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'; // eslint-disable-line
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './rootReducer';

const loggerMiddleware = createLogger({
  collapse: true,
  level: 'info',
});

const middleware = applyMiddleware(
  loggerMiddleware,
  thunkMiddleware,
);

const composeMiddleware = composeWithDevTools(middleware);

export default function configureStore(initialState) {
  const store = createStore(rootReducer, initialState, composeMiddleware);

  if (module.hot) {
    const newReducer = require('./rootReducer'); // eslint-disable-line

    module.hot.accept(newReducer, () => (
      store.replaceReducer(newReducer.default)
    ));
  }

  return store;
}
