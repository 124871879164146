import { get } from "lodash-es";

import {
  REMOVE_CARD,
  REMOVE_CARD_SUCCESS,
  ACTIVATE_HOST_SUCCESS,
  ACTIVATE_HOST_FAIL,
  ACTIVATE_HOST,
  ACTIVATE_HOST_ACCOUNT_SUCCESS,
  ACTIVATE_HOST_ACCOUNT_FAIL,
  ACTIVATE_HOST_ACCOUNT,
  REMOVE_ACCOUNT,
  REMOVE_ACCOUNT_SUCCESS,
  REDIRECT_FALSE,
  ADD_CARD,
  ADD_CARD_SUCCESS,
  ADD_CARD_FAIL,
  ADD_CARD_RESET,
  GET_CARD_DETAILS,
  GET_CARD_DETAILS_SUCCESS,
  GET_CARD_DETAILS_FAIL,
  GET_BANK_DETAILS,
  GET_BANK_DETAILS_SUCCESS,
  GET_BANK_DETAILS_FAIL,
  ACCEPT_TERMS,
  ACCEPT_TERMS_SUCCESS,
  ACCEPT_TERMS_FAIL
} from "./actionTypes";
import { User } from "../../../api/endpoints";
import { setCurrentUser, getLoggedUser } from "../../auth/actions/actions";
import { saveUserToStorage } from "../../../util/utils";

export function removeCard(payload) {
  return {
    type: REMOVE_CARD,
    payload
  };
}

export function removeCardSuccess(payload) {
  return {
    type: REMOVE_CARD_SUCCESS,
    payload
  };
}

export function removeAccount(payload) {
  return {
    type: REMOVE_ACCOUNT,
    payload
  };
}

export function removeAccountSuccess(payload) {
  return {
    type: REMOVE_ACCOUNT_SUCCESS,
    payload
  };
}

export function activateHost(payload) {
  return {
    type: ACTIVATE_HOST,
    payload
  };
}

export function activateHostSuccess(payload) {
  return {
    type: ACTIVATE_HOST_SUCCESS,
    payload
  };
}

export function setRegisterFail(payload) {
  return {
    type: ACTIVATE_HOST_FAIL,
    payload
  };
}

export function activateHostAccount(payload) {
  return {
    type: ACTIVATE_HOST_ACCOUNT,
    payload
  };
}

export function activateHostAccountSuccess(payload) {
  return {
    type: ACTIVATE_HOST_ACCOUNT_SUCCESS,
    payload
  };
}

export function activateHostAccountFail(payload) {
  return {
    type: ACTIVATE_HOST_ACCOUNT_FAIL,
    payload
  };
}

export function redirectFalse(payload) {
  return {
    type: REDIRECT_FALSE,
    payload
  };
}

export function addCard(payload) {
  return {
    type: ADD_CARD,
    payload
  };
}

export function addCardSuccess(payload) {
  return {
    type: ADD_CARD_SUCCESS,
    payload
  };
}

export function addCardReset(payload) {
  return {
    type: ADD_CARD_RESET,
    payload
  };
}

export function addCardFail(payload) {
  return {
    type: ADD_CARD_FAIL,
    payload
  };
}

export function getCardDetails(payload) {
  return {
    type: GET_CARD_DETAILS,
    payload
  };
}

export function getCardDetailsSuccess(payload) {
  return {
    type: GET_CARD_DETAILS_SUCCESS,
    payload
  };
}

export function getCardDetailsFail(payload) {
  return {
    type: GET_CARD_DETAILS_FAIL,
    payload
  };
}

export function getBankDetails(payload) {
  return {
    type: GET_BANK_DETAILS,
    payload
  };
}

export function getBankDetailsSuccess(payload) {
  return {
    type: GET_BANK_DETAILS_SUCCESS,
    payload
  };
}

export function getBankDetailsFail(payload) {
  return {
    type: GET_BANK_DETAILS_FAIL,
    payload
  };
}

export function acceptTerms(payload) {
  return {
    type: ACCEPT_TERMS,
    payload
  };
}

export function acceptTermsSuccess(payload) {
  return {
    type: ACCEPT_TERMS_SUCCESS,
    payload
  };
}

export function acceptTermsFail(payload) {
  return {
    type: ACCEPT_TERMS_FAIL,
    payload
  };
}

export function removeCardFromUser(payload) {
  return async dispatch => {
    dispatch(removeCard(payload));

    const response = await User.deleteCard(payload);

    dispatch(removeCardSuccess(response));
    dispatch(getLoggedUser());
  };
}

export function registerHost(payload, terms) {
  return async dispatch => {
    dispatch(activateHost(payload));
    try {
      let response = await User.registerOrEditHostAccount(payload);
      const accountId = get(response, "user.stripe.accountId");

      if (terms && accountId) {
        [response] = await Promise.all([
          User.activateHostAccount(),
          User.acceptTermsOfService({ accountId })
        ]);
      }

      dispatch(activateHostSuccess(response));

      saveUserToStorage(response);
      dispatch(setCurrentUser(response));
    } catch (e) {
      dispatch(setRegisterFail(e));
    }
  };
}

export function deleteUser(payload) {
  return async dispatch => {
    dispatch(removeAccount(payload));

    const response = await User.deleteUserAccount(payload);

    dispatch(removeAccountSuccess(response));
    saveUserToStorage(response);
    dispatch(setCurrentUser(response));
  };
}

export function deactivateHost(payload) {
  return async dispatch => {
    dispatch(removeAccount(payload));

    const response = await User.deactivateHostAccount(payload);

    dispatch(removeAccountSuccess(response));
    saveUserToStorage(response);
    dispatch(setCurrentUser(response));
  };
}

export function activateHostAction(payload) {
  return async dispatch => {
    dispatch(activateHostAccount(payload));
    try {
      const response = await User.activateHostAccount(payload);

      dispatch(activateHostAccountSuccess(response));

      saveUserToStorage(response);
      dispatch(setCurrentUser(response));
    } catch (e) {
      dispatch(activateHostAccountFail(e));
    }
  };
}

export function stopRedirect(payload) {
  return dispatch => {
    dispatch(redirectFalse(payload));
  };
}

export function addCardAction(payload) {
  return async dispatch => {
    dispatch(addCard(payload));
    try {
      const response = await User.addCard(payload);
      dispatch(addCardSuccess(response));

      dispatch(getLoggedUser());
    } catch (e) {
      const message = get(e, "message", "{}");
      const parsed = JSON.parse(message);
      const payload = get(
        parsed,
        "error.msg",
        `An unknown card error has occured: ${e}`
      );

      dispatch(addCardFail(payload));
    }
  };
}

export function getCardDetailsAction(payload) {
  return async dispatch => {
    dispatch(getCardDetails(payload));
    try {
      const response = await User.getCardDetails(payload);
      dispatch(getCardDetailsSuccess(response));
    } catch (e) {
      dispatch(getCardDetailsFail(e));
    }
  };
}

export function getBankDetailsAction(payload) {
  return async dispatch => {
    dispatch(getBankDetails(payload));
    try {
      const response = await User.getBankDetails(payload);
      dispatch(getBankDetailsSuccess(response));
    } catch (e) {
      dispatch(getBankDetailsFail(e));
    }
  };
}

export function acceptTermsAction(payload) {
  return async dispatch => {
    dispatch(acceptTerms(payload));
    try {
      const response = await User.acceptTermsOfService(payload);
      dispatch(acceptTermsSuccess(response));
    } catch (e) {
      dispatch(acceptTermsFail(e));
    }
  };
}
