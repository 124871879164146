import { SET_POSTER_SPACES, SET_SPACES } from '../actions/actionTypes';
import { Map } from 'immutable';

const initialState = Map({
  posterSpaces: {},
  isLoading: false
});

function setSpaces(state, { payload }) {
  return state.set('isLoading', true);
}

function setPosterSpaces(state, { payload }) {
  return state.set('posterSpaces', payload).set('isLoading', false);
}

const handlers = {
  [SET_POSTER_SPACES]: setPosterSpaces,
  [SET_SPACES]: setSpaces,
};

export default function createPosterSpacesReducer(state = initialState, action = {}) {
  const handler = handlers[action.type];

  if (!handler) {
    return state;
  }

  return handler(state, action);
}
