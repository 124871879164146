import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Avatar from 'react-avatar-edit'
import Loader from 'react-loader-spinner';

import MainModal from '../../../components/MainModal';
import TextInput from '../../../components/TextInput';
import PrimaryButton from '../../../components/PrimaryButton';
import SecondaryButton from '../../../components/SecondaryButton';
import NumberInput from '../../../components/NumberInput';
import { sendSmsCode, verifySmsCode, register } from '../actions/actions';
import { currentUser } from '../reducers/selectors';
import SuccessModal from '../../../components/SuccessModal';
import { ResendWaitingTime, MaxPictureSize, PasswordLength, Regex } from '../../../settings';
import history from '../../../history';
import { Mixpanel } from '../../../util/MixPanel';
import moment from 'moment';

class SignupScreen extends Component {
  state = {
    modalIsOpen: false,
    invalidFields: {},
    EmailOrPhoneAlreadyExists: false,
    adjustPhotoModalIsOpen: false,
    preview: null,
    invalidFileSize: false,
    resendEnabled: null,
    successModalisOpen: false,
    isCreating: false
  }

  componentDidMount() {
    document.title = 'Deski';
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  readURL = (event) => {
    const input = event.target;
    if (input.files && input.files[0]) {
      let reader = new FileReader();
      const sizeInMB = input.files[0].size / 1024 / 1024;

      if (sizeInMB > MaxPictureSize) {
        this.setState({ invalidFileSize: true })
      } else {
        reader.onload = (e) => {
          this.setState({
            profilePictureBase64: e.target.result,
            preview: e.target.result,
            invalidFileSize: false
          })
        };

        reader.readAsDataURL(input.files[0]);
      }
    }
  };

  sendCode = async () => {
    const { phoneNumber, prefix, resendEnabled } = this.state;
    this.setState({ AccountAlreadyConnected: '', Unknown: '' })
    try {
      await this.props.sendSmsCode({ phone: (prefix + phoneNumber).replace(/\+|-/g, ''), forgot: false });
      this.setState({ modalIsOpen: true, resendEnabled: resendEnabled === null ? true : false, AccountAlreadyConnected: null });
    }
    catch (e) {
      let error = JSON.parse(e.message);
      if (error.error.code === "AccountAlreadyConnected")
        this.setState({ [error.error.code]: "The phone number you entered is already registred. Please try again." })
      else
        this.setState({ [error.error.code]: "An error occured. Please try again with a valid phone number." })

    }

    setTimeout(() => {
      this.setState({ resendEnabled: true })
    }, ResendWaitingTime);

  }

  verifySmsCode = async () => {
    const { verifyCode, phoneNumber, prefix } = this.state
    try {
      const response = await this.props.verifySmsCode({ code: verifyCode, phone: (prefix + phoneNumber).replace(/\+|-/g, '') })
      if (response.isCodeValid) {
        this.setState({
          modalIsOpen: false,
          isCodeVerified: true
        });
      }
    } catch (e) {
      this.setState({ isCodeVerified: false });
    }
  }

  register = async () => {
    const {
      preview,
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      phoneNumber,
      prefix,
      verifyCode
    } = this.state;

    let phone = prefix + phoneNumber;

    Mixpanel.identify(email)
    Mixpanel.track('Sign Up', { firstName, lastName, email, phone })
    Mixpanel.setPeople({
      "$firstName": firstName,
      "$lastName": lastName,
      "$email": email,
      "phone": phone,
      "loginMethod": "Email",
      "registrationDate": moment().format("DD/MM/YYYY")
    })

    try {
      this.setState({ isCreating: true })
      await this.props.register({
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        pictureBase64: preview,
        phone,
        code: verifyCode
      });
      this.setState({ successModalisOpen: true, isCreating: false })
    }
    catch (e) {
      let error = JSON.parse(e.message);
      this.setState({ [error.error.code]: error.error.msg, isCreating: false, isCodeVerified: false })
    }
  }

  validatePhoneNumber = (e) => {
    this.setState({
      Unknown: '', AccountAlreadyConnected: '',
      EmailOrPhoneAlreadyExists: ''
    })
    this.validate(e);
    this.handleInputChange(e);
  }

  validate = (e) => {
    const { invalidFields } = this.state;
    const type = e.target.id;
    const value = e.target.value;

    const validations = {
      email: Regex.emailRegex.test(value),
      password: value.trim().length > PasswordLength,
      phone: value.replace(/\+|-/g, '').length >= 7,
      firstName: value.trim().length,
      lastName: value.trim().length
    }

    if (type === 'password') this.setState({ password: value })

    let invalidArray = invalidFields;
    invalidArray[type] = !validations[type];
    this.setState({ invalidFields: invalidArray });
  }

  isFormValid = () => {
    return ['email', 'password', 'phone', 'firstName', 'lastName']
      .every(x => (typeof this.state.invalidFields[x] !== undefined) && this.state.invalidFields[x] === false) && this.state.passwordIncompatible === false;
  }

  getPhoneNumber = (e) => {
    this.setState({ phoneNumber: e.target.value })
  }

  checkPassword = (e) => {
    this.setState({ passwordIncompatible: this.state.password !== e.target.value })
  }
  onCrop = (profilePictureBase64) => {
    this.setState({ preview: profilePictureBase64 })
  }

  getPrefix = (prefix) => {
    this.setState({ prefix })
  }

  render() {
    const { isCreating, prefix, phoneNumber, invalidFields, passwordIncompatible, isCodeVerified, EmailOrPhoneAlreadyExists, invalidFileSize, resendEnabled, AccountAlreadyConnected, Unknown, successModalisOpen } = this.state;
    if (isCreating) {
      return (
        <div className="loader-wrapper">
          <Loader
            type="Ball-Triangle"
            height="100"
            width="100"
            color="#0EA800"
          />
        </div>
      )
    }

    return (
      successModalisOpen ?
        <SuccessModal
          title="Success!"
          description="Your account has been created. You may start searching and listing your favourite business spaces right away!"
          buttonText="Start Exploring"
          onClick={() => history.push('/')}
          onCancel={() => history.push('/')}
        />
        :
        <MainModal title="Welcome" noPhoneTitle>
          <div className="flex-center direction-column content-wrapper sign-up">
            <Modal
              ariaHideApp={false}
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModal}
              className="insert-code-modal"
              overlayClassName="overlay"
            >
              <div className="code-modal-title">Insert the code</div>
              <div className="code-modal-text">We sent the code to <br /> <b>{prefix + phoneNumber}</b></div>
              <input
                name='verifyCode'
                className={`code-modal-input ${isCodeVerified === false ? 'invalid' : ''}`}
                onChange={e => {
                  this.handleInputChange(e)
                }}
              />
              <div className="buttons-wrapper">
                <button className="resend-button" disabled={!resendEnabled} onClick={this.sendCode}>Resend</button>
                <SecondaryButton text="Submit" onClick={this.verifySmsCode} />
              </div>
            </Modal>
            <Modal
              ariaHideApp={false}
              isOpen={this.state.adjustPhotoModalIsOpen}
              onRequestClose={() => this.setState({ adjustPhotoModalIsOpen: false })}
              className="insert-code-modal"
              overlayClassName="overlay"
            >
              <div>
                <Avatar
                  width={390}
                  height={295}
                  onCrop={this.onCrop}
                  onClose={() => this.setState({ adjustPhotoModalIsOpen: false })}
                  src={this.state.profilePictureBase64}
                />
              </div>
            </Modal>
            <div className="img-uploader">
              <div
                className="uploader-img"
                style={{
                  backgroundImage: `url(${this.state.preview || require('../../../assets/images/ic_avatar_placeholder.svg')})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center'
                }}>
              </div>
              <input type="file" onChange={this.readURL} className="photo-uploader" accept="image/*" />
            </div>
            {EmailOrPhoneAlreadyExists && <span className="validation-message">{EmailOrPhoneAlreadyExists}</span>}
            {AccountAlreadyConnected && <span className="validation-message">{AccountAlreadyConnected}</span>}
            {Unknown && <span className="validation-message">{Unknown}</span>}
            {this.state.profilePictureBase64 && <button className="resend-button" onClick={() => this.setState({ adjustPhotoModalIsOpen: true })}>Adjust Photo</button>}
            {invalidFileSize && <span className="validation-message validation-message-avatar">Invalid file size</span>}

            <div className="flex-center direction-column">
              <div className="inputs-line-wrapper">
                <TextInput
                  id="firstName"
                  name='firstName'
                  placeholder="First Name"
                  type="text"
                  onBlur={event => this.validate(event)}
                  onChange={e => {
                    this.handleInputChange(e)
                  }}
                  isInvalid={invalidFields.firstName}
                  maxLength={25}
                />
                <TextInput
                  id="lastName"
                  name='lastName'
                  placeholder="Last Name"
                  type="text"
                  onBlur={event => this.validate(event)}
                  onChange={e => {
                    this.handleInputChange(e)
                  }}
                  isInvalid={invalidFields.lastName}
                  maxLength={25}
                />
              </div>
              {(invalidFields.firstName && invalidFields.lastName)
                ? <span className="validation-message">Please enter First Name and Last Name.</span>
                : invalidFields.firstName
                  ? <span className="validation-message">Please enter First Name.</span>
                  : invalidFields.lastName
                    ? <span className="validation-message">Please enter Last Name.</span>
                    : null
              }
              <div className="inputs-line-wrapper">
                <TextInput
                  id="email"
                  name="email"
                  placeholder="Email address"
                  type="email"
                  isInvalid={invalidFields.email}
                  className="big-width"
                  onBlur={event => this.validate(event)}
                  onChange={e => {
                    this.handleInputChange(e)
                  }}
                />
              </div>
              {invalidFields.email &&
                <span className="validation-message">Please enter an email address with the format email@email.com.</span>}
              <div className="inputs-line-wrapper">
                <TextInput
                  id="password"
                  placeholder="Password"
                  type="password"
                  name='password'
                  onBlur={event => this.validate(event)}
                  onChange={e => {
                    this.handleInputChange(e)
                  }}
                  isInvalid={invalidFields.password}
                />
                <TextInput
                  id="retypedPassword"
                  placeholder="Retype Password"
                  type="password"
                  name='confirmPassword'
                  onBlur={event => this.checkPassword(event)}
                  onChange={e => {
                    this.handleInputChange(e)
                  }}
                  readOnly={this.state.invalidFields["password"] && "readOnly"}
                />
              </div>
              {invalidFields.password &&
                <span className="validation-message">The password needs to have at least minimum 6 characters.</span>}
              {passwordIncompatible &&
                <span className="validation-message">Password does not match. Please try again.</span>}
            </div>
            <div>
              {invalidFields.phone ?
                <span className="validation-message">Please enter a valid phone number.</span>
                :
                <span className="medium-text">Enter your phone number for verification.</span>

              }

              <div className="code-wrapper">
                <NumberInput
                  id="phone"
                  name="phoneNumber"
                  type="text"
                  onChange={event => this.validatePhoneNumber(event)}
                  isInvalid={invalidFields.phone}
                  onBlur={event => this.getPhoneNumber(event)}
                  className="number-input-width"
                  readOnly={this.state.isCodeVerified && "readOnly"}
                  getPrefix={this.getPrefix}
                  smallHeight
                  onPrefixChange={() => this.setState({ isCodeVerified: false })}
                />
                {isCodeVerified ?
                  <div className="secondary-button flex-center green-bg">
                    <img src={require('../../../assets/images/ic_check.svg')} className="social-media-img" alt="" />
                    <span className="secondary button-text">Verified</span>
                  </div>
                  :
                  <SecondaryButton
                    disabled={!((typeof this.state.invalidFields['phone'] !== undefined) && (this.state.invalidFields['phone'] === false))}
                    text="Send code"
                    onClick={this.sendCode} />
                }

              </div>
            </div>
            <PrimaryButton disabled={!this.isFormValid() || !isCodeVerified} text="Done" className="top-margin-phone" onClick={this.register} />
          </div>
        </MainModal>
    );
  }
}


SignupScreen.propTypes = {
  sendSmsCode: PropTypes.func.isRequired,
  verifySmsCode: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  sendSmsCode,
  verifySmsCode,
  register
};

const mapStateToProps = (state) => ({
  currentUser: currentUser(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupScreen);
