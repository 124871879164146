import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import Modal from "react-modal";
import { get } from "lodash-es";

import {
  searchSpaces,
  validateBooking,
  getPricePerBooking,
  resetPrice
} from "../actions/actions";
import {
  getSearchResults,
  getPrice,
  getIndividualSpace,
  getBookings,
  getLoading,
  getValidateFail
} from "../reducers/selectors";
import { getCardDetailsAction } from "../../profile/actions/actions";

import PrimaryButton from "../../../components/PrimaryButton";
import GuestsPicker from "../../../components/GuestsPicker";
import DateTypeSwitch from "../../../components/DateTypeSwitch";

import DailyPickerWrapper from "./DailyPickerWrapper";
import HourlyPickerWrapper from "./HourlyPickerWrapper";
import Amounts from "./Amounts";

import { SpaceType, DateType, TimeLimits } from "../../../settings";
import { Mixpanel } from "../../../util/MixPanel";

class BookingModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: isNaN(this.props.startDate)
        ? null
        : moment(this.props.startDate),
      endDate: isNaN(this.props.endDate) ? null : moment(this.props.endDate),
      focusedInput: "startDate",
      guestsNumber: this.props.guestsNumber || 1,
      isGuestsPickerVisible: false,
      startHour: isNaN(this.props.startHour) ? null : +this.props.startHour,
      endHour: isNaN(this.props.endHour) ? null : +this.props.endHour,
      notComplete: false,
      spaceName: this.props.spaceName,
      isDateValid: false
    };
  }

  getNumber = number => {
    this.setState({ guestsNumber: number });
    Mixpanel.track("Changed Booking Details", { changed: "Number of guests" });
  };

  componentDidMount() {
    const { startDate, endDate, startHour, endHour } = this.state;
    const { dateType, resetPrice } = this.props;

    if (dateType === DateType.HOURLY) {
      if (!startDate || !endDate || !startHour || !endHour) {
        resetPrice();
      }
    } else {
      if (!startDate || !endDate) {
        resetPrice();
      }
    }
  }

  checkAndSubmit = async () => {
    const { startDate, endDate, startHour, endHour, guestsNumber } = this.state;
    const {
      spaceId,
      validateBooking,
      dateType,
      individualSpace,
      getCardDetails
    } = this.props;

    Mixpanel.track("Book Now Clicked");

    let queryParams = {
      startDate: null,
      endDate: null,
      startHour: null,
      endHour: null,
      guestsNumber: guestsNumber,
      spaceId: spaceId
    };

    let isFormValid = false;

    switch (dateType) {
      case DateType.HOURLY:
        isFormValid = this.validateFormHourly();

        queryParams.startDate = moment(startDate).format("X");
        queryParams.endDate = moment(startDate).format("X");
        queryParams.startHour = startHour;
        queryParams.endHour = endHour ? endHour : startHour + 1;
        break;

      case DateType.DAILY:
      case DateType.WEEKLY:
      case DateType.MONTHLY:
        isFormValid = this.validateFormDaily();

        queryParams.startDate = moment(startDate).format("X");
        queryParams.endDate = moment(endDate).format("X");
        queryParams.startHour =
          individualSpace.space.bookingHours.days[
            startDate.isoWeekday()
          ].startTime;
        queryParams.endHour = get(
          individualSpace.space.bookingHours.days[endDate.isoWeekday()],
          "endTime",
          0
        );
        break;

      default:
        break;
    }

    if (!isFormValid) {
      this.setState({ notComplete: true, notValid: false });
    } else {
      const query = `spaceId=${queryParams.spaceId}&startTime=${queryParams.startHour}&endTime=${queryParams.endHour}&startDate=${queryParams.startDate}&endDate=${queryParams.endDate}&bookingInterval=${dateType}`;

      // const res = await validateBooking(query)
      const [res] = await Promise.all([
        validateBooking(query),
        getCardDetails()
      ]);

      this.setState({ notComplete: false });

      if (res && res.isValid && res.isValid === false) {
        this.setState({ notValid: true });
      }
      //  else if (isDateValid) {

      //     this.setState({ notValid: false })
      //     this.props.getSearchInfo(startDate, endDate, startHour, endHour, guestsNumber)
      //     this.props.onDone()
      // }
    }
  };

  validateFormDaily = () => {
    const { startDate, endDate } = this.state;

    let ret = false;

    if (startDate && endDate) {
      ret = true;
    }

    return ret;
  };

  validateFormHourly = () => {
    const { startDate, startHour } = this.state;

    let ret = false;

    if (startDate && startHour) {
      ret = true;
    }

    return ret;
  };

  getTotalPrice = () => {
    const { startDate, endDate, startHour, endHour } = this.state;
    let { spaceId, getPricePerBooking, dateType, individualSpace } = this.props;

    if (!dateType) {
      if (individualSpace.space.ratePerHour) {
        dateType = "hourly";
      } else if (individualSpace.space.ratePerDay) {
        dateType = "daily";
      } else if (individualSpace.space.ratePerWeek) {
        dateType = "weekly";
      } else if (individualSpace.space.ratePerMonth) {
        dateType = "monthly";
      }
    }

    let queryParams = {
      startDate: null,
      endDate: null,
      startHour: null,
      endHour: null,
      spaceId: spaceId,
      bookingInterval: dateType
    };

    switch (dateType) {
      case DateType.HOURLY:
        if (startDate) {
          queryParams.startDate = moment(startDate).format("X");
          queryParams.endDate = moment(startDate).format("X");
        }

        queryParams.startHour = startHour;
        queryParams.endHour = endHour ? endHour : startHour + 1;
        break;

      case DateType.DAILY:
      case DateType.WEEKLY:
      case DateType.MONTHLY:
        if (startDate && endDate) {
          queryParams.startDate = moment(startDate).format("X");
          queryParams.endDate = moment(endDate).format("X");
        }

        queryParams.startHour = TimeLimits.MIN_HOUR;
        queryParams.endHour = TimeLimits.MAX_HOUR;
        break;
      default:
        break;
    }

    if (
      queryParams.startDate &&
      queryParams.endDate &&
      queryParams.startHour &&
      queryParams.endHour
    ) {
      const query = `spaceId=${queryParams.spaceId}&startDate=${queryParams.startDate}&endDate=${queryParams.endDate}&startTime=${queryParams.startHour}&endTime=${queryParams.endHour}&bookingInterval=${queryParams.bookingInterval}`;

      getPricePerBooking(query);
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { startDate, endDate, startHour, endHour, guestsNumber } = this.state;
    const {
      startDate: prevSDate,
      endDate: prevEDate,
      startHour: prevSHour,
      endHour: prevEHour
    } = prevState;
    const { dateType, loading, error } = this.props;
    const { dateType: prevDateType } = prevProps;

    if (
      startDate !== prevSDate ||
      endDate !== prevEDate ||
      startHour !== prevSHour ||
      endHour !== prevEHour ||
      dateType !== prevDateType
    ) {
      this.getTotalPrice();
    }

    if (prevProps.loading !== loading && loading === false) {
      if (error && error.message) {
        this.setState({ notValid: true });
      } else {
        this.setState({ isDateValid: true, notValid: false });
        this.props.getSearchInfo(
          startDate,
          endDate,
          startHour,
          endHour,
          guestsNumber
        );
        this.props.onDone();
      }
    }
  }

  isDayBlocked = day => {
    const { bookedDates } = this.props;

    let dayFormatted = day.format("YYYY-MM-DD");

    if (bookedDates.has(dayFormatted)) {
      return true;
    }

    return false;
  };

  /*
        change date Type and reset all start/end date and time
    */
  changeDateType = newType => {
    const { resetPrice, dateType, onChangeDateType } = this.props;

    if (dateType !== newType) {
      resetPrice();

      onChangeDateType(newType);

      this.setState({
        startDate: null,
        endDate: null,
        startHour: null,
        endHour: null
      });
    }

    Mixpanel.track("Changed Booking Details", { changed: "Duration type" });
  };

  changeDates = (startDate, endDate) => {
    this.setState({
      startDate,
      endDate,
      notComplete: false,
      startHour: null,
      endHour: null
    });
    Mixpanel.track("Changed Booking Details", {
      changed: "Booking Start/End Date"
    });
  };

  changeHours = (startHour, endHour) => {
    this.setState({
      startHour: startHour,
      endHour: endHour,
      notComplete: false
    });
    Mixpanel.track("Changed Booking Details", {
      changed: "Booking Start/End Hours"
    });
  };

  openGuestPicker = () => {
    const { individualSpace } = this.props;
    if (individualSpace.space.type !== SpaceType.desk.id) {
      this.setState({ isGuestsPickerVisible: true });
    }
  };

  render() {
    const {
      spaceName,
      notComplete,
      notValid,
      isGuestsPickerVisible,
      startDate,
      endDate,
      startHour,
      endHour,
      guestsNumber
    } = this.state;
    let {
      dateType,
      modalIsOpen,
      name,
      closeModal,
      price,
      bookedDates,
      individualSpace,
      nrOfPax
    } = this.props;

    let monthlyMessage = "";
    let weeklyMessage = "";

    if (dateType === DateType.MONTHLY) {
      monthlyMessage = (
        <div className="monthly-msg-wrapper flex-center">
          <div className="monthly-message ">
            For monthly bookings, you will be charged one month in advance for
            every subsequent month. Please see below a breakdown of your current
            and future payment details.
          </div>
        </div>
      );
    }

    if (dateType === DateType.WEEKLY || dateType === DateType.MONTHLY) {
      weeklyMessage = (
        <div className="monthly-msg-wrapper flex-center">
          <div className="monthly-message ">
            Please check the space availability hours, as it might not be open
            the whole week. Note that the reservation only applies for available
            days.
          </div>
        </div>
      );
    }

    let datePickerContent = "";
    if (dateType === DateType.HOURLY) {
      datePickerContent = (
        <HourlyPickerWrapper
          startDate={startDate}
          endDate={endDate}
          startHour={startHour}
          endHour={endHour}
          bookedDates={bookedDates}
          changeDates={this.changeDates}
          changeHours={this.changeHours}
          notComplete={notComplete}
        />
      );
    } else {
      datePickerContent = (
        <DailyPickerWrapper
          key={dateType}
          startDate={startDate}
          endDate={endDate}
          bookedDates={bookedDates}
          changeDates={this.changeDates}
          notComplete={notComplete}
          dateType={dateType}
        />
      );
    }

    return (
      <Modal
        isOpen={modalIsOpen}
        className="booking-modal"
        onRequestClose={closeModal}
        ariaHideApp={false}
      >
        <div className="modal-title name white booking">{name}</div>
        {monthlyMessage}
        {weeklyMessage}
        <img
          src={require("../../../assets/images/ic_close.svg")}
          className="close-button white booking-close-btn"
          alt=""
          onClick={closeModal}
        />
        <div className="flex-center direction-column">
          <DateTypeSwitch
            selectedType={dateType}
            changeDateType={this.changeDateType}
            isHourlyClosed={!individualSpace.space.ratePerHour}
            isDailyClosed={!individualSpace.space.ratePerDay}
            isWeeklyClosed={!individualSpace.space.ratePerWeek}
            isMonthlyClosed={!individualSpace.space.ratePerMonth}
          />

          {datePickerContent}

          <div className="guests-wrapper">
            <div className="guests-text white">Number of guests</div>
            <GuestsPicker
              isDeskSelected={individualSpace.space.type === SpaceType.desk.id}
              guestsNumber={guestsNumber}
              isPickerVisible={isGuestsPickerVisible}
              getNumber={this.getNumber}
              onClose={() => this.setState({ isGuestsPickerVisible: false })}
              onClickPicker={() => this.openGuestPicker()}
              maxNrOfPax={nrOfPax}
            />
          </div>

          <Amounts
            price={price}
            isMonthly={dateType === DateType.MONTHLY}
            startDate={startDate}
          />

          {notComplete && (
            <span className="validation-message from-modal">
              Please fill in all Booking Information.
            </span>
          )}
          {notValid && (
            <span className="validation-message from-modal">
              Oh no! Looks like {spaceName} is no longer available for the date
              and time you have selected. Please try a different date.
            </span>
          )}
          <PrimaryButton
            text="Book Now"
            className="top-margin"
            onClick={this.checkAndSubmit}
          />
        </div>
      </Modal>
    );
  }
}

BookingModal.propTypes = {
  searchSpaces: PropTypes.func.isRequired,
  validateBooking: PropTypes.func.isRequired,
  getPricePerBooking: PropTypes.func.isRequired,
  resetPrice: PropTypes.func.isRequired,
  getCardDetails: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  searchSpaces,
  validateBooking,
  getPricePerBooking,
  resetPrice,
  getCardDetails: getCardDetailsAction
};

const mapStateToProps = state => ({
  searchResults: getSearchResults(state),
  individualSpace: getIndividualSpace(state),
  bookings: getBookings(state),
  price: getPrice(state),
  loading: getLoading(state),
  error: getValidateFail(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingModal);
