import React, { Component } from 'react';
import { connect } from 'react-redux';
import PrimaryButton from '../../../components/PrimaryButton';

import Modal from 'react-modal';

const NO_RATING = -1;

class ReviewModal extends Component {
  state = {
    rating: NO_RATING,
    note: '',
    nrOfChars: 0
  };

  setRating = (rating) => {
    const { loggedUserRating } = this.props;
    if (loggedUserRating && loggedUserRating.rating) return;
    else this.setState({ rating })
  }

  renderRating = (rating) => {
    const ratingArray = []
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        ratingArray.push(
          <div key={i} className={`review-stars filter`} onClick={() => this.setRating(i)}>
            <img src={require('../../../assets/images/big_full_star.svg')} alt="" />
          </div>
        )
      } else {
        ratingArray.push(
          <div key={i} className={`review-stars filter`} onClick={() => this.setRating(i)}>
            <img src={require('../../../assets/images/big_empty_star.svg')} alt="" />
          </div>
        )
      }
    }
    return ratingArray;
  };

  componentDidMount() {
    const { loggedUserRating } = this.props;
    this.setState({
      rating: loggedUserRating && loggedUserRating.rating ? loggedUserRating.rating.stars : this.state.rating,
      note: loggedUserRating && loggedUserRating.rating ? loggedUserRating.rating.message : this.state.note
    });
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  };

  submitReviewToSpace = () => {
    const { rating, note } = this.state;
    const { submitReview, closeModal } = this.props;

    submitReview(rating, note);
    closeModal()
  };


  render() {
    const { openModal, closeModal, spaceName, loggedUserRating, userName, isHost } = this.props;

    const { rating, note, nrOfChars } = this.state;
    let rateText = ''
    if (rating !== NO_RATING) {
      switch (rating) {
        case 0:
          rateText = "Poor!";
          break;
        case 1:
          rateText = "Needs Improvement.";
          break;
        case 2:
          rateText = "Fair.";
          break;
        case 3:
          rateText = "Great!";
          break;
        case 4:
          rateText = "Excellent!";
          break;
        default:
          rateText = "Excellent!";
          break;
      }
    }

    let modalTitle = spaceName;
    let message = (
      <div className="modal-description gray">Please rate your recent experience at <span
        style={{ fontWeight: 'bold' }}>{spaceName}</span> and write a review.
          </div>
    )

    if (isHost) {
      modalTitle = "Rate Renter";
      message = (
        <div className="modal-description gray">Please rate your recent experience with <span
          style={{ fontWeight: 'bold' }}>{userName}</span> and write a review.
          </div>
      )
    }



    return (
      <Modal
        isOpen={openModal}
        className="review-modal"
        onRequestClose={closeModal}
        ariaHideApp={false}
      >
        <div className="modal-title booking">{modalTitle}</div>
        <img src={require('../../../assets/images/ic_close.svg')} className="close-button booking-close-btn"
          alt=""
          onClick={closeModal}
        />
        <div className="flex-center direction-column">
          {message}
          <div className="rate-text flex-center">{rateText}</div>
          <div className="rate-starts-wrapper">
            {this.renderRating(rating)}
          </div>
          <div className="note-wrapper review">
            <div className="note-header">
              <div></div>
              <div className="nr-of-chars review">{nrOfChars}/1,000 characters</div>
            </div>
            <textarea
              name="note"
              placeholder="Write a review..."
              value={note || ''}
              className="review-textarea"
              maxLength="1000"
              spellCheck="false"
              disabled={rating === NO_RATING || loggedUserRating.rating}
              onChange={e => {
                this.handleInputChange(e)
                this.setState({ nrOfChars: e.target.value.length })
              }}>{this.state.message}</textarea>
          </div>
          <div className="flex-center" style={{ paddingTop: '30px' }}>
            <PrimaryButton
              text="Submit"
              className="footer-button booking orange-bg"
              onClick={this.submitReviewToSpace}
              disabled={note === '' || loggedUserRating.rating}
            />
          </div>
        </div>

      </Modal>
    );
  }
}

ReviewModal.propTypes = {};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewModal);

