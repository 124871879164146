import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux';

import { searchSpaceTypeAndLocation } from '../modules/search/actions/actions';
import { currentUser } from '../modules/auth/reducers/selectors';
import { Mixpanel } from '../util/MixPanel';
import { spaces } from '../configReduxSetup/reducers/selectors';

class Footer extends Component {
    state = {

    }

    companyInfo = [
        {
            name: "Global Locations",
            url: "/global"
        },
        {
            name: "Mission",
            url: "/mission"
        },
        {
            name: "Careers",
            url: "/careers"
        },
        {
            name: "FAQ",
            url: "/faq"
        },
        {
            name: "Blog",
            url: "https://deski.blog/"
        },
    ]

    policies = [
        {
            name: "Terms & Conditions",
            url: "/terms-of-use"
        },
        {
            name: "Privacy Policy",
            url: "/privacy-policy"
        },
    ]

    socialMedias = [
        {
            icon: "Facebook_social.svg",
            url: "https://www.facebook.com/deskiaustralia",
            name: "Facebook"
        },
        {
            icon: "Twitter_social.svg",
            url: "https://twitter.com/Deski50233800",
            name: "Twitter"
        },
        {
            icon: "Instagram_social.svg",
            url: "https://www.instagram.com/deskicompany/",
            name: "Instagram"
        },

    ]


    searchSpaces = async (type, name) => {
        const { searchSpaceTypeAndLocation } = this.props

        const query = `type=${type}`;
        await searchSpaceTypeAndLocation(query)
        Mixpanel.track(`Footer search - ${name}`)
    }

    setMixpanelMedias = (name) => {
        Mixpanel.track(`Clicked Connect with us - ${name}`)
    }

    setMixpanelPolicies = (name) => {
        Mixpanel.track(`Clicked ${name}`)
    }

    setMixpanelCompanyInfo = (name) => {
        Mixpanel.track(`Clicked ${name}`)
    }

    renderFirstColumn = () => {
        const { spaces } = this.props;

        let categories = Object.keys(spaces).map((type) => (
            <NavLink to={`/search/${spaces[type].url}`} onClick={() => this.searchSpaces(spaces[type].key, spaces[type].name)} key={spaces[type].key}>
                <div className="column-element" >
                    {spaces[type].name}
                </div>
            </NavLink>
        ))

        let company = Object.keys(this.companyInfo).map((info) => (
            this.companyInfo[info].name === "Blog" ?
                <a href={this.companyInfo[info].url} target="_blank" key={this.companyInfo[info].url} rel="noopener noreferrer" onClick={() => this.setMixpanelCompanyInfo(this.companyInfo[info].name)}>
                    <div
                        className="column-element"
                        key={this.companyInfo[info].name}
                        style={{ cursor: 'pointer' }}>
                        {this.companyInfo[info].name}
                    </div>
                </a>
                :
                <NavLink to={this.companyInfo[info].url} target="_blank" key={this.companyInfo[info].url} rel="noopener noreferrer" onClick={() => this.setMixpanelCompanyInfo(this.companyInfo[info].name)}>
                    <div
                        className="column-element"
                        key={this.companyInfo[info].name}
                        style={{ cursor: 'pointer' }}>
                        {this.companyInfo[info].name}
                    </div>
                </NavLink>
        ))

        return (
            <div className="footer-side">
                <div>
                    <div className="column-title">Browse Other Categories</div>
                    {categories}
                </div>
                <div>
                    <div className="column-title">Company</div>
                    {company}
                </div>
            </div>
        )

    }


    renderSecondColumn = () => {

        let policies = Object.keys(this.policies).map((type) => (
            <NavLink to={this.policies[type].url} target="_blank" key={this.policies[type].url} rel="noopener noreferrer" onClick={() => this.setMixpanelPolicies(this.policies[type].name)}>
                <div
                    className="column-element"
                    key={this.policies[type].name}
                    style={{ cursor: 'pointer' }}
                >
                    {this.policies[type].name}
                </div>
            </NavLink>
        ))

        let socialMedias = Object.keys(this.socialMedias).map((media) => (
            <a href={this.socialMedias[media].url} target="_blank" key={this.socialMedias[media].url} rel="noopener noreferrer" onClick={() => this.setMixpanelMedias(this.socialMedias[media].name)}>
                <img
                    src={require('../assets/images/' + this.socialMedias[media].icon)}
                    key={this.socialMedias[media].icon}
                    style={{ cursor: 'pointer' }}
                    alt=""
                />
            </a>
        ))

        return (
            <div className="footer-side">
                <div>
                    <div className="column-title">Legal</div>
                    {policies}
                </div>
                <div>
                    <div className="column-title no-display">Connect with us</div>
                    <div className="social-icons-wrapper">{socialMedias}</div>
                </div>
            </div>
        )

    }





    render() {
        let firstColumn = this.renderFirstColumn()
        let secondColumn = this.renderSecondColumn()
        return (
            <div className={`footer`}>
                <div className="footer-section">
                    <div className={`footer-first-column`}>
                        {firstColumn}
                    </div>
                    <div className={`footer-second-column`}>
                        {secondColumn}
                    </div>
                </div>
                <div className="footer-line"></div>

                <div className="copyright">
                    <img src={require('../assets/images/logo.svg')} className="copyright-logo" alt="" />
                    <span className="copyright-text">Copyright © 2019 Deski. All rights reserved</span>
                </div>
            </div>
        );
    }
}

Footer.propTypes = {
    searchSpaceTypeAndLocation: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    searchSpaceTypeAndLocation
};

const mapStateToProps = (state) => ({
    currentUser: currentUser(state),
    spaces: spaces(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);