import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { NavLink } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { capitalize } from "lodash-es";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import Geocode from "react-geocode";
import { currentUser } from "../../../modules/auth/reducers/selectors";
import history from "../../../history";
import { MapsApiKey, DateType } from "../../../settings";
import Loader from "react-loader-spinner";
import MetaTags from "react-meta-tags";
import { get } from "lodash-es";
import { Helmet } from "react-helmet";

import {
  getSpaceById,
  getBookingsPerSpace,
  getPricePerBooking
} from "../actions/actions";
import {
  getIndividualSpace,
  getBookings,
  getIsLoading,
  spaceError,
  getPrice,
  getBookedDates,
  getSearchQueries
} from "../reducers/selectors";
import {
  getSearchResults,
  getSearchResultsLoading
} from "../../search/reducers/selectors";
import {
  searchSpaces,
  searchSpaceTypeAndLocation
} from "../../search/actions/actions";
import {
  amenities,
  groupMeetingTypes,
  spaces
} from "../../../configReduxSetup/reducers/selectors";

import ResultCard from "../../../components/ResultCard";
import PrimaryButton from "../../../components/PrimaryButton";
import BookingModal from "../components/BookingModal";
import PaymentModal from "../components/PaymentModal";
import EditModal from "../components/EditModal";
import BookingSuccessful from "../components/BookingSuccessful";
import BookingUnsuccessful from "../components/BookingUnsuccessful";
import TimeTablePanel from "../components/TimeTablePanel";

import config from "../../../config/config";
import ReviewComponent from "../../../components/ReviewComponent";
import { Mixpanel } from "../../../util/MixPanel";

const capitalizeAddress = address =>
  address
    .split(" ")
    .map(word => capitalize(word))
    .join(" ");

class IndividualSpaceScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      thumbs: false,
      width: "50vw",
      isSecondColumnVisible: true,
      dateType: this.props.match.params.interval
    };
  }

  callGeocoder = address => {
    Geocode.setApiKey(`${MapsApiKey}&sensor=true`);
    Geocode.fromAddress(address).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({ lat, lng });
      },
      error => {
        console.error(error);
      }
    );
  };

  getSimilarSpaces = individualSpace => {
    const { searchSpaceTypeAndLocation } = this.props;
    let type = individualSpace.space.type;
    let location = individualSpace.space.location;
    let query = `location=${location}&type=${type}`;

    searchSpaceTypeAndLocation(query);
  };

  getSpaceById = spaceID => {
    const { getSpaceById } = this.props;

    getSpaceById(spaceID);
  };

  getTotalPrice = () => {
    const {
      startDate,
      endDate,
      startTime,
      endTime,
      spaceID
    } = this.props.match.params;
    const { getPricePerBooking } = this.props;
    const { dateType } = this.state;

    const query = `spaceId=${spaceID}&startDate=${startDate}&endDate=${endDate}&startTime=${startTime}&endTime=${endTime}&bookingInterval=${dateType}`;
    getPricePerBooking(query);
  };

  componentDidMount() {
    window.scroll(0, 0);

    const { spaceID, startDate, endDate, endTime } = this.props.match.params;
    const { getBookingsPerSpace } = this.props;

    this.getSpaceById(spaceID);

    getBookingsPerSpace(spaceID);

    if ((startDate || endDate) && endTime) {
      this.getTotalPrice();
    }
  }

  componentDidUpdate(prevProps) {
    const { spaceID } = this.props.match.params;
    const {
      getBookingsPerSpace,
      individualSpace,
      isLoading,
      spaceError
    } = this.props;

    if (prevProps.location !== this.props.location) {
      this.getSpaceById(spaceID);
      getBookingsPerSpace(spaceID);

      window.scrollTo(0, 0);
    }

    if (prevProps.isLoading === true && isLoading === false && !spaceError) {
      this.callGeocoder(
        `${individualSpace.space.address} ${individualSpace.space.location}`
      );
      this.getSimilarSpaces(individualSpace);
      this.selectDateDypeBasedOnSpaceProperties();
    }
  }

  selectDateDypeBasedOnSpaceProperties = () => {
    const { dateType } = this.state;
    const { individualSpace } = this.props;

    if (!dateType) {
      if (individualSpace.space.ratePerHour) {
        this.setState({ dateType: DateType.HOURLY });
      } else if (individualSpace.space.ratePerDay) {
        this.setState({ dateType: DateType.DAILY });
      } else if (individualSpace.space.ratePerWeek) {
        this.setState({ dateType: DateType.WEEKLY });
      } else if (individualSpace.space.ratePerMonth) {
        this.setState({ dateType: DateType.MONTHLY });
      }
    }
  };

  expandCarousel = () => {
    this.setState({
      thumbs: true,
      width: "80vw",
      isSecondColumnVisible: false
    });
  };

  contractCarousel = () => {
    this.setState({
      thumbs: false,
      width: "50vw",
      isSecondColumnVisible: true
    });
  };

  renderSimilarSpaces = device => {
    const { searchResults, individualSpace, loading } = this.props;
    const {
      searchResults: { regular }
    } = this.props;
    const length = 4;
    const { _id, type } = individualSpace.space;

    if (loading) {
      return (
        <div
          className={`${
            device === "desktop" ? "desktop-similar" : "mobile-similar"
          }`}
        >
          <Loader
            type="Ball-Triangle"
            height="100"
            width="100"
            color="#0EA800"
          />
        </div>
      );
    }

    return (
      <div
        className={`${
          device === "desktop" ? "desktop-similar" : "mobile-similar"
        }`}
      >
        <div className="results-category">SIMILAR SPACES</div>
        {searchResults.regular && regular.length ? (
          <div className="result-cards details-page-results similar ">
            {regular
              .filter(space => space._id !== _id)
              .slice(0, length)
              .map((space, index) => (
                <div className="card-wrapper" key={space._id}>
                  <NavLink
                    to={`/spaces/${space._id}/${space.name
                      .replace(/\W+(?!$)/g, "-")
                      .replace(/\W$/, "")
                      .toLowerCase()}/${encodeURIComponent(
                      space.location
                        .replace(/\W+(?!$)/g, "-")
                        .replace(/\W$/, "")
                        .toLowerCase()
                    )}/${encodeURIComponent(
                      space.address
                        .replace(/\W+(?!$)/g, "-")
                        .replace(/\W$/, "")
                        .toLowerCase()
                    )}`}
                    onClick={() => Mixpanel.track("Accessed Similar Spaces")}
                  >
                    <ResultCard
                      key={space._id}
                      type={type}
                      isVerified={space.verified}
                      spaceName={space.name}
                      nrOfReviewers={space.nrOfRaters}
                      rating={space.rating}
                      ratePerHour={space.ratePerHour}
                      ratePerDay={space.ratePerDay}
                      ratePerWeek={space.ratePerWeek}
                      ratePerMonth={space.ratePerMonth}
                      photoUrl={space.photosUrls[0].photoUrl}
                    />
                  </NavLink>
                </div>
              ))}
          </div>
        ) : (
          <div className="results-category">No similar spaces available.</div>
        )}
      </div>
    );
  };

  renderRating = rating => {
    const ratingArray = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        ratingArray.push(
          <div key={i} className={`rating-stars`}>
            <img
              src={require("../../../assets/images/ic_full_star.svg")}
              alt=""
            />
          </div>
        );
      } else {
        ratingArray.push(
          <div key={i} className={`rating-stars`}>
            <img
              src={require("../../../assets/images/ic_empty_star.svg")}
              alt=""
            />
          </div>
        );
      }
    }
    return ratingArray;
  };

  openModal = () => {
    this.setState({ bookingModalIsOpen: true });
    Mixpanel.track("Clicked Continue - Individual Space");
  };

  getSearchInfo = (startDate, endDate, startHour, endHour, guestsNumber) => {
    this.setState({
      startDate,
      endDate,
      startHour,
      endHour,
      guestsNumber
    });
  };

  onDone = (status, error) => {
    if (status === "successful") {
      this.setState({ successModalIsOpen: true, paymentModalIsOpen: false });
    } else if (status === "unsuccessful") {
      this.setState({
        unsuccessModalIsOpen: true,
        paymentModalIsOpen: false,
        bookingError:
          error.error.code === "Unknown"
            ? "Oops! Looks like something bad happened. Please try again."
            : error.error.msg
      });
    }
  };

  goToPayment = () => {
    const { currentUser } = this.props;

    if (!Object.keys(currentUser).length)
      history.push({
        pathname: "/login/fromSpace"
      });
    else if (!currentUser.email || !currentUser.phone)
      history.push({
        pathname: "/add-missing-info"
      });
    else this.setState({ bookingModalIsOpen: false, paymentModalIsOpen: true });
  };

  renderPricing = () => {
    const { startDate, endDate, startTime, endTime } = this.props.match.params;
    const { individualSpace, isPriceLoading, price } = this.props;

    if (typeof individualSpace.space === "undefined") {
      return <div>Loading</div>;
    }

    if (isPriceLoading) {
      return <div>Loading</div>;
    }

    let ratesArray = [
      individualSpace.space.ratePerHour,
      individualSpace.space.ratePerDay,
      individualSpace.space.ratePerWeek,
      individualSpace.space.ratePerMonth
    ];
    let ratesTitles = ["Hour", "Day", "Week", "Month"];
    let rateContent = [];

    ratesArray.forEach((rate, index) => {
      if (rate !== null && rate !== 0) {
        rateContent.push(
          <div className="price-wrapper" key={index}>
            <span className="price-text">
              Rate per <b>{ratesTitles[index]}</b>
            </span>
            <span className="price">${rate}</span>
          </div>
        );
      }
    });

    let content = <div className="flex-center">{rateContent.slice(0, 2)}</div>;

    if (price && startDate && endDate && startTime && endTime) {
      content = (
        <div className="price-wrapper">
          <span className="price-text"> Total price</span>
          <span className="price">${price.price.totalPrice}</span>
        </div>
      );
    }

    return content;
  };

  toggleIsCopiedVisible = () => {
    this.setState({ isCopiedVisible: true });
    setTimeout(() => {
      this.setState({ isCopiedVisible: false });
    }, 3000);
    Mixpanel.track("Shared space");
  };

  renderEditModal = () => {
    const { editModalIsOpen } = this.state;

    return (
      <EditModal
        modalIsOpen={editModalIsOpen}
        closeModal={() =>
          this.setState({ editModalIsOpen: false, paymentModalIsOpen: true })
        }
        name="Edit Payment Details"
      />
    );
  };

  renderBookingModal = () => {
    const { dateType } = this.state;
    const { individualSpace, bookedDates } = this.props;

    const {
      startDate,
      endDate,
      startTime,
      endTime,
      nrOfGuests,
      spaceID,
      spaceName
    } = this.props.match.params;
    const startDateNoYear = new Date(startDate * 1000);
    const endDateNoYear = new Date(endDate * 1000);

    const { bookingModalIsOpen } = this.state;

    return (
      <BookingModal
        modalIsOpen={bookingModalIsOpen}
        name={individualSpace.space.name}
        closeModal={() => this.setState({ bookingModalIsOpen: false })}
        startDate={startDateNoYear}
        endDate={endDateNoYear}
        startDateUnix={startDate}
        endDateUnix={endDate}
        startHour={startTime}
        endHour={endTime}
        guestsNumber={nrOfGuests}
        onDone={this.goToPayment}
        getSearchInfo={this.getSearchInfo}
        spaceId={spaceID}
        spaceName={spaceName}
        bookedDates={bookedDates}
        onChangeDateType={this.onChangeDateType}
        dateType={dateType}
        nrOfPax={individualSpace.space.nrOfPax}
      />
    );
  };

  renderPaymentModal = () => {
    const { spaceID } = this.props.match.params;
    const { paymentModalIsOpen, dateType } = this.state;

    return (
      <PaymentModal
        modalIsOpen={paymentModalIsOpen}
        closeModal={() => this.setState({ paymentModalIsOpen: false })}
        name="Payment Details"
        onEdit={() =>
          this.setState({ paymentModalIsOpen: false, editModalIsOpen: true })
        }
        onDone={this.onDone}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        startHour={this.state.startHour}
        endHour={this.state.endHour}
        guestsNumber={this.state.guestsNumber}
        spaceID={spaceID}
        dateType={dateType}
      />
    );
  };

  renderBookingSuccess = () => {
    const { successModalIsOpen } = this.state;

    return (
      <BookingSuccessful
        modalIsOpen={successModalIsOpen}
        name="Booking Successful"
        closeModal={() => history.push("/")}
        onDone={() => history.push("/bookings?page=1&type=active")}
      />
    );
  };

  renderBookingUnSuccessful = () => {
    const { unsuccessModalIsOpen, bookingError } = this.state;

    return (
      <BookingUnsuccessful
        modalIsOpen={unsuccessModalIsOpen}
        name="Booking Unsuccessful"
        closeModal={() => this.setState({ unsuccessModalIsOpen: false })}
        onDone={() => this.setState({ unsuccessModalIsOpen: false })}
        error={bookingError}
      />
    );
  };

  onChangeDateType = newDateType => {
    this.setState({ dateType: newDateType });
  };

  setMixpanelCarousel = () => {
    Mixpanel.track("Browsed space pictured");
  };

  render() {
    const {
      individualSpace,
      isLoading,
      amenities,
      groupMeetingTypes,
      spaces,
      spaceError
    } = this.props;

    if (isLoading || typeof individualSpace.space === "undefined") {
      if (spaceError && !isLoading)
        return (
          <div className="loader-wrapper modal-title">{spaceError.msg}</div>
        );
      else
        return (
          <div className="loader-wrapper">
            <Loader
              type="Ball-Triangle"
              height="100"
              width="100"
              color="#0EA800"
            />
          </div>
        );
    }

    const {
      thumbs,
      width,
      paymentModalIsOpen,
      bookingModalIsOpen,
      editModalIsOpen,
      successModalIsOpen,
      unsuccessModalIsOpen,
      isSecondColumnVisible
    } = this.state;
    const MyMapComponent = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          defaultZoom={16}
          defaultCenter={{ lat: this.state.lat, lng: this.state.lng }}
        >
          {props.isMarkerShown && (
            <Marker position={{ lat: this.state.lat, lng: this.state.lng }} />
          )}
        </GoogleMap>
      ))
    );

    let modal = null;

    if (bookingModalIsOpen) {
      modal = this.renderBookingModal();
    } else if (paymentModalIsOpen) {
      modal = this.renderPaymentModal();
    } else if (editModalIsOpen) {
      modal = this.renderEditModal();
    } else if (successModalIsOpen) {
      modal = this.renderBookingSuccess();
    } else if (unsuccessModalIsOpen) {
      modal = this.renderBookingUnSuccessful();
    }

    let pricingContent = this.renderPricing();

    let photosContent = [];

    if (
      individualSpace &&
      individualSpace.space &&
      individualSpace.space.photosUrls.length > 0
    ) {
      photosContent = individualSpace.space.photosUrls.map(photo => (
        <div key={photo}>
          <img
            src={
              photo.photoUrl
                ? `${config.api}/files/${photo.photoUrl}`
                : `/imgs/alesia-kazantceva-283288-unsplash.png`
            }
            alt=""
          />
        </div>
      ));
    }

    let copied = null;
    if (this.state.isCopiedVisible) {
      copied = (
        <span className="copied-text info-text">Copied to clipboard!</span>
      );
    }

    let owner = null;
    let hostAccount = null;
    let price = null;

    if (
      individualSpace &&
      Object.keys(individualSpace).length &&
      individualSpace.space
    ) {
      price =
        individualSpace.space.ratePerHour ||
        individualSpace.space.ratePerDay ||
        individualSpace.space.ratePerWeek ||
        individualSpace.space.ratePerMonth;
      owner = individualSpace.space.ownerId;
      hostAccount = individualSpace.space.ownerId.hostAccount;
    }
    let profilePic = null;

    if (
      hostAccount &&
      hostAccount.generalInfo &&
      hostAccount.generalInfo.photoUrl
    ) {
      profilePic = `${config.api}/files/${hostAccount.generalInfo.photoUrl}`;
    } else {
      let profileSrcSocialMedia =
        individualSpace &&
        Object.keys(individualSpace).length &&
        individualSpace.space &&
        Object.keys(individualSpace.space).length &&
        owner &&
        Object.keys(owner).length &&
        owner.socialLogin &&
        Object.keys(owner.socialLogin).length &&
        owner.profilePictureUrl
          ? owner.profilePictureUrl
          : "";

      let profileSrc =
        individualSpace &&
        Object.keys(individualSpace).length &&
        individualSpace.space &&
        Object.keys(individualSpace.space).length &&
        owner &&
        Object.keys(owner).length &&
        !owner.socialLogin &&
        owner.profilePictureUrl
          ? `${config.api}/files/${owner.profilePictureUrl}`
          : "";

      profilePic = profileSrcSocialMedia
        ? profileSrcSocialMedia
        : profileSrc
        ? profileSrc
        : require("../../../assets/images/placeholder_profile.svg");
    }

    let posterName = `${owner.firstName} ${owner.lastName}`;

    if (
      hostAccount &&
      hostAccount.companyDetails &&
      hostAccount.companyDetails.companyName
    ) {
      posterName = `${hostAccount.companyDetails.companyName}`;
    }

    let meetingSpaceTypes = null;

    if (
      Object.keys(individualSpace).length &&
      individualSpace.space.type === "groupMeeting" &&
      individualSpace.space.amenitiesType
    ) {
      meetingSpaceTypes = (
        <div className="space-features">
          <div className="results-header individual-amenitie">TYPE</div>
          <ul>
            {Object.keys(individualSpace.space.amenitiesType).map(amenitie => (
              // CHECK WITH BACKEND (TRUE FALSE ISSUE)
              <li key={amenitie} className="amenitie-text">
                {individualSpace.space.amenitiesType[amenitie] === true &&
                  get(
                    groupMeetingTypes.find(
                      groupAmenitie => groupAmenitie.key === amenitie
                    ),
                    "name"
                  )}
              </li>
            ))}
          </ul>
        </div>
      );
    }
    let officeType = spaces.find(
      space => space.key === individualSpace.space.type
    );

    return (
      <div className="page-template">
        <Helmet>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        {Object.keys(individualSpace).length ? (
          <div>
            {modal}
            <MetaTags>
              <title>{`Rent ${individualSpace.space.name} From $${String(
                price
              ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} | ${capitalizeAddress(
                individualSpace.space.address
              )}`}</title>
              <meta
                name="description"
                content={`Book ${
                  individualSpace.space.name
                } on Deski on a flexible basis. Located at ${capitalizeAddress(
                  individualSpace.space.address
                )}`}
              />
            </MetaTags>
            <div className="page-wrapper individual-space">
              <div className="individual-content">
                {thumbs && (
                  <img
                    src={require("../../../assets/images/ic_close.svg")}
                    className="close-button big-button"
                    alt=""
                    onClick={this.contractCarousel}
                  />
                )}
                <Carousel
                  showArrows={
                    !bookingModalIsOpen &&
                    !paymentModalIsOpen &&
                    !editModalIsOpen &&
                    !successModalIsOpen &&
                    !unsuccessModalIsOpen
                  }
                  className="carousel-wrapper"
                  width={width}
                  showThumbs={thumbs}
                  showStatus={!thumbs}
                  onClickItem={this.expandCarousel}
                  swipeable={true}
                  emulateTouch={true}
                  onChange={() => this.setMixpanelCarousel()}
                >
                  {photosContent}
                </Carousel>
                <div className="content-top-wrapper">
                  <NavLink to={`/poster/${owner._id}?page=0`}>
                    <div className="poster-details">
                      <div
                        className="small-img-poster"
                        style={{
                          backgroundImage: `url(${profilePic})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center center"
                        }}
                      ></div>
                      <div className="text-details-wrapper">
                        <div
                          className="poster-name"
                          onClick={() =>
                            Mixpanel.track("Accessed spaces by poster", {
                              posterName
                            })
                          }
                        >
                          {posterName}
                        </div>
                        <div
                          className="nr-of-spaces"
                          style={{ cursor: "pointer" }}
                        >
                          {individualSpace.spacesCount} spaces
                        </div>
                      </div>
                    </div>
                  </NavLink>

                  <div
                    className={`${
                      individualSpace.space.verified
                        ? "verified-wrapper"
                        : "hidden"
                    }`}
                  >
                    <div className="verified">verified</div>
                  </div>
                </div>
                <div className="description-wrapper">
                  <div className="breadcrumbs text-link no-underline">
                    <NavLink
                      to={`/search/${officeType.url}`}
                      onClick={() =>
                        Mixpanel.track("Breadcrumb", { type: "space type" })
                      }
                    >
                      <span className="text-link">
                        {
                          spaces.find(
                            space => space.key === individualSpace.space.type
                          ).name
                        }
                      </span>
                    </NavLink>
                    &nbsp;>&nbsp;
                    <NavLink
                      to={`/search/${
                        officeType.url
                      }/${individualSpace.space.location
                        .replace(/\W+(?!$)/g, "-")
                        .replace(/\W$/, "")
                        .toLowerCase()}`}
                      onClick={() =>
                        Mixpanel.track("Breadcrumb", { type: "location" })
                      }
                    >
                      <span className="text-link">
                        {individualSpace.space.location}
                      </span>
                    </NavLink>
                    &nbsp;>&nbsp;
                    <span className="text-link no-link no-underline">
                      {individualSpace.space.name}
                    </span>
                  </div>
                  <h1 className="space-name">{individualSpace.space.name}</h1>
                  {individualSpace.space.type !== "desk" && (
                    <div className="max-guests">
                      {" "}
                      Max no. of Pax - <b>{individualSpace.space.nrOfPax}</b>
                    </div>
                  )}
                  <div className="space-description">
                    <ShowMoreText
                      lines={5}
                      more={
                        <span className="max-guests">
                          <b>Show more</b>
                        </span>
                      }
                      less={
                        <span className="max-guests">
                          <b>Show less</b>
                        </span>
                      }
                    >
                      <div className="flex-center">
                        {individualSpace.space.description}
                      </div>
                    </ShowMoreText>
                  </div>
                  {meetingSpaceTypes}
                  {individualSpace.space.amenities &&
                    Object.keys(individualSpace.space.amenities).length && (
                      <div className="space-features">
                        <div className="results-header individual-amenitie">
                          AMENITIES
                        </div>
                        <ul>
                          {Object.keys(individualSpace.space.amenities).map(
                            amenitie => (
                              <li key={amenitie} className="amenitie-text">
                                {individualSpace.space.amenities[amenitie] ===
                                  true &&
                                  get(
                                    amenities.find(
                                      singleAmenitie =>
                                        singleAmenitie.key === amenitie
                                    ),
                                    "name"
                                  )}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                </div>
                {this.renderSimilarSpaces("desktop")}
              </div>
              {isSecondColumnVisible && (
                <div className="individual-sidebar flex-center direction-column">
                  <div className="map-wrapper flex-center direction-column">
                    <div className="address-wrapper">
                      <img
                        src={require("../../../assets/images/ic_location.svg")}
                        alt=""
                      />
                      &nbsp;&nbsp;
                      {individualSpace.space.address}
                    </div>
                    <MyMapComponent
                      isMarkerShown
                      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBOKfqQ4jZtuoQX_dCocGiThiN9tGfbTeE&v=3.exp&libraries=geometry,drawing,places"
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div className="map" />}
                      // containerElement={<div style={{ height: `300px`, width: `25vw` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                    />
                  </div>
                  <div className="hours-title">AVAILABILITY HOURS</div>

                  <div className="hours-wrapper">
                    <TimeTablePanel individualSpace={individualSpace} />
                  </div>

                  <ReviewComponent
                    title="REVIEWS FROM OTHERS"
                    rating={individualSpace.space.rating}
                    ratings={individualSpace.space.ratings}
                    nrOfRaters={individualSpace.space.nrOfRaters}
                  />
                </div>
              )}
              {this.renderSimilarSpaces("phone")}
            </div>
            <div className="space-footer">
              <div className="section-wrapper sticky-details">
                <div className="left-side-footer">
                  <div className="col">
                    <div className="card-result-name indiv-space">
                      {individualSpace.space.name}
                    </div>
                    <div className="rating-wrapper">
                      {this.renderRating(individualSpace.space.rating)}
                      <span className="review-number">
                        {individualSpace.space.nrOfRaters}
                      </span>
                    </div>
                  </div>
                  <div className="col">{pricingContent}</div>
                </div>
                <div className="right-side-footer">
                  <div className="rating-wrapper mobile-only">
                    {this.renderRating(individualSpace.space.rating)}
                    <span className="review-number">
                      {individualSpace.space.nrOfRaters}
                    </span>
                  </div>
                  <PrimaryButton
                    text="Continue"
                    className="footer-button"
                    onClick={this.openModal}
                  />
                  <CopyToClipboard text={window.location.href}>
                    <div
                      className="share-button flex-center"
                      onClick={() => this.toggleIsCopiedVisible()}
                    >
                      <img
                        src={require("../../../assets/images/ic_share.svg")}
                        alt=""
                      />
                    </div>
                  </CopyToClipboard>
                  {copied}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

IndividualSpaceScreen.propTypes = {
  searchSpaces: PropTypes.func.isRequired,
  searchSpaceTypeAndLocation: PropTypes.func.isRequired,
  getSpaceById: PropTypes.func.isRequired,
  getBookingsPerSpace: PropTypes.func.isRequired,
  getPricePerBooking: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  searchSpaces,
  searchSpaceTypeAndLocation,
  getSpaceById,
  getBookingsPerSpace,
  getPricePerBooking
};

const mapStateToProps = state => ({
  searchResults: getSearchResults(state),
  individualSpace: getIndividualSpace(state),
  bookings: getBookings(state),
  bookedDates: getBookedDates(state),
  isLoading: getIsLoading(state),
  spaceError: spaceError(state),
  currentUser: currentUser(state),
  price: getPrice(state),
  loading: getSearchResultsLoading(state),
  searchQueries: getSearchQueries(state),
  amenities: amenities(state),
  groupMeetingTypes: groupMeetingTypes(state),
  spaces: spaces(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndividualSpaceScreen);
