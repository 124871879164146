import { SET_SEARCH_RESULTS, FETCH_INDIVIDUAL_SPACE, SET_INDIVIDUAL_SPACE, SET_INDIVIDUAL_SPACE_FAIL, SET_BOOKINGS_PER_SPACE, GET_BOOKING_RESPONSE, FETCH_PRICE_SUCCESS, FETCH_PRICE, RESET_PRICE, VALIDATE_BOOKING, VALIDATE_BOOKING_FAIL, VALIDATE_BOOKING_SUCCESS } from './actionTypes';

import { Search, Booking } from '../../../api/endpoints';

export function setSearchResults(payload) {
  return {
    type: SET_SEARCH_RESULTS,
    payload,
  };
}

export function fetchIndividualSpace(payload) {
  return {
    type: FETCH_INDIVIDUAL_SPACE,
    payload,
  };
}

export function fetchPrice(payload) {
  return {
    type: FETCH_PRICE,
    payload,
  };
}

export function setIndividualSpace(payload) {
  return {
    type: SET_INDIVIDUAL_SPACE,
    payload,
  };
}

export function setIndividualSpaceFail(payload) {
  return {
    type: SET_INDIVIDUAL_SPACE_FAIL,
    payload,
  };
}

export function setBookingsPerSpace(payload) {
  return {
    type: SET_BOOKINGS_PER_SPACE,
    payload,
  };
}

export function getBookingResponse(payload) {
  return {
    type: GET_BOOKING_RESPONSE,
    payload,
  };
}
export function fetchPriceSuccess(payload) {
  return {
    type: FETCH_PRICE_SUCCESS,
    payload,
  };
}

export function resetPrice(payload) {
  return {
    type: RESET_PRICE,
    payload,
  };
}

export function validateBookingDates(payload) {
  return {
    type: VALIDATE_BOOKING,
    payload,
  };
}

export function validateBookingDatesSuccess(payload) {
  return {
    type: VALIDATE_BOOKING_SUCCESS,
    payload,
  };
}

export function validateBookingFail(payload) {
  return {
    type: VALIDATE_BOOKING_FAIL,
    payload,
  };
}

export function searchSpaces(payload) {
  return async (dispatch) => {
    const response = await Search.searchSpaces(payload);

    dispatch(setSearchResults(response));
  };
}

export function searchSpaceTypeAndLocation(payload) {
  return async (dispatch) => {
    const response = await Search.searchSpaceTypeAndLocation(payload);
    dispatch(setSearchResults(response));
  };
}

export function getSpaceById(payload) {
  return async (dispatch) => {
    dispatch(fetchIndividualSpace(payload));
    try {
      const response = await Search.getIndividualSpace(payload);
      dispatch(setIndividualSpace(response));
    }
    catch (e) {
      dispatch(setIndividualSpaceFail(e));
    }
  };
}

export function validateBooking(payload) {
  return async (dispatch) => {
    dispatch(validateBookingDates(payload))
    try {
      const response = await Booking.validateBooking(payload);
      dispatch(validateBookingDatesSuccess(response))
    }
    catch (e) {
      dispatch(validateBookingFail(e));
    }
  };
}

export function getBookingsPerSpace(payload) {
  return async (dispatch) => {
    const response = await Booking.getBookings(payload);
    dispatch(setBookingsPerSpace(response));
  };
}

export function createBooking(payload) {
  return async (dispatch) => {
    const response = await Booking.createBooking(payload);
    dispatch(getBookingResponse(response))
  };
}

export function getPricePerBooking(payload) {
  return async (dispatch) => {
    dispatch(fetchPrice(payload));

    const response = await Booking.getPricePerBooking(payload);

    dispatch(fetchPriceSuccess(response));
  };
}