import configureStoreProd from './configureStore.prod';
import configureStoreDev from './configureStore.dev';

export default function (initialState) {
  if (process.env.NODE_ENV === 'production') {
    return configureStoreProd(initialState);
  }

  return configureStoreDev(initialState);
}
