import React, { Component } from 'react';
import BookingsResults from './BookingsResults';


class PosterBookings extends Component {

    render() {
        const { response, page, type, bookingsPerPage } = this.props;
        const { bookings } = response.response;
        let content = (
            <div className="no-bookings">No bookings received</div>
        )
        if (Object.keys(bookings).length) {
            content = (
                <BookingsResults
                    bookingsPerUser={response.response}
                    elementsPerPage={bookingsPerPage}
                    page={page}
                    type={type}
                />
            )
        }
        return (
            <div>
                {content}
            </div>
        )
    }
}


export default (PosterBookings);
