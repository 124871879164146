import React, { Component } from 'react';
import Picker from '../../../components/Picker';
import moment from 'moment';
import { TimeLimits } from '../../../settings';


export default class BookingTimePicker extends Component {
    state = {
        startHour: this.props.startHour || null,
        endHour: this.props.endHour || null,
        isStartVisible: true,
        isEndVisible: false,
        allDay: false,
        hoveredItem: null
    }
    componentWillMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.onClose();
        }
    }

    selectHour = (h) => {
        const { allDay, startHour } = this.state
        if (allDay)
            this.setState({
                allDay: false,
                startHour: null,
                endHour: null,
                isStartVisible: true
            })
        setTimeout(() => {
            if (startHour === null || this.state.isStartVisible === true)
                this.setState({ startHour: h, endHour: null, isStartVisible: false, isEndVisible: true })

            else {
                if (h > startHour)
                    this.setState({ endHour: h + 1, isStartVisible: false, isEndVisible: true })
                if (h < startHour)
                    this.setState({ startHour: h })
            }
        }, 0);

    }

    isHourBlocked = (hour, nextBookedHourIndex) => {
        const { selectedDate, bookedDates, bookingHours } = this.props;

        if (!selectedDate) {
            return true;
        }

        let isToday = false;

        if (selectedDate && moment().isSame(selectedDate, 'day')) {
            isToday = true;
        }

        if (isToday && hour < moment().hour()) {
            return true;
        }

        let selectedDateFormatted = selectedDate.format('YYYY-MM-DD');
        let bookedHours = bookedDates.get(selectedDateFormatted);

        let dayOfWeekNo = selectedDate.isoWeekday();
        let selectedDateBookingHours = bookingHours[dayOfWeekNo];

        if ((bookedHours && bookedHours.includes(hour)) || hour < selectedDateBookingHours.startTime || hour > selectedDateBookingHours.endTime - 1) {
            return true;
        }

        if (nextBookedHourIndex > -1 && hour > bookedHours[nextBookedHourIndex]) {
            return true;
        }

        return false;
    }

    renderHour = (hour, nextBookedHourIndex) => {
        const { allDay, startHour, endHour, hoveredItem } = this.state;

        let hourText = hour <= 12 ? hour : hour - 12;
        let hourFormat = "AM";
        if (hour <= 12) {
            if (hour === 12)
                hourFormat = "PM";
            else
                hourFormat = "AM";
        }
        else {
            hourFormat = "PM";
        }
        let hourClassName = "hour-wrapper flex-center ";

        if ((hour === startHour || hour === endHour - 1) || (hour === startHour && allDay === false) || (hour === endHour - 1 && allDay === false)) {
            hourClassName += "isHourSelected ";
        }

        if (hour > startHour && hour < endHour - 1) {
            hourClassName += "isHourInterval ";
        }

        if (startHour !== null && (hour > startHour && hoveredItem >= hour)) {
            hourClassName += "isInHourInterval ";
        }
        
        if (this.isHourBlocked(hour, nextBookedHourIndex)) {
            hourClassName += "blockedHour ";

            return (
                <div key={hour} className={hourClassName} >
                    <div className={`hour ${(hour === startHour || hour === endHour - 1) ? 'isHourSelectedText' : ''} ${(hour > startHour && hour < endHour - 1) ? 'isHourSelectedText' : ''} ${(hour === startHour && allDay === false) ? 'isHourSelectedText' : ''} ${(hour === endHour - 1 && allDay === false) ? 'isHourSelectedText' : ''}`}>
                        <span className="bold-hour"> {hourText} </span>
                        {hourFormat}
                    </div>
                </div>
            );

        }

        return (
            <div key={hour} className={hourClassName}
                onClick={() => this.selectHour(hour)}
                onMouseOver={() => (hour > startHour && startHour !== null) && this.setState({ hoveredItem: hour })}
                onMouseOut={() => this.setState({ hoveredItem: null })}
            >
                <div className={`hour ${(hour === startHour || hour === endHour - 1) ? 'isHourSelectedText' : ''} ${(hour > startHour && hour < endHour - 1) ? 'isHourSelectedText' : ''} ${(hour === startHour && allDay === false) ? 'isHourSelectedText' : ''} ${(hour === endHour - 1 && allDay === false) ? 'isHourSelectedText' : ''}`}>
                    <span className="bold-hour"> {hourText} </span>
                    {hourFormat}
                </div>
            </div>
        );
    }

    renderHours = () => {
        let hoursContent = []
        let hourContent = null;
        let nextBookedHourIndex = this.getNextBookedHourIndex();

        for (let i = 7; i <= 22; i++) {
            hourContent = this.renderHour(i, nextBookedHourIndex);

            hoursContent.push(hourContent);
        }

        return hoursContent;
    }

    getNextBookedHourIndex = () => {
        const { selectedDate, bookedDates } = this.props;
        const { startHour } = this.state;

        let nextBookedHourIndex = -1;

        if (!selectedDate || !startHour) {

            return nextBookedHourIndex;
        }

        let selectedDateFormatted = selectedDate.format('YYYY-MM-DD');
        let bookedHours = bookedDates.get(selectedDateFormatted);

        if (bookedHours) {
            let i = 0;

            while (bookedHours[i] < startHour) {
                i++;
            }

            nextBookedHourIndex = i;
        }

        return nextBookedHourIndex;
    }

    onDone = () => {
        const { getHours, onDone } = this.props;
        const { startHour, endHour } = this.state;
        if (startHour) {
            getHours(startHour, endHour);
        }
        onDone();
    }

    clearTimePicker = () => {
        const { getHours } = this.props;

        this.setState({
            startHour: null,
            endHour: null,
            isStartVisible: true,
            isEndVisible: false,
            allDay: false
        })

        getHours(null, null);
    }

    render() {

        const { allDay, isStartVisible, isEndVisible, startHour, endHour } = this.state;
        const { selectedDate, bookedDates, bookingHours } = this.props;

        let selectedDateFormatted = null;
        let bookedHours = null;
        let dayOfWeekNo = null;
        let selectedDateBookingHours = null;
        let isAllDayBlocked = false;

        if (selectedDate) {
            selectedDateFormatted = selectedDate.format('YYYY-MM-DD');
            bookedHours = bookedDates.get(selectedDateFormatted);
            dayOfWeekNo = selectedDate.isoWeekday();
            selectedDateBookingHours = bookingHours[dayOfWeekNo];

            if (bookedHours && bookedHours.length) {
                isAllDayBlocked = true;
            }

            if (selectedDateBookingHours.startTime !== 7 || selectedDateBookingHours.endTime !== 23) {
                isAllDayBlocked = true;
            }
        }

        let endTimeText = "End Time";

        if (startHour && !endHour) {
            endTimeText = moment(startHour + 1, ["h"]).format('h:mm A')
        }
        else if (startHour && endHour) {
            endTimeText = moment(endHour, ["h"]).format('h:mm A');
        }



        return (
            <div ref={this.setWrapperRef}>
                <div className="time-picker picker-margin">
                    <div className="time-picker-header">
                        <div onClick={this.props.onClose}>
                            <img src={require('../../../assets/images/ic_close.svg')} className="close-timepicker" alt="" />
                        </div>
                        <div className="clear-timepicker" onClick={() => this.clearTimePicker()}>Clear</div>
                    </div>
                    <button disabled={isAllDayBlocked} className={`all-day flex-center ${(allDay || (this.state.startHour === TimeLimits.MIN_HOUR && this.state.endHour === TimeLimits.MAX_HOUR)) ? 'selected-all-day' : ''}`} onClick={() => this.setState({ allDay: true, startHour: TimeLimits.MIN_HOUR, endHour: TimeLimits.MAX_HOUR })}>
                        <span className={`all-day-text  ${(allDay || (this.state.startHour === TimeLimits.MIN_HOUR && this.state.endHour === TimeLimits.MAX_HOUR)) ? 'selected-all-day-text' : ''}`}>All day</span>
                    </button>
                    <div>
                        <div className="intervals-header">
                            <span className="from-to-timepicker">FROM </span>
                            <span className="from-to-timepicker">TO </span>
                        </div>
                        <div>
                            <div className="headers-wrapper">
                                {isStartVisible === false ?
                                    <Picker
                                        className="time-selector"
                                        openIcon
                                        placeholder="Start Time"
                                        text={this.state.startHour ? moment(this.state.startHour, ["h"]).format('h:mm A') : 'Start Time'}
                                        placeholderStyle="timepicker-placeholder"
                                        iconStyle="timepicker-open-icon"
                                        onClick={() => this.setState({ isStartVisible: true, isEndVisible: false })}
                                    />
                                    :
                                    <div className="time-table-header">
                                        <div className="time-header-wrapper">
                                            <span className="timepicker-text">{`${this.state.startHour ? moment(this.state.startHour, ["h"]).format('h:mm A') : 'Start Time'}`}</span>
                                            <img
                                                className="open-icon-reversed"
                                                src={require('../../../assets/images/ic_arrow_down.svg')}
                                                alt=""
                                                onClick={() => this.setState({ isStartVisible: false, isEndVisible: true })}
                                            />
                                        </div>
                                    </div>
                                }
                                {isEndVisible === false ?
                                    <Picker
                                        className="time-selector"
                                        openIcon
                                        placeholder="End Time"
                                        text={endTimeText}
                                        placeholderStyle="timepicker-placeholder"
                                        iconStyle="timepicker-open-icon"
                                        onClick={() => this.setState({ isEndVisible: true, isStartVisible: false })}
                                    />
                                    :
                                    <div className="time-table-header">
                                        <div className="time-header-wrapper">
                                            <span className="timepicker-text">{endTimeText}</span>
                                            <img
                                                className="open-icon-reversed"
                                                src={require('../../../assets/images/ic_arrow_down.svg')}
                                                alt=""
                                                onClick={() => this.setState({ isEndVisible: false, isStartVisible: true })}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="time-table flex-center wrap">
                                {this.renderHours()}
                            </div>
                        </div>
                    </div>
                    <div className="calendar-done-button flex-center time-done-button"
                        onClick={this.onDone}>Done</div>
                </div>
            </div>

        )
    }
}
