import React, { Component } from 'react';
import { connect } from 'react-redux';

import config from '../config/config';
import { spaces } from '../configReduxSetup/reducers/selectors';


class ResultCard extends Component {
    state = {}

    renderRating = (rating) => {
        const ratingArray = []
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                ratingArray.push(
                    <div key={i} className={`rating-stars`}>
                        <img src={require('../assets/images/ic_full_star.svg')} alt="" />
                    </div>
                )
            }
            else {
                ratingArray.push(
                    <div key={i} className={`rating-stars`}>
                        <img src={require('../assets/images/ic_empty_star.svg')} alt="" />
                    </div>
                )
            }
        }

        return ratingArray;
    }

    truncate = (s) => (
        (s.length > 20) ? s.substring(0, 20) + '...' : s
    )

    render() {
        const { type, onClick, isVerified, spaceName, rating, nrOfReviewers, distance, price, ratePerHour, ratePerDay, ratePerWeek, ratePerMonth, isBookingCard, isCanceled, startDate, endDate, startTime, endTime, posterName, posterPic, photoUrl } = this.props;
        const { spaces } = this.props;

        let sum = null;
        if (ratePerHour) {
            sum = (<div className="price-wrapper">
                <span className="price-text">rate per hour</span>
                <span className="price">${ratePerHour}</span>
            </div>)
        }
        else if (ratePerDay) {
            sum = (<div className="price-wrapper">
                <span className="price-text">rate per day</span>
                <span className="price">${ratePerDay}</span>
            </div>)
        }
        else if (ratePerWeek) {
            sum = (<div className="price-wrapper">
                <span className="price-text">rate per week</span>
                <span className="price">${ratePerWeek}</span>
            </div>)
        }
        else if (ratePerMonth) {
            sum = (<div className="price-wrapper">
                <span className="price-text">rate per month</span>
                <span className="price">${ratePerMonth}</span>
            </div>)
        }

        if (price) {
            sum = (
                <div className="price-wrapper">
                    <span className="price-text">total price</span>
                    <span className="price">${price}</span>
                </div>
            )
        }

        let status = null;
        if (isCanceled === true) {
            status = (
                <div className="card-dr">
                    <div className={`verified-wrapper`}>
                        <div className="canceled-dot"></div>
                        <div className="canceled">Cancelled</div>
                    </div>
                </div>
            )
        }

        let distanceFromLocation = null;

        if (distance && distance !== 0) {
            distanceFromLocation = (

                <div className="distance-wrapper">
                    <b>{distance} km </b>
                    from location
                </div>
            )
        }
        let content = (
            <div>
                <div className="card-description">
                    <div className="card-dl">
                        <div className={`${isVerified ? 'verified-wrapper' : 'hidden'}`}>
                            <div className="verified">verified</div>
                        </div>
                        <div className="card-result-name">
                            {spaceName}
                        </div>
                        <div className="rating-wrapper">
                            {this.renderRating(rating)}
                            <span className="review-number">{nrOfReviewers}</span>
                        </div>
                        {distanceFromLocation}

                    </div>
                    <div className="card-dr">
                        {sum}
                        {/* <div className="distance-wrapper">
                            only <b>{spacesLeft}</b> {spacesLeft > 1 ? 'spaces' : 'space'} left!
                </div> */}
                    </div>
                </div>
            </div>)

        let dateSection = (
            <div className="card-section">
                <img src={require('../assets/images/ic_calendar.svg')} style={{ marginRight: '5px' }} alt="" />
                <span className="booking-card-text">{startDate}</span> - <span className="booking-card-text">{endDate}</span>
            </div>
        )

        let timeSection = null;

        if (startDate === endDate) {
            dateSection = (
                <div className="card-section">
                    <img src={require('../assets/images/ic_calendar.svg')} style={{ marginRight: '5px' }} alt="" />
                    <span className="booking-card-text">{startDate}</span>
                </div>
            )

            timeSection = (
                <div className="card-section">
                    <img src={require('../assets/images/ic_time.svg')} style={{ marginRight: '5px' }} alt="" />
                    <span className="booking-card-text">{startTime}</span> - <span className="booking-card-text">{endTime}</span>
                </div>
            )
        }

        if (isBookingCard) {
            content = (
                <div className="card-description booking-card">
                    <div className="headers-wrapper">
                        <div className="card-result-name booking-card">
                            {spaceName}
                        </div>
                        {status}
                    </div>
                    <div className="flex-center">
                        <div className="separation-line booking-card"></div>
                    </div>
                    <div className="booking-date-time">
                        {dateSection}
                        {timeSection}
                    </div>
                    <div className="flex-center">
                        <div className="separation-line booking-card"></div>
                    </div>
                    <div className="poster-details-booking card-section">
                        <div
                            className="small-img-poster"
                            style={{
                                backgroundImage: `url(${posterPic})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center'
                            }}>
                        </div>
                        <div className="text-details-wrapper">
                            <div className="poster-name">
                                {posterName}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }


        return (
            <div className={`card`} onClick={onClick}>
                <img
                    className={`type-icon`}
                    src={spaces.find(space => space.key === type).overlayUrl ? `${config.api}/files/${spaces.find(space => space.key === type).overlayUrl}` : ''}
                    alt=""
                />
                <div className="card-image">
                    <img
                        src={photoUrl ? `${config.api}/files/${photoUrl}` : `/imgs/main_search_bg.jpg`}
                        alt=""
                    />
                </div>
                {content}
            </div>
        );
    }
}

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
    spaces: spaces(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultCard);
