import React, { Component } from "react";
import Input from "../../../components/Input";

class BusinessHost extends Component {
  render() {
    const {
      handleInputChange,
      editMode,
      userType,
      displayBusinessName,
      isDisplayed,
      defaultBusinessName,
      defaultLegalName,
      defaultTaxNumber,
      defaultCompanyAddress,
      defaultCompanyCity,
      defaultCompanyState,
      defaultCompanyZip,
      defaultCompanyCountry,
      emptyFields
    } = this.props;

    return (
      <div
        className={`user-details-business ${userType !== "company" &&
          userType !== "business" &&
          "closed-modal"}`}
      >
        <div className="row">
          <div className="field-wrapper business-name-wrapper">
            <div className="field-name">
              Business Name{" "}
              <span className="red">
                {emptyFields.includes("companyName") && "*"}
              </span>
            </div>
            <Input
              id="hostBusinessName"
              name="hostBusinessName"
              className="input-style"
              disabled={!editMode}
              onChange={e => handleInputChange(e)}
              value={defaultBusinessName}
            />

            <label className="business-name-feature">
              <input
                type="checkbox"
                defaultChecked={isDisplayed}
                disabled={!editMode}
              />
              <div className="checkmark" onClick={displayBusinessName}>
                <img
                  src={require("../../../assets/images/ic_check_fill.svg")}
                  alt=""
                />
              </div>
              <span>
                Display my business name on my space listing instead of my
                personal name.
              </span>
            </label>
          </div>

          <div className="field-wrapper">
            <div className="field-name">
              Legal Name{" "}
              <span className="red">
                {emptyFields.includes("legalName") && "*"}
              </span>
            </div>
            <Input
              id="hostLegalName"
              name="hostLegalName"
              className="input-style"
              disabled={!editMode}
              onChange={e => handleInputChange(e)}
              value={defaultLegalName}
            />
          </div>
        </div>

        <div className="row">
          <div className="field-wrapper">
            <div className="field-name">
              Tax Number{" "}
              <span className="red">
                {emptyFields.includes("taxNumber") && "*"}
              </span>
            </div>
            <Input
              id="hostTaxNumber"
              name="hostTaxNumber"
              className="input-style"
              disabled={!editMode}
              onChange={e => handleInputChange(e)}
              value={defaultTaxNumber}
            />
          </div>

          <div className="field-wrapper">
            <div className="field-name">
              Address{" "}
              <span className="red">
                {emptyFields.includes("companyAddress") && "*"}
              </span>
            </div>
            <Input
              id="hostBusinessAddress"
              name="hostBusinessAddress"
              className="input-style"
              disabled={!editMode}
              onChange={e => handleInputChange(e)}
              value={defaultCompanyAddress}
            />
          </div>
        </div>

        <div className="row">
          <div className="field-wrapper">
            <div className="field-name">
              City{" "}
              <span className="red">
                {emptyFields.includes("companyCity") && "*"}
              </span>
            </div>
            <Input
              id="hostBusinessCity"
              name="hostBusinessCity"
              className="input-style"
              disabled={!editMode}
              onChange={e => handleInputChange(e)}
              value={defaultCompanyCity}
            />
          </div>

          <div className="field-wrapper">
            <div className="field-name">
              State{" "}
              <span className="red">
                {emptyFields.includes("companyState") && "*"}
              </span>
            </div>
            <Input
              id="hostBusinessState"
              name="hostBusinessState"
              className="input-style"
              disabled={!editMode}
              onChange={e => handleInputChange(e)}
              value={defaultCompanyState}
            />
          </div>
        </div>

        <div className="row">
          <div className="field-wrapper">
            <div className="field-name">
              POSTAL CODE{" "}
              <span className="red">
                {emptyFields.includes("companyZip") && "*"}
              </span>
            </div>
            <Input
              id="hostBusinessZip"
              name="hostBusinessZip"
              className="input-style"
              disabled={!editMode}
              onChange={e => handleInputChange(e)}
              value={defaultCompanyZip}
              maxLength={12}
            />
          </div>

          <div className="field-wrapper">
            <div className="field-name">
              Country{" "}
              <span className="red">
                {emptyFields.includes("companyCountry") && "*"}
              </span>
            </div>
            <Input
              id="hostBusinessCountry"
              name="hostBusinessCountry"
              className="input-style"
              disabled={true}
              onChange={e => handleInputChange(e)}
              value={defaultCompanyCountry}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BusinessHost;
