import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Mixpanel } from '../../../util/MixPanel';

class DeskiSwitch extends Component {


    chooseType = (e) => {
        const { selectedType } = this.props;

        if (selectedType !== e.target.id) {
            this.props.getSelected(e.target.id);
        }

        if (e.target.id === "viewSpace") Mixpanel.track("Opened View Space tab")
    }

    render() {
        const { selectedType } = this.props;

        return (

            <table className="switch-wrapper">
                <tbody>
                    <tr className="switch-wrapper">
                        <th
                            id="bookings"
                            className={`switch-button ${selectedType === 'bookings' && 'active'}`}
                            onClick={(e) => this.chooseType(e)}>
                            BOOKINGS
                        </th>
                        <th
                            id="viewSpace"
                            className={`switch-button ${selectedType === 'viewSpace' && 'active'}`}
                            onClick={(e) => this.chooseType(e)}>
                            VIEW SPACE
                        </th>
                    </tr>
                </tbody>
            </table>


        )
    }
}


DeskiSwitch.propTypes = {
};

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DeskiSwitch);
