import React, { Component } from "react";
import { PhonePrefixes } from "../settings";

export default class NumberInput extends Component {
  state = {
    prefix: this.props.prefix
      ? `+${this.props.prefix.replace(/\+|-/g, "")}`
      : PhonePrefixes.Australia.prefix
  };

  componentDidMount() {
    this.props.getPrefix(this.state.prefix);
  }

  setPrefix = country => {
    const { isOpen } = this.state;
    const { disabled, onPrefixChange, prefix } = this.props;
    if (!disabled) {
      if (!prefix) {
        this.setState({ prefix: PhonePrefixes[country].prefix });
      }
      this.setState({ isOpen: !isOpen });
      this.props.getPrefix(PhonePrefixes[country].prefix);
      onPrefixChange();
    }
  };

  openModal = () => {
    const { disabled } = this.props;

    if (!disabled) {
      this.setState({ isOpen: true });
    }
  };

  render() {
    const {
      className,
      id,
      onBlur,
      maxLength,
      placeholder,
      onChange,
      name,
      onFocus,
      isInvalid,
      readOnly,
      defaultValue,
      disabled,
      smallHeight,
      prefix
    } = this.props;
    const { isOpen } = this.state;

    let phonePrefix = (
      <div
        className={`phone-prefix ${smallHeight ? "small-height" : ""}`}
        onClick={() => this.openModal()}
      >
        <span className={` ${smallHeight ? "small-height" : ""}`}>
          {prefix ? `+${prefix.replace(/\+|-/g, "")}` : this.state.prefix}
        </span>
        <img src={require("../assets/images/ic_arrow_down.svg")} alt="" />
      </div>
    );

    let prefixDropdown = null;
    if (isOpen) {
      prefixDropdown = (
        <div className="prefix-dropdown flex-center direction-column">
          <div
            className="prefix flex-center"
            onClick={() => this.setPrefix("Australia")}
          >
            <img
              src={require("../assets/images/" + PhonePrefixes.Australia.icon)}
              alt=""
            />
            {PhonePrefixes.Australia.prefix}
          </div>
          <div
            className="prefix flex-center"
            onClick={() => this.setPrefix("NewZeeland")}
          >
            <img
              src={require("../assets/images/" + PhonePrefixes.NewZeeland.icon)}
              alt=""
              onClick={() => this.openModal()}
            />
            {PhonePrefixes.NewZeeland.prefix}
          </div>
          {process.env.REACT_APP_STAGE !== "production" && (
            <div
              className="prefix flex-center"
              onClick={() => this.setPrefix("Romania")}
            >
              <img
                src={require("../assets/images/" + PhonePrefixes.Romania.icon)}
                alt=""
                onClick={() => this.openModal()}
              />
              {PhonePrefixes.Romania.prefix}
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="flex-center phone-input">
        <div
          className={`prefix-wrapper ${disabled ? "disabled" : "editable"} ${
            smallHeight ? "small-height" : ""
          }`}
        >
          {phonePrefix}
          {prefixDropdown}
        </div>
        <input
          placeholder={placeholder}
          id={id}
          type="number"
          className={`${className} ${isInvalid ? "invalid" : ""}`}
          spellCheck={false}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          name={name}
          maxLength={maxLength}
          value={defaultValue}
          disabled={disabled}
          readOnly={readOnly}
        />
      </div>
    );
  }
}
