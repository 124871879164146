import { post, get, getPublic, put, patch, del, getWordpress } from './methods';

export const Auth = {
  requestSmsCode: async userData => {
    const route = "/auth/request-sms-code";
    return await get(route, userData);
  },
  verifySmsCode: async userData => {
    const route = "/auth/verify-sms-code";
    return await get(route, userData);
  },
  signUp: async userData => {
    const route = "/auth/register";
    return await post(route, userData);
  },
  login: async loginData => {
    const route = "/auth/login";
    const data = await post(route, loginData);
    return data;
  },
  socialLogin: async data => {
    const route = "/auth/social-login";
    return await post(route, data);
  },
  registerUpload: async userData => {
    const route = "/auth/register";
    return await put(route, userData);
  },
  changePassword: async userData => {
    const route = "/auth/password";
    return await patch(route, userData);
  },
  changeProfilePassword: async userData => {
    const route = "/auth/reset/password";
    return await patch(route, userData);
  },
  logout: async data => {
    const route = "/auth/logout";
    return await post(route, data);
  }
}

export const User = {
  updateUser: async userData => {
    const route = "/users";
    return await patch(route, userData);
  },
  getLoggedUser: async userData => {
    const route = "/users";
    return await get(route, userData);
  },
  getBankAccount: async (data) => {
    const route = `/users/bankAccount`;
    return await get(route, data);
  },
  getCardDetails: async (data) => {
    const route = `/users/card`;
    return await get(route, data);
  },
  getBankDetails: async (data) => {
    const route = `/users/bankAccount`;
    return await get(route, data);
  },
  addCard: async (data) => {
    const route = `/users/card`;
    return await post(route, data);
  },
  acceptTermsOfService: async (data) => {
    const route = `/users/acceptTermsOfService`;
    return await post(route, data);
  },
  deleteCard: async data => {
    const route = `/users/card/${data}`;
    return await del(route);
  },
  registerOrEditHostAccount: async userData => {
    const route = `/users/host/profile`;
    return await put(route, userData);
  },
  activateHostAccount: async userData => {
    const route = `/users/host/activate`;
    return await put(route, userData);
  },
  deactivateHostAccount: async userData => {
    const route = `/users/host/deactivate`;
    return await put(route, userData);
  },
  deleteUserAccount: async data => {
    const route = `/users/delete/account`;
    return await del(route, data);
  },
  getIp: async data => {
    const url = 'https://api.ipify.org/?format=json';
    return await getPublic(url)
  }
}

export const Search = {
  searchSpaces: async userQueries => {
    const route = `/spaces`;
    return await get(route, userQueries);
  },
  searchSpaceTypeAndLocation: async userQueries => {
    const route = `/spaces/filter`;
    return await get(route, userQueries);
  },
  getIndividualSpace: async data => {
    const route = `/spaces/${data}`;
    return await get(route);
  },
  getPosterSpaces: async data => {
    const route = `/spaces/users/${data}`;
    return await get(route);
  },
  getFeaturedPrice: async data => {
    const route = `/spaces/boost/${data}`;
    return await get(route);
  },
  boostSpace: async (data) => {
    const route = `/spaces/boost/${data.spaceId}`;
    return await post(route, data.postData);
  },
  getTopVerified: async data => {
    const route = `/spaces/top/verified`;
    return await get(route, data);
  }
}

export const Booking = {
  validateBooking: async userQueries => {
    const route = `/bookings/validate`;
    return await get(route, userQueries);
  },
  getBookings: async data => {
    const route = `/bookings/spaces/${data}`;
    return await get(route);
  },
  createBooking: async data => {
    const route = `/bookings/createBooking`;
    return await post(route, data);
  },
  getPricePerBooking: async userQueries => {
    const route = `/bookings/calculatePrice`;
    return await get(route, userQueries);
  },
  getBookingsPerUser: async data => {
    const route = `/bookings/users/${data.userId}`;
    return await get(route, data.query);
  },
  getIndividualBooking: async data => {
    const route = `/bookings/${data}`;
    return await get(route);
  },
  cancelAndRefundBooking: async data => {
    const route = `/bookings/cancel/renter`;
    return await put(route, data);
  },
  cancelAndRefundBookingHost: async data => {
    const route = `/bookings/cancel/host`;
    return await put(route, data);
  },
  getHostBookings: async data => {
    const route = `/bookings/host`;
    return await get(route, data);
  },
  createReportDispute: async data => {
    const route = `/support`;
    return await post(route, data);
  }
};

export const Rating = {
  createRatingSpace: async (data) => {
    const route = `/ratings/spaces/${data.spaceId}`;
    return await post(route, data.postData);
  },
  createRatingRenter: async (data) => {
    const route = `/ratings/users/${data.renterId}`;
    return await post(route, data.postData);
  },
  getRatingForSpace: async (data) => {
    const route = `/ratings/spaces/${data.spaceId}/currentUser`;
    return await get(route);
  },
  getRatingForUser: async (data) => {
    const route = `/ratings/users/${data.renterId}/currentUser`;
    return await get(route);
  }
};

export const Space = {
  createSpace: async (data) => {
    const route = `/spaces/create`;
    return await post(route, data);
  },
  editSpace: async (data) => {
    const route = `/spaces/${data.spaceId}`;
    return await put(route, data.putData);
  },
  deleteSpace: async data => {
    const route = `/spaces/${data}`;
    return await del(route);
  },
}

export const Blog = {
  getPosts: async (data) => {
    const route = `/posts/?number=2&pretty=true`;
    return await getWordpress(route, data);
  }
}

export const Config = {
  getConfig: async (data) => {
    const route = `/space-config`;
    return await get(route, data);
  },
  getFAQ: async (data) => {
    const route = `/faq`;
    return await get(route, data);
  },
}
