import React, { Component } from 'react';

export default class Input extends Component {
    state = {
    }

    constructor(props) {
        super(props);

        this.searchInputRef = React.createRef();
    }


    render() {
        const { placeholder, readOnly, defaultValue, onIconClick, onClick, passwordIcon, disabled, type, className, id, onBlur, name, isInvalid, icon, small, onChange, onFocus, onKeyPress, maxLength, value } = this.props;

        return (
            <div className="input-wrapper" onClick={onClick}>
                <input
                    ref={this.searchInputRef}
                    id={id}
                    type={type}
                    placeholder={placeholder}
                    className={`${id} ${small && 'small-input'} ${className} ${isInvalid ? 'invalid' : ''}`}
                    spellCheck="false"
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={onFocus}
                    name={name}
                    autoComplete="off"
                    onKeyPress={onKeyPress}
                    maxLength={maxLength}
                    value={value}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    readOnly={readOnly}
                />
                {icon && <img
                    className="input-icon"
                    src={icon}
                    alt=""
                />}
                {passwordIcon && <img
                    className="input-icon-password"
                    src={passwordIcon}
                    alt=""
                    onClick={onIconClick}
                />}
            </div>
        )
    }
}