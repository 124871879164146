import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import moment from 'moment-timezone';
import { NavLink } from 'react-router-dom'


import { searchSpaces, getPricePerBooking } from '../actions/actions';
import { getSearchResults, getBookingResponse, getPrice } from '../reducers/selectors';
import { currentUser } from '../../../modules/auth/reducers/selectors';
import { BookingStates } from '../../../settings';

import PrimaryButton from '../../../components/PrimaryButton';
import Amounts from './Amounts';
import { Mixpanel } from '../../../util/MixPanel';

class BookingSuccessful extends Component {

    state = {
        notComplete: false,
        modalHeight: '778px'

    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    cardFormat = (e) => {
        let formatted = e.target.value.split("-").join("");
        if (formatted.length > 0) {
            formatted = formatted.match(new RegExp('.{1,4}', 'g')).join("-");
        }
        e.target.value = formatted;
    }

    selectCard = (position) => {
        this.setState({ selectedCard: position })
    }

    openEditModal = (id) => {
        this.props.getCardId(id)
        this.props.onEdit();
    }

    showCancelMessage = () => {
        const { bookingResponse: { booking } } = this.props;
        let bookingRes = booking[0];

        let cancelMessage = null;

        if (Object.keys(booking).length > 1) {
            cancelMessage = (<div className="modal-description">
                Your payment and reservation were successful! <br />
                You will recieve a confirmation email shortly. <br />
                <br />
                To check your booking or to make a cancellation, visit your Booking section.
            </div>)
        }
        else {
            const cancelDate = moment(bookingRes.startDate).tz(bookingRes.timeZone).subtract(3, "days");
            if (cancelDate.format("X") <= moment().format("X")) {
                cancelMessage = (<div className="modal-description">
                    Bookings made within 72 hours of the allocated time and date will not be refunded in case of cancellation.
                <br />
                    <br />
                    Read <NavLink to={`/terms-of-use`} target="_blank" className="modal-description-span">
                        <span className="modal-description-span">Terms and Conditions</span>
                    </NavLink> for more information.
            </div>)
            }
            else {
                cancelMessage = (<div className="modal-description">
                    Cancel before {cancelDate.format("DD MMM YYYY")} at 12:00 AM (GMT +10) to receive refunds of your payment.
            </div>)
            }
        }
        return cancelMessage;
    }

    renderSuccessModal = () => {
        const { currentUser, price } = this.props
        const { bookingResponse: { booking } } = this.props;

        Mixpanel.track("Payment - Booking Successful")

        let bookingRes = booking[0];

        let showCancelMessage = this.showCancelMessage();
        let dateString = (`${moment(bookingRes.startDate).tz(bookingRes.timeZone).format("DD MMM YYYY")} - ${moment(bookingRes.endDate).tz(bookingRes.timeZone).format("DD MMM YYYY")}`);
        let dateStringSingle = (`${moment(bookingRes.startDate).tz(bookingRes.timeZone).format("DD MMM YYYY")}`)
        let timeString = (`${moment(bookingRes.startTime, ["h"]).format('h:mm A')} - ${moment(bookingRes.endTime, ["h"]).format('h:mm A')}`);
        let time = null;
        let date = null;
        let cardInfo = null;
        let bookingInfo = null;
        let nrOfGuests = null;

        if (Object.keys(booking).length === 1) {
            date = (
                <div>
                    <div className="card-info">
                        Confirmed Booking Start & End Date:
                        </div>
                    <div className="card-data">
                        {dateString}
                    </div>
                </div>
            )
            if (moment(bookingRes.startDate).tz(bookingRes.timeZone).format("DD MMM YYYY") === moment(bookingRes.endDate).tz(bookingRes.timeZone).format("DD MMM YYYY")) {
                time = (
                    <div>
                        <div className="card-info">
                            Confirmed Booking Start & End Time:
                                        </div>
                        <div className="card-data">
                            {timeString}
                        </div>
                    </div>
                )
                date = (
                    <div>
                        <div className="card-info">
                            Confirmed Booking Date:
                            </div>
                        <div className="card-data">
                            {dateStringSingle}
                        </div>
                    </div>
                )
            }
            bookingInfo = (
                <div>
                    <div className="card-info">
                        Booking ID:
                            </div>
                    <div className="card-data">
                        {bookingRes._id}
                    </div>
                </div>
            )
            nrOfGuests = (
                <div>
                    <div className="card-info">
                        Confirmed Number of Guests:
                                        </div>
                    <div className="card-data">
                        {bookingRes.nrOfGuests}
                    </div>
                </div>
            )
        }

        if ((currentUser.paymentMethods && Object.keys(currentUser.paymentMethods).length)) {
            cardInfo = (
                <div>
                    <div className="card-info">
                        Cardholder's Name:
                                    </div>
                    <div className="card-data payment">
                        {currentUser.paymentMethods[0].name}
                    </div>
                    <div className="card-info">
                        Credit/Debit Card With No Ending with:
                                    </div>
                    <div className="card-data">
                        {currentUser.paymentMethods[0].cardNumber}
                    </div>
                </div>
            )
        }

        return (
            <div className="flex-center direction-column">
                {showCancelMessage}
                <div className="status-wrapper">
                    <div>
                        {bookingInfo}
                        <div className="card-info">
                            Booking Status:
                                        </div>
                        <div className="card-data">
                            {BookingStates.confirmed.name}
                        </div>
                        {date}
                        {time}
                        {nrOfGuests}
                    </div>
                    <div className={`separation-line booking`}></div>
                    {cardInfo}
                    {cardInfo && <div className={`separation-line booking`}></div>}
                    <Amounts price={price} />
                </div>
                <PrimaryButton text="Done" className="top-margin" onClick={this.props.onDone} />
            </div>
        );
    }
    render() {
        const { bookingResponse } = this.props;
        const customStyles = {
            content: {
                height: this.state.modalHeight
            }
        };
        return (
            <Modal
                isOpen={this.props.modalIsOpen}
                className="payment-modal success"
                style={customStyles}
                onRequestClose={this.props.closeModal}
                ariaHideApp={false}
            >
                <div className="modal-title green booking">{this.props.name}</div>
                <img src={require('../../../assets/images/ic_close.svg')} className="close-button  booking-close-btn white" alt=""
                    onClick={this.props.closeModal} />
                {bookingResponse &&
                    this.renderSuccessModal()}

            </Modal>
        );
    }
}

BookingSuccessful.propTypes = {
    searchSpaces: PropTypes.func.isRequired,
    getPricePerBooking: PropTypes.func.isRequired,

};

const mapDispatchToProps = {
    searchSpaces,
    getPricePerBooking

};

const mapStateToProps = (state) => ({
    searchResults: getSearchResults(state),
    bookingResponse: getBookingResponse(state),
    currentUser: currentUser(state),
    price: getPrice(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingSuccessful);
