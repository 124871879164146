import React, { Component } from 'react';


class Careers extends Component {

    render() {
        return (
            <div className="page-template new-space-page global">
                <div className="page-wrapper">
                    <h1 className="page-title">Careers</h1>
                    <div>No jobs currently avalaible.</div>
                </div>
            </div>
        )
    }
}

export default (Careers);
