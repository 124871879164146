export const getRemoveCardLoading = (state) => state.getIn(['profileReducer', 'isLoading']);
export const getRemoveAccountLoading = (state) => state.getIn(['profileReducer', 'isLoading'])
export const getLoading = (state) => state.getIn(['profileReducer', 'isLoading'])
export const registerOrEditHostLoading = (state) => state.getIn(['profileReducer', 'isLoading']);
export const registerFail = (state) => state.getIn(['profileReducer', 'hasFailed']);
export const registerFailError = (state) => state.getIn(['profileReducer', 'createError']);
export const activateFail = (state) => state.getIn(['profileReducer', 'hasActivateFailed']);
export const activateFailError = (state) => state.getIn(['profileReducer', 'activateError']);
export const redirectHost = (state) => state.getIn(['profileReducer', 'redirect']);
export const redirectRemoveHost = (state) => state.getIn(['profileReducer', 'redirectRemove']);
export const cardDetails = (state) => state.getIn(['profileReducer', 'cardDetails']);
export const cardHasFailed = (state) => state.getIn(['profileReducer', 'cardHasFailed'])
export const cardError = (state) => state.getIn(['profileReducer', 'cardError'])
export const cardLoading = (state) => state.getIn(['profileReducer', 'getLoading']);
export const bankDetails = (state) => state.getIn(['profileReducer', 'bankDetails']);
