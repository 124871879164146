import {
  GET_BOOKINGS,
  GET_BOOKINGS_SUCCESS,
  GET_INDIVIDUAL_BOOKING,
  GET_INDIVIDUAL_BOOKING_SUCCESS,
  GET_REVIEW_SPACE,
  GET_REVIEW_SPACE_SUCCESS,
  GET_REVIEW_USER,
  GET_REVIEW_USER_SUCCESS,
  CREATE_REVIEW_SPACE,
  CREATE_REVIEW_SPACE_SUCCESS,
  CREATE_REPORT_DISPUTE,
  CREATE_REPORT_DISPUTE_SUCCESS,
  CREATE_REVIEW_RENTER,
  CREATE_REVIEW_RENTER_SUCCESS,
  
} from '../actions/actionTypes';

import { Map } from 'immutable';

const initialState = Map({
  isLoading: true,
  isReportLoading: false,
  bookingsPerUser: {},
  individualBooking: {},
  rating: {},
  userRating: {},
});


function getBookings(state, { payload }) {
  return state.set('isLoading', true);
}

function getBookingsSuccess(state, { payload }) {
  return state.set('bookingsPerUser', payload).set('isLoading', false);
}

function getBookingById(state, { payload }) {
  return state.set('isLoading', true);
}

function getBookingByIdSuccess(state, { payload }) {
  return state.set('individualBooking', payload).set('isLoading', false);
}

function getRatingSpace(state, { payload }) {
  return state.set('isLoading', true);
}

function getRatingSpaceSuccess(state, { payload }) {
  return state.set('rating', payload).set('isLoading', false);
}

function getRatingUser(state, { payload }) {
  return state.set('isLoading', true);
}

function getRatingUserSuccess(state, { payload }) {
  return state.set('userRating', payload).set('isLoading', false);
}

function createRatingSpace(state, { payload }) {
  return state.set('isLoading', true);
}

function createRatingSpaceSuccess(state, { payload }) {
  return state.set('rating', payload).set('isLoading', false);
}

function createReportDispute(state, { payload }) {
  return state.set('isReportLoading', true);
}

function createReportDisputeSuccess(state, { payload }) {
  return state.set('isReportLoading', false);
}
  
function createRatingRenter(state, { payload }) {
  return state.set('isLoading', true);
}

function createRatingRenterSuccess(state, { payload }) {
  return state.set('userRating', payload).set('isLoading', false);
}

const handlers = {
  [GET_BOOKINGS_SUCCESS]: getBookingsSuccess,
  [GET_BOOKINGS]: getBookings,
  [GET_INDIVIDUAL_BOOKING_SUCCESS]: getBookingByIdSuccess,
  [GET_INDIVIDUAL_BOOKING]: getBookingById,
  [GET_REVIEW_SPACE]: getRatingSpace,
  [GET_REVIEW_SPACE_SUCCESS]: getRatingSpaceSuccess,
  [GET_REVIEW_USER]: getRatingUser,
  [GET_REVIEW_USER_SUCCESS]: getRatingUserSuccess,
  [CREATE_REVIEW_SPACE]: createRatingSpace,
  [CREATE_REVIEW_SPACE_SUCCESS]: createRatingSpaceSuccess,
  [CREATE_REPORT_DISPUTE]: createReportDispute,
  [CREATE_REPORT_DISPUTE_SUCCESS]: createReportDisputeSuccess,
  [CREATE_REVIEW_RENTER]: createRatingRenter,
  [CREATE_REVIEW_RENTER_SUCCESS]: createRatingRenterSuccess
};

export default function createBookingReducer(state = initialState, action = {}) {
  const handler = handlers[action.type];

  if (!handler) {
    return state;
  }

  return handler(state, action);
}