import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DateType } from '../settings.js'
import { getIndividualSpace } from '../modules/individualSpace/reducers/selectors';

class DateTypeSwitch extends Component {


  chooseType = (e) => {
    const { selectedType } = this.props;

    if (selectedType !== e.target.id) {
      this.props.changeDateType(e.target.id);
    }
  }

  render() {
    const { selectedType, isHourlyClosed, isDailyClosed, isWeeklyClosed, isMonthlyClosed } = this.props;

    return (

      <table className="switch-wrapper">
        <tbody>
          <tr className="switch-wrapper">
            <th
              id={DateType.HOURLY}
              className={`switch-button ${selectedType === DateType.HOURLY && 'active'} ${isHourlyClosed && 'disabled'}`}
              onClick={(e) => !isHourlyClosed && this.chooseType(e)}>
              Hourly
            </th>
            <th
              id={DateType.DAILY}
              className={`switch-button ${selectedType === DateType.DAILY && 'active'} ${isDailyClosed && 'disabled'}`}
              onClick={(e) => !isDailyClosed && this.chooseType(e)}>
              Daily
            </th>
            <th
              id={DateType.WEEKLY}
              className={`switch-button ${selectedType === DateType.WEEKLY && 'active'} ${isWeeklyClosed && 'disabled'}`}
              onClick={(e) => !isWeeklyClosed && this.chooseType(e)}>
              Weekly
            </th>
            <th
              id={DateType.MONTHLY}
              className={`switch-button ${selectedType === DateType.MONTHLY && 'active'} ${isMonthlyClosed && 'disabled'}`}
              onClick={(e) => !isMonthlyClosed && this.chooseType(e)}>
              Monthly
            </th>
          </tr>
        </tbody>
      </table>


    )
  }
}


DateTypeSwitch.propTypes = {
};

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
  individualSpace: getIndividualSpace(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateTypeSwitch);
