import { SAVE_PHONE_NUMBER, SET_CURRENT_USER, SAVE_VALIDATION_CODE } from '../actions/actionTypes';
import { Map } from 'immutable';

const initialState = Map({
  currentUser: JSON.parse(localStorage.Current_user || "{}"),
  phoneNumber: '',
});

function setCurrentUser(state, { payload }) {
  return state.set('currentUser', payload.user);
}

function setPhoneNumber(state, { payload }) {
  return state.set('phoneNumber', payload);
}

function setValidationCode(state, { payload }) {
  return state.set('validationCode', payload);
}

const handlers = {
  [SET_CURRENT_USER]: setCurrentUser,
  [SAVE_PHONE_NUMBER]: setPhoneNumber,
  [SAVE_VALIDATION_CODE]: setValidationCode
};

export default function createAuthReducer(state = initialState, action = {}) {
  const handler = handlers[action.type];

  if (!handler) {
    return state;
  }

  return handler(state, action);
}
