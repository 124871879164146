import React, { Component } from 'react';

import PrimaryButton from '../../../components/PrimaryButton';
import { NavLink } from 'react-router-dom';
import { Mixpanel } from '../../../util/MixPanel';

class ActivateHost extends Component {

    addMixpanelEvent = () => {
        Mixpanel.track("Activate Clicked")
    }

    render() {

        return (
            <div className="page-wrapper">
                <div className="headers-wrapper direction-column-mobile">
                    <h1 className="space-name-booking no-top-padding text-center-mobile">My Deski</h1>
                </div>
                <div className="flex-center direction-column top-padding">
                    <img src={require("../../../assets/images/deski_logo_large.svg")} alt="" />
                    <div className="space-name-booking centered text-center-mobile">Want to list your space?</div>
                    <div className="activate-text">Please activate your My Deski (host) account to access this page.</div>
                    <NavLink to="/profile/host" onClick={() => this.addMixpanelEvent()} >
                        <PrimaryButton text="Activate" />
                    </NavLink>
                </div>
            </div>
        )
    }
}


export default (ActivateHost);
