import {
  REMOVE_CARD,
  REMOVE_CARD_SUCCESS,
  ACTIVATE_HOST,
  ACTIVATE_HOST_SUCCESS,
  REMOVE_ACCOUNT,
  REMOVE_ACCOUNT_SUCCESS,
  REDIRECT_FALSE,
  ACTIVATE_HOST_FAIL,
  ADD_CARD,
  ADD_CARD_SUCCESS,
  ADD_CARD_FAIL,
  ADD_CARD_RESET,
  GET_CARD_DETAILS,
  GET_CARD_DETAILS_SUCCESS,
  GET_CARD_DETAILS_FAIL,
  GET_BANK_DETAILS,
  GET_BANK_DETAILS_SUCCESS,
  GET_BANK_DETAILS_FAIL
} from "../actions/actionTypes";

import { Map } from "immutable";

const initialState = Map({
  isLoading: false,
  redirect: false,
  redirectRemove: false,
  hasFailed: false,
  cardHasFailed: false,
  cardError: "",
  cardDetails: {},
  bankDetails: {},
  addLoading: false,
  getLoading: false,
  isActivateLoading: false,
  hasActivateFailed: false,
  activateError: null,
  createError: null
});

function removeCard(state, { payload }) {
  return state.set("isLoading", true);
}

function removeCardSuccess(state, { payload }) {
  return state.set("isLoading", false);
}

function removeAccount(state, { payload }) {
  return state.set("isLoading", true);
}

function removeAccountSuccess(state, { payload }) {
  return state.set("isLoading", false).set("redirectRemove", true);
}

function activateHost(state, { payload }) {
  return state.set("isActivateLoading", true).set("hasFailed", false);
}

function activateHostSuccess(state, { payload }) {
  return state.set("isActivateLoading", false);
}

function activateHostFail(state, { payload }) {
  return state
    .set("isActivateLoading", false)
    .set("redirect", false)
    .set("hasFailed", true)
    .set("createError", JSON.parse(payload.message).error);
}

// function activateHostAccount(state, { payload }) {
//   return state.set('isActivateLoading', true).set('hasActivateFailed', false);
// }
//
// function activateHostAccountSuccess(state, { payload }) {
//   return state.set('isActivateLoading', false).set('redirect', true);
// }
//
// function activateHostAccountFail(state, { payload }) {
//   return state.set('isActivateLoading', false).set('redirect', false).set('hasActivateFailed', true).set('activateError', JSON.parse(payload.message).error);
// }

function redirectFalse(state, { payload }) {
  return state
    .set("redirect", false)
    .set("isLoading", false)
    .set("redirectRemove", false);
}

function addCard(state, { payload }) {
  return state
    .set("isLoading", true)
    .set("cardHasFailed", false)
    .set("cardError", "");
}

function addCardSuccess(state, { payload }) {
  return state
    .set("isLoading", false)
    .set("cardHasFailed", false)
    .set("cardError", "")
    .set("cardDetails", { paymentMethod: { ...payload } });
}

function addCardReset(state, { payload }) {
  return state
    .set("isLoading", false)
    .set("cardHasFailed", false)
    .set("cardError", "");
}

function addCardFail(state, { payload }) {
  return state
    .set("isLoading", false)
    .set("cardHasFailed", true)
    .set("cardError", payload);
}

function getCardDetails(state, { payload }) {
  return state.set("getLoading", true).set("getFailed", false);
}

function getCardDetailsSuccess(state, { payload }) {
  return state.set("getLoading", false).set("cardDetails", payload);
}

function getCardDetailsFail(state, { payload }) {
  return state.set("getLoading", false).set("getFailed", true);
}

function getBankDetails(state, { payload }) {
  return state.set("isLoading", true).set("getFailed", false);
}

function getBankDetailsSuccess(state, { payload }) {
  return state.set("isLoading", false).set("bankDetails", payload);
}

function getBankDetailsFail(state, { payload }) {
  return state.set("isLoading", false).set("getFailed", true);
}

const handlers = {
  [REMOVE_CARD_SUCCESS]: removeCardSuccess,
  [REMOVE_CARD]: removeCard,
  [REMOVE_ACCOUNT_SUCCESS]: removeAccountSuccess,
  [REMOVE_ACCOUNT]: removeAccount,
  [ACTIVATE_HOST_SUCCESS]: activateHostSuccess,
  [ACTIVATE_HOST_FAIL]: activateHostFail,
  [ACTIVATE_HOST]: activateHost,
  [REDIRECT_FALSE]: redirectFalse,
  [ADD_CARD]: addCard,
  [ADD_CARD_SUCCESS]: addCardSuccess,
  [ADD_CARD_FAIL]: addCardFail,
  [ADD_CARD_RESET]: addCardReset,
  [GET_CARD_DETAILS]: getCardDetails,
  [GET_CARD_DETAILS_SUCCESS]: getCardDetailsSuccess,
  [GET_CARD_DETAILS_FAIL]: getCardDetailsFail,
  [GET_BANK_DETAILS]: getBankDetails,
  [GET_BANK_DETAILS_SUCCESS]: getBankDetailsSuccess,
  [GET_BANK_DETAILS_FAIL]: getBankDetailsFail
};

export default function createProfileReducer(
  state = initialState,
  action = {}
) {
  const handler = handlers[action.type];

  if (!handler) {
    return state;
  }

  return handler(state, action);
}
