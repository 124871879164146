import React, { Component } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  injectStripe
} from "react-stripe-elements";
import PrimaryButton from "./PrimaryButton";
import Input from "./Input";

class StripeComponent extends Component {
  state = {
    name: "",
    cardNumberComplete: false,
    cardExpiryComplete: false,
    cardCvcComplete: false,
    cardNumberError: "",
    cardExpiryError: "",
    cardCvcError: ""
  };

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  async submit(ev) {
    const { setNotComplete } = this.props;
    const {
      name,
      cardNumberComplete,
      cardExpiryComplete,
      cardCvcComplete
    } = this.state;
    ev.preventDefault();

    if (!name || !cardNumberComplete || !cardExpiryComplete || !cardCvcComplete) {
      setNotComplete(true)
      return;
    }

    setNotComplete(false)

    try {
      const { name } = this.state;
      let { token } = await this.props.stripe.createToken({ name });
      this.props.token(token);
    } catch (err) {
      console.log(err);
    }
  }

  handleNameChange = event => {
    this.setState({ name: event.target.value });
  };

  handleChange = changeObject => {
    const { setStripeError } = this.props;
    const { error, complete, elementType } = changeObject;

    if (error) {
      this.setState({ [`${elementType}Error`]: error.message });
      setStripeError(error.message);
      return;
    } else {
      this.setState({ [`${elementType}Error`]: "" });

      const { cardNumberError, cardExpiryError, cardCvcError } = this.state;

      setStripeError(cardNumberError || cardExpiryError || cardCvcError);
    }

    this.setState({ [`${elementType}Complete`]: complete });
  };

  render() {
    return (
      <div className="checkout">
        {/* cardholders' name = element with id card_name */}
        <div className="payment-input-wrapper">
          <Input
            className="uppercase payment-input StripeElement"
            placeholder="Cardholder Name"
            name="name"
            value={this.state.name}
            onChange={this.handleNameChange}
            required
          />
        </div>
        <CardNumberElement
          className="stripe-number"
          onChange={this.handleChange}
        />
        <div>
          <CardExpiryElement
            className="stripe-number"
            onChange={this.handleChange}
          />
          <CardCvcElement
            className="stripe-number"
            onChange={this.handleChange}
          />
        </div>
        <div className="flex-center">
          <PrimaryButton
            text="Send"
            className={`top-margin absolute-button-top `}
            onClick={this.submit}
          />
        </div>
      </div>
    );
  }
}

export default injectStripe(StripeComponent);
