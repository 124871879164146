import React, { Component } from 'react';
import Modal from 'react-modal';

import PrimaryButton from './PrimaryButton';

import connect from 'react-redux/es/connect/connect';


class FailModal extends Component {

    render() {
        const { title, description, buttonText, onClick, onCancel } = this.props;
        return (
            <Modal
                ariaHideApp={false}
                isOpen={true}
                className="forgot-password-modal"
                overlayClassName="overlay"
            >
                <img src={require('../assets/images/ic_close.svg')} className="close-button" alt=""
                    onClick={onCancel} />
                <div className="flex-center direction-column">
                    <img src={require('../assets/images/ic_payment_error.svg')} className="success-icon" alt="" />
                    <div className="modal-title" style={{
                        width: '300px'
                    }}>{title}</div>
                </div>
                <div className="flex-center direction-column success-content-wrapper">
                    <div className="subtitle modal-subtitle"> {description}</div>
                    <PrimaryButton text={buttonText} onClick={onClick} className="success-button" />
                </div>
            </Modal>
        );
    }
}

FailModal.propTypes = {
};

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FailModal);