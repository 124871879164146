import { SET_CURRENT_USER, SAVE_PHONE_NUMBER, SAVE_VALIDATION_CODE } from './actionTypes';

import { Auth, User } from '../../../api/endpoints';
import { saveUserToStorage, deleteUserFromStorage } from '../../../util/utils'


export function setCurrentUser(payload) {
  return {
    type: SET_CURRENT_USER,
    payload,
  };
}

export function savePhoneNumber(payload) {
  return {
    type: SAVE_PHONE_NUMBER,
    payload,
  };
}

export function saveValidationCode(payload) {
  return {
    type: SAVE_VALIDATION_CODE,
    payload,
  };
}

export function sendSmsCode(payload) {
  return async () => {
    await Auth.requestSmsCode(payload)
  };
}

export function verifySmsCode(payload) {
  return async () => {
    return await Auth.verifySmsCode(payload)
  };
}

export function register(payload) {
  return async (dispatch) => {
    const response = await Auth.signUp(payload);

    saveUserToStorage(response);
    dispatch(setCurrentUser(response));
  };
}

export function socialLogin(payload) {
  return async (dispatch) => {
    const response = await Auth.socialLogin(payload);

    saveUserToStorage(response);
    dispatch(setCurrentUser(response));
  };
}

export function login(payload) {
  return async (dispatch) => {
    const response = await Auth.login(payload);

    saveUserToStorage(response);
    dispatch(setCurrentUser(response));
  };
}
export function logout(payload) {
  return async (dispatch) => {
    const response = await Auth.logout();

    deleteUserFromStorage();
    dispatch(setCurrentUser(response));
  };
}

export function registerUpload(payload) {
  return async (dispatch) => {
    const response = await Auth.registerUpload(payload);

    saveUserToStorage(response);
    dispatch(setCurrentUser(response));
  };
}

export function updateUser(payload) {
  return async (dispatch) => {
    const response = await User.updateUser(payload);

    saveUserToStorage(response);
    dispatch(setCurrentUser(response));
  };
}

export function changePassword(payload) {
  return async () => {
    return await Auth.changePassword(payload)
  };
}

export function changeProfilePassword(payload) {
  return async () => {
    return await Auth.changeProfilePassword(payload)
  };
}

export function getLoggedUser(payload) {
  return async (dispatch) => {
    const response = await User.getLoggedUser(payload);

    saveUserToStorage(response);
    dispatch(setCurrentUser(response));
  };
}