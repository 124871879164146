import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";
import history from "../../../history";
import queryString from "query-string";
import Loader from "react-loader-spinner";
import { Helmet } from "react-helmet";

import { getSpacesByPoster } from "../actions/actions";
import { getPosterSpaces } from "../reducers/selectors";
import ResultCard from "../../../components/ResultCard";
import FilterModal from "../../../components/FilterModal";

import config from "../../../config/config";

const REGULAR_PER_PAGE = 12;

class PosterSpacesScreen extends Component {
  state = {
    sortOpened: false,
    redirectToSearch: false,
    pageNumber: 1,
    modalOpen: false,
    totalFilters: 0
  };

  getSpacesByPoster = async posterID => {
    const { getSpacesByPoster } = this.props;
    await getSpacesByPoster(posterID);
  };

  getFilteredSpaces = async filters => {
    const { getSpacesByPoster, posterSpaces } = this.props;
    const query = `${posterSpaces.user._id}${filters}&perPage=${REGULAR_PER_PAGE}`;
    await getSpacesByPoster(query);
  };

  componentDidMount() {
    this.getSpacesByPoster(
      this.props.match.params.posterID +
        history.location.search +
        `&perPage=${REGULAR_PER_PAGE}`
    );
    document.title = "Deski";
  }

  componentWillReceiveProps(nextProps) {
    const page = queryString.parse(this.props.location.search).page;
    const nextPage = queryString.parse(nextProps.location.search).page;
    let search = history.location.search.replace(/page=\d*&/g, "page=0&");

    if (nextProps.location !== this.props.location) {
      this.getFilteredSpaces(history.location.search);
      if (page !== 0) {
        if (page === nextPage) {
          history.push({
            search: search
          });
        }
      }
    }

    window.scrollTo(0, 0);
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ sortOpened: false });
    }
  };

  handlePagination = pageObj => {
    const currentPageNumber = pageObj.selected + 1;
    const page = queryString.parse(window.location.search).page;

    if (page !== currentPageNumber) {
      const searchQuery = queryString.parse(this.props.location.search);
      let pushedItem = `page=${+currentPageNumber - 1}`;

      Object.keys(searchQuery).forEach(filter => {
        if (filter !== "page")
          pushedItem = pushedItem.concat(`&${filter}=${searchQuery[filter]}`);
      });

      history.push({
        pathname: history.location.pathname,
        search: pushedItem
      });
    }
    this.setState({ pageNumber: page });
  };

  sortResults = () => {
    let pushedItem = "";
    const searchQuery = queryString.parse(this.props.location.search);

    if (!window.location.search.includes("sortByPrice")) {
      if (Object.keys(searchQuery).length > 0) {
        pushedItem = window.location.search + `&sortByPrice=${true}`;
      } else {
        pushedItem = window.location.search + `?sortByPrice=${true}`;
      }

      history.push({
        search: pushedItem
      });
    }
  };

  cities = [
    "Sydney NSW, Australia",
    "Brisbane QLD, Australia",
    "Canberra ACT, Australia",
    "Hobart TAS, Australia",
    "Melbourne VIC, Australia",
    "Perth WA, Australia",
    "Adelaide SA, Australia",
    "Darwin NT, Australia"
  ];

  render() {
    const { posterSpaces } = this.props;

    if (typeof posterSpaces.user === "undefined") {
      return (
        <div className="loader-wrapper">
          <Loader
            type="Ball-Triangle"
            height="100"
            width="100"
            color="#0EA800"
          />
        </div>
      );
    }

    const {
      posterSpaces: { spaces }
    } = this.props;
    const { modalOpen, sortOpened } = this.state;
    const values = queryString.parse(this.props.location.search);
    let userSpaces = null;
    let hostAccount = null;
    let pageCount = null;
    const page = queryString.parse(window.location.search).page;

    if (posterSpaces.countSpaces.length) {
      pageCount =
        posterSpaces.countSpaces[0].count % REGULAR_PER_PAGE === 0
          ? Math.trunc(posterSpaces.countSpaces[0].count / REGULAR_PER_PAGE)
          : Math.trunc(posterSpaces.countSpaces[0].count / REGULAR_PER_PAGE) +
            1;
    }

    if (posterSpaces && Object.keys(posterSpaces).length && posterSpaces.user) {
      userSpaces = posterSpaces.user;
      hostAccount = posterSpaces.user.hostAccount;
    }

    let profilePic = null;

    let posterName = `${userSpaces.firstName} ${userSpaces.lastName}`;
    let posterShortName = `${userSpaces.firstName}`;

    if (hostAccount) {
      if (hostAccount.generalInfo && hostAccount.generalInfo.photoUrl) {
        profilePic = `${config.api}/files/${hostAccount.generalInfo.photoUrl}`;
      } else {
        const profileSrcSocialMedia =
          posterSpaces &&
          Object.keys(posterSpaces).length &&
          userSpaces &&
          Object.keys(userSpaces).length &&
          userSpaces.socialLogin &&
          Object.keys(userSpaces.socialLogin).length &&
          userSpaces.profilePictureUrl
            ? userSpaces.profilePictureUrl
            : "";

        let profileSrc =
          posterSpaces &&
          Object.keys(posterSpaces).length &&
          userSpaces &&
          Object.keys(userSpaces).length &&
          !userSpaces.socialLogin &&
          userSpaces.profilePictureUrl
            ? `${config.api}/files/${userSpaces.profilePictureUrl}`
            : "";

        profilePic = profileSrcSocialMedia
          ? profileSrcSocialMedia
          : profileSrc
          ? profileSrc
          : require("../../../assets/images/placeholder_profile.svg");
      }

      if (
        hostAccount.companyDetails &&
        hostAccount.companyDetails.companyName
      ) {
        posterName = `${hostAccount.companyDetails.companyName}`;
        posterShortName = `${hostAccount.companyDetails.companyName}`;
      }
    }
    let pagination = null;

    if (pageCount > 1) {
      pagination = (
        <ReactPaginate
          pageCount={pageCount}
          forcePage={+page}
          containerClassName="flex-center pagination spacing"
          previousLabel={
            <div className="page-switcher flex-center">
              <img
                className="previous label"
                src={require("../../../assets/images/ic_arrow.svg")}
                alt=""
              />
            </div>
          }
          nextLabel={
            <div className="page-switcher flex-center">
              <img
                className="label"
                src={require("../../../assets/images/ic_arrow.svg")}
                alt=""
              />
            </div>
          }
          pageLinkClassName="pagination padding-page"
          previousLinkClassName="pagination"
          nextLinkClassName="pagination"
          breakLabel="..."
          breakClassName="break flex-center"
          breakLinkClassName="pagination"
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={this.handlePagination}
          pageClassName="page flex-center"
          activeClassName="active-page"
        />
      );
    }

    let filtersCount = null;

    if (Object.keys(values).length > 0) {
      if (
        Object.keys(values).length === 1 ||
        (Object.keys(values).length === 2 && values.sortByPrice)
      ) {
        filtersCount = null;
      } else {
        filtersCount = (
          <span className="filters-number flex-center">
            {values.sortByPrice
              ? Object.keys(values).length - 2
              : Object.keys(values).length - 1}
          </span>
        );
      }
    }

    let sortType = "";

    if (queryString.parse(this.props.location.search).sortByPrice) {
      sortType = "price";
    }

    return (
      <div className="page-template">
        <Helmet>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        {Object.keys(posterSpaces).length ? (
          <div className="page-wrapper poster-page">
            {modalOpen && (
              <FilterModal
                activeFilters={values}
                activeRating={values.rating}
                isGroupMeeting={true}
                onCancel={() => this.setState({ modalOpen: false })}
                isFromPosterSpaces={true}
              />
            )}
            <div className="poster-details mobile-details">
              <div
                className="big-img-poster"
                style={{
                  backgroundImage: `url(${profilePic})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center"
                }}
              ></div>
              <div className="text-details-wrapper poser-focus">
                <div className="big-poster-name">{posterName}</div>
              </div>
            </div>
            <div className="space-between">
              <div
                className={`sort-button ${sortOpened && "sort-active"}`}
                onClick={() => this.setState({ sortOpened: !sortOpened })}
                ref={this.setWrapperRef}
              >
                <div className={`sort-wrapper `}>
                  <div className="sort-text">Sorted by {sortType}</div>
                  <img
                    className={`${sortOpened && "open-icon-reversed"}`}
                    src={require("../../../assets/images/ic_arrow_down.svg")}
                    alt=""
                  />
                </div>

                <div className="sort-line" />
                {sortOpened && (
                  <div className="sort-type-wrapper">
                    <div
                      className="sort-type"
                      onClick={() => this.sortResults()}
                    >
                      <div className="sort-type-title">By Price</div>
                      <div className="sort-type-description">
                        Sort based on lowest to highest price.
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="poster-filters"
                onClick={() => this.setState({ modalOpen: true })}
              >
                <img
                  src={require("../../../assets/images/ic_filters.svg")}
                  style={{ alignSelf: "center" }}
                  alt=""
                />
                <div>
                  Filter &nbsp;
                  {filtersCount}
                </div>
              </div>
            </div>
            <div className="results-wrapper">
              <div className="results-category">
                <span style={{ textTransform: "uppercase" }}>
                  {posterShortName}
                </span>
                <span>'S SPACE LIST</span>
              </div>
              {posterSpaces.spaces && spaces.length ? (
                <div className="result-cards">
                  {spaces.map((space, index) => (
                    <div className="card-wrapper" key={space._id}>
                      <NavLink
                        to={`/spaces/${space._id}/${space.name
                          .replace(/\W+(?!$)/g, "-")
                          .replace(/\W$/, "")
                          .toLowerCase()}/${encodeURIComponent(
                          space.location
                            .replace(/\W+(?!$)/g, "-")
                            .replace(/\W$/, "")
                            .toLowerCase()
                        )}/${encodeURIComponent(
                          space.address
                            .replace(/\W+(?!$)/g, "-")
                            .replace(/\W$/, "")
                            .toLowerCase()
                        )}`}
                      >
                        <ResultCard
                          key={space._id}
                          type={space.type}
                          isVerified={space.verified}
                          spaceName={space.name}
                          nrOfReviewers={space.nrOfRaters}
                          rating={space.rating}
                          ratePerHour={space.ratePerHour}
                          ratePerDay={space.ratePerDay}
                          ratePerWeek={space.ratePerWeek}
                          ratePerMonth={space.ratePerMonth}
                          photoUrl={space.photosUrls[0].photoUrl}
                        />
                      </NavLink>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="results-category">No spaces available.</div>
              )}
            </div>
            {pagination}
          </div>
        ) : null}
      </div>
    );
  }
}

PosterSpacesScreen.propTypes = {
  getSpacesByPoster: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  getSpacesByPoster
};

const mapStateToProps = state => ({
  posterSpaces: getPosterSpaces(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PosterSpacesScreen);
