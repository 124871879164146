/* global Promise */
import request from 'superagent';
import { get as lodashGet } from 'lodash-es';
import constants from '../config/config';

// const processEnv = process.env.NODE_ENV || 'development';
// const configPath = "../config/" + processEnv;

const TIMEOUT = 50000;

/**
 * @description Append token & other header fields to the default request header
 *
 * @param token: String
 * @param others: Object
 * @returns Object
 * @private
 */
function getRequestHeader() {
  const token = localStorage.Auth_token;
  let header = {
    "Content-Type": "application/json",
  };
  if (token) {
    header = Object.assign({}, header, { 'Authorization': `Bearer ${token}` });
  }
  return header;
}

/**
 * @description GET request
 *
 * @param path: String
 * @param query: Object
 * @param token: String
 * @returns Promise
 */
export function get(path, query = {}, token = null) {
  return new Promise((resolve, reject) => {
    const header = getRequestHeader(token);
    request
      .get(constants.api + path)
      .query(query)
      .set(header)
      .timeout({ response: TIMEOUT })
      .end((err, res) => handleResponse(err, res, resolve, reject));
  });
}

/**
 * @description GET public request
 *
 * @param path: String
 * @param query: Object
 * @param token: String
 * @returns Promise
 */
export function getPublic(path, query = {}, token = null) {
  return new Promise((resolve, reject) => {
    // const header = getRequestHeader(token);
    request
      .get(path)
      .query(query)
      // .set(header)
      .timeout({ response: TIMEOUT })
      .end((err, res) => handleResponse(err, res, resolve, reject));
  });
}

/**
 * @description GET request
 *
 * @param path: String
 * @param query: Object
 * @param token: String
 * @returns Promise
 */
export function getWordpress(path, query = {}, token = null) {
  return new Promise((resolve, reject) => {
    request
      .get(constants.blog + path)
      .query(query)
      .timeout({ response: TIMEOUT })
      .end((err, res) => handleResponse(err, res, resolve, reject));
  });
}

/**
 * @description POST request
 *
 * @param path: String
 * @param payload: Object
 * @param token: String
 * @returns Promise
 */
export function post(path, payload, token = null) {
  return new Promise((resolve, reject) => {
    const header = getRequestHeader(token);
    request
      .post(constants.api + path)
      .set(header)
      .send(payload)
      .timeout({ response: TIMEOUT })
      .end((err, res) => handleResponse(err, res, resolve, reject));
  });
}

/**
 * @description PATCH request
 *
 * @param path: String
 * @param payload: Object
 * @param token: String
 * @returns Promise
 */
export function patch(path, payload, token = null) {
  return new Promise((resolve, reject) => {
    const header = getRequestHeader(token);
    request
      .patch(constants.api + path)
      .set(header)
      .send(payload)
      .timeout({ response: TIMEOUT })
      .end((err, res) => handleResponse(err, res, resolve, reject));
  });
}

/**
 * @description PUT request
 *
 * @param path: String
 * @param payload: Object
 * @param token: String
 * @returns Promise
 */
export function put(path, payload, token = null) {
  return new Promise((resolve, reject) => {
    const header = getRequestHeader(token);
    request
      .put(constants.api + path)
      .send(payload)
      .set(header)
      .timeout({ response: TIMEOUT })
      .end((err, res) => handleResponse(err, res, resolve, reject));
  });
}

/**
 * @description DELETE request
 *
 * @param path: String
 * @param query: Object
 * @param token: String
 * @returns Promise
 */
export function del(path, query = {}, token = null) {
  return new Promise((resolve, reject) => {
    const header = getRequestHeader(token);
    request
      .delete(constants.api + path)
      .query(query)
      .set(header)
      .timeout({ response: TIMEOUT })
      .end((err, res) => handleResponse(err, res, resolve, reject));
  });
}

const handleResponse = (err, res = {}, resolve, reject) => {
  if (!res || !res.body || !res.ok || err) {
    const error = JSON.stringify(res.body || err || "Unkown error.");
    if (res.req) console.log(res, error);
    if (lodashGet(res, 'error.status') === 401 || lodashGet(res, 'error.url', '').includes('logout')) localStorage.clear();
    return reject({ message: error });
  }

  return resolve(res.body);
};
