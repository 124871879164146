import React, { Component } from 'react';


class Mission extends Component {

    render() {
        return (
            <div className="page-template mission-page">
                <div className="page-wrapper">
                    <h1 className="page-title">Our Mission</h1>
                    <div className="ms-wrapper">
                        <div className="ms-box">
                            <h2>Deski aims to connect people all over the world with their perfect workspace.</h2>
                            <p>We help businesses, homes, boat owners and others utilise all their space and reduce cost wastage for both them and the renter.</p>
                        </div>
                        <div className="ms-box">
                            <img src={require('../../assets/images/our_mission.jpg')} alt="Team Working" title="Our Mission" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (Mission);
