import { GET_VERIFIED, GET_VERIFIED_SUCCESS, GET_BLOG, GET_BLOG_SUCCESS } from '../actions/actionTypes';
import { Map } from 'immutable';

const initialState = Map({
  verifiedSpaces: {},
  isLoading: false,
  blogPosts: {}
});

function getVerified(state, { payload }) {
  return state.set('isLoading', true);
}

function getVerifiedSuccess(state, { payload }) {
  return state.set('verifiedSpaces', payload).set('isLoading', false);
}

function getBlog(state, { payload }) {
  return state.set('isLoading', true);
}

function getBlogSuccess(state, { payload }) {
  return state.set('blogPosts', payload.posts).set('isLoading', false);
}

const handlers = {
  [GET_VERIFIED]: getVerified,
  [GET_VERIFIED_SUCCESS]: getVerifiedSuccess,
  [GET_BLOG]: getBlog,
  [GET_BLOG_SUCCESS]: getBlogSuccess,
};

export default function createHomeReducer(state = initialState, action = {}) {
  const handler = handlers[action.type];

  if (!handler) {
    return state;
  }

  return handler(state, action);
}
