import React, { Component } from 'react';

export default class PrivacyPolicy extends Component {
    state = {}

    render() {
        return (
            <div className="terms-wrapper">
                <p className="p1"><span className="s1"><strong>Privacy Policy </strong></span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1">[Deski </span><span className="s2">Australia </span><span className="s1">Pty Ltd] (ACN 630 048 823) (&ldquo;Deski&rdquo;) is committed to providing quality services to you and this policy outlines our ongoing obligations to you in respect of how we manage your Personal Information. </span></p>
                <p className="p3"><span className="s1">We have adopted the Australian Privacy Principles (APPs) contained in the Privacy Act 1988 (Cth) (the Privacy Act). The NPPs govern the way in which we collect, use, disclose, store, secure and dispose of your Personal Information. </span></p>
                <p className="p3"><span className="s1">A copy of the Australian Privacy Principles may be obtained from the website of the Office of the Australian Information Commissioner at </span><a href="https://www.oaic.gov.au">www.oaic.gov.au </a></p>
                <p className="p4">&nbsp;</p>
                <p className="p1"><span className="s1"><strong>What is Personal Information and why do we collect it? </strong></span></p>
                <p className="p2">&nbsp;</p>
                <p className="p3"><span className="s1">Personal Information is information or an opinion that identifies an individual. Examples of Personal Information we collect include: names, addresses, email addresses, phone and facsimile numbers. </span></p>
                <p className="p3"><span className="s1">This Personal Information is obtained in many ways including by telephone, by email, through our website<a href="https://www.deski.com.au"> www.deski.com.au</a>, from cookies and from third parties. We don&rsquo;t guarantee website links or policy of authorised third parties. </span></p>
                <p className="p3"><span className="s1">We collect your Personal Information for the primary purpose of providing our services to you, providing information to our clients and marketing. We may also use your Personal Information for secondary purposes closely related to the primary purpose, in circumstances where you would reasonably expect such use or disclosure. You may unsubscribe from our mailing/marketing lists at any time by contacting us in writing. </span></p>
                <p className="p3"><span className="s1">When we collect Personal Information we will, where appropriate and where possible, explain to you why we are collecting the information and how we plan to use it. </span></p>
                <p className="p5"><span className="s1"><strong>Sensitive Information </strong></span></p>
                <p className="p3"><span className="s1">Sensitive information is defined in the Privacy Act to include information or opinion about such things as an individual's racial or ethnic origin, political opinions, membership of a political association, religious or philosophical beliefs, membership of a trade union or other professional body, criminal record or health information. </span></p>
                <p className="p3"><span className="s1">Sensitive information will be used by us only: </span></p>
                <ul className="ul1">
                    <li className="li3"><span className="s1">For the primary purpose for which it was obtained </span></li>
                    <li className="li3"><span className="s1">For a secondary purpose that is directly related to the primary purpose </span></li>
                    <li className="li3"><span className="s1">With your consent; or where required or authorised by law. </span></li>
                </ul>
                <p className="p1"><span className="s5"><br /></span><span className="s1"><strong>Third Parties </strong></span></p>
                <p className="p6"><span className="s1"><br />Where reasonable and practicable to do so, we will collect your Personal Information only from you. However, in some circumstances we may be provided with information by third parties. In such a case we will take reasonable steps to ensure that you are made aware of the information provided to us by the third party. <br /></span></p>
                <p className="p5"><span className="s1"><strong>Disclosure of Personal Information </strong></span></p>
                <p className="p3"><span className="s1">Your Personal Information may be disclosed in a number of circumstances including the following: </span></p>
                <ul className="ul1">
                    <li className="li3"><span className="s1">Third parties where you consent to the use or disclosure; and </span></li>
                    <li className="li3"><span className="s1">Where required or authorised by law. </span></li>
                </ul>
                <p className="p1"><span className="s5"><br /></span><span className="s1"><strong>Security of Personal Information </strong></span></p>
                <p className="p3"><span className="s1"><br />Your Personal Information is stored in a manner that reasonably protects it from misuse and loss and from unauthorized access, modification or disclosure. <br />When your Personal Information is no longer needed for the purpose for which it was obtained, we will take reasonable steps to destroy or permanently de-identify your Personal Information. However, most of the Personal Information is or will be stored in client files which will be kept by us for a minimum of 7 years. </span></p>
                <p className="p1"><span className="s5"><br /></span><span className="s1"><strong>Access to your Personal Information </strong></span></p>
                <p className="p6"><span className="s1"><br />You may access the Personal Information we hold about you and to update and/or correct it, subject to certain exceptions. If you wish to access your Personal Information, please contact us in writing. <br /><strong>Deski </strong>will not charge any fee for your access request, but may charge an administrative fee for providing a copy of your Personal Information. <br />In order to protect your Personal Information we may require identification from you before releasing the requested information. </span></p>
                <p className="p1"><span className="s5"><br /></span><span className="s1"><strong>Maintaining the Quality of your Personal Information </strong></span></p>
                <p className="p6"><span className="s1"><br />It is an important to us that your Personal Information is up to date. We will take reasonable steps to make sure that your Personal Information is accurate, complete and up-to-date. If you find that the information we have is not up to date or is inaccurate, please advise us as soon as practicable so we can update our records and ensure we can continue to provide quality services to you. </span></p>
                <p className="p1"><span className="s5"><br /></span><span className="s1"><strong>Policy Updates </strong></span></p>
                <p className="p6"><span className="s1"><br />This Policy may change from time to time and is available on our website. </span></p>
                <p className="p1"><span className="s5"><br /></span><span className="s1"><strong>Privacy Policy Complaints and Enquiries </strong></span></p>
                <p className="p6"><span className="s1"><br />If you have any queries or complaints about our Privacy Policy please contact us at: </span></p>
                <p className="p7"><span className="s6"><br /></span><span className="s1">Suite 401, Level 4, 68 Alfred Street Milsons Point NSW 2061 </span></p>
                <p className="p6"><span className="s1"><br /><strong>Email: hello@deski.com.au </strong></span></p>
                <p className="p8">&nbsp;</p>
                <p className="p6"><span className="s1"><strong>04360216424 </strong><br /></span></p>
            </div>
        );
    }
}