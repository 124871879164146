import React, { Component } from 'react';
import { connect } from 'react-redux';
import { amenities, groupMeetingTypes } from '../../../configReduxSetup/reducers/selectors';


class SpaceAmenities extends Component {

  state = {
    amenitySelectedOption: {},
    amenityTypeSelectedOption: {},
  }

  componentDidUpdate(prevProps) {
    const { value, valueTypes } = this.props;
    if (prevProps.value !== value) {
      this.setState({
        amenitySelectedOption: value,
      })
    }
    if (prevProps.valueTypes !== valueTypes) {
      this.setState({
        amenityTypeSelectedOption: valueTypes,
      })
    }
  }

  // toggle amenities checkbox
  toggle = (position) => {
    const { amenitySelectedOption } = this.state;
    if (amenitySelectedOption[position] === true || amenitySelectedOption[position] === "true") {
      delete amenitySelectedOption[position]
      this.setState({})
    } else {
      let amenityArray = amenitySelectedOption;
      amenityArray[position] = true;
      this.setState({ amenitySelectedOption: amenityArray })
    }

    this.props.setAmenities(amenitySelectedOption)
  }

  // toggle amenities checkbox
  toggleTypes = (position) => {
    const { amenityTypeSelectedOption } = this.state;
    if (amenityTypeSelectedOption[position] === true || amenityTypeSelectedOption[position] === "true") {
      delete amenityTypeSelectedOption[position]
      this.setState({})
    } else {
      let amenityArray = amenityTypeSelectedOption;
      amenityArray[position] = true;
      this.setState({ amenityTypeSelectedOption: amenityArray })
    }

    this.props.setAmenitiesType(amenityTypeSelectedOption)
  }

  render() {
    const { isGroupMeeting, amenities, groupMeetingTypes } = this.props;
    let { amenitySelectedOption, amenityTypeSelectedOption } = this.state;
    let types = null;

    if (isGroupMeeting) {
      types = (
        <div>
          <div className="label">TYPES</div>
          <div className="amenities-wrapper">
            {isGroupMeeting &&
              groupMeetingTypes.map((type, index) => (
                <div className="amenity" key={index} onClick={() => this.toggleTypes(type.key)}>
                  <div className="amenity-label">
                    {type.name}
                  </div>
                  <div className={`${(!amenityTypeSelectedOption[type.key]) && 'checkmark'}`}>
                    {amenityTypeSelectedOption[type.key] &&
                      <img src={require('../../../assets/images/ic_check_fill.svg')} alt="" />
                    }
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      )
    }

    return (
      <div className="form-group">
        <div className="label">AMENITIES</div>
        <div className="amenities-wrapper">
          {amenities.map((type, index) => (
            <div className="amenity" key={index} onClick={() => this.toggle(type.key)}>
              <div className="amenity-label">
                {type.name}
              </div>
              <div className={`${(!amenitySelectedOption[type.key]) && 'checkmark'}`}>
                {amenitySelectedOption[type.key] &&
                  <img src={require('../../../assets/images/ic_check_fill.svg')} alt="" />
                }
              </div>
            </div>
          ))}
        </div>
        {types}
      </div>
    )
  }
}



const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
  amenities: amenities(state),
  groupMeetingTypes: groupMeetingTypes(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpaceAmenities);

