import React, { Component } from 'react';

export default class SecondaryButton extends Component {
  state = {}

  render() {
    const { text, onClick, disabled, className } = this.props;
    return (
      // eslint-disable-next-line react/button-has-type
      <button disabled={disabled} onClick={onClick} className={`secondary-button flex-center ${className}`}>
        <span className="secondary button-text">{text}</span>
      </button>
    );
  }
}