import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

class Amounts extends Component {

    renderMonthlyPrice = () => {
        const { price, startDate } = this.props;

        let totalGSTincluded = "-"
        let monthlyArray = [];

        if (price) {
            totalGSTincluded = `$${price.price.totalPrice}`;

            if (price.price.nrOfMonths) {
                for (let i = 0; i < price.price.nrOfMonths; i++) {
                    monthlyArray.push(<div className="amount" key={i}>
                        <div className="amount-text">Due {i === 0 ? 'NOW' : moment(startDate).add(i * 28, 'days').format('DD MMM YYYY')}</div>
                        <div className="amount-sum">{totalGSTincluded}</div>
                    </div>)
                }
            }
        }
        return (monthlyArray)
    }
    render() {
        const { price, isMonthly } = this.props;

        let totalGSTincluded = "-"
        let totalPriceSubscription = "-"
        let nrOfMonths = null

        if (price) {
            totalGSTincluded = `$${price.price.totalPrice}`;
            totalPriceSubscription = `$${price.price.totalPriceSubscription}`;
            nrOfMonths = price.price.nrOfMonths;
        }

        let monthlyPrice = null;
        if (isMonthly && nrOfMonths) {
            monthlyPrice =
                <div>
                    {this.renderMonthlyPrice()}
                </div>
        }
        return (
            <div>
                {monthlyPrice}
                <div className="amount total" style={{ width: '330px' }}>
                    <div>
                        <div className="total-amount-text">TOTAL</div>
                        <div className="gst-text">*GST included</div>
                    </div>
                    <div className="amount-sum">{(isMonthly && nrOfMonths) ? totalPriceSubscription : totalGSTincluded}</div>
                </div>
            </div>
        );
    }
}

Amounts.propTypes = {
};

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Amounts);