export const REMOVE_CARD = "profile/REMOVE_CARD";
export const REMOVE_CARD_SUCCESS = "profile/REMOVE_CARD_SUCCESS";
export const REMOVE_ACCOUNT = "profile/REMOVE_CARD";
export const REMOVE_ACCOUNT_SUCCESS = "profile/REMOVE_CARD_SUCCESS";
export const ACTIVATE_HOST = "profile/ACTIVATE_HOST";
export const ACTIVATE_HOST_SUCCESS = "profile/ACTIVATE_HOST_SUCCESS";
export const ACTIVATE_HOST_FAIL = "profile/ACTIVATE_HOST_FAIL";
export const ACTIVATE_HOST_ACCOUNT = "profile/ACTIVATE_HOST_ACCOUNT";
export const ACTIVATE_HOST_ACCOUNT_SUCCESS =
  "profile/ACTIVATE_HOST_ACCOUNT_SUCCESS";
export const ACTIVATE_HOST_ACCOUNT_FAIL = "profile/ACTIVATE_HOST_ACCOUNT_FAIL";
export const REDIRECT_FALSE = "profile/REDIRECT_FALSE";
export const ADD_CARD = "profile/ADD_CARD";
export const ADD_CARD_SUCCESS = "profile/ADD_CARD_SUCCESS";
export const ADD_CARD_FAIL = "profile/ADD_CARD_FAIL";
export const ADD_CARD_RESET = "profile/ADD_CARD_RESET";
export const GET_CARD_DETAILS = "profile/GET_CARD_DETAILS";
export const GET_CARD_DETAILS_SUCCESS = "profile/GET_CARD_DETAILS_SUCCESS";
export const GET_CARD_DETAILS_FAIL = "profile/GET_CARD_DETAILS_FAIL";
export const GET_BANK_DETAILS = "profile/GET_BANK_DETAILS";
export const GET_BANK_DETAILS_SUCCESS = "profile/GET_BANK_DETAILS_SUCCESS";
export const GET_BANK_DETAILS_FAIL = "profile/GET_BANK_DETAILS_FAIL";
export const ACCEPT_TERMS = "profile/ACCEPT_TERMS";
export const ACCEPT_TERMS_SUCCESS = "profile/ACCEPT_TERMS_SUCCESS";
export const ACCEPT_TERMS_FAIL = "profile/ACCEPT_TERMS_FAIL";
