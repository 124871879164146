import React, { Component } from 'react';
import { connect } from 'react-redux';

import Modal from 'react-modal';
import PrimaryButton from '../../../components/PrimaryButton';

class ReportDisputeModal extends Component {

    constructor(props) {
        super(props);

        this.state = ({
            subject: "",
            message: "",
        });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    };

    submitDispute = () => {
        const { subject, message } = this.state;
        const { submitDispute, closeModal } = this.props;
    
        submitDispute(subject, message);
        closeModal()
      };

    render() {
        const { openModal, closeModal,  } = this.props;
        const { subject, message } = this.state;

        return (
            <Modal
            isOpen={openModal}
            className="report-modal"
            overlayClassName="overlay"
            onRequestClose={closeModal}
            ariaHideApp={false}
            >
            
            <div className="modal-title booking">Report Dispute</div>
            
            <img src={require('../../../assets/images/ic_close.svg')} className="close-button booking-close-btn"
                alt=""
                onClick={closeModal}
            />
            
            <div className="flex-center direction-column">
                <div className="note-wrapper review">
                    <input 
                        type="text"
                        name="subject" 
                        value={subject} 
                        onChange={this.handleInputChange} 
                        placeholder="Subject"
                    />
                    <textarea
                        name="message"
                        placeholder="Your message ..."
                        value={message}
                        maxLength="1000"
                        spellCheck="false"
                        onChange={e => {
                            this.handleInputChange(e)
                            this.setState({ nrOfChars: e.target.value.length })
                        }}>{this.state.message}
                    </textarea>
                </div>
                <div className="flex-center" style={{ paddingTop: '30px' }}>
                    <PrimaryButton
                        text="Submit"
                        className="footer-button booking orange-bg"
                        onClick={this.submitDispute}
                    />
                </div>
            </div>

            </Modal>
        );
    }
}

ReportDisputeModal.propTypes = {};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReportDisputeModal);