import React, { Component } from 'react';
import Modal from 'react-modal';

import PrimaryButton from '../../../components/PrimaryButton';
import TextInput from '../../../components/TextInput';
import PropTypes from 'prop-types';
import { changePassword } from '../actions/actions';
import { currentUser, getPhoneNumber, getValidationCode } from '../reducers/selectors';
import connect from 'react-redux/es/connect/connect';
import { PasswordLength } from '../../../settings';


class ResetPasswordModal extends Component {
  state = {
    isPasswordValid: true,
    password: ''
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    })
  }

  changePassword = async () => {
    const { password } = this.state;
    const { phoneNumber, validationCode } = this.props;

    if (!(password.trim().length > PasswordLength))
      this.setState({
        isPasswordValid: false,
      })
    else {
      await this.props.changePassword({ phone: phoneNumber, password, code: validationCode });
      this.props.onSubmit()
    }
  }

  render() {
    const { isPasswordValid } = this.state;
    return (
      <Modal
        ariaHideApp={false}
        isOpen={true}
        className="forgot-password-modal"
        overlayClassName="overlay"
      >
        <img src={require('../../../assets/images/ic_close.svg')} className="close-button" alt=""
          onClick={this.props.onCancel} />
        <div className="modal-title">Reset Password</div>
        <div className="flex-center direction-column forgot-password-content-wrapper">
          <div className="flex-center direction-column">
            <div className="subtitle modal-subtitle">Your new password needs to be at least 6 characters.</div>
            <div className="mobile-nr-wrapper">
              <span className="pass-pic-title">Enter new password</span>
              <TextInput
                type="password"
                className="big-height medium-width"
                name="password"
                onChange={e => {
                  this.handleInputChange(e)
                }}
              />
              {!isPasswordValid &&
                <span className="validation-message">The password you have entered needs to have at least minimum 6 characters. Please try again.</span>}
            </div>
          </div>
          <PrimaryButton text="Reset your password" onClick={this.changePassword} />
        </div>
      </Modal>
    );
  }
}

ResetPasswordModal.propTypes = {
  changePassword: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  changePassword,
};

const mapStateToProps = (state) => ({
  currentUser: currentUser(state),
  phoneNumber: getPhoneNumber(state),
  validationCode: getValidationCode(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModal);