import { SET_SEARCH_RESULTS, SET_INDIVIDUAL_SPACE, SET_SEARCH_QUERIES, SET_SEARCH_RESULTS_SUCCESS } from './actionTypes';

import { Search } from '../../../api/endpoints';

export function setSearchResults(payload) {
  return {
    type: SET_SEARCH_RESULTS,
    payload,
  };
}

export function setSearchResultsSuccess(payload) {
  return {
    type: SET_SEARCH_RESULTS_SUCCESS,
    payload,
  };
}

export function setIndividualSpace(payload) {
  return {
    type: SET_INDIVIDUAL_SPACE,
    payload,
  };
}

export function setSearchQueries(payload) {
  return {
    type: SET_SEARCH_QUERIES,
    payload,
  };
}

export function searchSpaces(payload) {
  return async (dispatch) => {
    dispatch(setSearchResults(payload));

    const response = await Search.searchSpaces(payload);

    dispatch(setSearchResultsSuccess(response));
  };
}

export function searchSpaceTypeAndLocation(payload) {
  return async (dispatch) => {
    dispatch(setSearchResults(payload));

    const response = await Search.searchSpaceTypeAndLocation(payload);

    dispatch(setSearchResultsSuccess(response));
  };
}

export function getSpaceById(payload) {
  return async (dispatch) => {
    const response = await Search.getIndividualSpace(payload);
    dispatch(setIndividualSpace(response));
  };
}
