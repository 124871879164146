import React, { Component } from 'react';
import TextInput from '../../../components/TextInput';


class NoOfPax extends Component {

  render() {
    const { handleChange, isDesk, value } = this.props;
    let placeholder = 0;
    const { isEmpty } = this.props;

    if (isDesk) placeholder = 1;

    return (
      <div className="form-group">
        <div className="input-wrapper quantity-wrapper">
          <div className="col">
            <div className="label">Max No. of Pax <span className="red">{isEmpty && '*'}</span></div>
            <TextInput
              id="spaceMax"
              name="nrOfPax"
              placeholder={placeholder}
              disabled={isDesk}
              onChange={(e) => { handleChange(e) }}
              value={value === 0 ? '' : value}
              maxLength={3}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default (NoOfPax);
