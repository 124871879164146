import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'

class BookingInfo extends Component {

    render() {
        const { bookingId, startDate, endDate, startTime, endTime, nrOfGuests, status, notes, statusColor, spaceName, space } = this.props;
        let date = (
            <div>
                <div className="card-info gray">Confirmed Booking Start & End Date:</div>
                <div className="card-data gray">{startDate} - {endDate} </div>
            </div>
        )

        let time = null;

        let notesArea = null;

        if (notes) {
            notesArea = (
                <div className="booking-notes">
                    <div className="card-info gray">Notes</div>
                    <div className="notes-area">
                        <div className="notes-text">{notes}</div>
                    </div>
                </div>
            )
        }

        if (startDate === endDate) {
            date = (
                <div>
                    <div className="card-info gray">Confirmed Date:</div>
                    <div className="card-data gray">{startDate} </div>
                </div>
            )
            time = (
                <div>
                    <div className="card-info gray">Confirmed Booking Start & End Time:</div>
                    <div className="card-data gray">{startTime} - {endTime}</div>
                </div>
            )
        }
        return (
            <div className="individual-space booking">
                <div className="booking-info">
                    <NavLink to={`/spaces/${space._id}/${space.name.replace(/\W+(?!$)/g, '-').replace(/\W$/, '').toLowerCase()}/${encodeURIComponent(space.location.replace(/\W+(?!$)/g, '-').replace(/\W$/, '').toLowerCase())}/${encodeURIComponent(space.address.replace(/\W+(?!$)/g, '-').replace(/\W$/, '').toLowerCase())}`} >
                        <div className="section-header desktop-similar">{spaceName}</div>
                    </NavLink>
                    <div className="booking-status">
                        <div className="card-info gray">Booking Status:</div>
                        <div className={`card-data ${statusColor}`}>{status}</div>
                    </div>
                    <div>
                        <div className="card-info gray">Booking ID:</div>
                        <div className="card-data gray">{bookingId}</div>
                    </div>
                    <div>
                        {date}
                        {time}
                    </div>
                    <div>
                        <div className="card-info gray">Confirmed Number of Guests:</div>
                        <div className="card-data gray">{nrOfGuests}</div>
                    </div>
                </div>
                {notesArea}
            </div>
        )
    }
}


BookingInfo.propTypes = {
};

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingInfo);
