import { GET_BOOKING_RESPONSE, FETCH_PRICE_SUCCESS, FETCH_PRICE, RESET_PRICE, VALIDATE_BOOKING, VALIDATE_BOOKING_FAIL, VALIDATE_BOOKING_SUCCESS } from '../actions/actionTypes';

import { Map } from 'immutable';

const initialState = Map({
  isLoading: false,
  loading: false,
  error: null
});


function fetchPrice(state, { payload }) {
  return state.set('isLoading', true);
}

function fetchPriceSuccess(state, { payload }) {
  return state.set('price', payload).set('isLoading', false);
}

function resetPrice(state, { payload }) {
  return state.set('price', null);
}

function getBookingResponse(state, { payload }) {
  return state.set('bookingResponse', payload);
}

function validateBookingDates(state, { payload }) {
  return state.set('loading', true);
}

function validateBookingDatesSuccess(state, { payload }) {
  return state.set('loading', false).set('error', null);
}

function validateBookingFail(state, { payload }) {
  return state.set('loading', false).set('error', payload);
}


const handlers = {
  [FETCH_PRICE_SUCCESS]: fetchPriceSuccess,
  [FETCH_PRICE]: fetchPrice,
  [GET_BOOKING_RESPONSE]: getBookingResponse,
  [RESET_PRICE]: resetPrice,
  [VALIDATE_BOOKING]: validateBookingDates,
  [VALIDATE_BOOKING_SUCCESS]: validateBookingDatesSuccess,
  [VALIDATE_BOOKING_FAIL]: validateBookingFail,
};

export default function createBookingReducer(state = initialState, action = {}) {
  const handler = handlers[action.type];

  if (!handler) {
    return state;
  }

  return handler(state, action);
}