import { combineReducers } from 'redux-immutable';

import { authReducer } from '../modules/auth/reducers';
import { searchReducer } from '../modules/search/reducers';
import { posterSpacesReducer } from '../modules/posterSpaces/reducers';
import { individualSpaceReducer } from '../modules/individualSpace/reducers';
import { spaceBookingReducer } from '../modules/individualSpace/reducers';
import { bookingReducer } from '../modules/bookings/reducers';
import { profileReducer } from '../modules/profile/reducers';
import { newSpaceReducer } from '../modules/spacesManagement/reducers';
import { deskiReducer } from '../modules/myDeski/reducers';
import { homeReducer } from '../modules/home/reducers';
import { individualPosterReducer } from '../modules/individualSpacePoster/reducers';
import { configReducer } from '../configReduxSetup/reducers'

export default combineReducers({
  authReducer,
  searchReducer,
  posterSpacesReducer,
  individualSpaceReducer,
  spaceBookingReducer,
  bookingReducer,
  profileReducer,
  newSpaceReducer,
  deskiReducer,
  homeReducer,
  individualPosterReducer, 
  configReducer
});
