import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import history from '../../../history';
import moment from 'moment-timezone';
import { NavLink } from 'react-router-dom';
import Loader from 'react-loader-spinner';

import { currentUser } from '../../auth/reducers/selectors';

import { getUsersBookings } from '../actions/actions';
import { getUsersBookingsLoading, getBookingsPerUser } from '../reducers/selectors';

import ResultCard from '../../../components/ResultCard';

import config from '../../../config/config';
import { Mixpanel } from '../../../util/MixPanel';

class ResultsPage extends Component {

    handlePagination = (pageObj) => {
        const currentPageNumber = pageObj.selected + 1;
        const { type, page } = this.props;

        if (page !== currentPageNumber) {
            history.push({
                pathname: history.location.pathname,
                search: `page=${+currentPageNumber}&type=${type}`
            });
        }
    }

    getPicture = (booking) => {
        let picture = booking.spaceId.ownerId.profilePictureUrl ? booking.spaceId.ownerId.socialLogin ?
            booking.spaceId.ownerId.profilePictureUrl :
            `${config.api}/files/${booking.spaceId.ownerId.profilePictureUrl}` :
            require("../../../assets/images/placeholder_profile.svg")

        if (booking.spaceId.ownerId.hostAccount && booking.spaceId.ownerId.hostAccount.generalInfo && booking.spaceId.ownerId.hostAccount.generalInfo.photoUrl) {
            picture = `${config.api}/files/${booking.spaceId.ownerId.hostAccount.generalInfo.photoUrl}`
        }

        return picture;
    }

    getName = (booking) => {
        let name = `${booking.spaceId.ownerId.firstName} ${booking.spaceId.ownerId.lastName}`;
        if (booking.spaceId.ownerId.hostAccount && booking.spaceId.ownerId.hostAccount.companyDetails && booking.spaceId.ownerId.hostAccount.companyDetails.companyName) {
            name = `${booking.spaceId.ownerId.hostAccount.companyDetails.companyName}`
        }
        return name;
    }

    render() {
        const { page, elementsPerPage, type } = this.props;
        const { bookingsPerUser, bookingsLoading } = this.props;

        if (bookingsLoading || typeof (bookingsPerUser.bookings) === "undefined") {
            return (
                <div className="loader-wrapper">
                    <Loader
                        type="Ball-Triangle"
                        height="100"
                        width="100"
                        color="#0EA800"
                    />
                </div>
            )
        }


        let results = (
            <div className="no-bookings">You currently have no bookings</div>
        )
        let pagination = null;
        let pageCount = (bookingsPerUser.bookings.count % elementsPerPage) === 0 ? Math.trunc(bookingsPerUser.bookings.count / elementsPerPage) : Math.trunc(bookingsPerUser.bookings.count / elementsPerPage) + 1;


        if (bookingsPerUser.bookings.count) {

            if ((bookingsPerUser.bookings.count % elementsPerPage) === 0) {
                pageCount = Math.trunc(bookingsPerUser.bookings.count / elementsPerPage);
            }

            results = (
                <div className="result-cards">
                    {bookingsPerUser.bookings.bookings.map((booking) => (
                        <div className="card-wrapper" key={booking._id}>
                            <NavLink to={`/booking/${booking._id}`} onClick={() => Mixpanel.track("Bookings - opened space", { type })}>
                                <ResultCard
                                    isBookingCard
                                    type={booking.spaceId.type}
                                    spaceName={booking.spaceId.name}
                                    startDate={moment(booking.startDate).tz(booking.timeZone).format('DD MMM YYYY')}
                                    endDate={moment(booking.endDate).tz(booking.timeZone).format('DD MMM YYYY')}
                                    startTime={moment(booking.startTime, ["h"]).format('h:mm A')}
                                    endTime={moment(booking.endTime, ["h"]).format('h:mm A')}
                                    posterPic={this.getPicture(booking)}
                                    posterName={this.getName(booking)}
                                    isCanceled={booking.canceled}
                                    photoUrl={booking.spaceId.photosUrls[0].photoUrl}
                                />
                            </NavLink>
                        </div>
                    ))
                    }
                </div>
            )

            if (pageCount > 1)
                pagination = (
                    <ReactPaginate
                        pageCount={pageCount}
                        forcePage={+page.page - 1}
                        containerClassName="flex-center pagination spacing"
                        previousLabel={
                            <div className="page-switcher flex-center">
                                <img
                                    className="previous label"
                                    src={require('../../../assets/images/ic_arrow.svg')}
                                    alt=""
                                /></div>}
                        nextLabel={
                            <div className="page-switcher flex-center">
                                <img
                                    className="label"
                                    src={require('../../../assets/images/ic_arrow.svg')}
                                    alt=""
                                />
                            </div>}
                        pageLinkClassName="pagination padding-page"
                        previousLinkClassName="pagination"
                        nextLinkClassName="pagination"
                        breakLabel="..."
                        breakClassName="break flex-center"
                        breakLinkClassName="pagination"
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePagination}
                        pageClassName="page flex-center"
                        activeClassName="active-page"
                    />
                )

        }

        return (
            <div>
                {Object.keys(bookingsPerUser.bookings).length &&
                    <div>
                        <div>
                            {results}
                        </div>
                        {pagination}
                    </div>
                }
            </div>
        )
    }
}


ResultsPage.propTypes = {
    getUsersBookings: PropTypes.func.isRequired,

};

const mapDispatchToProps = {
    getUsersBookings
};

const mapStateToProps = (state) => ({
    bookingsPerUser: getBookingsPerUser(state),
    bookingsLoading: getUsersBookingsLoading(state),
    currentUser: currentUser(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultsPage);








