import React, { Component } from 'react';

export default class SpaceCard extends Component {
    state = {}

    render() {
        const { text, icon, isSelected, onClick, isInvalid, size } = this.props;

        return (
            // eslint-disable-next-line react/button-has-type
            <div className={`space-card flex-center direction-column ${size > 6 ? 'small-card-size' : ''} ${isSelected ? 'card-selected' : ''} ${isInvalid ? 'invalid' : ''}`} onClick={onClick}>
                <div className="card-icon-wrapper">
                    <img
                        className={`card-icon ${isSelected ? 'card-selected-icon' : ''}`}
                        src={icon}
                        alt=""
                    />
                </div>
                <span className={`space-card-text ${isSelected ? 'card-selected-text' : ''}`}>
                    {text}
                </span>
            </div>
        );
    }
}
