import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ShowMoreText from 'react-show-more-text';

import config from '../config/config';

class Review extends Component {


  render() {
    const { firstIndexRegular, lastIndexRegular, ratings } = this.props;

    let user = 'userId';
    if (Object.keys(ratings).length && ratings[0].hostId) {
      user = 'hostId';
    }

    return (

      <div>
        {ratings.slice(firstIndexRegular, lastIndexRegular).map(review =>
          (
            <div className="review" key={review._id}>
              <div className="poster-details">
                {review[user] &&
                  <div
                    className="small-img-poster"
                    style={{
                      backgroundImage: `url(${review[user].profilePictureUrl ?
                        review[user].socialLogin ?
                          review[user].profilePictureUrl :
                          `${config.api}/files/${review[user].profilePictureUrl}` :
                        require("../assets/images/placeholder_profile.svg")})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center center'
                    }}>
                  </div>
                }
                <div className="text-details-wrapper">
                  {review[user] &&
                    <div className="poster-name">
                      {review[user].firstName} {review[user].lastName}
                    </div>
                  }
                  {review.createdTs &&
                    <div className="nr-of-spaces">
                      {moment(review.createdTs).format("MMMM YYYY")}
                    </div>
                  }
                </div>
              </div>
              <div className="review-text">
                <ShowMoreText
                  lines={5}
                  more={<span className="max-guests"><b>Show more</b></span>}
                  less={<span className="max-guests"><b>Show less</b></span>}
                >
                  {review.message}
                </ShowMoreText>
              </div>
            </div>
          )
        )}
      </div>
    )
  }
}


Review.propTypes = {
};

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Review);









