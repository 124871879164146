import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class MainModal extends Component {
    state = {
        close: false
    }
    closeModal = () => {
        this.setState({
            closed: true
        })
    }
    render() {
        const { className, title, noPhoneTitle, noCloseButton, isLeftModal, isAuth, closeButtonStyle } = this.props;
        return (
            <div className="page-template landing-page">
                <div className="page-wrapper">
                    <div
                        className={`main-modal ${isAuth ? 'no-navbar' : ''} ${isLeftModal ? 'left-modal' : ''} ${className} ${this.state.closed ? 'closed-modal' : ''}`}>

                        {!noCloseButton &&
                            <NavLink to={`/`}>
                                <img src={require('../assets/images/ic_close.svg')} className={`close-button ${closeButtonStyle}`} alt="" />
                            </NavLink>
                        }
                        <div className={`modal-title ${noPhoneTitle ? 'no-display' : ''}`}>{title}</div>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}