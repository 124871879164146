export const BookingStates = {
    confirmed: {
        name: "Confirmed",
        color: "green"
    },
    completed: {
        name: "Completed",
        color: "green"
    },
    canceled: {
        name: "Cancelled",
        color: "red"
    }
};

export const Regex = {
    phoneRegex: /^\+?(?:[0-9] ?){6,14}[0-9]$/,
    emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    // phoneRegexAustralia: /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/
    phoneRegexAustralia: /(\+?( |-|\.)?\d{1,2}( |-|\.)?)?(\(?\d{3}\)?|\d{3})( |-|\.)?(\d{3}( |-|\.)?\d{4})/g
};

export const ResendWaitingTime = 30000;

export const PasswordLength = 5;

export const MaxPictureSize = 5;

export const spaces = [
    {
        id: "desk",
        name: "Coworking (Desk)",
        url: "desks-coworking-spaces",
        icon: "ic_desk.svg",
        cardIcon: "ic_category_desk.svg",
        metaTitle: "Coworking Spaces and Desks for Rent on Deski",
        metaDescription: "Book short and long term coworking spaces and hot desks on Deski - the easy wasy to find a flexible place to work. See spaces and prices."
    },
    {
        id: "privateOffice",
        name: "Private Office",
        url: "office-spaces",
        icon: "ic_office.svg",
        cardIcon: "ic_category_private.svg",
        metaTitle: "Shared and Private Office Spaces for Rent on Deski",
        metaDescription: "Book a shared or private office space on Deski - the easy wasy to find a flexible place to work. See spaces and prices."
    },
    {
        id: "groupMeeting",
        name: "Group Meetings",
        url: "meeting-spaces",
        icon: "ic_meeting.svg",
        cardIcon: "ic_category_meeting.svg",
        metaTitle: "Meeting Spaces and Rooms for Rent on Deski",
        metaDescription: "Book short term meeting rooms and spaces on Deski - the easy wasy to find a flexible place to work. See spaces and prices."
    },
    {
        id: "creative",
        name: "Creative",
        url: "creative-spaces",
        icon: "ic_creative.svg",
        cardIcon: "ic_category_creative.svg",
        metaTitle: "Creative Spaces to Work for Rent on Deski",
        metaDescription: "Book unique and creative spaces to work and meet on Deski - the easy wasy to find a flexible place to work. See spaces and prices."
    },
    // {
    //     id: "creatisdsve",
    //     name: "Creative sdsd",
    //     url: "creative-spaces",
    //     icon: "ic_creative.svg"
    // },
    // {
    //     id: "creatisdssdsve",
    //     name: "Creasdstive sdsd",
    //     url: "creative-spaces",
    //     icon: "ic_creative.svg"
    // },
]

export const MinNoOfPictures = 3;

export const MaxNoOfPictures = 10;

export const cities = [
    {
        name: "Sydney NSW, Australia",
        icon: "demo_browse_sydney.jpg"
    },
    {
        name: "Brisbane QLD, Australia",
        icon: "demo_browse_brisbane.jpg"
    },
    {
        name: "Canberra ACT, Australia",
        icon: "demo_browse_canberra.jpg"
    },
    {
        name: "Hobart TAS, Australia",
        icon: "demo_browse_hobart.jpg"
    },
    {
        name: "Melbourne VIC, Australia",
        icon: "demo_browse_melbourne.jpg"
    },
    {
        name: "Perth WA, Australia",
        icon: "demo_browse_perth.jpg"
    },
    {
        name: "Adelaide SA, Australia",
        icon: "demo_browse_adelaide.jpg"
    },
    {
        name: "Darwin NT, Australia",
        icon: "demo_browse_darwin.jpg"
    },
]

export const SpaceType = {
    desk: {
        id: "desk",
        name: "Coworking (Desk)",
        url: "desks-coworking-spaces",
        description: "Get amongst it all and work like you are a part of a team, with people on the same wavelength"
    },
    privateOffice: {
        id: "privateOffice",
        name: "Private Office",
        url: "office-spaces",
        description: "Have your own quiet space to call prospects, clients, close deals and just get things done!"
    },
    groupMeeting: {
        id: "groupMeeting",
        name: "Group Meeting",
        url: "meeting-spaces",
        description: "Get that team together and thrash it out, or present the perfect business deal to clients."
    },
    creative: {
        id: "creative",
        name: "Creative Space",
        url: "creative-spaces",
        description: "Get inspiration to get your best work done in the perfect environment."
    }
};

export const Countries = {
    Australia: {
        name: "Australia",
        code: "AU"
    }
};

export const UserType = {
    personal: "individual",
    business: "company"
};

export const AccountType = {
    savings: "savings",
    checking: "checking"
};

export const DateType = {
    HOURLY: "hourly",
    DAILY: "daily",
    WEEKLY: "weekly",
    MONTHLY: "monthly"
};

export const TimeLimits = {
    MIN_HOUR: 7,
    MAX_HOUR: 23
}

export const MinimumNights = {
    DAILY: 0,
    WEEKLY: 6,
    MONTHLY: 28,
}

export const MapsApiKey = "AIzaSyBOKfqQ4jZtuoQX_dCocGiThiN9tGfbTeE";

export const PhonePrefixes = {
    Australia: {
        prefix: "+61",
        icon: "AU.svg"
    },
    NewZeeland: {
        prefix: "+64",
        icon: "NZ.svg"
    },
    Romania: {
        prefix: "+40",
        icon: "NZ.svg"
    }
}

export const stripeApiKey = "pk_test_BwsxYCSFVMdEzvgsYrDkJt5n";
