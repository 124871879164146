import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Mixpanel } from '../../../util/MixPanel';

class BookingSwitch extends Component {


  chooseType = (e) => {
    const { selectedType } = this.props;

    if (selectedType !== e.target.id) {
      this.props.getSelected(e.target.id);
    }

    Mixpanel.track("Bookings - switched type", { type: e.target.id })
  }

  render() {
    const { selectedType } = this.props;

    return (

      <table className="switch-wrapper">
        <tbody>
          <tr className="switch-wrapper">
            <th
              id="active"
              className={`switch-button ${selectedType === 'active' && 'active'}`}
              onClick={(e) => this.chooseType(e)}>
              ACTIVE
            </th>
            <th
              id="past"
              className={`switch-button ${selectedType === 'past' && 'active'}`}
              onClick={(e) => this.chooseType(e)}>
              PAST
          </th>
          </tr>
        </tbody>
      </table>


    )
  }
}


BookingSwitch.propTypes = {
};

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingSwitch);
