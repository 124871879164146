import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { validateDate, cardFormat, dateFormat } from '../../../util/utils';


import { searchSpaces } from '../actions/actions';
import { getSearchResults } from '../reducers/selectors';
import { updateUser } from '../../auth/actions/actions'
import { currentUser } from '../../../modules/auth/reducers/selectors';

import PrimaryButton from '../../../components/PrimaryButton';
import Input from '../../../components/Input';

class EditModal extends Component {

    constructor(props) {
        super(props);
        const { currentUser } = this.props;
        const { paymentMethods } = currentUser;
        const currentUserLength = Object.keys(currentUser).length;
        const paymentMethodsLength = paymentMethods ? paymentMethods.length : 0;
        const { _id, name, expMonth, expYear } = paymentMethodsLength ? paymentMethods[0] : {}


        this.state = {
            notComplete: false,
            modalHeight: 653 + 'px',
            name: currentUserLength && paymentMethodsLength ? name : '',
            cardNumber: '',
            expMonth: currentUserLength && paymentMethodsLength ? expMonth : '',
            expYear: currentUserLength && paymentMethodsLength ? expYear : '',
            _id: currentUserLength && paymentMethodsLength ? _id : '',
            securityCode: ''
        }
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === "securityCode") {
            value = value.replace(/[\D+$ ]/g, "");
        }

        if (name === "name") {
            value = value.replace(/[^A-Za-z ]/g, "");
        }

        this.setState({ [name]: value })
    }

    updateUser = async (id, name, cardNumber, expMonth, expYear, securityCode) => {
        try {
            await this.props.updateUser({
                paymentMethodId: id,
                name,
                cardNumber,
                expMonth,
                expYear,
                cvv: securityCode
            });
            this.props.closeModal()
        }
        catch (e) {
            this.setState({ cardError: "Please enter a valid credit card." })
        }
    }

    checkAndSubmit = async () => {

        const { name, cardNumber, expMonth, expYear, _id, securityCode } = this.state;
        const { currentUser } = this.props;
        const { paymentMethods } = currentUser;

        this.setState({
            notComplete: false,
            cardError: false, 
            invalidDate: false
        })
        
        if (name === '' && cardNumber === '' && expMonth === '' && expYear === '' && _id === '') {
            if (Object.keys(currentUser).length && Object.keys(currentUser.paymentMethods).length) {
                this.setState({
                    name: paymentMethods[0].name,
                    cardNumber: paymentMethods[0].cardNumber,
                    expMonth: paymentMethods[0].expMonth,
                    expYear: paymentMethods[0].expYear,
                    _id: paymentMethods[0]._id,
                })
            }
        }
        else {

            if (!name || !cardNumber || !expMonth || !expYear || !securityCode) {
                this.setState({ notComplete: true })
            }
            else {
                if (!validateDate(expMonth, expYear)) {
                    this.setState({ invalidDate: true })
                }
                else {
                    this.setState({ notComplete: false, invalidDate: false })
                    let formattedCardNumber = cardNumber.replace(/-/g, '')

                    await this.updateUser(_id, name, formattedCardNumber, +expMonth, +(`${expYear.toString().length > 2 ? '' : '20'}${expYear}`), securityCode);

                }
            }
        }

    }



    renderEditDetails = () => {
        const { notComplete, _id, name, expMonth, expYear, invalidDate, cardError, securityCode } = this.state;
        const { currentUser } = this.props;
        if (!Object.keys(currentUser.paymentMethods).length)
            return;
        return (
            <div>
                <div className="modal-title white booking">{this.props.name}</div>
                <img src={require('../../../assets/images/ic_close.svg')} className="close-button  booking-close-btn white" alt=""
                    onClick={this.props.closeModal} />

                <div className="flex-center direction-column" key={_id}>
                    <div>
                        <div className="payment-input-wrapper">
                            <Input
                                id="payment-input"
                                className="uppercase"
                                placeholder="Name"
                                name="name"
                                value={name}
                                onChange={e => {
                                    this.handleInputChange(e)
                                }}
                            />
                        </div>
                        <div className="payment-input-wrapper">
                            <Input
                                id="payment-input"
                                placeholder="Card Number"
                                name="cardNumber"
                                // defaultValue={currentUser.paymentMethods[0].cardNumber}
                                maxLength={19}
                                onChange={e => {
                                    cardFormat(e)
                                    this.handleInputChange(e)
                                }}
                                onFocus={e => e.target.value = ''}
                            />
                        </div>
                        <div className="line-inputs">
                            <div className="payment-input-wrapper">
                                <Input
                                    id="payment-input"
                                    placeholder="Expiration Date"
                                    name="expDate"
                                    defaultValue={`${expMonth} /${expYear % 100}`}
                                    maxLength={5}
                                    onChange={e => {
                                        dateFormat(e)
                                        this.setState({ expMonth: e.target.value.split('/')[0], expYear: e.target.value.split('/')[1] })
                                    }
                                    }
                                    onFocus={(e) => e.target.placeholder = "MM/YY"
                                    }
                                    small
                                />
                            </div >
                            <div className="payment-input-wrapper">
                                <Input
                                    id="payment-input"
                                    placeholder="Security Code"
                                    name="securityCode"
                                    onChange={e => {
                                        this.handleInputChange(e)
                                    }}
                                    small
                                    maxLength={4}
                                    value={securityCode}
                                />
                            </div>
                        </div >
                    </div >
                    {invalidDate && <span className="validation-message">Invalid Expiration Date. Please try again.</span>}
                    {notComplete && <span className="validation-message">Please fill in all Payment Information.</span>}
                    {cardError && <span className="validation-message">{cardError}</span>}
                    <PrimaryButton text="Update" className="top-margin absolute-button" onClick={this.checkAndSubmit} />
                </div >
            </div >
        )
    }


    render() {
        const customStyles = {
            content: {
                height: this.state.modalHeight
            }
        };
        return (
            <Modal
                isOpen={this.props.modalIsOpen}
                className="payment-modal"
                style={customStyles}
                onRequestClose={this.props.closeModal}
                ariaHideApp={false}
            >
                {Object.keys(this.props.currentUser).length && Object.keys(this.props.currentUser.paymentMethods).length ?
                    this.renderEditDetails()
                    :
                    <div>Please Log In. </div>
                }

            </Modal>
        );
    }
}

EditModal.propTypes = {
    searchSpaces: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    searchSpaces,
    updateUser
};

const mapStateToProps = (state) => ({
    searchResults: getSearchResults(state),
    currentUser: currentUser(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(EditModal);
