import { SET_CONFIG, SET_CONFIG_SUCCESS, SET_CONFIG_FAIL, SET_FAQ, SET_FAQ_SUCCESS, SET_FAQ_FAIL } from './actionTypes';

import { Config } from '../../api/endpoints';
import { saveConfigToStorage } from '../../util/utils'


export function setConfig(payload) {
    return {
        type: SET_CONFIG,
        payload,
    };
}

export function setConfigSuccess(payload) {
    return {
        type: SET_CONFIG_SUCCESS,
        payload,
    };
}

export function setConfigFail(payload) {
    return {
        type: SET_CONFIG_FAIL,
        payload,
    };
}


export function setFAQ(payload) {
    return {
        type: SET_FAQ,
        payload,
    };
}

export function setFAQSuccess(payload) {
    return {
        type: SET_FAQ_SUCCESS,
        payload,
    };
}

export function setFAQFail(payload) {
    return {
        type: SET_FAQ_FAIL,
        payload,
    };
}


export function setConfigAction(payload) {
    return async (dispatch) => {
        dispatch(setConfig(payload))
        try {
            const response = await Config.getConfig(payload);
            dispatch(setConfigSuccess(response))
            saveConfigToStorage(response)
        }
        catch (e) {
            dispatch(setConfigFail(e))
        }
    };
}

export function setFaqAction(payload) {
    return async (dispatch) => {
        dispatch(setFAQ(payload))
        try {
            const response = await Config.getFAQ(payload);
            dispatch(setFAQSuccess(response))
            saveConfigToStorage(response)
        }
        catch (e) {
            dispatch(setFAQFail(e))
        }
    };
}