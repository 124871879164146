import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import NumberInput from '../../../components/NumberInput';

import { connect } from 'react-redux';
import { currentUser } from '../reducers/selectors';
import { goToPageBasedOnUser } from '../../../util/utils'
import { sendSmsCode, verifySmsCode, updateUser } from '../actions/actions';


import MainModal from '../../../components/MainModal';
import TextInput from '../../../components/TextInput';
import PrimaryButton from '../../../components/PrimaryButton';
import SecondaryButton from '../../../components/SecondaryButton';
import { ResendWaitingTime, Regex } from '../../../settings';
import history from '../../../history'

class AddMissingInfoScreen extends Component {
    state = {
        modalIsOpen: false,
        invalidFields: {},
        isPhoneVerified: false
    }

    componentDidMount() {
        const { currentUser } = this.props;

        if (!Object.keys(currentUser).length) {
            history.push("/login");
        }

        const { email = '', phone = '' } = currentUser;

        if (email.length && phone.length) {
            history.push("/");
        }
        document.title = 'Deski';
    }

    openModal = () => {
        this.setState({ modalIsOpen: true });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    sendCode = async () => {
        const { phoneNumber, prefix, resendEnabled } = this.state;
        try {
            await this.props.sendSmsCode({ phone: (prefix + phoneNumber).replace(/\+|-/g, ''), forgot: false });
            this.setState({ modalIsOpen: true, resendEnabled: resendEnabled === null ? true : false, AccountAlreadyConnected: null });
        }
        catch (e) {
            let error = JSON.parse(e.message);
            if (error.error.code === "AccountAlreadyConnected")
                this.setState({ [error.error.code]: "The phone number you entered is already registred. Please try again." })
            else
                this.setState({ [error.error.code]: "An error occured. Please try again with a valid phone number." })

        }

        setTimeout(() => {
            this.setState({ resendEnabled: true })
        }, ResendWaitingTime);

    }

    verifySmsCode = async () => {
        const { verifyCode, phoneNumber, prefix } = this.state
        try {
            const response = await this.props.verifySmsCode({ code: verifyCode, phone: (prefix + phoneNumber).replace(/\+|-/g, '') })
            if (response.isCodeValid) {
                this.setState({
                    modalIsOpen: false,
                    isCodeVerified: true,
                    isPhoneVerified: true
                });
            }
        } catch (e) {
            this.setState({ isCodeVerified: false });
        }
    }

    updateUser = async () => {
        await this.props.updateUser({ email: this.state.email, phone: this.state.phoneNumber });
        goToPageBasedOnUser(this.props);
    }

    validatePhoneNumber = (e) => {
        this.validate(e);
        this.handleInputChange(e);
    }

    validate = (e) => {
        const { invalidFields } = this.state;
        const type = e.target.id;
        const value = e.target.value;

        const validations = {
            email: Regex.emailRegex.test(value),
            phone: value.replace(/\+|-/g, '').length >= 7,
        }
        let invalidArray = invalidFields;
        invalidArray[type] = !validations[type];
        this.setState({ invalidFields: invalidArray, Unknown: '' });
    }

    isFormValid = () => {
        const { email = '', phone = '' } = this.props.currentUser;
        let emailValidation = (typeof this.state.invalidFields['email'] !== undefined) && (this.state.invalidFields['email'] === false);
        let phoneValidation = (typeof this.state.invalidFields['phone'] !== undefined) && (this.state.invalidFields['phone'] === false) && (this.state.isPhoneVerified === true);
        if (!email.length && !phone.length) return emailValidation && phoneValidation;
        else if (!email.length) return emailValidation;
        else if (!phone.length) return phoneValidation;


        return ['email', 'phone'].every(x => (typeof this.state.invalidFields[x] !== undefined) && this.state.invalidFields[x] === false)
    }
    getPhoneNumber = (e) => {
        this.setState({ phoneNumber: e.target.value })
    }


    getPrefix = (prefix) => {
        this.setState({ prefix })
    }

    render() {
        const { invalidFields, AccountAlreadyConnected, isCodeVerified, resendEnabled, prefix, phoneNumber, Unknown } = this.state;
        const { email = '', phone = '' } = this.props.currentUser;

        return (
            <MainModal title="Complete Your Profile" className="add-info-modal" noCloseButton>
                <div className="flex-center direction-column content-wrapper sign-up">
                    <Modal
                        ariaHideApp={false}
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        className="insert-code-modal"
                        overlayClassName="overlay"
                    >
                        <div className="code-modal-title">Insert the code</div>
                        <div className="code-modal-text">We sent the code to <br /> <b>{prefix + phoneNumber}</b></div>
                        <input
                            name="verifyCode"
                            className={`code-modal-input ${isCodeVerified === false ? 'invalid' : ''}`}
                            onChange={e => {
                                this.handleInputChange(e)
                            }}
                        />
                        <div className="buttons-wrapper">
                            <button className="resend-button" disabled={!resendEnabled} onClick={this.sendCode}>Resend</button>
                            <SecondaryButton text="Submit" onClick={this.verifySmsCode} />
                        </div>
                    </Modal>
                    <div className="inputs-wrapper">
                        {!email.length &&
                            <div>
                                <TextInput
                                    id="email"
                                    name="email"
                                    placeholder="Email address"
                                    type="email"
                                    isInvalid={invalidFields.email}
                                    className="big-width"
                                    onBlur={event => this.validate(event)}
                                    onChange={e => {
                                        this.handleInputChange(e)
                                    }}
                                />
                            </div>
                        }
                        {invalidFields.email &&
                            <span className="validation-message">Please enter an email address with the format email@email.com.</span>}
                        {!phone.length &&
                            <div>
                                <span className="medium-text">Enter your phone number for verification.</span>
                                <div className="code-wrapper">
                                    <NumberInput
                                        id="phone"
                                        name="phoneNumber"
                                        type="text"
                                        onChange={event => this.validatePhoneNumber(event)}
                                        isInvalid={invalidFields.phone}
                                        onBlur={event => this.getPhoneNumber(event)}
                                        className="number-input-width missing-info"
                                        readOnly={this.state.isCodeVerified && "readOnly"}
                                        getPrefix={this.getPrefix}
                                        onPrefixChange={() => this.setState({ isCodeVerified: false })}
                                    />
                                    {isCodeVerified ?
                                        <div className="secondary-button flex-center green-bg">
                                            <img src={require('../../../assets/images/ic_check.svg')} className="social-media-img" alt="" />
                                            <span className="secondary button-text">Verified</span>
                                        </div>
                                        :
                                        <SecondaryButton
                                            disabled={!((typeof this.state.invalidFields['phone'] !== undefined) && (this.state.invalidFields['phone'] === false))}
                                            text="Send code"
                                            onClick={this.sendCode}
                                            className="missing-info" />
                                    }
                                </div>
                                {invalidFields.phone && <span className="validation-message">Please enter a valid phone number.</span>}
                                {AccountAlreadyConnected && <span className="validation-message">{AccountAlreadyConnected}</span>}
                                {Unknown && <span className="validation-message">{Unknown}</span>}
                            </div>
                        }
                    </div>
                    <div>
                        <PrimaryButton disabled={!this.isFormValid() || !isCodeVerified} text="Done" className="top-margin-phone" onClick={this.updateUser} />
                    </div>
                </div>
            </MainModal>
        );
    }
}

AddMissingInfoScreen.propTypes = {
    sendSmsCode: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    sendSmsCode,
    verifySmsCode,
    updateUser
};

const mapStateToProps = (state) => ({
    currentUser: currentUser(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMissingInfoScreen);
