import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { get } from 'lodash-es';

import { currentUser } from '../../auth/reducers/selectors';
import { getHostBookings } from '../actions/actions';
import { hostBookings, hostBookingsLoading } from '../reducers/selectors';

import PrimaryButton from '../../../components/PrimaryButton';
import DeskiSwitch from '../components/DeskiSwitch';
import Earnings from '../components/Earnings';
import PosterBookings from '../components/PosterBookings';
import PosterListings from '../components/PosterListings';
import queryString from 'query-string'
import history from '../../../history';
import ActivateHost from '../components/ActivateHost';
import { Mixpanel } from '../../../util/MixPanel';
import { amountFormat } from '../../../util/utils';

const BOOKINGS_PER_PAGE = 12;

class MyDeski extends Component {

  getBookings = () => {
    const { getHostBookings } = this.props;
    const page = queryString.parse(this.props.location.search).page - 1;

    getHostBookings({
      perPage: BOOKINGS_PER_PAGE,
      page: page,
    });
  }

  componentDidMount() {
    this.getBookings();
    document.title = 'Deski';

    const type = queryString.parse(this.props.location.search).type;
    Mixpanel.track("Accessed My Deski", { type })
  }

  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0);
    const type = queryString.parse(this.props.location.search).type;

    if (prevProps.location.search !== this.props.location.search && type === 'bookings') {
      this.getBookings();
    }
  }

  getSelected = (selected) => {
    let page = '&page=1';
    history.push({
      pathname: history.location.pathname,
      search: `type=${selected}${page}`
    });
  }

  renderContent = () => {
    let pageContent = null;
    let stickyFooter = null;
    const { hostBookings } = this.props;
    const type = queryString.parse(this.props.location.search).type;
    const page = queryString.parse(this.props.location.search).page;

    const today = amountFormat(get(hostBookings, 'response.earnings.today', 0).toFixed(2))
    const week = amountFormat(get(hostBookings, 'response.earnings.week', 0).toFixed(2))
    const lifetime = amountFormat(get(hostBookings, 'response.earnings.lifeTime', 0).toFixed(2))

    if (Object.keys(hostBookings).length) {
      if (type === 'bookings') {
        pageContent = (
          <div className="page-content bookings-tab">
            <Earnings
              today={today}
              thisWeek={week}
              lifetime={lifetime}
            />
            <div className="separation-line"></div>
            <PosterBookings
              response={hostBookings}
              page={page}
              type={type}
              bookingsPerPage={BOOKINGS_PER_PAGE}
            />
          </div>
        )
      }

      else if (type === 'viewSpace') {
        pageContent = (
          <div className="page-content view-space-tab">
            <PosterListings
              page={page}
              type={type}
            />
          </div>
        );

        stickyFooter = (
          <div className="space-footer create-new-space">
            <NavLink to={`/space/add`} onClick={() => Mixpanel.track("Clicked Create new space")}>
              <PrimaryButton text="Create new space" />
            </NavLink>
          </div>
        )
      }
    }

    return (
      <div>
        <div className="page-wrapper">
          <div className="page-header">
            <h1 className="page-title">My Deski</h1>
            <DeskiSwitch
              getSelected={this.getSelected}
              selectedType={type}
            />
          </div>
          {pageContent}
        </div>
        {stickyFooter}
      </div>
    )
  }

  render() {
    const { currentUser } = this.props;
    const { bookingsLoading } = this.props;

    if (bookingsLoading) {
      return (
        <div className="page-template my-deski-page">
          <div className="loader-wrapper">
            <Loader
              type="Ball-Triangle"
              height="100"
              width="100"
              color="#0EA800"
            />
          </div>

        </div>
      )
    }

    let pageWrapper = (
      <ActivateHost />
    )

    if (currentUser.hostAccount.active) {
      pageWrapper = this.renderContent();
    }

    return (
      <div className="page-template my-deski-page">
        {pageWrapper}
      </div>
    )
  }
}


MyDeski.propTypes = {
  getHostBookings: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getHostBookings
};

const mapStateToProps = (state) => ({
  currentUser: currentUser(state),
  hostBookings: hostBookings(state),
  bookingsLoading: hostBookingsLoading(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(MyDeski);
