import { SET_SEARCH_RESULTS, SET_INDIVIDUAL_SPACE, SET_SEARCH_QUERIES, SET_SEARCH_RESULTS_SUCCESS } from '../actions/actionTypes';
import { Map } from 'immutable';

import { DateType } from '../../../settings';

const initialState = Map({
  searchResults: {},
  individualSpace: {},
  searchQueries: {
    selectedOption: '',
    startDate: null,
    endDate: null,
    guestsNumber: 1,
    dateType: DateType.DAILY,
    startHour: null,
    endHour: null,
  },
  isLoading: false
});

function setSearchResults(state, { payload }) {
  return state.set('isLoading', true);
}

function setSearchResultsSuccess(state, { payload }) {
  return state.set('searchResults', payload.spaces).set('isLoading', false);
}

function setIndividualSpace(state, { payload }) {
  return state.set('individualSpace', payload);
}

function setSearchQueries(state, { payload }) {
  let newSearchQueries = { ...state.get('searchQueries') };

  Object.keys(payload).forEach(key => {
    newSearchQueries[key] = payload[key];
  });

  return state.set('searchQueries', newSearchQueries);
}

const handlers = {
  [SET_SEARCH_RESULTS]: setSearchResults,
  [SET_SEARCH_RESULTS_SUCCESS]: setSearchResultsSuccess,
  [SET_INDIVIDUAL_SPACE]: setIndividualSpace,
  [SET_SEARCH_QUERIES]: setSearchQueries,
};

export default function createSearchReducer(state = initialState, action = {}) {
  const handler = handlers[action.type];

  if (!handler) {
    return state;
  }

  return handler(state, action);
}
