import React, { Component } from 'react';
import TextInput from '../../../components/TextInput';


class SpaceRates extends Component {

  render() {
    const { handleChange, perHour, perDay, perWeek, perMonth } = this.props;
    const { isEmpty } = this.props;

    return (
      <div className="form-group">
        <div className="label">Rates <span className="red">{isEmpty && '*'}</span></div>
        <div className="rates-wrapper">
          <div className="col">
            <TextInput
              id="spaceMax"
              name="perHour"
              placeholder="$0"
              value={perHour === 0 ? '' : perHour}
              onChange={(e) => { handleChange(e) }}
            />
            <span>Per hour</span>
          </div>
          <div className="col">
            <TextInput
              id="spaceMax"
              name="perDay"
              placeholder="$0"
              value={perDay === 0 ? '' : perDay}
              onChange={(e) => { handleChange(e) }}
            />
            <span>Per Day</span>
          </div>
          <div className="col">
            <TextInput
              id="spaceMax"
              name="perWeek"
              placeholder="$0"
              value={perWeek === 0 ? '' : perWeek}
              onChange={(e) => { handleChange(e) }}
            />
            <span>Per Week</span>
          </div>
          <div className="col">
            <TextInput
              id="spaceMax"
              name="perMonth"
              placeholder="$0"
              value={perMonth === 0 ? '' : perMonth}
              onChange={(e) => { handleChange(e) }}
            />
            <span>Per Month</span>
          </div>
        </div>
      </div>
    )
  }
}


export default (SpaceRates);
