import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

class TimeTablePanel extends Component {
    weekDays = {
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
        7: "Sunday"
    }

    render() {
        const { individualSpace, individualBooking } = this.props;

        let spaceInfo = null;

        if (individualSpace) {
            spaceInfo = individualSpace.space;
        }
        else if (individualBooking) {
            spaceInfo = individualBooking.booking[0].spaceId;
        }

        if (typeof (spaceInfo) === "undefined") {
            return (<div>Loading</div>);
        }

        const bookingHours = spaceInfo.bookingHours && Object.keys(spaceInfo.bookingHours).length ? spaceInfo.bookingHours.days : {};

        let weekdaysContent = Object.keys(this.weekDays).map((day) => (
            <div className="weekday" key={day}>
                {this.weekDays[day]}
            </div>
        ));

        let bookingHoursContent = Object.keys(this.weekDays).map((day) => {
            if (!bookingHours.hasOwnProperty(day)) {
                return (
                    <div className="opened-hours" key={day}>
                        Closed
                </div>);
            } else {
                let startTime = moment(bookingHours[day].startTime, ["h"]).format('h a');
                let endTime = moment(bookingHours[day].endTime, ["h"]).format('h a');

                return (
                    <div className="opened-hours" key={day}>
                        {startTime} - {endTime}
                    </div>
                );
            }
        })

        return (
            <div className="justify-between">
                <div>{weekdaysContent}</div>
                <div>{bookingHoursContent}</div>
            </div>
        );
    }
}

TimeTablePanel.propTypes = {
};

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeTablePanel);