import React, { Component } from 'react';
import { MaxPictureSize, MinNoOfPictures, MaxNoOfPictures } from '../../../settings'
import PictureComponent from './PictureComponent';
import moment from 'moment';

class SpacePictures extends Component {

  state = {
    previews: []
  }

  readURL = (event) => {
    const { setPictures } = this.props;
    const { previews } = this.state;
    const input = event.target;

    event.preventDefault();

    if (input.files && input.files[0]) {
      let reader = new FileReader();
      const sizeInMB = input.files[0].size / 1024 / 1024;

      if (sizeInMB > MaxPictureSize) {
        this.setState({ invalidFileSize: true })
      }

      else {
        reader.onload = (e) => {
          let picturesArray = JSON.parse(JSON.stringify(previews));

          picturesArray.push(e.target.result);

          this.setState({
            previews: picturesArray,
            invalidFileSize: false
          });

          setPictures(picturesArray);
        };

        reader.readAsDataURL(input.files[0]);
      }
    }
  };

  removePhoto = (position) => {
    const { previews } = this.state;
    let picturesArray = JSON.parse(JSON.stringify(previews));
    // let newPicturesArray = remove(picturesArray, function (n) {
    //   return n !== photo;
    // });
    picturesArray.splice(position, 1);

    this.setState({ previews: picturesArray })
  }

  renderPictures = () => {

    const { previews } = this.state;
    const { uploadedPictures, removeUploadedPicture } = this.props;
    let pictureComponent = [];

    let timestamp = moment().unix();
    let noOfPictures = uploadedPictures.length + previews.length;

    for (let i=0; i<uploadedPictures.length; i++) {
        pictureComponent.push(
          <PictureComponent
            key={"uploaded-" + i + "-" + timestamp}
            isUploaded={true}
            preview={uploadedPictures[i]}
            removePhoto={ () => removeUploadedPicture(uploadedPictures[i]) }
          />
        )
    }

    for (let i = 0; i < previews.length; i++) {
      pictureComponent.push(
        <PictureComponent
          key={i + "-" + timestamp}
          preview={previews[i]}
          isUploaded={false}
          removePhoto={() => this.removePhoto(i)}
        />
      )
    }

    if (noOfPictures < MinNoOfPictures) {
      for (let i = 0; i < (MinNoOfPictures - noOfPictures); i++) {
        
        pictureComponent.push(
          <PictureComponent
            key={(i+noOfPictures) + "-" + timestamp}
            isUploaded={false}
            readURL={this.readURL}
          />
        )
      }
    }

    else if (noOfPictures >= MinNoOfPictures && noOfPictures < MaxNoOfPictures) {
       
      pictureComponent.push(
        <PictureComponent
          key={noOfPictures + "-" + timestamp}
          readURL={this.readURL}
        />
      )
    }
    
    return pictureComponent
  }

  render() {
    let pictures = this.renderPictures()

    return (
      <div className="form-group">
        <div className="label">Add pictures</div>
        <div className="add-pictures-wrapper">
          {pictures}
        </div>
      </div>
    )
  }
}

export default SpacePictures;
