import { GET_BOOKINGS_HOST, GET_BOOKINGS_HOST_SUCCESS, GET_BOOKINGS_HOST_FAIL } from './actionTypes';

import { Booking } from '../../../api/endpoints';


export function setBookings(payload) {
  return {
    type: GET_BOOKINGS_HOST,
    payload,
  };
}

export function setBookingsSuccess(payload) {
  return {
    type: GET_BOOKINGS_HOST_SUCCESS,
    payload,
  };
}

export function setBookingsFail(payload) {
  return {
    type: GET_BOOKINGS_HOST_FAIL,
    payload,
  };
}

export function getHostBookings(payload) {
  return async (dispatch) => {
    dispatch(setBookings(payload))

    try {
      const response = await Booking.getHostBookings(payload);

      dispatch(setBookingsSuccess(response));
    } catch (error) {
      dispatch(setBookingsFail(error))
    }

  };
}
