import React, { Component } from 'react';


class SpaceDescription extends Component {

  render() {
    const { handleChange, nrOfChars, value } = this.props;
    const { isEmpty } = this.props;

    return (
      <div className="form-group description-wrapper">
        <div className="label">
          <span>Description <span className="red">{isEmpty && '*'}</span></span>
          <span className="character-count">{nrOfChars} / 3000 characters</span></div>
        <textarea
          name="spaceDescription"
          placeholder="Write here..."
          className="review-textarea"
          maxLength="3000"
          spellCheck="false"
          value={value}
          onChange={(e) => {
            handleChange(e)
          }}>
        </textarea>
      </div>


    )
  }
}


export default (SpaceDescription);
