import React, { Component } from 'react';

export default class PrimaryButton extends Component {
  state = {}

  render() {
    const { text, className, onClick, disabled, textClass, icon } = this.props;
    return (
      // eslint-disable-next-line react/button-has-type
      <button disabled={disabled} className={`primary button flex-center ${className}`} onClick={onClick}>
        {icon && <img className="right-padding" src={require(`../assets/images/${icon}`)} alt="" />}
        <span className={`button-text ${textClass}`}>{text}</span>
      </button>
    );
  }
}
