import React, { Component } from 'react';
import Modal from 'react-modal';
import PrimaryButton from '../../../components/PrimaryButton';


class DeleteSpaceModal extends Component {

  render() {
    const { deleteModalOpen, closeModal, removeSpace } = this.props;

    return (
      <Modal
        ariaHideApp={false}
        isOpen={deleteModalOpen}
        // onRequestClose={this.closeModal}
        className="delete-account-modal"
        overlayClassName="overlay"
      >
        <img src={require('../../../assets/images/ic_close.svg')}
          className="close-button filter-close-button"
          alt=""
          onClick={closeModal} />
        <div className="flex-center direction-column">
          <div className="delete-wrapper red-bg flex-center">
            <img src={require('../../../assets/images/ic_delete.svg')}
              style={{ width: '22px' }} alt="" />
          </div>
          <div className="code-modal-title delete">Delete your Space</div>
          <div className="delete-text flex-center direction-column">
            <div>Are you sure you want to delete this space?</div>
            <div>Deleting is permanent and cannot be reversed.</div>  <br />

            <div className="align-center">Need more info or you wish to tell us why you're deleting your space, please <b>contact us.</b></div><br />
          </div>
          <PrimaryButton
            text="Delete Space"
            className="top-margin red-bg"
            onClick={removeSpace} />
          <PrimaryButton
            text="Cancel"
            className="no-bg gray-border"
            textClass="gray"
            onClick={closeModal} />
        </div>
      </Modal>
    )
  }
}


export default (DeleteSpaceModal);
