import React, { Component } from "react";
import Input from "../../../components/Input";

class PaymentDetails extends Component {
  render() {
    const {
      clearInputs,
      emptyFields,
      handleInputChange,
      defaultBankName,
      editMode,
      defaultAccountName,
      defaultBsb,
      defaultAccountNumber,
      userType
    } = this.props;

    return (
      <div className="field-wrapper payment-details">
        <div className="field-name">PAYMENT DETAILS</div>
        <div className="payment-details-form">
          <div className="field-wrapper">
            <div className="field-name">
              Name of Bank{" "}
              <span className="red">
                {emptyFields.includes("bankName") && "*"}
              </span>
            </div>
            <Input
              id="hostBank"
              name="hostBank"
              className="input-style"
              disabled={!editMode}
              onChange={e => handleInputChange(e)}
              value={defaultBankName}
              onFocus={() => clearInputs()}
            />
          </div>
          <div className="field-wrapper">
            <div className="field-name">
              Account Name{" "}
              <span className="red">
                {emptyFields.includes("accountName") && "*"}
              </span>
            </div>
            <Input
              id="hostAccountName"
              name="hostAccountName"
              className="input-style"
              disabled={!editMode}
              onChange={e => handleInputChange(e)}
              value={defaultAccountName}
              onFocus={() => clearInputs()}
            />
          </div>
          <div className="field-wrapper">
            <div className="field-name">
              BSB{" "}
              <span className="red">{emptyFields.includes("bsb") && "*"}</span>
            </div>
            <Input
              id="hostBsb"
              name="hostBsb"
              className="input-style"
              disabled={!editMode}
              onChange={e => handleInputChange(e)}
              value={defaultBsb}
              maxLength={6}
              onFocus={() => clearInputs()}
            />
          </div>
          <div className="field-wrapper">
            <div className="field-name">
              Account Number{" "}
              <span className="red">
                {emptyFields.includes("accountNumber") && "*"}
              </span>
            </div>
            <Input
              id="hostAccountNumber"
              name="hostAccountNumber"
              className="input-style"
              disabled={!editMode}
              onChange={e => handleInputChange(e)}
              value={defaultAccountNumber}
              onFocus={() => clearInputs()}
            />
          </div>
          <div className="field-wrapper">
            <div className="field-name">Holder Type</div>
            <Input
              id="hostHolderType"
              name="hostHolderType"
              className="input-style"
              disabled={true}
              value={userType}
              onFocus={() => clearInputs()}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentDetails;
