import React, { Component } from 'react';
import BrowseCard from './BroswseCard';

class BrowseSection extends Component {


    render() {

        return (
            <div className="section-explore">
                <div className="section-header">Explore Deski</div>
                <div className="row">
                    <BrowseCard type="desk" icon="coworking" />
                    <BrowseCard type="privateOffice" icon="private" />
                    <BrowseCard type="groupMeeting" icon="meeting" />
                    <BrowseCard type="creative" icon="creative" />
                </div>
            </div>
        )
    }
}

export default BrowseSection;