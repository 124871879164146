import React, { Component } from 'react';
import { connect } from 'react-redux';

import SpaceCard from '../../../components/SpaceCard';
import { spaces } from '../../../configReduxSetup/reducers/selectors';
import config from '../../../config/config';



class SpaceTypes extends Component {
  state = {
    spaceSelectedOption: '',
    notComplete: false,
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.setState({ spaceSelectedOption: this.props.value })
    }
  }

  selectCard = (position) => {
    this.setState({ spaceSelectedOption: position });
    this.props.setType(position);
  }


  render() {
    const { notComplete, spaceSelectedOption } = this.state;
    const { isEmpty, spaces } = this.props;

    let size = spaces.length;

    return (

      <div className="form-group">
        <div className="label">Choose space type <span className="red">{isEmpty && '*'}</span></div>
        <div className="space-type-wrapper">
          {spaces.map(space => (
            <SpaceCard
              key={space.key}
              isSelected={spaceSelectedOption === space.key}
              text={space.name}
              icon={space.iconUrl ? `${config.api}/files/${space.iconUrl}` : ''}
              onClick={() => this.selectCard(space.key)}
              isInvalid={notComplete && !spaceSelectedOption}
              size={size}
            />
          ))}
        </div>
      </div>
    )
  }
}


const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
  spaces: spaces(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(SpaceTypes);
