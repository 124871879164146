import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';

import Picker from '../../../components/Picker';
import isInclusivelyAfterDay from '../../../util/isInclusivelyAfterDay';
import isBeforeDay from '../../../util/isBeforeDay';

import { getIndividualSpace } from '../reducers/selectors';

import { DateType, MinimumNights } from '../../../settings';

class DailyPickerWrapper extends Component {

    constructor(props) {
        super(props);

        this.state = ({
            focusedInput: null,
        });
    }

    focusChange = (focusedInput) => {
        if (focusedInput !== this.state.focusedInput) {
            this.setState({ focusedInput })
        }
    }

    renderDoneButton = () => (
        <div>
            <div
                onClick={() => {
                    this.setState({ focusedInput: null });
                }}>
                <img src={require('../../../assets/images/ic_close.svg')} className="close-button  x-calendar-button" alt="Close button" />
            </div>
            <div className="clear-calendar"
                onClick={() => {
                    this.setState({ focusedInput: 'startDate' });
                    this.onDatesChange(null, null);                  
                }}>
                <span>Clear</span>
            </div>
            <div className="calendar-done-button flex-center"
                onClick={() => {
                    this.setState({ focusedInput: null })
                }}>
                Done
            </div>
        </div>

    )

    renderCalendarInfo = () => {
        const {dateType} = this.props;

        let infoContent = null;
        let buttonContent = this.renderDoneButton();

        if ( dateType === DateType.WEEKLY ) {
            
            infoContent = "The minimum booking period should be " + MinimumNights.WEEKLY + " days.";
        } else if (dateType === DateType.MONTHLY) {

            infoContent = "The minimum booking period should be " + MinimumNights.MONTHLY + " days.";
        }

        return (
            <div className="picker-footer">
                <div className="picker-info">{infoContent}</div>
                {buttonContent}
            </div>
        );
    }

    onDatesChange = (startDate, endDate) => {
        const { changeDates } = this.props;

        changeDates(startDate, endDate);
    }

    isBookedDay = ( day ) => {
        const { bookedDates, individualSpace } = this.props;
        const bookingHours = individualSpace.space.bookingHours.days;
        
        let dayFormatted = day.format('YYYY-MM-DD');
        let dayOfWeekNo = day.isoWeekday();

        if (bookedDates.has(dayFormatted) || !bookingHours.hasOwnProperty(dayOfWeekNo)) {
            return true;
        }

        return false;
    }

    isDayBlockedDaily = (day) => {

        if(this.isBookedDay(day)) {
            return true;
        }

        return false;
    }

    isDayBlockedWeekly = (day, nextBookedDate) => {
        const { startDate } = this.props;

        if (nextBookedDate) {
            if (day.isAfter(nextBookedDate)) {
                
                return true;
            }    
        }

        if ( startDate ) {
            let isStartDateAfter = startDate.isAfter(day);

            // if the diff between startDate and endDate is a negative number the modulo 7 is not working so we add 7 to the difference in order to have the correct result
            let isAfterXWeeks = (startDate.isoWeekday() - day.isoWeekday() + 7) % 7 !== 1;
                
            if ( isStartDateAfter || isAfterXWeeks || day.diff(startDate, 'days') > 30) {

                return true
            }
        }

        if(this.isBookedDay(day) && !startDate) {
            return true;
        }

        return false;
    }

    isDayBlockedMonthly = (day, nextBookedDate) => {
        const { startDate } = this.props;

        if (nextBookedDate) {
            if (day.isAfter(nextBookedDate)) {
                
                return true;
            }    
        }

        if(this.isBookedDay(day) && !startDate) {
            return true;
        }

        let clonedDay = moment(day);
        let clonedStartDay = moment(startDate);

        if (startDate) {
            if (day.isAfter(startDate)) {    
                if (startDate.date() === 1 ) { // if startDate is first day of month 
                    
                    if (clonedDay.endOf("month").date() === day.date()){
                        return false;
                    }

                } else if (startDate.date() > 28 ) { // if startDate day of month is bigger than 28
                    
                    if (clonedDay.endOf("month").date() < startDate.date()) { // if startDate day of month is bigger than the current end of month
                        
                        if (clonedDay.endOf("month").date() === day.date()) { // if current day day of month is equal with end of month
                            
                            return false;
                        }
                    } else {
                        if (clonedStartDay.subtract(1,"days").date() === day.date()) {
                        
                            return false;
                        }
                    }
                } else {

                    if (clonedStartDay.subtract(1,"days").date() === day.date()) {
                        
                        return false;
                    }
            
                }

                return true;
            } else {

                return true;
            }
        }

        return false;
    }

    isDayBlocked = (day) => {
        const {dateType} = this.props;
        
        let ret = false;
        let nextBookedDate = null;

        switch (dateType) {
            case DateType.DAILY:
                ret = this.isDayBlockedDaily(day);
                
                break;
            case DateType.WEEKLY:
                nextBookedDate = this.getNextBookedDate();
                ret = this.isDayBlockedWeekly(day, nextBookedDate);

                break;
            case DateType.MONTHLY:
                nextBookedDate = this.getNextBookedDate();
                ret = this.isDayBlockedMonthly(day, nextBookedDate);

                break;
            default:
                ret = this.isDayBlockedDaily(day);
                
                break;
        }

        return ret;
    }

    getNextBookedDate = () => {
        const { bookedDates, startDate } = this.props;
        
        let ret = null;

        let minDiff = 200;
        let datesDiff = 0;
        
        if (startDate) {

            bookedDates.forEach((value, key) => {
                let momentDay = moment(key, 'YYYY-MM-DD');
                
                if (startDate.isBefore(momentDay)) {
                    datesDiff = momentDay.diff(startDate, 'days');
                    
                    if (datesDiff < minDiff) {
                        minDiff = datesDiff;
                        ret = momentDay;
                    }
                }
            });
        }
        
        return ret;
    }

    getMinimumNights = () => {
        const {dateType} = this.props;

        let ret = MinimumNights.DAILY;

        switch (dateType) {
            case DateType.WEEKLY:
                ret = MinimumNights.WEEKLY;

                break;
            case DateType.MONTHLY:
                ret = MinimumNights.MONTHLY;

                break;
            default:
                ret = MinimumNights.DAILY;
                
                break;
        }

        return ret;
    }

    isCalendarOpen = () => {
        const {focusedInput} = this.state;

        let ret = false;

        if (focusedInput === "startDate" || focusedInput === "endDate") {
            ret = true;
        }

        return ret;
    }

    render() {
        const { focusedInput } = this.state;
        const { startDate, endDate, notComplete } = this.props;

        let date = ''
        let placeholder = "Booking Start & End Date";

        if (startDate !== null && endDate !== null) {
            date = `${moment(startDate).format('ll')} - ${moment(endDate).format('ll')}`
        }

        let minNights= this.getMinimumNights();

        let isCalendarVisible = this.isCalendarOpen();

        return (
            <div className={`calendar-wrapper ${isCalendarVisible && 'calendar-wrapper-opened'}`}>
                {isCalendarVisible ?
                    <DateRangePicker
                        startDateId="startDate"
                        endDateId="endDate"
                        startDate={startDate}
                        endDate={endDate}
                        onDatesChange={(dates) => this.onDatesChange(dates.startDate, dates.endDate)}
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) => this.focusChange(focusedInput)}
                        verticalHeight={280}
                        numberOfMonths={1}
                        keepOpenOnDateSelect
                        daySize={40}
                        firstDayOfWeek={1}
                        calendarInfoPosition="bottom"
                        renderCalendarInfo={this.renderCalendarInfo}
                        displayFormat="D MMM YYYY"
                        isDayBlocked={this.isDayBlocked}
                        customInputIcon={<span className="from-to-text border-color-green">From: </span>}
                        customArrowIcon={<span className="from-to-text border-color-green">To: </span>}
                        isOutsideRange={(day) => isBeforeDay(day, moment()) || isInclusivelyAfterDay(day, moment().add(365, 'days'))}
                        minimumNights={minNights}
                        readOnly={true}
                    />
                    :
                    <Picker
                        isInvalid={notComplete && !startDate}
                        placeholder={placeholder}
                        text={date}
                        icon={require('../../../assets/images/ic_calendar.svg')}
                        openIcon
                        onClick={() => this.setState({ focusedInput:"startDate" })}
                    />
                }
            </div>
        );
    }
}

DailyPickerWrapper.propTypes = {
};

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
    individualSpace: getIndividualSpace(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(DailyPickerWrapper);