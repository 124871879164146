import React, { Component } from 'react';


export default class TextInput extends Component {
  state = {
  }

  render() {
    const { placeholder, type, className, id, onBlur, onChange, name, isInvalid, onFocus, readOnly, onKeyPress, disabled, value, maxLength } = this.props;

    return <>
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        className={`text-input ${className} ${isInvalid ? 'invalid' : ''}`}
        spellCheck="false"
        onBlur={onBlur}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        readOnly={readOnly}
        onKeyPress={onKeyPress}
        disabled={disabled}
        value={value}
        maxLength={maxLength}
      />
    </>
  }
}