import {
    GET_BOOKINGS_HOST,
    GET_BOOKINGS_HOST_SUCCESS,
    GET_BOOKINGS_HOST_FAIL
} from '../actions/actionTypes';

import { Map } from 'immutable';

const initialState = Map({
    isLoading: true,
    bookingsPerHost: {},
});


function setBookings(state, { payload }) {
    return state.set('isLoading', true);
}

function setBookingsSuccess(state, { payload }) {
    return state.set('bookingsPerHost', payload).set('isLoading', false);
}

function setBookingsFail(state, { payload }) {
    return state.set('isLoading', false);
}

const handlers = {
    [GET_BOOKINGS_HOST_SUCCESS]: setBookingsSuccess,
    [GET_BOOKINGS_HOST_FAIL]: setBookingsFail,
    [GET_BOOKINGS_HOST]: setBookings,

};

export default function createDeskiReducer(state = initialState, action = {}) {
    const handler = handlers[action.type];

    if (!handler) {
        return state;
    }

    return handler(state, action);
}
