import { SET_CONFIG, SET_CONFIG_SUCCESS, SET_CONFIG_FAIL, SET_FAQ, SET_FAQ_SUCCESS, SET_FAQ_FAIL } from '../actions/actionTypes';
import { Map } from 'immutable';

const initialState = Map({
  config: JSON.parse(localStorage.Config || "{}"),
  amenities: localStorage.Config ? (JSON.parse(localStorage.Config).amenities || "{}") : {},
  spaces: localStorage.Config ? (JSON.parse(localStorage.Config).spaceTypes || "{}") : {},
  faqLoading: false
});

function setConfig(state, { payload }) {
  return state.set('loading', true);
}

function setConfigSuccess(state, { payload }) {
  let groupAmenities = payload.spaceTypes.find(space => space.key === "groupMeeting").amenities;
  return state.set('config', payload).set('amenities', payload.amenities).set('groupMeetingTypes', groupAmenities).set('spaces', payload.spaceTypes);
}

function setConfigFail(state, { payload }) {
  return state.set('error', payload).set('loading', false);
}

function setFaq(state, { payload }) {
  return state.set('faqLoading', true);
}

function setFaqSuccess(state, { payload }) {
  return state.set('faq', payload).set('faqLoading', false);
}

function setFaqFail(state, { payload }) {
  return state.set('faqLoading', false).set('faqErr', payload);
}

const handlers = {
  [SET_CONFIG]: setConfig,
  [SET_CONFIG_SUCCESS]: setConfigSuccess,
  [SET_CONFIG_FAIL]: setConfigFail,
  [SET_FAQ]: setFaq,
  [SET_FAQ_SUCCESS]: setFaqSuccess,
  [SET_FAQ_FAIL]: setFaqFail,
};

export default function createConfigReducer(state = initialState, action = {}) {
  const handler = handlers[action.type];

  if (!handler) {
    return state;
  }

  return handler(state, action);
}
