import React, { Component } from 'react';
import Picker from '../../src/components/Picker';
import moment from 'moment';
import { TimeLimits } from '../settings'

export default class TimePicker extends Component {
    state = {
        startHour: this.props.startHour || null,
        endHour: this.props.endHour || null,
        isStartVisible: true,
        isEndVisible: false,
        allDay: false,
        hoveredItem: null
    }
    componentWillMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.onClose();
        }
    }

    selectHour = (h) => {
        const { allDay, startHour } = this.state
        if (allDay)
            this.setState({
                allDay: false,
                startHour: null,
                endHour: null,
                isStartVisible: true
            })
        setTimeout(() => {
            if (startHour === null || this.state.isStartVisible === true)
                this.setState({ startHour: h, endHour: null, isStartVisible: false, isEndVisible: true })

            else {
                if (h > startHour)
                    this.setState({ endHour: h + 1, isStartVisible: false, isEndVisible: true })
                if (h < startHour)
                    this.setState({ startHour: h })
            }
        }, 0);

    }


    renderHours = () => {
        const { allDay, startHour, endHour, hoveredItem } = this.state;
        const { startDate } = this.props;
        let hours = [];
        let hour = 0;
        let format = '';

        let isToday = false;

        if (startDate && moment().isSame(startDate, 'day')) {
            isToday = true;
        }

        for (let i = TimeLimits.MIN_HOUR; i < TimeLimits.MAX_HOUR; i++) {
            if (i <= 12) {
                hour = i;
                if (i === 12)
                    format = "PM";
                else
                    format = "AM";
            }
            else {
                hour = i - 12;
                format = "PM";
            }

            if (isToday && i < moment().hour()) {
                hours.push(
                    <div key={i}
                        className={`hour-wrapper flex-center blockedHour 
                            ${(i === startHour || i === endHour - 1) ? 'isHourSelected' : ''}
                            ${(i > startHour && i < endHour) ? 'isHourInterval' : ''} 
                            ${(i === startHour && allDay === false) ? 'isHourSelected' : ''} 
                            ${(i === - 1 && allDay === false) ? 'isHourSelected' : ''}`} >

                        <div className={`hour ${(i === startHour || i === endHour - 1) ? 'isHourSelectedText' : ''} ${(i > startHour && i < endHour - 1) ? 'isHourSelectedText' : ''} ${(i === startHour && allDay === false) ? 'isHourSelectedText' : ''} ${(i === endHour - 1 && allDay === false) ? 'isHourSelectedText' : ''}`}><span className="bold-hour">{hour}</span> {format}</div>
                    </div>
                )
            } else {
                hours.push(
                    <div key={i}
                        className={`hour-wrapper flex-center 
                            ${(i === startHour || i === endHour - 1) ? 'isHourSelected' : ''} 
                            ${(i > startHour && i < endHour) ? 'isHourInterval' : ''} 
                            ${(i === startHour && allDay === false) ? 'isHourSelected' : ''} 
                            ${(startHour !== null && (i > startHour && hoveredItem >= i)) ? 'isInHourInterval' : ''} 
                            ${(i === endHour - 1 && allDay === false) ? 'isHourSelected' : ''}`}
                        onClick={() => this.selectHour(i)}
                        onMouseOver={() => (i > startHour && startHour !== null) && this.setState({ hoveredItem: i })}
                        onMouseOut={() => this.setState({ hoveredItem: null })}>

                        <div className={`hour ${(i === startHour || i === endHour - 1) ? 'isHourSelectedText' : ''} ${(i > startHour && i < endHour - 1) ? 'isHourSelectedText' : ''} ${(i === startHour && allDay === false) ? 'isHourSelectedText' : ''} ${(i === endHour - 1 && allDay === false) ? 'isHourSelectedText' : ''}`}><span className="bold-hour">{hour}</span> {format}</div>
                    </div>
                )
            }
        }

        return hours;
    }

    onDone = () => {
        const { getHours, onDone } = this.props;
        const { startHour, endHour } = this.state;
        if (startHour) {
            getHours(startHour, endHour);
        }
        onDone();
    }

    clearTimePicker = () => {
        const { getHours } = this.props;

        this.setState({
            startHour: null,
            endHour: null,
            isStartVisible: true,
            isEndVisible: false,
            allDay: false
        })

        getHours(null, null);
    }



    render() {

        const { allDay, isStartVisible, isEndVisible, endHour, startHour } = this.state;

        let endTimeText = "End Time";

        if (startHour && !endHour) {
            endTimeText = moment(startHour + 1, ["h"]).format('h:mm A')
        }
        else if (startHour && endHour) {
            endTimeText = moment(endHour, ["h"]).format('h:mm A')
        }

        return (
            <div ref={this.setWrapperRef}>
                <div className="time-picker picker-margin">
                    <div className="time-picker-header">
                        <div onClick={this.props.onClose}>
                            <img src={require('../assets/images/ic_close.svg')} className="close-timepicker" alt="" />
                        </div>
                        <div className="clear-timepicker" onClick={() => this.clearTimePicker()}>Clear</div>
                    </div>
                    <div className={`all-day flex-center ${(allDay || (this.state.startHour === TimeLimits.MIN_HOUR && this.state.endHour === TimeLimits.MAX_HOUR)) ? 'selected-all-day' : ''}`} onClick={() => this.setState({ allDay: true, startHour: TimeLimits.MIN_HOUR, endHour: TimeLimits.MAX_HOUR })}>
                        <span className={`all-day-text  ${(allDay || (this.state.startHour === TimeLimits.MIN_HOUR && this.state.endHour === TimeLimits.MAX_HOUR)) ? 'selected-all-day-text' : ''}`}>All day</span>
                    </div>
                    <div>
                        <div className="intervals-header">
                            <span className="from-to-timepicker">FROM </span>
                            <span className="from-to-timepicker">TO </span>
                        </div>
                        <div>
                            <div className="headers-wrapper">
                                {isStartVisible === false ?
                                    <Picker
                                        className="time-selector"
                                        openIcon
                                        placeholder="Start Time"
                                        text={this.state.startHour ? moment(this.state.startHour, ["h"]).format('h:mm A') : 'Start Time'}
                                        placeholderStyle="timepicker-placeholder"
                                        iconStyle="timepicker-open-icon"
                                        onClick={() => this.setState({ isStartVisible: true, isEndVisible: false })}
                                    />
                                    :
                                    <div className="time-table-header">
                                        <div className="time-header-wrapper">
                                            <span className="timepicker-text">{`${this.state.startHour ? moment(this.state.startHour, ["h"]).format('h:mm A') : 'Start Time'}`}</span>
                                            <img
                                                className="open-icon-reversed"
                                                src={require('../assets/images/ic_arrow_down.svg')}
                                                alt=""
                                                onClick={() => this.setState({ isStartVisible: false, isEndVisible: true })}
                                            />
                                        </div>
                                    </div>
                                }
                                {isEndVisible === false ?
                                    <Picker
                                        className="time-selector"
                                        openIcon
                                        placeholder="End Time"
                                        text={endTimeText}
                                        placeholderStyle="timepicker-placeholder"
                                        iconStyle="timepicker-open-icon"
                                        onClick={() => this.setState({ isEndVisible: true, isStartVisible: false })}
                                    />
                                    :
                                    <div className="time-table-header">
                                        <div className="time-header-wrapper">
                                            <span className="timepicker-text">{endTimeText}</span>
                                            <img
                                                className="open-icon-reversed"
                                                src={require('../assets/images/ic_arrow_down.svg')}
                                                alt=""
                                                onClick={() => this.setState({ isEndVisible: false, isStartVisible: true })}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="time-table flex-center wrap">
                                {this.renderHours()}
                            </div>
                        </div>
                    </div>
                    <div className="calendar-done-button flex-center time-done-button"
                        onClick={this.onDone}>Done</div>
                </div>
            </div>

        )
    }
}
