import { GET_VERIFIED, GET_VERIFIED_SUCCESS, GET_BLOG, GET_BLOG_SUCCESS } from './actionTypes';

import { Search, Blog } from '../../../api/endpoints';

export function getVerified(payload) {
  return {
    type: GET_VERIFIED,
    payload,
  };
}

export function getVerifiedSuccess(payload) {
  return {
    type: GET_VERIFIED_SUCCESS,
    payload,
  };
}

export function getBlog(payload) {
  return {
    type: GET_BLOG,
    payload,
  };
}

export function getBlogSuccess(payload) {
  return {
    type: GET_BLOG_SUCCESS,
    payload,
  };
}

export function getVerifiedSpaces(payload) {
  return async (dispatch) => {
    dispatch(getVerified(payload));

    const response = await Search.getTopVerified(payload);

    dispatch(getVerifiedSuccess(response));
  };
}

export function getBlogPosts(payload) {
  return async (dispatch) => {
    dispatch(getBlog(payload));

    const response = await Blog.getPosts(payload);

    dispatch(getBlogSuccess(response));
  };
}
