import React, { Component } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom'
import Loader from 'react-loader-spinner';

import MainModal from '../../../components/MainModal';
import TextInput from '../../../components/TextInput';
import PrimaryButton from '../../../components/PrimaryButton';
import ForgotPasswordModal from '../components/ForgotPasswordModal';
import ResetPasswordModal from '../components/ResetPasswordModal';
import VerificationCodeModal from '../components/VerificationCodeModal';


import { login, socialLogin } from '../actions/actions';
import { currentUser } from '../reducers/selectors';

import { goToPageBasedOnUser } from '../../../util/utils';
import SuccessModal from '../../../components/SuccessModal';
import { Mixpanel } from '../../../util/MixPanel';


class LoginScreen extends Component {
    state = {
        modal: null,
        IncorrectEmailOrPassword: false,
        loggedWithSocialMedia: false,
        isLoggingIn: false
    }

    componentDidMount() {
        document.title = 'Deski';
    }

    loginWithEmailPassword = async () => {
        const { email, password } = this.state;

        this.setState({ isLoggingIn: true });

        try {
            await this.props.login({ email, password })
            goToPageBasedOnUser(this.props);
        }
        catch (e) {
            let error = JSON.parse(e.message);
            if (error.error.code)
                this.setState({ loginError: error.error.msg })
        }

        this.setState({ isLoggingIn: false });

        Mixpanel.identify(email)
        Mixpanel.track('Log In via Email', { email })
        Mixpanel.setPeople({
            "email": email,
            "$loginMethod": "Email",
        })
    }

    loginWithFacebook = async ({ accessToken }) => {
        this.setState({ isLoggingIn: true });

        try {
            await this.props.socialLogin({ type: "facebook", token: accessToken });
            goToPageBasedOnUser(this.props);
        }
        catch (e) {
            let error = JSON.parse(e.message);

            if (error.error.code)
                this.setState({ loginError: error.error.msg })
        }
        this.setState({ isLoggingIn: false });

        Mixpanel.track('Log In via Facebook')
        Mixpanel.setPeople({
            "$loginMethod": "Facebook",
        })
    };

    loginWithGoogle = async ({ accessToken }) => {
        this.setState({ isLoggingIn: true });

        try {
            await this.props.socialLogin({ type: "google", token: accessToken });
            goToPageBasedOnUser(this.props);
        } catch (e) {
            let error = JSON.parse(e.message);

            if (error.error.code)
                this.setState({ loginError: error.error.msg })
        }
        this.setState({ isLoggingIn: false });
        Mixpanel.track('Log In via Google')
        Mixpanel.setPeople({
            "$loginMethod": "Google",
        })
    };

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    emailPasswordLogin = () => {
        const { email, password } = this.state;
        if (!email || !password) {
            this.setState({ emptyEmail: !email, emptyPassword: !password });
            return;
        }
        this.setState({ emptyEmail: false, emptyPassword: false });
        this.loginWithEmailPassword();
    }
    modals = {
        forgotPassword: <ForgotPasswordModal
            onSubmit={() => this.goToModal('verificationCode')}
            onCancel={() => this.goToModal(null)}
        />,
        verificationCode: <VerificationCodeModal
            onSubmit={() => this.goToModal('resetPassword')}
            onCancel={() => this.goToModal(null)}
        />,
        resetPassword: <ResetPasswordModal
            onSubmit={() => this.goToModal('successfulUpdate')}
            onCancel={() => this.goToModal(null)}
        />,
        successfulUpdate: <SuccessModal
            title="Your password had been updated!"
            description="Try logging in using this new password."
            buttonText="Login"
            onClick={() => this.goToModal(undefined)}
            onCancel={() => this.goToModal(null)}
        />,
    }

    goToModal = (modal) => {
        this.setState({ modal });
    }

    clearErrors = () => {
        this.setState({
            IncorrectEmailOrPassword: false,
            loggedWithSocialMedia: false,
            EmailAlreadyExists: false
        })
    }


    render() {
        const { isLoggingIn } = this.state;
        const { currentUser } = this.props;
        let loader = null;
        let loginDisabled = '';



        if (isLoggingIn) {
            loader = (
                <div className="login-loader">
                    <Loader
                        type="Ball-Triangle"
                        height="100"
                        width="100"
                        color="#0EA800"
                    />
                </div>
            )
            loginDisabled = 'login-disabled';
        }

        if (Object.keys(currentUser).length && window.location.pathname.includes("activateHost")) {
            return (
                <Redirect to="/profile/host" />
            )
        }

        else return (
            <MainModal title="Welcome" isAuth>
                {loader}
                <div className={`flex-center direction-column content-wrapper log-in ${loginDisabled}`}>
                    {this.modals[this.state.modal]}
                    <div>
                        <GoogleLogin
                            clientId='733935862246-ejkiuo1sqj4k04sgj9a11fpl4qlblstf.apps.googleusercontent.com'
                            buttonText="Login"
                            onSuccess={this.loginWithGoogle}
                            onFailure={console.log}
                            render={renderProps => (
                                <button className="google button flex-center" onClick={() => {
                                    setTimeout(() => {
                                        renderProps.onClick();
                                    }, 0);
                                }}>
                                    <img src={require('../../../assets/images/ic_login_google.svg')} className="social-media-img" alt="" />
                                    <span className="button-text-social">Login via Google</span>
                                </button>
                            )}
                        />
                        <FacebookLogin
                            appId="1269204886555662"
                            fields="name,email,picture"
                            redirectUri={`${window.location.origin}/login`}
                            callback={this.loginWithFacebook}
                            render={renderProps => (
                                <button className="facebook button flex-center" onClick={() => {
                                    setTimeout(() => {
                                        renderProps.onClick();
                                    }, 0);
                                }}>
                                    <img src={require('../../../assets/images/ic_login_facebook.svg')} className="social-media-img" alt="" />
                                    <span className="button-text-social">Login via Facebook</span>
                                </button>
                            )}
                        />
                    </div>
                    <div className="flex-center or-wrapper">
                        <span className="grey-line"></span>
                        <span className="or-text">OR</span>
                        <span className="grey-line"></span>
                    </div>
                    <div className="flex-center direction-column">
                        <TextInput
                            isInvalid={this.state.emptyEmail}
                            placeholder="Email"
                            name='email'
                            type="text"
                            className="medium-width"
                            onChange={e => {
                                this.handleInputChange(e)
                            }}
                            onFocus={() => this.clearErrors()}
                            onKeyPress={(event) => {
                                let code = event.keyCode || event.which;
                                if (code === 13) { this.emailPasswordLogin() }
                            }
                            }
                        />
                        {this.state.emptyEmail && <span className="validation-message login-validation-width">Please enter your email.</span>}
                        <TextInput
                            isInvalid={this.state.emptyPassword}
                            placeholder="Password"
                            type="password"
                            name='password'
                            className="medium-width"
                            onChange={e => {
                                this.handleInputChange(e)
                            }}
                            onFocus={() => this.clearErrors()}
                            onKeyPress={(event) => {
                                let code = event.keyCode || event.which;
                                if (code === 13) { this.emailPasswordLogin() }
                            }
                            }
                        />
                        {this.state.emptyPassword && <span className="validation-message login-validation-width">Please enter your password.</span>}
                        {this.state.loginError && <span className="validation-message login-validation-width">{this.state.loginError}</span>}
                        <span className="forgot-password" onClick={() => this.goToModal('forgotPassword')}>Forgot Password?</span>
                    </div>
                    <PrimaryButton
                        text="Submit"
                        onClick={this.emailPasswordLogin}
                    />
                    <div className="sign-up-button top-margin-phone">
                        Not a member yet? <span style={{ fontWeight: 'bold', cursor: 'pointer' }}
                            onClick={() => this.props.history.push('/signup')}>Sign up</span> now
              </div>
                    <div className="footer-text">
                        By using Deski you have read and agree to our <NavLink to={`/terms-of-use`} target="_blank">
                            <span className="terms-and-use">Terms</span>
                        </NavLink> and that you have read our <NavLink to={`/privacy-policy`} target="_blank">
                            <span className="terms-and-use">Data Use Policy</span>
                        </NavLink>
                    </div>
                </div>
            </MainModal>
        );
    }
}

LoginScreen.propTypes = {
    socialLogin: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    socialLogin,
    login,
};

const mapStateToProps = (state) => ({
    currentUser: currentUser(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
