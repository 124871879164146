import React, { Component } from 'react';
import Modal from 'react-modal';
import SecondaryButton from '../components/SecondaryButton'
import history from '../history';
import { amenities, groupMeetingTypes } from '../configReduxSetup/reducers/selectors';


import connect from 'react-redux/es/connect/connect';

const NO_RATING = -1;

class FilterModal extends Component {
  state = {
    isChecked: false,
    selectedOption: this.props.activeFilters || {},
    rating: this.props.activeRating || NO_RATING
  }


  toggle = (position) => {
    const { selectedOption } = this.state;

    if (selectedOption[position] === true || selectedOption[position] === "true") {
      delete selectedOption[position];
      this.setState({});
    } else {
      let amenityArray = selectedOption;
      amenityArray[position] = true;
      this.setState({ selectedOption: amenityArray })
    }
  }

  renderRating = (rating) => {
    const ratingArray = []
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        ratingArray.push(
          <div key={i} className={`rating-stars filter`} onClick={() => this.setState({ rating: i })}>
            <img src={require('../assets/images/ic_full_star.svg')} alt="" />
          </div>
        )
      }
      else {
        ratingArray.push(
          <div key={i} className={`rating-stars filter`} onClick={() => this.setState({ rating: i })}>
            <img src={require('../assets/images/ic_empty_star.svg')} alt="" />
          </div>
        )
      }
    }
    return ratingArray;
  }

  clearFilters = () => {
    const { isFromPosterSpaces } = this.props;
    this.setState({
      selectedOption: isFromPosterSpaces ? { page: 0 } : {},
      rating: NO_RATING
    })
  }


  applyFilters = () => {
    const { selectedOption, rating } = this.state;
    let filterString = '';

    Object.keys(selectedOption).forEach(filter => {
      if (filter !== 'rating')
        filterString === '' ? filterString = filterString.concat(`${filter}=${selectedOption[filter]}`) : filterString = filterString.concat(`&${filter}=${selectedOption[filter]}`)
    });
    if (rating > NO_RATING)
      filterString === '' ? filterString = filterString.concat(`rating=${rating}`) : filterString = filterString.concat(`&rating=${rating}`);

    history.push({
      pathname: history.location.pathname,
      search: filterString
    });

    this.props.onCancel();

  }

  render() {
    const { isGroupMeeting, amenities, groupMeetingTypes } = this.props;
    const { selectedOption, rating } = this.state;
    let amenitiesHeight = amenities.length % 2 === 0 ? (amenities.length - 1) * 25 : amenities.length * 25

    return (
      <Modal
        ariaHideApp={false}
        isOpen={true}
        className="filter-modal"
        overlayClassName="overlay"
      >
        <img src={require('../assets/images/ic_close.svg')}
          className="close-button filter-close-button"
          alt=""
          onClick={this.props.onCancel} />
        <div className="modal-title">Filter</div>
        <div >
          <div className="results-header filter-header">
            AMENITIES
          </div>
          <div className="filters-wrapper flex-center direction-column" style={{ height: amenitiesHeight }}>
            {amenities.map((type, index) => (
              <div className="type-wrapper" key={index}>
                <div className="filter-text">
                  {type.name}
                </div>
                <div className={`${(!selectedOption[type.key]) && 'checkmark'}`} onClick={() => this.toggle(type.key)}>
                  {selectedOption[type.key] &&
                    <img src={require('../assets/images/ic_check_fill.svg')} alt="" />
                  }
                </div>
              </div>

            ))}
          </div>
          <div className="flex-center">
            <div className="separation-line filter-line"></div>
          </div>
          {isGroupMeeting &&
            <div className="results-header filter-header">
              TYPE
          </div>}
          {isGroupMeeting &&
            <div className="filters-wrapper group flex-center direction-column">
              {groupMeetingTypes.map((type, index) => (
                <div className="type-wrapper" key={index}>
                  <div className="filter-text">
                    {type.name}
                  </div>
                  <div className={`${!selectedOption[type.key] && 'checkmark'}`} onClick={() => this.toggle(type.key)}>
                    {selectedOption[type.key] &&
                      <img src={require('../assets/images/ic_check_fill.svg')} alt="" />
                    }
                  </div>
                </div>

              ))}
            </div>
          }
          {isGroupMeeting &&
            <div className="flex-center">
              <div className="separation-line filter-line"></div>
            </div>
          }
          <div className="results-header filter-header">
            RATING
          </div>
          <div className="rate-starts-wrapper">
            {this.renderRating(rating)}
          </div>
          <div className="filter-buttons-wrapper">
            <button className="resend-button" onClick={this.clearFilters}>Clear</button>
            <div onClick={() => this.applyFilters()}>
              <SecondaryButton text="Apply" />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
  groupMeetingTypes: groupMeetingTypes(state),
  amenities: amenities(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterModal);