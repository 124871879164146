import React, { Component } from 'react';


class DeleteIcon extends Component {

    state = {
        isDeleteClicked: false
    }

    deletePhoto = () => {
        const { removePhoto } = this.props;
        this.setState({ isDeleteClicked: false });
        removePhoto();

    }

    render() {
        const { isDeleteClicked } = this.state;
        let remove = (
            <div className="oval flex-center" onClick={() => this.setState({ isDeleteClicked: true })}>
                <img src={require('../../../assets/images/ic_delete.svg')} alt='' />
            </div>
        )
        if (isDeleteClicked) {
            remove = (
                <div className="remove-button flex-center" onClick={() =>this.deletePhoto()}>
                    <img src={require('../../../assets/images/ic_delete.svg')} alt='' />
                    <span className="text">Remove?</span>
                </div>
            )
        }
        return (
            <div className="flex-center">
                {remove}
            </div>
        )
    }
}

export default (DeleteIcon);
