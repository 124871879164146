import React, { Component } from 'react';
import Picker from '../components/Picker';


export default class GuestsPicker extends Component {
    state = {
    }
    componentWillMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.onClose();
        }
    }
    renderNumbers = () => {
        const { maxNrOfPax } = this.props;
        let numbers = []

        let max = 999;
        if (maxNrOfPax) {
            max = maxNrOfPax;
        }

        for (let i = 1; i <= max; i++) {
            numbers.push(
                <div className="guest-number" key={"guest-number-" + i} onClick={() => {
                    this.props.getNumber(i)
                    this.props.onClose()
                }}>
                    {i}
                </div >
            )
        }
        return numbers;
    }
    render() {
        const { onClickPicker, isPickerVisible, guestsNumber, isDeskSelected } = this.props;
        let cursor = 'cursor-default';
        if (!isDeskSelected) {
            cursor = 'cursor-pointer';
        }
        return (

            <div className={`number-picker-wrapper `} ref={this.setWrapperRef}>
                {isPickerVisible ?
                    <div className="number-picker">
                        {this.renderNumbers()}
                    </div>
                    :
                    <Picker
                        notRelative
                        text={isDeskSelected ? 1 : guestsNumber}
                        openIcon={!isDeskSelected}
                        className={`picker-margin number-selector ${cursor}`}
                        iconStyle="timepicker-open-icon"
                        onClick={onClickPicker}
                    />
                }


            </div>
        )
    }
}