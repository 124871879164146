import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import queryString from 'query-string'
import history from '../../../history';
import Loader from 'react-loader-spinner';

import ResultCard from '../../../components/ResultCard';
import { NavLink } from 'react-router-dom';

import { currentUser } from '../../auth/reducers/selectors';
import { getPosterSpaces, posterSpacesLoading } from '../../posterSpaces/reducers/selectors';
import { getSpacesByPoster } from '../../posterSpaces/actions/actions';
import { Mixpanel } from '../../../util/MixPanel';

const REGULAR_PER_PAGE = 12;

class PosterListings extends Component {

    state = {
        firstIndexRegular: 0,
        lastIndexRegular: 12,
        pageNumber: this.props.page,
    }

    componentDidMount() {
        const { currentUser } = this.props;
        this.getSpacesByPoster(currentUser._id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        window.scrollTo(0, 0);
        const { currentUser } = this.props;

        if (prevState.pageNumber !== this.state.pageNumber) {
            this.getSpacesByPoster(currentUser._id)
        }
    }

    getSpacesByPoster = async (posterID) => {
        const { getSpacesByPoster } = this.props;
        const page = queryString.parse(window.location.search).page - 1;

        let query = `${posterID}?page=${+page}&perPage=${REGULAR_PER_PAGE}&sortByPrice=${false}`
        await getSpacesByPoster(query)
    }

    handlePagination = (pageObj) => {
        const currentPageNumber = pageObj.selected + 1;
        const { type } = this.props;
        const page = queryString.parse(window.location.search).page;

        if (page !== currentPageNumber) {
            history.push({
                pathname: history.location.pathname,
                search: `type=${type}&page=${+currentPageNumber}`
            });
        }
        this.setState({ pageNumber: page })
    }


    renderPagination = () => {
        const { posterSpaces } = this.props;
        let pagination = null;
        let pageCount = null;
        const page = queryString.parse(window.location.search).page;

        if (posterSpaces.countSpaces) {
            pageCount = (posterSpaces.countSpaces[0].count % REGULAR_PER_PAGE) === 0 ? Math.trunc(posterSpaces.countSpaces[0].count / REGULAR_PER_PAGE) : Math.trunc(posterSpaces.countSpaces[0].count / REGULAR_PER_PAGE) + 1;
        }

        if (pageCount > 1) {
            pagination = (
                <ReactPaginate
                    pageCount={pageCount}
                    forcePage={+page - 1}
                    containerClassName="flex-center pagination spacing"
                    previousLabel={
                        <div className="page-switcher flex-center">
                            <img
                                className="previous label"
                                src={require('../../../assets/images/ic_arrow.svg')}
                                alt=""
                            /></div>}
                    nextLabel={
                        <div className="page-switcher flex-center">
                            <img
                                className="label"
                                src={require('../../../assets/images/ic_arrow.svg')}
                                alt=""
                            />
                        </div>}
                    pageLinkClassName="pagination padding-page"
                    previousLinkClassName="pagination"
                    nextLinkClassName="pagination"
                    breakLabel="..."
                    breakClassName="break flex-center"
                    breakLinkClassName="pagination"
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePagination}
                    pageClassName="page flex-center"
                    activeClassName="active-page"
                />)
        }

        return pagination;
    }

    renderContent = () => {
        const { posterSpaces } = this.props;

        return (
            <div>
                <div className="results-wrapper">
                    <div className="result-cards">
                        {posterSpaces.spaces.map((space, index) => (
                            <div className="card-wrapper" key={space._id}>
                                <NavLink to={`/space/${space._id}`} onClick={() => Mixpanel.track("View Space - opened space")}>
                                    <ResultCard
                                        key={space._id}
                                        type={space.type}
                                        isVerified={space.verified}
                                        spaceName={space.name}
                                        nrOfReviewers={space.nrOfRaters}
                                        rating={space.rating}
                                        photoUrl={space.photosUrls[0].photoUrl}
                                    />
                                </NavLink>
                            </div>
                        ))}
                    </div>
                </div>
                {this.renderPagination()}
            </div>
        )
    }


    render() {
        const { posterSpaces, spacesLoading } = this.props;

        if (spacesLoading) return (
            <div className="loader-wrapper">
                <Loader
                    type="Ball-Triangle"
                    height="100"
                    width="100"
                    color="#0EA800"
                />
            </div>
        )

        let content = (
            <div className="flex-center direction-column">
                <div className="no-bookings">No space created yet. <br />Create one below.</div>
            </div >
        )

        if (posterSpaces.spaces && Object.keys(posterSpaces.spaces).length) {
            content = this.renderContent();
        }

        return (
            <div>
                {content}
            </div>
        )
    }
}



PosterListings.propTypes = {
    getSpacesByPoster: PropTypes.func.isRequired,

};

const mapDispatchToProps = {
    getSpacesByPoster
};

const mapStateToProps = (state) => ({
    currentUser: currentUser(state),
    posterSpaces: getPosterSpaces(state),
    spacesLoading: posterSpacesLoading(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(PosterListings);