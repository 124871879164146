import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import moment from 'moment-timezone';
import { NavLink } from 'react-router-dom';
import Modal from 'react-modal';
import Loader from 'react-loader-spinner';

import { currentUser } from '../../auth/reducers/selectors';

import {
  getBooking, createRatingForSpace, createRatingForRenter, cancelAndRefundBooking, cancelAndRefundBookingHost,
  getRatingForSpace, getRatingForUser, sendReportDispute
} from '../actions/actions';
import { getIndividualBookingLoading, getIndividualBooking, getRatingPerSpace, getRatingPerUser } from '../reducers/selectors';

import PrimaryButton from '../../../components/PrimaryButton';
import SecondaryButton from '../../../components/SecondaryButton';
import BookingInfo from '../components/BookingInfo';
import PriceInfo from '../components/PriceInfo';
import ReviewModal from '../components/ReviewModal';
import ReportDisputeModal from '../components/ReportDisputeModal';
import { BookingStates } from '../../../settings';
import TimeTablePanel from '../../individualSpace/components/TimeTablePanel';

import config from '../../../config/config';
import { Mixpanel } from '../../../util/MixPanel';

class BookingDetailsScreen extends Component {
  state = {
    width: "60vw",
    isReviewOpen: false,
    modalIsOpen: false,
    reportDisputeModalIsOpen: false,
    isHost: window.location.pathname.includes("host")
  }

  componentDidMount() {
    this.getIndividualBooking().then((res, err) => {
      if (window.location.pathname.includes("host")) {
        this.getReviewPerUser();
      }
      else {
        this.getLoggedUserReviewForSpace();
      }
    });
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  getIndividualBooking = () => {
    const { getBooking } = this.props;
    const { bookingId } = this.props.match.params;

    return new Promise((resolve, reject) => {
      resolve(getBooking(bookingId));
    })
  };

  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0)
    if (prevProps.location !== this.props.location) {
      this.getIndividualBooking();
    }
  }

  showCancelMessage = () => {
    const { individualBooking } = this.props;
    let cancelMessage = null;

    if (moment(individualBooking.booking[0].startDate).subtract(1, "days").format("X") > moment().format("X")) {

      if (individualBooking.booking[0].canceled === true || moment(individualBooking.booking[0].endDate).format('X') < moment().format('X')) return;

      if (moment(individualBooking.booking[0].startDate).subtract(3, "days").format("X") <= moment().format("X")) {
        cancelMessage = (<div>
          Bookings made within 72 hours of the allocated time and date will not be refunded in case of cancellation.<br />
          Read <NavLink to={`/terms-of-use`} target="_blank">
            <span>Terms and Conditions</span>
          </NavLink> for more information.
      </div>)
      } else {
        cancelMessage = (<div>
          Cancel before {moment(individualBooking.booking[0].startDate).subtract(3, "days").format("DD MMM YYYY")} at
          12:00 AM (GMT +10) to receive refunds of your payment.
      </div>)
      }
    }
    return cancelMessage;
  }

  openReviews = () => {
    this.setState({ isReviewOpen: true })
    const { isHost } = this.state;
    if (!isHost) {
      Mixpanel.track("Rate Space Clicked")
    }
    else {
      Mixpanel.track("Rate Renter Clicked")
    }
  };

  openReportDisputeModal = () => {
    this.setState({ reportDisputeModalIsOpen: true })
    Mixpanel.track("Report Dispute Opened")
  }

  submitReview = (stars, message) => {
    const { createRatingForSpace, createRatingForRenter, currentUser, individualBooking } = this.props;
    const { isHost } = this.state;
    const userId = currentUser._id;
    const spaceId = individualBooking.booking[0].spaceId._id;
    const renterId = individualBooking.booking[0].renterId._id;

    if (isHost) {
      createRatingForRenter({
        postData: {
          stars,
          message,
        },
        renterId
      })
    }
    else {
      createRatingForSpace({
        postData: {
          stars,
          message,
          userId
        },
        spaceId
      })
    }

  };

  submitReportDispute = (subject, message) => {
    const { currentUser, individualBooking, sendReportDispute } = this.props;
    const spaceId = individualBooking.booking[0].spaceId._id;

    let payload = {
      support: {
        ticket: {
          subject: subject,
          comment: {
            body: message
          },
          custom_fields: [
            { "id": 360001648760, "value": spaceId },
            { "id": 360001648780, "value": currentUser.email }
          ]
        }
      }
    };

    sendReportDispute(payload);
  };

  getLoggedUserReviewForSpace = () => {
    const { getRatingForSpace, currentUser, individualBooking } = this.props;
    const userId = currentUser._id;

    const spaceId = individualBooking && individualBooking.booking && individualBooking.booking[0] ? individualBooking.booking[0].spaceId._id : '';

    return new Promise((resolve, reject) => {
      resolve(getRatingForSpace({
        userId,
        spaceId
      }));
    })
  };

  getReviewPerUser = () => {
    const { getRatingForUser, individualBooking } = this.props;

    const renterId = individualBooking && individualBooking.booking && individualBooking.booking[0] ? individualBooking.booking[0].renterId._id : '';

    return new Promise((resolve, reject) => {
      resolve(getRatingForUser({
        renterId
      }));
    })
  };

  cancelBooking = () => {
    const { cancelAndRefundBooking, cancelAndRefundBookingHost, individualBooking } = this.props;
    const { _id } = individualBooking.booking[0];
    const { isHost } = this.state;

    this.setState({ modalIsOpen: false });
    if (isHost) {
      cancelAndRefundBookingHost({ bookingId: _id });
    }
    else {
      cancelAndRefundBooking({ bookingId: _id });
    }
  };

  openCancelModal = () => {
    this.setState({ modalIsOpen: true })
    Mixpanel.track("Cancel Booking Clicked")
  }

  renderCancelButton = () => {
    return (
      <PrimaryButton
        text="Cancel"
        className="footer-button booking red-bg cancel-btn"
        onClick={() => this.openCancelModal()}
      />
    )
  }

  showModalText = () => {
    const { individualBooking } = this.props;
    const { isHost } = this.state;
    let message = null;

    if (isHost) {
      message = (
        <div className="cancel-text">
          Are you sure you want to cancel this booking?
          <br /><br />
          Per your cancellation policy, a refund will be issued shortly for your guest.
          For more information on cancellation payouts, please see our
          <NavLink to={`/terms-of-use`} target="_blank">
            <span>Terms and Conditions</span>
          </NavLink>.
          <br /><br />
          You'll receive an email confirming the cancellation.
        </div>
      )
    }
    else {
      if (moment(individualBooking.booking[0].startDate).subtract(3, "days").format("X") <= moment().format("X")) {
        message = (
          <div className="cancel-text">
            Are you sure you want to cancel this booking?
            <br /><br />
            Per your cancellation policy, you won’t be refunded if you make cancel this booking.
            For more information on cancellation payouts, please see our
            <NavLink to={`/terms-of-use`} target="_blank">
              <span>Terms and Conditions</span>
            </NavLink>.
            <br /><br />
            You'll receive an email confirming the cancellation.
          </div>
        )
      } else {
        message = (
          <div className="cancel-text">
            Are you sure you want to cancel this booking?
            <br /><br />
            Per your cancellation policy, a refund will be issued shortly.
            For more information on cancellation payouts, please see our
            <NavLink to={`/terms-of-use`} target="_blank">
              <span>Terms and Conditions</span>
            </NavLink>.
            <br /><br />
            You'll receive an email confirming the cancellation.
          </div>
        )
      }
    }

    return message;
  }

  render() {
    const { individualBooking, bookingLoading, loggedUserRating, ratingPerUser } = this.props;
    const { isReviewOpen, modalIsOpen, reportDisputeModalIsOpen, isHost } = this.state;
    let photosContent = [];
    let status = BookingStates.confirmed.name;
    let statusColor = BookingStates.confirmed.color;
    let rateButton = null;

    let currentBooking = null;

    if (individualBooking && individualBooking.booking) {
      currentBooking = individualBooking.booking[0];
    }

    if (Object.keys(individualBooking).length && moment(currentBooking.endDate).format('X') < moment().format('X')) {
      status = BookingStates.completed.name;
      statusColor = BookingStates.completed.color;
    }

    if (Object.keys(individualBooking).length && currentBooking.canceled) {
      status = BookingStates.canceled.name;
      statusColor = BookingStates.canceled.color;
      ;
    }

    let rateBtnText = "Rate Space";

    if (isHost) {
      rateBtnText = "Rate Renter"
    }

    if (status === BookingStates.completed.name) {
      rateButton = (
        <div className="flex-center rate-btn">
          <PrimaryButton
            text={rateBtnText}
            className="footer-button booking orange-bg"
            onClick={() => this.openReviews()}
          />
        </div>
      )
    }

    if (individualBooking && individualBooking.booking && currentBooking && currentBooking.spaceId && currentBooking.spaceId.photosUrls.length > 0) {
      photosContent = currentBooking.spaceId.photosUrls.map(photo => (
        <div key={photo}>
          <img
            src={photo.photoUrl ? `${config.api}/files/${photo.photoUrl}` : `/imgs/main_search_bg.jpg`}
            alt="" />
        </div>
      ));
    }

    let cancelButton = null;

    let cancelModalText = null;

    if (Object.keys(individualBooking).length) {

      if (status === BookingStates.confirmed.name && !(moment(currentBooking.startDate).subtract(1, "days").format("X") <= moment().format("X"))) {
        cancelButton = this.renderCancelButton();
      }
      cancelModalText = this.showModalText()
    }

    let phoneNr = '';
    let posterName = '';
    let profilePic = '';
    let owner = null;
    let hostAccount = null;
    let renter = null;

    if (Object.keys(individualBooking).length) {
      if (isHost) {
        renter = currentBooking.renterId;

        phoneNr = renter.phone;
        posterName = `${renter.firstName} ${renter.lastName}`
        profilePic = renter.profilePictureUrl ?
          renter.socialLogin ?
            renter.profilePictureUrl :
            `${config.api}/files/${renter.profilePictureUrl}` :
          require("../../../assets/images/placeholder_profile.svg")
      }
      else {
        owner = currentBooking.spaceId.ownerId;

        phoneNr = owner.phone;
        posterName = `${owner.firstName} ${owner.lastName}`
        profilePic = owner.profilePictureUrl ?
          owner.socialLogin ?
            owner.profilePictureUrl :
            `${config.api}/files/${owner.profilePictureUrl}` :
          require("../../../assets/images/placeholder_profile.svg")

        if (owner.hostAccount) {
          hostAccount = owner.hostAccount;

          if (hostAccount.generalInfo && hostAccount.generalInfo.businessPhone) {
            phoneNr = hostAccount.generalInfo.businessPhone;
          }

          if (hostAccount.generalInfo && hostAccount.generalInfo.photoUrl) {
            profilePic = `${config.api}/files/${hostAccount.generalInfo.photoUrl}`;
          }

          if (hostAccount.companyDetails && hostAccount.companyDetails.companyName) {
            posterName = hostAccount.companyDetails.companyName;
          }
        }
      }
    }

    let reportModal = null;

    if (reportDisputeModalIsOpen) {

      reportModal = <ReportDisputeModal
        openModal={(reportDisputeModalIsOpen)}
        closeModal={() => this.setState({ reportDisputeModalIsOpen: false })}
        submitDispute={this.submitReportDispute}
      />
    }

    return (
      bookingLoading ?
        <div className="loader-wrapper">
          <Loader
            type="Ball-Triangle"
            height="100"
            width="100"
            color="#0EA800"
          />
        </div>

        :
        <div className="page-template booking-details-page">
          {reportModal}
          <Modal
            ariaHideApp={false}
            isOpen={modalIsOpen}
            onRequestClose={this.closeModal}
            className="cancel-modal"
            overlayClassName="overlay"
          >
            <div className="flex-center direction-column">
              <div className="code-modal-title cancel">Cancel Booking</div>
              {cancelModalText}
              <div className="buttons-wrapper">
                <button className="resend-button" onClick={() => this.setState({ modalIsOpen: false })}>No, Return
              </button>
                <SecondaryButton text="Yes, Cancel" className="red-bg" onClick={this.cancelBooking} />
              </div>
            </div>

          </Modal>
          {Object.keys(individualBooking).length &&
            <div>
              <ReviewModal
                openModal={(isReviewOpen)}
                closeModal={() => this.setState({ isReviewOpen: false })}
                spaceName={currentBooking.spaceId.name}
                userName={currentBooking.renterId.firstName}
                submitReview={this.submitReview}
                loggedUserRating={isHost ? ratingPerUser : loggedUserRating}
                isHost={isHost}
              />
              <div className="page-wrapper individual-space">
                <div className="individual-content">
                  <h1
                    className="page-title booking-details text-center-mobile desktop-display-none">{currentBooking.spaceId.name}</h1>
                  <div className="carousel-wrapper">
                    <Carousel
                      showArrows={true}
                      className="carousel desktop-similar"
                      width={this.state.width}
                      showThumbs={false}
                      swipeable={true}
                      emulateTouch={true}
                    >
                      {photosContent}
                    </Carousel>

                  </div>

                  <BookingInfo
                    space={currentBooking.spaceId}
                    spaceName={currentBooking.spaceId.name}
                    bookingId={currentBooking._id}
                    startDate={moment(currentBooking.startDate).tz(currentBooking.timeZone).format('DD MMM YYYY')}
                    endDate={moment(currentBooking.endDate).tz(currentBooking.timeZone).format('DD MMM YYYY')}
                    startTime={moment(currentBooking.startTime, ["h"]).format('h:mm A')}
                    endTime={moment(currentBooking.endTime, ["h"]).format('h:mm A')}
                    nrOfGuests={currentBooking.nrOfGuests}
                    status={status}
                    statusColor={statusColor}
                    notes={currentBooking.notesFromGuest}
                  />
                </div>
                <div className="individual-sidebar">
                  <PriceInfo
                    price={currentBooking.price}
                    isMonthly={currentBooking.isSubscription}
                    startDate={currentBooking.startDate}
                    isCanceled={currentBooking.canceled}
                    timeZone={currentBooking.timeZone}
                    isHost={isHost}
                  />
                  <div className="flex-center">
                    <div className="separation-line booking" />
                  </div>
                  <div className="poster-details-booking card-section">
                    <img
                      src={profilePic}
                      className="small-img-poster" alt="" />
                    <div className="text-details-wrapper">
                      <div
                        className="poster-name">{posterName}</div>
                      <div className="flex-center flex-start">
                        <img src={require("../../../assets/images/phone.svg")} className="phone-icon" alt="" />
                        <a href={`tel:+${phoneNr}`} className="phone-nr">{`+${phoneNr}`}</a>
                      </div>
                    </div>
                  </div>
                  {rateButton}
                  <div className="hours-title">AVAILABILITY HOURS</div>
                  <div className="monthly-message booking">For weekly and monthly bookings, please check the space availability hours, as it might not be open the whole week.
                  <br /> Note that the reservation only applies for available days.</div>
                  <div className="hours-wrapper">
                    <TimeTablePanel individualBooking={individualBooking} />
                  </div>
                </div>
              </div>
              <div className="space-footer">
                <div className="section-wrapper">
                  <div className="cancel-text">
                    {!isHost && this.showCancelMessage()}
                  </div>
                  <div className="sticky-buttons">
                    {cancelButton}
                    <PrimaryButton
                      text="Report Dispute"
                      className="footer-button booking gray-bg report-btn"
                      onClick={() => this.openReportDisputeModal()}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
    )
  }
}


BookingDetailsScreen.propTypes = {
  getBooking: PropTypes.func.isRequired,
  createRatingForSpace: PropTypes.func.isRequired,
  createRatingForRenter: PropTypes.func.isRequired,
  cancelAndRefundBooking: PropTypes.func.isRequired,
  cancelAndRefundBookingHost: PropTypes.func.isRequired,
  getRatingForSpace: PropTypes.func.isRequired,
  getRatingForUser: PropTypes.func.isRequired,
  sendReportDispute: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getBooking,
  createRatingForSpace,
  createRatingForRenter,
  cancelAndRefundBooking,
  cancelAndRefundBookingHost,
  getRatingForSpace,
  getRatingForUser,
  sendReportDispute,
};

const mapStateToProps = (state) => ({
  individualBooking: getIndividualBooking(state),
  bookingLoading: getIndividualBookingLoading(state),
  currentUser: currentUser(state),
  loggedUserRating: getRatingPerSpace(state),
  ratingPerUser: getRatingPerUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailsScreen);
