import React, { Component } from 'react';


class GlobalLocations extends Component {

    render() {
        return (
            <div className="page-template global-location-page">
                <div className="page-wrapper">
                    <h1 className="page-title">Global locations</h1>
                    <div className="gl-wraper">
                        <div className="gl-entry">
                            <div className="gl-image">
                                <img src={require('../../assets/images/global_australia.jpg')} alt="Australia" title="Australia" />
                            </div>
                            <div className="gl-title">Australia</div>
                        </div>
                        <div className="gl-entry">
                            <div className="gl-image">
                                <img src={require('../../assets/images/global_new_zealand.jpg')} alt="New Zealand" title="New Zealand" />
                            </div>
                            <div className="gl-title">New Zealand</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (GlobalLocations);
