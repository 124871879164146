export const SET_SEARCH_RESULTS = 'search/SET_SEARCH_RESULTS';
export const SET_INDIVIDUAL_SPACE = 'search/SET_INDIVIDUAL_SPACE';
export const SET_INDIVIDUAL_SPACE_FAIL = 'search/SET_INDIVIDUAL_SPACE_FAIL';
export const FETCH_INDIVIDUAL_SPACE = 'search/FETCH_INDIVIDUAL_SPACE';
export const SET_BOOKINGS_PER_SPACE = 'search/SET_BOOKINGS_PER_SPACE';
export const GET_BOOKING_RESPONSE = 'search/GET_BOOKING_RESPONSE';
export const FETCH_PRICE_SUCCESS = 'search/FETCH_PRICE_SUCCESS';
export const FETCH_PRICE = 'search/FETCH_PRICE';
export const RESET_PRICE = 'search/RESET_PRICE';
export const VALIDATE_BOOKING = 'search/VALIDATE_BOOKING';
export const VALIDATE_BOOKING_FAIL = 'search/VALIDATE_BOOKING_FAIL';
export const VALIDATE_BOOKING_SUCCESS = 'search/VALIDATE_BOOKING_SUCCESS';
