import React, { Component } from 'react';
import { faq, faqLoading } from '../../configReduxSetup/reducers/selectors';
import { setFaqAction } from '../../configReduxSetup/actions/actions';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';

class FAQ extends Component {

    componentDidMount() {
        const { setFaqAction } = this.props;
        setFaqAction();
    }

    renderDeskiFaq = () => {
        const { faq, faqLoading } = this.props;

        if (faqLoading) {
            return <div className="loader-wrapper">
                <Loader
                    type="Ball-Triangle"
                    height="100"
                    width="100"
                    color="#0EA800"
                />
            </div>
        }
        else if (faq) {
            return (
                faq.map((paragraph, index) => (
                    <div key={index} dangerouslySetInnerHTML={{ __html: paragraph.html }} />
                ))
            )
        }
    }

    render() {
        let deskiFaq = this.renderDeskiFaq();
        return (
            <div className="page-template new-space-page global">
                <div className="page-wrapper">
                    <h1 className="page-title">FAQ</h1>
                    {deskiFaq}
                </div>
            </div>
        )
    }
}


const mapDispatchToProps = {
    setFaqAction
};

const mapStateToProps = (state) => ({
    faq: faq(state),
    faqLoading: faqLoading(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);

