export const getSearchResults = (state) => state.getIn(['individualSpaceReducer', 'searchResults']);
export const getIndividualSpace = (state) => state.getIn(['individualSpaceReducer', 'individualSpace']);
export const getBookings = (state) => state.getIn(['individualSpaceReducer', 'bookings']);
export const getBookedDates = (state) => state.getIn(['individualSpaceReducer', 'bookedDates']);
export const getIsLoading = (state) => state.getIn(['individualSpaceReducer', 'isLoading']);
export const spaceError = (state) => state.getIn(['individualSpaceReducer', 'individualSpaceError']);
export const getBookingResponse = (state) => state.getIn(['spaceBookingReducer', 'bookingResponse']);
export const getPrice = (state) => state.getIn(['spaceBookingReducer', 'price']);
export const getIsPriceLoading = (state) => state.getIn(['spaceBookingReducer', 'isLoading']);
export const getSearchQueries = (state) => state.getIn(['searchReducer', 'searchQueries']);
export const getLoading = (state) => state.getIn(['spaceBookingReducer', 'loading']);
export const getValidateFail = (state) => state.getIn(['spaceBookingReducer', 'error']);