import { GET_FEATURED_PRICE, GET_FEATURED_PRICE_SUCCESS, SET_FEATURED_SUCCESS, SET_FEATURED, SET_FEATURED_FAIL } from '../actions/actionTypes';

import { Map } from 'immutable';

const initialState = Map({
  isLoading: false,
  isPriceLoading: false,
  price: {},
  hasFailed: false
});


function fetchPrice(state, { payload }) {
  return state.set('isPriceLoading', true);
}

function fetchPriceSuccess(state, { payload }) {
  return state.set('price', payload).set('isPriceLoading', false);
}

function setFeatured(state, { payload }) {
  return state.set('isLoading', true).set('hasFailed', false);
}

function setFeaturedSuccess(state, { payload }) {
  return state.set('isLoading', false);
}

function setFeaturedFail(state, { payload }) {
  return state.set('isLoading', false).set('hasFailed', true);
}

const handlers = {
  [GET_FEATURED_PRICE_SUCCESS]: fetchPriceSuccess,
  [GET_FEATURED_PRICE]: fetchPrice,
  [SET_FEATURED_SUCCESS]: setFeaturedSuccess,
  [SET_FEATURED_FAIL]: setFeaturedFail,
  [SET_FEATURED]: setFeatured,
};

export default function createIndividualPosterReducer(state = initialState, action = {}) {
  const handler = handlers[action.type];

  if (!handler) {
    return state;
  }

  return handler(state, action);
}