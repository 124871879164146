import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { padStart, get } from 'lodash-es';

import { searchSpaces, getPricePerBooking } from '../actions/actions';
import { getSearchResults, getBookingResponse, getPrice } from '../reducers/selectors';
import { currentUser } from '../../../modules/auth/reducers/selectors';
import { cardDetails } from '../../profile/reducers/selectors';

import PrimaryButton from '../../../components/PrimaryButton';
import { Mixpanel } from '../../../util/MixPanel';

class BookingUnsuccessful extends Component {

    state = {
        notComplete: false,
        modalHeight: '778px'

    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    cardFormat = (e) => {
        let formatted = e.target.value.split("-").join("");
        if (formatted.length > 0) {
            formatted = formatted.match(new RegExp('.{1,4}', 'g')).join("-");
        }
        e.target.value = formatted;
    }

    selectCard = (position) => {
        this.setState({ selectedCard: position })
    }

    openEditModal = (id) => {
        this.props.getCardId(id)
        this.props.onEdit();
    }

    renderDetails = () => {
      const { currentUser, cardDetails } = this.props;

      const cardName = get(cardDetails, 'paymentMethod.name', '-')
      const cardNumber = `XXXX-XXXX-XXXX-${get(cardDetails, 'paymentMethod.last4', 'XXXX')}`
      const cardExpMonth = padStart(get(cardDetails, 'paymentMethod.exp_month', 'XX'), 2, '0')
      const cardExpYear = String(get(cardDetails, 'paymentMethod.exp_year', 'XXXX')).slice(2)
      const cardExp = `${cardExpMonth} / ${cardExpYear}`

      if (!Object.keys(currentUser) && !currentUser.saveCreditCard)
        return;
      return (
        <div style={{ paddingTop: '10px' }}>
          <div className="justify-between card-name">
            <div >
              <div className="card-info">
                Name
              </div>
              <div className="credit-card">{cardName}</div>
            </div>
            <div>
              <img src={require('../../../assets/images/ic_edit.svg')} alt='' />
              <span className="edit" onClick={this.editCard}>Edit Card</span>
            </div>
          </div>

          <div>
            <div className="card-info">
              Card Number
            </div>
            <div className="card-data payment">
              {cardNumber}
            </div>
            <div className="card-info">
              Expiration Date
            </div>
            <div className="card-data payment">
              {cardExp}
            </div>
          </div>
        </div>
      )
    }

    renderUnsuccessModal = () => {
        const { currentUser, price, error } = this.props;
        let cardInfo = null;

        Mixpanel.track("Payment - Booking Unsuccessful")

        if (!!currentUser.saveCreditCard && price) {
            cardInfo = this.renderDetails()
        }
        return (
            <div className="flex-center direction-column">
                <div className="modal-description">{error}</div>
                <div className="status-wrapper">
                    <div>
                        <div className="card-info">
                            Booking Status:
                                        </div>
                        <div className="card-data">
                            Unsuccessful
                    </div>
                    </div>
                    {cardInfo}
                    <div className="separation-line booking"></div>
                    <div>
                        <div className="amount total fixed-width">
                            <div>
                                <div className="total-amount-text">TOTAL</div>
                                <div className="gst-text">*GST included</div>
                            </div>
                            <div className="amount-sum">{`$${price.price.totalPrice}`}</div>
                        </div>
                    </div>



                </div>

                <PrimaryButton text="Back" className="top-margin" onClick={this.props.onDone} />
            </div>
        );
    }
    render() {
        const { currentUser } = this.props;
        const customStyles = {
            content: {
                height: this.state.modalHeight
            }
        };

        return (
            <Modal
                isOpen={this.props.modalIsOpen}
                className="payment-modal success"
                style={customStyles}
                onRequestClose={this.props.closeModal}
                ariaHideApp={false}
            >
                <div className="modal-title red booking">{this.props.name}</div>
                <img src={require('../../../assets/images/ic_close.svg')} className="close-button  booking-close-btn white" alt=""
                    onClick={this.props.closeModal} />
                {Object.keys(currentUser).length &&
                    this.renderUnsuccessModal()}

            </Modal>
        );
    }
}

BookingUnsuccessful.propTypes = {
    searchSpaces: PropTypes.func.isRequired,
    getPricePerBooking: PropTypes.func.isRequired,

};

const mapDispatchToProps = {
    searchSpaces,
    getPricePerBooking

};

const mapStateToProps = (state) => ({
    searchResults: getSearchResults(state),
    bookingResponse: getBookingResponse(state),
    currentUser: currentUser(state),
    price: getPrice(state),
    cardDetails: cardDetails(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingUnsuccessful);
