import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

class PriceInfo extends Component {
    state = {
    }

    renderMonthlyPrice = () => {
        const { price, startDate, isCanceled, timeZone } = this.props;

        let totalGSTincluded = "-"
        let monthlyArray = [];
        let paidMonths = 1;

        if (price) {
            totalGSTincluded = `$${price.totalPrice}`;

            if (price.nrOfMonths) {
                for (let i = 1; i < price.nrOfMonths; i++) {
                    const dueDate = moment(startDate).tz(timeZone).add(i * 28, 'days');
                    if (dueDate.format('X') < moment().format('X')) {
                        paidMonths++;
                    }
                    else if (!isCanceled) {
                        monthlyArray.push(<div className="amount" key={i}>
                            <div className="amount-text gray">Due {dueDate.format('DD MMM YYYY')}</div>
                            <div className="amount-sum gray">{totalGSTincluded}</div>
                        </div>)
                    }
                }
                monthlyArray.unshift(
                    <div className="amount" key={price.nrOfMonths}>
                        <div className="amount-text gray">Already Paid</div>
                        <div className="amount-sum gray">{`$${+price.totalPrice * paidMonths}`}</div>
                    </div>
                )
            }
        }
        return (monthlyArray)
    }

    render() {
        const { price, isMonthly, isHost } = this.props;

        let monthlyPrice = null;
        if (isMonthly && price.nrOfMonths) {
            monthlyPrice =
                <div>
                    {this.renderMonthlyPrice()}
                </div>
        }

        let totalGSTincluded = `$${price.totalPrice}`;
        if (isHost) {
            totalGSTincluded = `$${price.totalNoFees}`;
        }
        let totalPriceSubscription = `$${price.totalPriceSubscription}`;
        let nrOfMonths = price.nrOfMonths;

        return (
            <div>
                {monthlyPrice}
                <div className="amount total" style={{ width: '100%' }}>
                    <div>
                        <div className="total-amount-text gray">TOTAL</div>
                        <div className="gst-text gray">*GST included</div>
                    </div>
                    <div className="amount-sum gray">{(isMonthly && nrOfMonths) ? totalPriceSubscription : totalGSTincluded}</div>
                </div>
            </div>
        )
    }
}

PriceInfo.propTypes = {
};

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PriceInfo);