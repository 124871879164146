import React, { Component } from 'react';

import PlacesAutocomplete from 'react-places-autocomplete';

export default class SearchInput extends Component {
    state = {
    }

    constructor(props) {
        super(props);

        this.searchInputRef = React.createRef();
        this.autoComplete = null;

        this.state = ({
            inputValue: this.props.value ? this.props.value : "",
        });
    }

    componentDidUpdate(prevProps) {
        const { value } = this.props;
        if (prevProps.value !== value) {
            this.setState({
                inputValue: value,
            })
        }
    }


    handleChange = (value) => {
        this.setState({ inputValue: value });
    }

    renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => {
        const { className, id, isInvalid, icon, small, onBlur } = this.props;

        let inputClassName = `${id} ${small && 'small-input'} ${className} ${isInvalid ? 'invalid' : ''}`;

        return (
            <div className="autocomplete-root">
                <input {...getInputProps({
                    className: inputClassName,
                    autoFocus: false,
                    placeholder: 'Location ...',
                    name: 'location',
                    onBlur
                })} />
                {icon && <img
                    className="input-icon"
                    src={icon}
                    alt=""
                />}

                <div className="pac-container">
                    {suggestions.map(suggestion => (
                        <div className="pac-item" {...getSuggestionItemProps(suggestion)}>
                            <span>{suggestion.description}</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    render() {
        const { inputValue } = this.state;

        const searchOptions = {
            types: ['(cities)'],
            componentRestrictions: { country: ["au", "nz"] }
        };

        return (
            <div className="input-wrapper search-input-wrapper">
                <PlacesAutocomplete
                    value={inputValue}
                    onChange={this.handleChange}
                    googleCallbackName="searchAutocomplete"
                    searchOptions={searchOptions}
                >
                    {this.renderFunc}
                </PlacesAutocomplete>
            </div>
        )
    }
}