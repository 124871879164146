import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string'
import history from '../../../history';


import { currentUser } from '../../auth/reducers/selectors';

import { getUsersBookings } from '../actions/actions';
import { getUsersBookingsLoading, getBookingsPerUser } from '../reducers/selectors';

import BookingSwitch from '../components/BookingSwitch';
import ResultsPage from '../components/ResultsPage';
import { Mixpanel } from '../../../util/MixPanel';

const BOOKINGS_PER_PAGE = 12;

class BookingsScreen extends Component {


  getBookings = () => {
    const { getUsersBookings, currentUser } = this.props;
    const page = +queryString.parse(this.props.location.search).page - 1;
    const type = queryString.parse(this.props.location.search).type;

    getUsersBookings({
      userId: currentUser._id,
      query: {
        perPage: BOOKINGS_PER_PAGE,
        page: page,
        filter: type
      }
    })

  }


  getSelected = (selected) => {

    history.push({
      pathname: history.location.pathname,
      search: `page=1&type=${selected}`
    });
  }


  componentDidMount() {
    this.getBookings();
    document.title = 'Deski';
    Mixpanel.track("Viewed Bookings", { type: "active" })
  }

  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0);
    if (prevProps.location.search !== this.props.location.search) {
      this.getBookings();
    }
  }


  render() {
    const page = queryString.parse(this.props.location.search);
    const type = queryString.parse(this.props.location.search).type;

    return (

      <div className="page-template bookings-page">
        <div className="page-wrapper">
          <div className="headers-wrapper direction-column-mobile">
            <h1 className="page-title text-center-mobile">Bookings</h1>
            <BookingSwitch
              getSelected={this.getSelected}
              selectedType={type}
            />
          </div>
          <ResultsPage
            page={page}
            type={type}
            elementsPerPage={BOOKINGS_PER_PAGE}
          />
        </div>
      </div>
    )
  }
}


BookingsScreen.propTypes = {
  getUsersBookings: PropTypes.func.isRequired,

};

const mapDispatchToProps = {
  getUsersBookings
};

const mapStateToProps = (state) => ({
  bookingsPerUser: getBookingsPerUser(state),
  bookingsLoading: getUsersBookingsLoading(state),
  currentUser: currentUser(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingsScreen);
