export const GET_BOOKINGS_SUCCESS = 'booking/GET_BOOKINGS_SUCCESS';
export const GET_BOOKINGS = 'booking/GET_BOOKINGS';
export const GET_INDIVIDUAL_BOOKING_SUCCESS = 'booking/GET_INDIVIDUAL_BOOKING_SUCCESS';
export const GET_INDIVIDUAL_BOOKING = 'booking/GET_INDIVIDUAL_BOOKING';
export const CREATE_REVIEW_SPACE = 'booking/CREATE_REVIEW_SPACE';
export const CREATE_REVIEW_SPACE_SUCCESS = 'booking/CREATE_REVIEW_SPACE_SUCCESS';
export const CREATE_REVIEW_RENTER = 'booking/CREATE_REVIEW_RENTER';
export const CREATE_REVIEW_RENTER_SUCCESS = 'booking/CREATE_REVIEW_RENTER_SUCCESS';
export const GET_REVIEW_SPACE = 'booking/GET_REVIEW_SPACE';
export const GET_REVIEW_SPACE_SUCCESS = 'booking/GET_REVIEW_SPACE_SUCCESS';
export const GET_REVIEW_USER = 'booking/GET_REVIEW_USER';
export const GET_REVIEW_USER_SUCCESS = 'booking/GET_REVIEW_USER_SUCCESS';
export const CREATE_REPORT_DISPUTE = 'booking/CREATE_REPORT_DISPUTE';
export const CREATE_REPORT_DISPUTE_SUCCESS = 'booking/CREATE_REPORT_DISPUTE_SUCCESS';