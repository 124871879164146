import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Redirect } from 'react-router';
import MetaTags from 'react-meta-tags';
import config from '../../../config/config';
import { Helmet } from "react-helmet";

import { searchSpaces, setSearchQueries } from '../actions/actions';
import { getSearchResults, getSearchQueries } from '../reducers/selectors';

import MainModal from '../../../components/MainModal';
import SearchInput from '../../../components/SearchInput';
import PrimaryButton from '../../../components/PrimaryButton';
import SpaceCard from '../../../components/SpaceCard';
import GuestsPicker from '../../../components/GuestsPicker';
import DateTypeSwitch from '../../../components/DateTypeSwitch';

import DailyPickerWrapper from '../components/DailyPickerWrapper';
import HourlyPickerWrapper from '../components/HourlyPickerWrapper';

import { DateType, TimeLimits } from '../../../settings';
import { spaces } from '../../../configReduxSetup/reducers/selectors';

import { Mixpanel } from '../../../util/MixPanel';


class SearchScreen extends Component {
    state = {
        notComplete: false
    }

    selectCard = (position) => {
        const { setSearchQueries } = this.props;

        let searchQueries = {
            selectedOption: position,
        };

        if (position === "desks-coworking-spaces") {
            searchQueries.guestsNumber = 1;
        }

        setSearchQueries(searchQueries);

        this.setState({ notComplete: false })
    }

    getNumber = (number) => {
        const { setSearchQueries } = this.props;

        let searchQueries = {
            guestsNumber: number,
        };

        setSearchQueries(searchQueries);
    }

    getLocation = (e) => {
        const { name, value } = e.target;

        const { setSearchQueries } = this.props;

        let searchQueries = {
            [name]: value,
        };

        setSearchQueries(searchQueries);

        e.preventDefault();
    }

    checkAndSubmit = () => {
        const { startDate, endDate, startHour, endHour, selectedOption, guestsNumber, dateType, location } = this.props.searchQueries;



        let queryParams = {
            "startDate": null,
            "endDate": null,
            "startHour": null,
            "endHour": null,
            "location": location,
            "guestsNumber": guestsNumber,
            "selectedOption": selectedOption
        };

        let isFormValid = false;

        switch (dateType) {
            case DateType.HOURLY:
                isFormValid = this.validateFormHourly();

                queryParams.startDate = moment(startDate).format("X");
                queryParams.endDate = moment(startDate).format("X");
                queryParams.startHour = startHour;
                queryParams.endHour = endHour ? endHour : startHour + 1;
                break;

            case DateType.DAILY:
            case DateType.WEEKLY:
            case DateType.MONTHLY:
                isFormValid = this.validateFormDaily();

                queryParams.startDate = moment(startDate).format("X");
                queryParams.endDate = moment(endDate).format("X");
                queryParams.startHour = TimeLimits.MIN_HOUR;
                queryParams.endHour = TimeLimits.MAX_HOUR;
                break;
            default:
                break
        }
        if (isFormValid) {

            let queryLink = `${queryParams.selectedOption}/${queryParams.location.replace(/\W+(?!$)/g, '-').replace(/\W$/, '').toLowerCase()}/${queryParams.startDate}/${queryParams.endDate}/${queryParams.startHour}/${queryParams.endHour}/${queryParams.guestsNumber}/${dateType}`;
            this.setState({ notComplete: false, redirect: true, query: queryLink });
        } else {

            this.setState({ notComplete: true });
        }

        let mixpanelDetails = {
            location,
            spaceType: selectedOption,
            durationType: dateType,
            bookingStartDate: moment(startDate).format(),
            bookingEndDate: endDate && moment(endDate).format(),
            bookingStartHour: startHour && moment(startHour, ["h"]).format("h:mm A"),
            bookingEndHour: endHour && moment(endHour, ["h"]).format("h:mm A"),
            numberOfGuests: guestsNumber
        }
        Mixpanel.track("Searched Spaces", mixpanelDetails)
    }

    validateFormDaily = () => {
        const { startDate, endDate, selectedOption, location } = this.props.searchQueries;

        let ret = false;

        if (startDate && endDate && location && selectedOption) {
            ret = true;
        }

        return ret;
    }

    validateFormHourly = () => {
        const { startDate, startHour, selectedOption, location } = this.props.searchQueries;

        let ret = false;

        if (startDate && startHour && location && selectedOption) {
            ret = true;
        }

        return ret;
    }

    /* 
        change date Type and reset all start/end date and time
    */
    changeDateType = (newType) => {
        const { dateType } = this.props.searchQueries;
        const { setSearchQueries } = this.props;

        if (dateType !== newType) {
            let searchQueries = {
                dateType: newType,
                startDate: null,
                endDate: null,
                startHour: null,
                endHour: null
            };

            setSearchQueries(searchQueries);
        }
    }

    changeDates = (startDate, endDate) => {
        const { setSearchQueries } = this.props;

        let searchQueries = {
            startDate,
            endDate
        };

        setSearchQueries(searchQueries);

        this.setState({ notComplete: false })

    }

    changeHours = (startHour, endHour) => {
        const { setSearchQueries } = this.props;

        let searchQueries = {
            startHour,
            endHour
        };

        setSearchQueries(searchQueries);

        this.setState({ notComplete: false })

    }

    openGuestPicker = () => {
        const { selectedOption } = this.props.searchQueries;

        if (selectedOption !== "desks-coworking-spaces") {

            this.setState({ isGuestsPickerVisible: true });
        }
    }

    render() {
        const { spaces } = this.props;
        if (this.state.redirect) {
            return <Redirect push to={`/search/${this.state.query}`} />;
        }
        let size = spaces.length;

        const { notComplete, isGuestsPickerVisible } = this.state;

        const { dateType, location, selectedOption, startDate, endDate, guestsNumber, startHour, endHour } = this.props.searchQueries;

        let datePickerContent = "";
        if (dateType === DateType.HOURLY) {

            datePickerContent = <HourlyPickerWrapper
                startDate={startDate}
                endDate={endDate}
                startHour={startHour}
                endHour={endHour}
                changeDates={this.changeDates}
                changeHours={this.changeHours}
                notComplete={notComplete}
            />
        } else {

            datePickerContent = <DailyPickerWrapper
                key={dateType}
                startDate={startDate}
                endDate={endDate}
                changeDates={this.changeDates}
                notComplete={notComplete}
                dateType={dateType}
            />
        }

        return (
            <MainModal
                noCloseButton
                isLeftModal
            >
                <MetaTags>
                    <title>Search For Shared Offices and Coworking Spaces | Deski</title>
                    <meta name="description" content="Search thousands of work spaces from private and shared offices to hot desks at Deski." />
                </MetaTags>
                <Helmet>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <div className="landing-search-form flex-center direction-column">
                    <div className="location-wrapper">
                        <SearchInput
                            id="search-input"
                            icon={require('../../../assets/images/ic_location.svg')}
                            placeholder="Location"
                            name="location"
                            onBlur={this.getLocation}
                            value={location}
                            isInvalid={notComplete && !location}
                        />
                    </div>

                    <div className="cards-wrapper">
                        {spaces.map(space => (
                            <SpaceCard
                                key={space.key}
                                isSelected={selectedOption === space.url}
                                text={space.name}
                                icon={space.iconUrl ? `${config.api}/files/${space.iconUrl}` : ''}
                                onClick={() => this.selectCard(space.url)}
                                isInvalid={notComplete && !selectedOption}
                                size={size}
                            />
                        ))}
                    </div>

                    <DateTypeSwitch selectedType={dateType} changeDateType={this.changeDateType} />

                    {datePickerContent}

                    <div className="guests-wrapper">
                        <div className="guests-text">Number of guests</div>
                        <GuestsPicker
                            isDeskSelected={selectedOption === "desks-coworking-spaces"}
                            guestsNumber={guestsNumber}
                            isPickerVisible={isGuestsPickerVisible}
                            getNumber={this.getNumber}
                            onClose={() => this.setState({ isGuestsPickerVisible: false })}
                            onClickPicker={() => this.openGuestPicker()}
                        />
                    </div>
                    {notComplete && <span className="validation-message">Please fill in all Search Information.</span>}
                    <PrimaryButton text="Search" className="top-margin absolute-button" onClick={this.checkAndSubmit} />
                </div>
            </MainModal >
        );
    }
}

SearchScreen.propTypes = {
    searchSpaces: PropTypes.func.isRequired,
    setSearchQueries: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    searchSpaces,
    setSearchQueries
};

const mapStateToProps = (state) => ({
    searchResults: getSearchResults(state),
    searchQueries: getSearchQueries(state),
    spaces: spaces(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchScreen);
