import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import { NavLink } from "react-router-dom";
import { get, padStart } from "lodash-es";

import { currentUser } from "../../auth/reducers/selectors";
import ProfileSwitch from "../components/ProfileSwitch";
import PrimaryButton from "../../../components/PrimaryButton";
import SecondaryButton from "../../../components/SecondaryButton";
import {
  ResendWaitingTime,
  MaxPictureSize,
  Countries,
  UserType,
  AccountType
} from "../../../settings";
import history from "../../../history";
import Loader from "react-loader-spinner";

import { sendSmsCode, verifySmsCode } from "../../auth/actions/actions";
import { updateUser } from "../../auth/actions/actions";

import {
  registerHost,
  stopRedirect,
  deactivateHost,
  getBankDetailsAction
} from "../actions/actions";
import {
  registerOrEditHostLoading,
  redirectHost,
  redirectRemoveHost,
  getRemoveAccountLoading,
  registerFail,
  registerFailError,
  bankDetails,
  getLoading
} from "../reducers/selectors";

import config from "../../../config/config";
import PersonalHost from "../components/PersonalHost";
import BusinessHost from "../components/BusinessHost";
import PaymentDetails from "../components/PaymentDetails";
import UserTypeSwitch from "../components/UserTypeSwitch";

class HostProfile extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidMount() {
    document.title = "Deski";

    const { currentUser, getBankDetails } = this.props;

    if (currentUser.saveBankAccount) {
      getBankDetails();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { registerFail, registerFailError } = this.props;
    if (!prevProps.bankDetails.account && !!this.props.bankDetails.account) {
      this.setState(() => this.getInitialState());
    }

    if (prevProps.registerFail !== registerFail && registerFail === true) {
      this.setState({
        editMode: true,
        registerError: true,
        registerErrorMessage:
          registerFailError.code !== "Unknown"
            ? registerFailError.msg
            : "Some of the data you entered may be invalid. Please try again with valid data."
      });
    }
  }

  getInitialState = () => {
    const {
      currentUser,
      bankDetails: { account }
    } = this.props;
    const { hostAccount, saveBankAccount } = currentUser;

    const initialState = {
      type: "host",
      preview: null,
      phone:
        hostAccount.generalInfo && hostAccount.generalInfo.businessPhone
          ? hostAccount.generalInfo.businessPhone.substr(
              2,
              hostAccount.generalInfo.businessPhone
            )
          : currentUser.phone.substr(2, currentUser.phone.length),
      prefix:
        hostAccount.generalInfo && hostAccount.generalInfo.businessPhone
          ? hostAccount.generalInfo.businessPhone.substr(0, 2)
          : currentUser.phone.substr(0, 2),
      editMode: !hostAccount.active,
      passwordVisible: false,
      isCodeVerified: true,
      isPhoneValid: true,
      EmailOrPhoneAlreadyExists: false,
      userType:
        hostAccount.generalInfo && hostAccount.generalInfo.isCompany
          ? UserType.business
          : UserType.personal,
      selectedAccountType: AccountType.savings,
      displayBusinessName:
        hostAccount.generalInfo &&
        hostAccount.generalInfo.isCompany &&
        hostAccount.companyDetails.displayBusinessName
          ? true
          : false,
      hostAddress: hostAccount.generalInfo
        ? hostAccount.generalInfo.address
        : null,
      hostCity: hostAccount.generalInfo ? hostAccount.generalInfo.city : null,
      hostGovNumber: hostAccount.generalInfo
        ? hostAccount.generalInfo.governmentNumber
        : null,
      hostState: hostAccount.generalInfo ? hostAccount.generalInfo.state : null,
      hostZip: hostAccount.generalInfo ? hostAccount.generalInfo.zip : null,
      hostBirthday: hostAccount.generalInfo
        ? hostAccount.generalInfo.dob
        : null,
      ...(saveBankAccount && {
        hostAccountName: get(account, "accountName", null),
        hostAccountNumber: padStart(
          get(account, "accountNumber", "XXXX"),
          9,
          "X"
        ),
        hostBank: get(account, "bankName", null),
        hostBsb: get(account, "bsb", null)
      }),
      hostBusinessName: hostAccount.companyDetails
        ? hostAccount.companyDetails.companyName
        : null,
      hostLegalName: hostAccount.companyDetails
        ? hostAccount.companyDetails.legalName
        : null,
      hostTaxNumber: hostAccount.companyDetails
        ? hostAccount.companyDetails.taxNumber
        : null,
      hostBusinessAddress: hostAccount.companyDetails
        ? hostAccount.companyDetails.companyAddress
        : null,
      hostBusinessCity: hostAccount.companyDetails
        ? hostAccount.companyDetails.companyCity
        : null,
      hostBusinessState: hostAccount.companyDetails
        ? hostAccount.companyDetails.companyState
        : null,
      hostBusinessZip: hostAccount.companyDetails
        ? hostAccount.companyDetails.companyZip
        : null,
      hostCountry: Countries.Australia.name,
      hostBusinessCountry: Countries.Australia.name,
      incomplete: [],
      notComplete: false,
      firstFocus: true,
      terms: hostAccount.active ? true : false,
      stripeTerms: hostAccount.active
        ? !!get(account, "tos_acceptance.date")
        : false,
      registerError: false,
      registerErrorMessage:
        "Some of the data you entered may be invalid. Please try again with valid data.",
      activateFailError: null
    };

    return initialState;
  };

  getSelected = selected => {
    this.setState({
      type: selected
    });
    if (selected === "renter") {
      history.push("/profile");
    }
  };

  readURL = event => {
    const input = event.target;

    if (input.files && input.files[0]) {
      let reader = new FileReader();
      const sizeInMB = input.files[0].size / 1024 / 1024;

      if (sizeInMB > MaxPictureSize) {
        this.setState({ invalidFileSize: true });
      } else {
        reader.onload = e => {
          this.setState({
            profilePictureBase64: e.target.result,
            preview: e.target.result,
            invalidFileSize: false,
            previewChanged: true
          });
        };

        reader.readAsDataURL(input.files[0]);
      }
    }
  };

  handleInputChange = e => {
    let { name, value } = e.target;

    if (
      name === "hostZip" ||
      name === "hostBusinessZip" ||
      name === "hostAccountNumber" ||
      name === "hostBsb"
    ) {
      value = value.replace(/[A-Za-z ]/g, "");
    }

    if (name === "hostBank" || name === "hostAccountName") {
      value = value.replace(/[^A-Za-z ]/g, "");
    }

    this.setState({ [name]: value });
    if (name === "phone") this.setState({ isPhoneValid: false });
  };

  sendCode = async () => {
    const { phone, resendEnabled, prefix } = this.state;

    try {
      await this.props.sendSmsCode({
        phone: (prefix + phone).replace(/\+|-/g, ""),
        forgot: false
      });
      this.setState({
        modalIsOpen: true,
        resendEnabled: resendEnabled === null ? true : false,
        AccountAlreadyConnected: null,
        Unknown: null
      });
    } catch (e) {
      let error = JSON.parse(e.message);
      if (error.error.code === "AccountAlreadyConnected")
        this.setState({
          [error.error.code]:
            "The phone number you entered is already registred. Please try again."
        });
      else
        this.setState({
          [error.error.code]: "Please enter a valid phone number."
        });
    }

    setTimeout(() => {
      this.setState({ resendEnabled: true });
    }, ResendWaitingTime);
  };

  verifySmsCode = async () => {
    const { verifyCode, phone, prefix } = this.state;

    try {
      const response = await this.props.verifySmsCode({
        code: verifyCode,
        phone: (prefix + phone).replace(/\+|-/g, "")
      });
      if (response.isCodeValid) {
        this.setState({
          modalIsOpen: false,
          isCodeVerified: true,
          isPhoneValid: true
        });
      }
    } catch (e) {
      this.setState({ isCodeVerified: false });
    }
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      editModalOpen: false,
      addCardModal: false,
      deleteModalOpen: false
    });
  };

  saveChanges = photo => {
    const { registerHost } = this.props;
    const {
      hostAddress,
      hostCity,
      hostState,
      hostZip,
      hostBirthday,
      hostGovNumber,
      phone,
      previewChanged,
      prefix,
      terms,
      stripeTerms
    } = this.state;
    const {
      hostBank,
      hostAccountName,
      hostBsb,
      hostAccountNumber,
      selectedAccountType
    } = this.state;

    let body = {};
    let paymentBody = {};
    let userType = this.state.userType;
    let preview = "";
    let cardChanged = false;
    const initialState = this.getInitialState();

    if (previewChanged) {
      preview = photo;
    }

    paymentBody = {
      bankName: hostBank,
      accountName: hostAccountName,
      bsb: hostBsb,
      accountNumber: hostAccountNumber,
      accountType: selectedAccountType,
      holderType: userType
    };

    if (
      initialState.hostBank !== hostBank ||
      initialState.hostAccountName !== hostAccountName ||
      initialState.hostBsb !== hostBsb ||
      initialState.hostAccountNumber !== hostAccountNumber ||
      initialState.selectedAccountType !== selectedAccountType
    ) {
      cardChanged = true;
    }

    body = {
      ...(previewChanged && { photoBase64: preview }),
      businessPhone: prefix.replace(/\+|-/g, "") + phone,
      address: hostAddress,
      city: hostCity,
      state: hostState,
      zip: hostZip,
      dob: hostBirthday,
      governmentNumber: hostGovNumber,
      isCompany: userType === UserType.business,
      ...(cardChanged && { ...paymentBody })
    };

    if (userType === UserType.business) {
      const {
        displayBusinessName,
        hostBusinessName,
        hostLegalName,
        hostTaxNumber,
        hostBusinessAddress,
        hostBusinessCity,
        hostBusinessState,
        hostBusinessZip
      } = this.state;

      body = {
        ...body,
        displayBusinessName,
        companyName: hostBusinessName,
        legalName: hostLegalName,
        taxNumber: hostTaxNumber,
        companyAddress: hostBusinessAddress,
        companyCity: hostBusinessCity,
        companyState: hostBusinessState,
        companyZip: hostBusinessZip,
        companyCountry: Countries.Australia.code
      };
    }

    let emptyFields = 0;
    let incompleteArray = [];

    let paymentAndInfo = { ...body, ...paymentBody };
    Object.keys(paymentAndInfo).forEach(field => {
      if (
        paymentAndInfo[field] === "" ||
        paymentAndInfo[field] === null ||
        paymentAndInfo[field] === undefined
      ) {
        emptyFields++;
        incompleteArray.push(field);
      }
    });

    if (emptyFields > 0) {
      this.setState({ notComplete: true, incomplete: incompleteArray });
    } else if (!terms || !stripeTerms) {
      this.setState({ notComplete: false, termsError: true });
    } else {
      registerHost(body, terms);
      this.setState({
        editMode: false,
        notComplete: false,
        firstFocus: true,
        termsError: false
      });
    }
  };

  editProfile = () => {
    this.setState({ editMode: true });
  };

  deactivateAccount = () => {
    const { deactivateHost } = this.props;
    this.setState({ deleteModalOpen: false });
    deactivateHost();
  };

  clearInputs = () => {
    const { firstFocus } = this.state;
    if (firstFocus)
      this.setState({
        hostAccountName: "",
        hostBank: "",
        hostBsb: "",
        hostAccountNumber: "",
        firstFocus: false
      });
  };

  getPrefix = prefix => {
    this.setState({ prefix });
  };

  toggleCheck = e => {
    const { name } = e.target;

    this.setState({ [name]: !this.state[name] });
  };

  render() {
    const {
      notComplete,
      type,
      previewChanged,
      invalidFileSize,
      editMode,
      isCodeVerified,
      isPhoneValid,
      modalIsOpen,
      resendEnabled,
      AccountAlreadyConnected,
      Unknown
    } = this.state;
    const {
      currentUser,
      redirectHost,
      redirectRemoveHost,
      registerLoader,
      stopRedirect,
      removeLoader,
      loading
    } = this.props;
    const {
      accountSelectionVisible,
      selectedAccountType,
      phone,
      deleteModalOpen,
      incomplete,
      prefix
    } = this.state;
    const {
      hostAddress,
      hostCity,
      hostState,
      hostCountry,
      hostZip,
      hostBirthday,
      hostGovNumber
    } = this.state;
    const {
      hostBusinessName,
      hostLegalName,
      hostTaxNumber,
      hostBusinessAddress,
      hostBusinessCity,
      hostBusinessState,
      hostBusinessZip,
      hostBusinessCountry
    } = this.state;
    const {
      hostAccountName,
      hostBank,
      hostBsb,
      hostAccountNumber,
      termsError,
      terms,
      stripeTerms,
      registerError,
      activateFailError
    } = this.state;

    let userType = this.state.userType;
    let preview = this.state.preview;

    if (loading || registerLoader || removeLoader) {
      return (
        <div className="page-template profile-page-host profile">
          <div className="loader-wrapper">
            <Loader
              type="Ball-Triangle"
              height="100"
              width="100"
              color="#0EA800"
            />
          </div>
        </div>
      );
    }

    let termsText = (
      <Fragment>
        <div className="terms-acc-wrapper">
          <input
            type="checkbox"
            name="terms"
            onChange={this.toggleCheck}
            disabled={!editMode}
            checked={terms}
          />
          <span className="terms-acc-text">
            By checking this box, I accept Deski's
            <NavLink to={`/terms-of-use`} target="_blank">
              {" "}
              <b>Terms and Conditions</b>{" "}
            </NavLink>
            and{" "}
            <NavLink to={`/privacy-policy`} target="_blank">
              <b>Privacy Policy.</b>{" "}
            </NavLink>{" "}
          </span>
        </div>
        <div className="terms-acc-wrapper">
          <input
            type="checkbox"
            name="stripeTerms"
            onChange={this.toggleCheck}
            disabled={!editMode}
            checked={stripeTerms}
          />
          <span className="terms-acc-text">
            By checking this box, I accept Stripe's
            <a
              href={`https://stripe.com/au/connect-account/legal`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {" "}
              <b>Connected Account Agreement</b>
            </a>
            .
          </span>
        </div>
      </Fragment>
    );

    let buttonText = "Save Changes";
    let cancelButton = (
      <PrimaryButton
        text="Cancel"
        className="footer-button booking red-bg report-btn"
        onClick={() => this.setState(this.getInitialState())}
      />
    );

    if (!currentUser.hostAccount.active) {
      buttonText = "Activate Account";
      cancelButton = null;
      termsText = (
        <Fragment>
          <div className="terms-acc-wrapper">
            <input
              type="checkbox"
              name="terms"
              onChange={this.toggleCheck}
              disabled={!editMode}
              checked={terms}
            />
            <span className="terms-acc-text">
              By checking this box, I accept Deski's
              <NavLink to={`/terms-of-use`} target="_blank">
                {" "}
                <b>Terms and Conditions</b>{" "}
              </NavLink>
              and{" "}
              <NavLink to={`/privacy-policy`} target="_blank">
                <b>Privacy Policy.</b>{" "}
              </NavLink>{" "}
            </span>
          </div>
          <div className="terms-acc-wrapper">
            <input
              type="checkbox"
              name="stripeTerms"
              onChange={this.toggleCheck}
              disabled={!editMode}
              checked={stripeTerms}
            />
            <span className="terms-acc-text">
              By checking this box, I accept Stripe's
              <a
                href={`https://stripe.com/au/connect-account/legal`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {" "}
                <b>Connected Account Agreement</b>
              </a>
              .
            </span>
          </div>
        </Fragment>
      );
    }

    if (redirectHost && buttonText === "Activate Account") {
      stopRedirect();
      history.push("/my-deski?type=bookings");
    }

    if (redirectRemoveHost && buttonText !== "Activate Account") {
      stopRedirect();
      history.push("/");
    }

    let status = (
      <SecondaryButton
        disabled={false}
        text="Send code"
        className="send-code-button"
        onClick={this.sendCode}
      />
    );

    if (isPhoneValid) {
      status = (
        <div className="verified-button">
          <img src={require("../../../assets/images/ic_check.svg")} alt="" />
          <span className="button-text">Verified</span>
        </div>
      );
    }

    let accountType = null;

    if (accountSelectionVisible) {
      let type = null;

      if (selectedAccountType === AccountType.savings) {
        type = AccountType.checking;
        this.clearInputs();
      } else {
        type = AccountType.savings;
        this.clearInputs();
      }

      accountType = (
        <div
          className="input-style cursor-pointer flex-center"
          onClick={() =>
            this.setState({
              selectedAccountType: type,
              accountSelectionVisible: false
            })
          }
        >
          {type}
        </div>
      );
    }

    let profileSrc =
      currentUser &&
      currentUser.socialLogin &&
      Object.keys(currentUser.socialLogin).length
        ? currentUser.profilePictureUrl
        : `${config.api}/files/${currentUser.profilePictureUrl}`;

    let isDisplayed = null;

    if (currentUser.hostAccount.active) {
      if (currentUser.hostAccount.generalInfo.photoUrl && !previewChanged) {
        preview = `${config.api}/files/${currentUser.hostAccount.generalInfo.photoUrl}`;
      }
      if (
        currentUser.hostAccount.generalInfo.isCompany &&
        currentUser.hostAccount.companyDetails.displayBusinessName
      ) {
        isDisplayed = true;
      }
    }

    let bottomBarButton = (
      <div className="sticky-buttons">
        <PrimaryButton
          text="Edit Profile"
          className="footer-button booking gray-bg report-btn"
          onClick={this.editProfile}
        />
        <PrimaryButton
          text="Deactivate"
          icon={"ic_delete.svg"}
          className="footer-button booking red-bg report-btn"
          onClick={() => this.setState({ deleteModalOpen: true })}
        />
      </div>
    );

    if (editMode) {
      bottomBarButton = (
        <div className="sticky-buttons">
          {cancelButton}
          <PrimaryButton
            text={buttonText}
            className="footer-button booking green-bg report-btn"
            onClick={() => this.saveChanges(preview)}
            disabled={!isPhoneValid}
          />
        </div>
      );
    }

    return (
      <div className="page-template profile-page-host profile">
        <Modal
          ariaHideApp={false}
          isOpen={deleteModalOpen}
          // onRequestClose={this.closeModal}
          className="disable-account-modal"
          overlayClassName="overlay"
        >
          <img
            src={require("../../../assets/images/ic_close.svg")}
            className="close-button filter-close-button"
            alt=""
            onClick={this.closeModal}
          />
          <div className="flex-center direction-column">
            <div className="delete-wrapper gray-bg flex-center">
              <img
                src={require("../../../assets/images/ic_deactivate.svg")}
                style={{ width: "22px" }}
                alt=""
              />
            </div>
            <div className="code-modal-title delete">
              Deactivate your Deski (Host) Account
            </div>
            <div className="delete-text direction-column">
              You can deactivate your Deski Account at any time. <br />
              If you change your mind, you are able to reactivate it at any
              point. <br /> <br />
              Learn what deactivating your account means!
              <ul>
                <li>
                  Any reservations you currently have as a host will
                  automatically be cancelled.
                </li>
                <li>Your host profile will be hidden.</li>
                <li>Your listings will be hidden.</li>
                <li>
                  Some information, such as your reviews, may remain visible to
                  others.
                </li>
                <li>
                  We’ll retain your account data so you can reactivate your
                  account in the future.
                </li>
              </ul>
              To reactivate your account, click here or visit the List Your
              Spaces section. <br /> <br />
              Need more info, please contact us.
            </div>
            <PrimaryButton
              text="Deactivate Account"
              className="top-margin gray-bg"
              onClick={this.deactivateAccount}
            />
            <PrimaryButton
              text="Cancel"
              className="no-bg gray-border"
              textClass="gray"
              onClick={this.closeModal}
            />
          </div>
        </Modal>
        <Modal
          ariaHideApp={false}
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          className="insert-code-modal"
          overlayClassName="overlay"
        >
          <div className="code-modal-title">Insert the code</div>
          <div className="code-modal-text">
            We sent the code to <br /> <b>{prefix + phone}</b>
          </div>
          <input
            name="verifyCode"
            className={`code-modal-input ${
              isCodeVerified === false ? "invalid" : ""
            }`}
            onChange={e => {
              this.handleInputChange(e);
            }}
          />
          <div className="buttons-wrapper">
            <button
              className="resend-button"
              disabled={!resendEnabled}
              onClick={this.sendCode}
            >
              Resend
            </button>
            <SecondaryButton text="Submit" onClick={this.verifySmsCode} />
          </div>
        </Modal>

        <div className="page-wrapper">
          <div className="page-header">
            <h1 className="page-title">Profile</h1>
            <ProfileSwitch getSelected={this.getSelected} selectedType={type} />
          </div>
          <div className="page-content">
            <div className="main-content">
              <div className="row">
                <div className="field-wrapper">
                  <div className="img-uploader-wrapper">
                    <div className="img-uploader flex-center direction-column">
                      <div
                        className="uploader-img-profile"
                        style={{
                          backgroundImage: `url(${preview ||
                            (currentUser.profilePictureUrl
                              ? profileSrc
                              : require("../../../assets/images/placeholder_profile.svg"))})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center center"
                        }}
                      ></div>
                      <input
                        type="file"
                        disabled={!editMode}
                        onChange={this.readURL}
                        className="photo-uploader"
                        style={{ height: "130px" }}
                        accept="image/*"
                      />
                      <button
                        className={`text-button align-center ${
                          editMode ? "" : "hidden"
                        }`}
                      >
                        Upload Photo Or Logo
                      </button>
                    </div>
                  </div>
                  {invalidFileSize && (
                    <span className="validation-message validation-message-avatar">
                      Invalid file size
                    </span>
                  )}
                  <UserTypeSwitch
                    setPersonal={() => {
                      this.setState({ userType: UserType.personal });
                      this.clearInputs();
                    }}
                    setBusiness={() => {
                      this.setState({ userType: UserType.business });
                      this.clearInputs();
                    }}
                    editMode={editMode}
                    userType={userType}
                  />
                </div>
              </div>
              <div className="separation-line host"></div>
              {notComplete && (
                <span className="validation-message">
                  Please fill in all information.
                </span>
              )}
              {registerError && (
                <span className="validation-message">
                  {this.state.registerErrorMessage}
                </span>
              )}
              {activateFailError && (
                <span className="validation-message">{activateFailError} </span>
              )}
              {termsError && (
                <span className="validation-message">
                  You must accept all Terms and Conditions before activating
                  your account.
                </span>
              )}
              <PersonalHost
                editMode={editMode}
                defaultAddress={hostAddress}
                status={status}
                defaultCity={hostCity}
                defaultState={hostState}
                defaultZip={hostZip}
                defaultCountry={hostCountry}
                defaultDob={hostBirthday}
                defaultGovNumber={hostGovNumber}
                phone={phone}
                prefix={prefix}
                getPrefix={this.getPrefix}
                handleInputChange={this.handleInputChange}
                AccountAlreadyConnected={AccountAlreadyConnected}
                Unknown={Unknown}
                emptyFields={incomplete}
                prefixChanged={() => this.setState({ isPhoneValid: false })}
              />

              <div className="separation-line"></div>

              <BusinessHost
                displayBusinessName={() =>
                  this.setState({ displayBusinessName: true })
                }
                editMode={editMode}
                handleInputChange={this.handleInputChange}
                userType={userType}
                AccountAlreadyConnected={AccountAlreadyConnected}
                Unknown={Unknown}
                defaultBusinessName={hostBusinessName}
                defaultLegalName={hostLegalName}
                defaultTaxNumber={hostTaxNumber}
                defaultCompanyAddress={hostBusinessAddress}
                defaultCompanyCity={hostBusinessCity}
                defaultCompanyState={hostBusinessState}
                defaultCompanyZip={hostBusinessZip}
                defaultCompanyCountry={hostBusinessCountry}
                isDisplayed={isDisplayed}
                emptyFields={incomplete}
              />
              {termsText}
            </div>

            <div className="sidebar-content">
              <PaymentDetails
                selectAccount={() =>
                  this.setState({
                    accountSelectionVisible: !accountSelectionVisible
                  })
                }
                defaultBankName={hostBank}
                defaultAccountName={hostAccountName}
                defaultBsb={hostBsb}
                defaultAccountNumber={hostAccountNumber}
                selectedAccountType={selectedAccountType}
                userType={userType}
                editMode={editMode}
                handleInputChange={this.handleInputChange}
                accountType={accountType}
                emptyFields={incomplete}
                clearInputs={this.clearInputs}
              />
            </div>
          </div>
        </div>
        <div className="space-footer">
          <div className="section-wrapper">{bottomBarButton}</div>
        </div>
      </div>
    );
  }
}

HostProfile.propTypes = {
  sendSmsCode: PropTypes.func.isRequired,
  verifySmsCode: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  registerHost: PropTypes.func.isRequired,
  stopRedirect: PropTypes.func.isRequired,
  deactivateHost: PropTypes.func.isRequired,
  getBankDetails: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  sendSmsCode,
  verifySmsCode,
  updateUser,
  registerHost,
  stopRedirect,
  deactivateHost,
  getBankDetails: getBankDetailsAction
};

const mapStateToProps = state => ({
  currentUser: currentUser(state),
  registerLoader: registerOrEditHostLoading(state),
  redirectHost: redirectHost(state),
  redirectRemoveHost: redirectRemoveHost(state),
  removeLoader: getRemoveAccountLoading(state),
  registerFail: registerFail(state),
  registerFailError: registerFailError(state),
  bankDetails: bankDetails(state),
  loading: getLoading(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HostProfile);
