import React, { Component } from 'react';


export default class Picker extends Component {
    state = {
    }

    render() {
        const { placeholder, text, className, id, name, onClick, icon, openIcon, placeholderStyle, textStyle, iconStyle, notRelative, isInvalid } = this.props;

        return (
            <div className={`search-input-wrapper picker ${notRelative ? 'not-relative' : ''} `} onClick={onClick}>
                <div
                    id={id}
                    className={`search-input flex-center ${className} ${isInvalid ? 'invalid' : ''} `}
                    name={name}
                >
                    <span
                        className={`${text ? 'picker-text' : 'picker-placeholder'} ${placeholderStyle ? placeholderStyle : ''} ${textStyle ? textStyle : ''}`}
                    >
                        {text ? text : placeholder}
                    </span>
                    {openIcon &&
                        <img
                            className={`open-icon ${iconStyle ? iconStyle : ''}`}
                            src={require('../assets/images/ic_arrow_down.svg')}
                            alt=""
                        />}
                </div>
                {icon && <img
                    className="input-icon"
                    src={icon}
                    alt=""
                />}

            </div>
        )
    }
}