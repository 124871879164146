import mixpanel from 'mixpanel-browser';

let mixpanelToken = '';

switch (process.env.REACT_APP_STAGE) {
  case 'production':
    mixpanelToken = '3b50f9353a6dd54d22c930d0f02714d4';
    break;
  case 'staging':
    mixpanelToken = '2599ccd95d625654d56907e7858851ab';
    break;
  case 'develop':
    mixpanelToken = '2599ccd95d625654d56907e7858851ab';
    break;
  default:
    mixpanelToken = '2599ccd95d625654d56907e7858851ab';
    break;
}

mixpanel.init(mixpanelToken);

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  setPeople: (props) => {
    mixpanel.people.set(props);
  },
};

export let Mixpanel = actions;