import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { SpaceType } from '../../../settings';
import { Mixpanel } from '../../../util/MixPanel';

class BrowseCard extends Component {



    setMixpanel = () => {
        const { type } = this.props;

        Mixpanel.track("Explore Deski", { spaceType: type })
    }

    render() {
        const { type, icon } = this.props;
        let card = null;

        if (true) {
            card = (
                <NavLink to={`/search/${SpaceType[type].url}`} onClick={() => this.setMixpanel()}>
                    <img src={require(`../../../assets/images/${icon}.png`)} className="browse-img" alt="" />
                    <div className="browse-card-text">{SpaceType[type].name}</div>
                    <div className="browse-card-description">{SpaceType[type].description}</div>
                    <div className="browse-card-link">Explore {SpaceType[type].name}</div>
                </NavLink>
            )
        }
        return (
            <div className="card">
                {card}
            </div>
        )
    }
}

export default BrowseCard;