import React, { Component } from 'react';
import TextInput from '../../../components/TextInput';


class SpaceName extends Component {

  render() {
    const { handleChange, value } = this.props;
    const { isEmpty } = this.props;

    return (
      <div className="form-group">
        <div className="label">Name of space <span className="red">{isEmpty && '*'}</span></div>
        <TextInput
          id="spaceName"
          name="spaceName"
          type="text"
          onChange={(e) => handleChange(e)}
          value={value}
        />
      </div>
    )
  }
}

export default (SpaceName);
