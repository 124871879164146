import {
  CREATE_SPACE,
  CREATE_SPACE_SUCCESS,
  MODIFY_SPACE,
  MODIFY_SPACE_SUCCESS,
  DELETE_SPACE,
  DELETE_SPACE_SUCCESS,
  DELETE_SPACE_FAIL,
  REDIRECT_FALSE
} from '../actions/actionTypes';

import { Map } from 'immutable';

const initialState = Map({
  isLoading: false,
  isLoadingDelete: false,
  newSpace: {},
  redirect: false,
  error: {}
});

function createNewSpace(state, { payload }) {
  return state.set('isLoading', true);
}

function createNewSpaceSuccess(state, { payload }) {
  return state.set('newSpace', payload).set('isLoading', false).set('redirect', true);
}

function modifySpace(state, { payload }) {
  return state.set('isLoading', true);
}

function modifySpaceSuccess(state, { payload }) {
  return state.set('isLoading', false).set('redirect', true);
}

function deleteSpace(state, { payload }) {
  return state.set('isLoadingDelete', true);
}

function deleteSpaceSuccess(state, { payload }) {
  return state.set('isLoadingDelete', false).set('redirect', true);
}

function deleteSpaceFail(state, { payload }) {
  return state.set('error', payload).set('isLoadingDelete', false);
}

function redirectFalse(state, { payload }) {
  return state.set('redirect', false).set('isLoading', false);
}

const handlers = {
  [CREATE_SPACE]: createNewSpace,
  [CREATE_SPACE_SUCCESS]: createNewSpaceSuccess,
  [MODIFY_SPACE]: modifySpace,
  [MODIFY_SPACE_SUCCESS]: modifySpaceSuccess,
  [DELETE_SPACE]: deleteSpace,
  [DELETE_SPACE_SUCCESS]: deleteSpaceSuccess,
  [DELETE_SPACE_FAIL]: deleteSpaceFail,
  [REDIRECT_FALSE]: redirectFalse
};

export default function createNewSpaceReducer(state = initialState, action = {}) {
  const handler = handlers[action.type];

  if (!handler) {
    return state;
  }

  return handler(state, action);
}