import React, { Component } from "react";
import Modal from "react-modal";

import PrimaryButton from "../../../components/PrimaryButton";
import NumberInput from "../../../components/NumberInput";
import PropTypes from "prop-types";
import { sendSmsCode, savePhoneNumber } from "../actions/actions";
import { currentUser, getPhoneNumber } from "../reducers/selectors";
import connect from "react-redux/es/connect/connect";
import { Mixpanel } from "../../../util/MixPanel";

class ForgotPasswordModal extends Component {
  state = {
    IncorrectPhone: false
  };

  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  sendCode = async () => {
    const { phoneNumber, prefix } = this.state;

    if (!phoneNumber) {
      this.setState({ emptyPhone: !phoneNumber });
      return;
    }
    try {
      this.props.savePhoneNumber(prefix + phoneNumber);
      await this.props.sendSmsCode({
        phone: (prefix + phoneNumber).replace(/\+|-/g, ""),
        forgot: true
      });
      this.props.onSubmit();
    } catch (e) {
      this.setState({ IncorrectPhone: true });
    }

    let phone = prefix + phoneNumber;

    Mixpanel.identify(phone);
    Mixpanel.track("Forgot password", { phone });
  };

  getPrefix = prefix => {
    this.setState({ prefix });
  };

  render() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={true}
        className="forgot-password-modal"
        overlayClassName="overlay"
      >
        <img
          src={require("../../../assets/images/ic_close.svg")}
          className="close-button"
          alt=""
          onClick={this.props.onCancel}
        />
        <div className="modal-title">Forgot Password</div>
        <div className="flex-center direction-column forgot-password-content-wrapper">
          <div>
            <div className="subtitle modal-subtitle">
              {" "}
              Type your registered mobile number and we’ll send a password reset
              pin to you.
            </div>
            <div className="mobile-nr-wrapper">
              <span className="pass-pic-title">Enter your mobile number</span>
              <NumberInput
                isInvalid={this.state.IncorrectPhone}
                id="phoneNumber"
                name="phoneNumber"
                className="big-height medium-width"
                onFocus={() => this.setState({ IncorrectPhone: false })}
                onPrefixChange={() => this.setState({ IncorrectPhone: false })}
                onChange={e => {
                  this.handleInputChange(e);
                }}
                getPrefix={this.getPrefix}
              />
              {this.state.emptyPhone && (
                <span className="validation-message align-center">
                  Please enter your phone number.
                </span>
              )}
              {this.state.IncorrectPhone && (
                <span className="validation-message forgot">
                  The number you have entered is not recognised! Please ensure
                  you have entered a registered mobile number.
                </span>
              )}
            </div>
          </div>
          <PrimaryButton text="Submit" onClick={this.sendCode} />
        </div>
      </Modal>
    );
  }
}

ForgotPasswordModal.propTypes = {
  sendSmsCode: PropTypes.func.isRequired,
  savePhoneNumber: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  sendSmsCode,
  savePhoneNumber
};

const mapStateToProps = state => ({
  currentUser: currentUser(state),
  phoneNumber: getPhoneNumber(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordModal);
