import moment from 'moment';

export const saveUserToStorage = (userData) => {
  localStorage.setItem('Current_user', JSON.stringify(userData.user));
  if (userData.authToken)
    localStorage.setItem('Auth_token', userData.authToken);
};

export const saveConfigToStorage = (userData) => {
  localStorage.setItem('Config', JSON.stringify(userData));
};

export const deleteUserFromStorage = (userData) => {
  localStorage.removeItem('Current_user');
  localStorage.removeItem('Auth_token');
};

export const currentUserFromStorage = (userData) => {
  localStorage.getItem('Current_user');
};

export const getAuthTokenserFromStorage = (userData) => {
  localStorage.getItem('Auth_token');
};

export const goToPageBasedOnUser = ({ currentUser, history }) => {
  //Take him to ID pictures upload or AddMissingInfo based on its data
  const { email = '', phone = '' } = currentUser;
  if (!email.length || !phone.length) {
    return history.push("/add-missing-info");
  }
  if (window.location.pathname === ("/login/fromSpace")) {
    return history.go(-1);
  }
  if (window.location.pathname === ("/login/activateHost") || window.location.pathname === ("/profile/host")) {
    return history.push("/profile/host");
  }
  return history.push('/');
};

export const validateDate = (expMonth, expYear) => {
  if (expYear < moment().format('YY')) {
    return false;
  }
  else if (expYear === moment().format('YY')) {
    if (expMonth < moment().format('MM')) {
      return false;
    }
  }
  return true;
}

export const cardFormat = (e) => {
  let formatted = e.target.value.split("-").join("");
  if (formatted.length > 0) {
    formatted = formatted.match(new RegExp('.{1,4}', 'g')).join("-");
  }
  e.target.value = formatted.replace(
    /[^\d|^-``]*/g, '' // To allow only digits and `-`
  );
}

export const dateFormat = (e) => {
  const code = e.keyCode;
  const allowedKeys = [8];
  if (allowedKeys.indexOf(code) !== -1) {
    return;
  }

  e.target.value = e.target.value.replace(
    /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
  ).replace(
    /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
  ).replace(
    /^1([3-9])$/g, '01/$1' // 13 > 01/3
  ).replace(
    /^0\/|0+$/g, '0' // 0/ > 0 and 00 > 0
  ).replace(
    /[^\d|^/]*/g, '' // To allow only digits and `/`
  ).replace(
    /\/\//g, '/' // Prevent entering more than 1 `/`
  );

}

export const amountFormat = number => number
  .toString()
  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
