import React, { Component } from 'react';
import PrimaryButton from '../../../components/PrimaryButton'
import moment from 'moment';
import { Mixpanel } from '../../../util/MixPanel';

class BlogSection extends Component {

    addArticleEvent = (post) => {
        Mixpanel.track("Opened Blog Article", { post })
    }

    addSeeMoreEvent = () => {
        Mixpanel.track("Clicked See All Blog Posts Button")
    }

    render() {
        const { posts } = this.props;

        return (
            <div className="section-blog-list">
                <div className="section-header">Blog</div>
                <div className="blog-section-wrapper">
                    {posts.map((post) => (
                        <div className="blog-post-wrapper flex-center" key={post.ID} >
                            <img className="blog-img" src={post.featured_image} alt="" />
                            <div className="blog-content-wrapper">
                                <div className="blog-date">{moment(post.date).format('DD MMM YYYY')}</div>
                                <div className="blog-title">{post.title}</div>
                                <div className="blog-author">by {post.author.name}</div>
                                <div className="blog-content" onClick={() => this.addArticleEvent(post.title)}>
                                    <div dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>
                                </div>
                            </div>
                        </div>
                    )
                    )}
                </div>
                <div className="flex-center">
                    <a href="https://deski.blog/" target="_blank" rel="noopener noreferrer">
                        <PrimaryButton text="See all Articles" className="gray-bg" onClick={() => this.addSeeMoreEvent()} />
                    </a>
                </div>

            </div>
        )
    }
}

export default BlogSection;