import {
  CREATE_SPACE,
  CREATE_SPACE_SUCCESS,
  MODIFY_SPACE,
  MODIFY_SPACE_SUCCESS,
  DELETE_SPACE,
  DELETE_SPACE_SUCCESS,
  DELETE_SPACE_FAIL,
  REDIRECT_FALSE
} from './actionTypes';
import { Space } from '../../../api/endpoints';


export function createNewSpace(payload) {
  return {
    type: CREATE_SPACE,
    payload,
  };
}

export function createNewSpaceSuccess(payload) {
  return {
    type: CREATE_SPACE_SUCCESS,
    payload,
  };
}

export function modifySpace(payload) {
  return {
    type: MODIFY_SPACE,
    payload,
  };
}

export function modifySpaceSuccess(payload) {
  return {
    type: MODIFY_SPACE_SUCCESS,
    payload,
  };
}

export function deleteSpace(payload) {
  return {
    type: DELETE_SPACE,
    payload,
  };
}

export function deleteSpaceSuccess(payload) {
  return {
    type: DELETE_SPACE_SUCCESS,
    payload,
  };
}

export function deleteSpaceFail(payload) {
  return {
    type: DELETE_SPACE_FAIL,
    payload,
  };
}

export function redirectFalse(payload) {
  return {
    type: REDIRECT_FALSE,
    payload,
  };
}


export function addSpace(payload) {
  return async (dispatch) => {
    dispatch(createNewSpace(payload));

    const response = await Space.createSpace(payload);

    dispatch(createNewSpaceSuccess(response));
  };
}

export function stopRedirect(payload) {
  return (dispatch) => {
    dispatch(redirectFalse(payload));
  };
}

export function editIndividualSpace(payload) {
  return async (dispatch) => {
    dispatch(modifySpace(payload));

    const response = await Space.editSpace(payload);

    dispatch(modifySpaceSuccess(response));
  };
}

export function deleteIndividualSpace(payload) {
  return async (dispatch) => {
    dispatch(deleteSpace(payload));
    try {
      const response = await Space.deleteSpace(payload);
      dispatch(deleteSpaceSuccess(response));
    }
    catch (e) {
      dispatch(deleteSpaceFail(e));
    }
  };
}