import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import history from '../../../history';
import Loader from 'react-loader-spinner';
import MetaTags from 'react-meta-tags';
import { Helmet } from "react-helmet";

import { getVerifiedSpaces, getBlogPosts } from '../actions/actions';
import { getTopSpaces, getLoading, getBlogArticles } from '../reducers/selectors';

import SearchInput from '../../../components/SearchInput';
import SpaceCard from '../../../components/SpaceCard';
import PrimaryButton from '../../../components/PrimaryButton';

import { spaces } from '../../../settings';
import BrowseSection from '../components/BrowseSection';
import ListSpaceSection from '../components/ListSpaceSection';
import VerifiedSpaces from '../components/VerifiedSpaces';
import BlogSection from '../components/BlogSection';
import { Mixpanel } from '../../../util/MixPanel';

import { config } from '../../../configReduxSetup/reducers/selectors';

class HomePage extends Component {

    state = {
        isCardSelected: false,
        selectedOption: '',
        notComplete: false,
        location: '',
    }

    componentDidMount() {
        const { getVerifiedSpaces, getBlogPosts } = this.props;
        getVerifiedSpaces();
        getBlogPosts();
        Mixpanel.track("View Homepage")
    }

    selectCard = (position) => {
        this.setState({ selectedOption: position, notComplete: false })
        Mixpanel.track("Select space type", { searchTerm: position })
    }

    getLocation = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value })

        e.preventDefault();
        Mixpanel.track("Insert location", { location: value })
    }

    checkAndSubmit = () => {
        const { selectedOption, location } = this.state;

        let queryParams = {
            "location": location,
            "selectedOption": spaces.find(space => space.id === selectedOption).url
        };



        if (selectedOption && location) {
            let queryLink = `/search/${queryParams.selectedOption}/${queryParams.location.replace(/\W+(?!$)/g, '-').replace(/\W$/, '').toLowerCase()}`;
            history.push(queryLink);
        }
        else {
            this.setState({ notComplete: true })
        }
    }


    render() {
        const { notComplete, location, selectedOption } = this.state;
        const { isLoading, verifiedSpaces, articles } = this.props;

        let verified = null;

        if (Object.keys(verifiedSpaces).length && Object.keys(verifiedSpaces.spaces).length) {
            verified = <VerifiedSpaces spaces={verifiedSpaces} />;
        }

        if (isLoading) {
            return (
                <div className="page-template home-page">
                    <div className="loader-wrapper">
                        <Loader
                            type="Ball-Triangle"
                            height="100"
                            width="100"
                            color="#0EA800"
                        />
                    </div>
                </div>
            )
        }

        return (
            <div className="page-template home-page">
                <Helmet>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <MetaTags>
                    <title>Find Shared Office and Coworking Spaces | Deski</title>
                    <meta name="description" content="Deski makes it easy to book unique offices, hot desks, cooworking spaces, meeting room and creative spaces online." />
                </MetaTags>
                <div className="home-section-search">
                    <div className="section-wrapper">
                        <h1>Your workspace sharing marketplace to suit your every need.</h1>
                        <p>From desks to offices, we create environments for productivity, innovation, and connection.</p>
                        <div className="search-form">
                            <div className="cards-wrapper">
                                {spaces.map(space => (
                                    <SpaceCard
                                        key={space.id}
                                        isSelected={selectedOption === space.id}
                                        text={space.name}
                                        icon={space.icon ? require('../../../assets/images/' + space.icon) : ''}
                                        onClick={() => this.selectCard(space.id)}
                                        isInvalid={notComplete && !selectedOption}
                                    />
                                ))}
                            </div>
                            <div className="location-wrapper">
                                <SearchInput
                                    id="search-input"
                                    icon={require('../../../assets/images/ic_location.svg')}
                                    placeholder="Location"
                                    name="location"
                                    onBlur={this.getLocation}
                                    isInvalid={notComplete && !location}
                                />
                                <PrimaryButton text="Search" onClick={this.checkAndSubmit} />
                            </div>
                            {notComplete &&
                                <span className="validation-message align-center">Please fill in all information.</span>}
                        </div>
                    </div>
                </div>

                <div className="home-section-why">
                    <div className="section-wrapper">
                        <h2>We want to make the world a better place</h2>
                        <p>Deski aims to connect people all over the world with their perfect workspace. We help businesses, homes, boat owners and others utilise all their space and reduce cost wastage for both them and the renter.</p>
                    </div>
                </div>
                <div className="home-section-bottom">
                    <div className="section-wrapper">
                        <BrowseSection />
                        <ListSpaceSection />
                        {verified}
                        {Object.keys(articles).length && <BlogSection
                            posts={articles}
                        />}
                    </div>
                </div>
            </div >
        );
    }
}

HomePage.propTypes = {
    getVerifiedSpaces: PropTypes.func.isRequired,
    getBlogPosts: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    getVerifiedSpaces,
    getBlogPosts
};

const mapStateToProps = (state) => ({
    verifiedSpaces: getTopSpaces(state),
    isLoading: getLoading(state),
    articles: getBlogArticles(state),
    config: config(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
