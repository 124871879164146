import { SET_POSTER_SPACES, SET_SPACES } from './actionTypes';

import { Search } from '../../../api/endpoints';


export function setSpaces(payload) {
  return {
    type: SET_SPACES,
    payload,
  };
}

export function setPosterSpaces(payload) {
  return {
    type: SET_POSTER_SPACES,
    payload,
  };
}

export function getSpacesByPoster(payload) {
  return async (dispatch) => {
    dispatch(setSpaces(payload))

    const response = await Search.getPosterSpaces(payload);

    dispatch(setPosterSpaces(response));
  };
}
