import React, { Component } from 'react';
import DeleteIcon from './DeleteIcon';

import config from '../../../config/config';

class PictureComponent extends Component {
    renderUploadedPicture = () => {
        const { preview, readURL, removePhoto } = this.props;
        let deleteIcon = null;

        if (preview) {
            deleteIcon = <DeleteIcon removePhoto={removePhoto} />
        }

        return (
            <div className="col img-uploader-wrapper create-space">
                <img src={preview ? `${config.api}/files/${preview.photoUrl}` : preview} className="photo-preview" alt="" />
                <input
                    id="front"
                    type="file"
                    accept="image/*"
                    onChange={readURL}
                    className="photo-uploader"
                    name="frontPictureBase64"
                    disabled={preview}
                />
                {deleteIcon}
            </div>
        )
    }

    renderPicture = () => {
        const { preview, readURL, removePhoto } = this.props;
        let deleteIcon = null;

        if (preview) {
            deleteIcon = <DeleteIcon removePhoto={removePhoto} />
        }

        return (
            <div className="col img-uploader-wrapper create-space">
                <img src={preview || require('../../../assets/images/add_photo.svg')} className="photo-preview" alt="" />
                <input
                    id="front"
                    type="file"
                    accept="image/*"
                    onChange={readURL}
                    className="photo-uploader"
                    name="frontPictureBase64"
                    disabled={preview}
                />
                {deleteIcon}
            </div>
        )

    }

    render() {
        const { isUploaded } = this.props;
        let content = "";
        
        if (isUploaded) {

            content = this.renderUploadedPicture();
        } else {
            
            content = this.renderPicture();
        }
        
        return (
            <div> {content} </div>
        )
    }
}

export default PictureComponent;
