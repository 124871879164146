import { SET_SEARCH_RESULTS, SET_INDIVIDUAL_SPACE, FETCH_INDIVIDUAL_SPACE, SET_BOOKINGS_PER_SPACE, SET_INDIVIDUAL_SPACE_FAIL } from '../actions/actionTypes';

import { Map } from 'immutable';
import moment from 'moment-timezone';
import { range, sortBy } from 'lodash';

const initialState = Map({
  searchResults: {},
  individualSpace: {},
  bookings: [],
  bookedDates: new Map(),
  isLoading: false,
  individualSpaceError: null
});

function setSearchResults(state, { payload }) {
  return state.set('searchResults', payload.spaces);
}

function fetchIndividualSpace(state, { payload }) {
  return state.set('isLoading', true);
}

function setIndividualSpace(state, { payload }) {
  return state.set('individualSpace', payload).set('isLoading', false);
}

function setIndividualSpaceFail(state, { payload }) {
  return state.set('individualSpaceError', JSON.parse(payload.message).error).set('isLoading', false);
}

function fetchSpaceBookings(state, { payload }) {
  let bookings = payload.bookings;
  let bookedDates = new Map();

  for (let i = 0; i < bookings.length; i++) {

    let booking = bookings[i];
    let sDate = moment(booking.startDate).tz(booking.timeZone);
    let eDate = moment(booking.endDate).tz(booking.timeZone);

    for (var date = sDate; date.diff(eDate, 'minutes') <= 0; date.add(1, 'days')) {
      let dateFormatted = date.format('YYYY-MM-DD');

      if (bookedDates.has(dateFormatted)) {
        let currHoursArray = bookedDates.get(dateFormatted);
        let newHoursArray = range(booking.startTime, booking.endTime, 1);

        currHoursArray = currHoursArray.concat(newHoursArray);
        currHoursArray = sortBy(currHoursArray);

        bookedDates = bookedDates.set(dateFormatted, currHoursArray);
      } else {

        let newHoursArray = range(booking.startTime, booking.endTime, 1);

        bookedDates = bookedDates.set(dateFormatted, newHoursArray);
      }
    }
  }

  return state.set('bookings', payload).set("bookedDates", bookedDates);
}

const handlers = {
  [SET_SEARCH_RESULTS]: setSearchResults,
  [SET_INDIVIDUAL_SPACE]: setIndividualSpace,
  [SET_INDIVIDUAL_SPACE_FAIL]: setIndividualSpaceFail,
  [FETCH_INDIVIDUAL_SPACE]: fetchIndividualSpace,
  [SET_BOOKINGS_PER_SPACE]: fetchSpaceBookings,
};

export default function createIndividualSpaceReducer(state = initialState, action = {}) {
  const handler = handlers[action.type];

  if (!handler) {
    return state;
  }

  return handler(state, action);
}