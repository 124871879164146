import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Geocode from "react-geocode";
import Loader from "react-loader-spinner";

import { remove } from "lodash";

import SearchInput from "../../../components/SearchInput";
import SearchInputAddress from "../../../components/SearchInputAddress";
import PrimaryButton from "../../../components/PrimaryButton";

import {
  addSpace,
  stopRedirect,
  editIndividualSpace,
  deleteIndividualSpace
} from "../actions/actions";
import {
  getCreateSpaceLoading,
  newSpace,
  redirectHost,
  deleteSpaceLoading,
  deleteError
} from "../reducers/selectors";
import { getSpaceById } from "../../individualSpace/actions/actions";
import {
  getIndividualSpace,
  getIsLoading
} from "../../individualSpace/reducers/selectors";

import SpaceTypes from "../components/SpaceTypes";
import SpaceName from "../components/SpaceName";
import SpaceDescription from "../components/SpaceDescription";
import SpaceRates from "../components/SpaceRates";
import BookingHours from "../components/BookingHours";
import SpaceAmenities from "../components/SpaceAmenities";
import NoOfPax from "../components/NoOfPax";
import SpacePictures from "../components/SpacePictures";
import { MapsApiKey } from "../../../settings";
import history from "../../../history";
import DeleteSpaceModal from "../components/DeleteSpaceModal";

class CreateSpaceScreen extends Component {
  state = {
    spaceSelectedOption: "",
    amenitySelectedOption: {},
    pictures: [],
    uploadedPictures: [],
    removedPictures: [],
    spaceDescription: "",
    spaceName: "",
    address: "",
    nrOfPax: 1,
    days: {},
    perHour: "",
    perDay: "",
    perWeek: "",
    perMonth: "",
    deleteCallDone: false,
    incomplete: []
  };

  componentDidMount() {
    const { spaceID } = this.props.match.params;

    if (spaceID) this.getSpaceById(spaceID);
    document.title = "Deski";
  }

  componentDidUpdate(prevProps) {
    const { isLoading, individualSpace } = this.props;
    const { space } = individualSpace;

    if (prevProps.isLoading !== isLoading && isLoading === false) {
      this.setState({
        spaceSelectedOption: space.type,
        amenitySelectedOption: space.amenities ? space.amenities : {},
        pictures: [],
        uploadedPictures: individualSpace.space.photosUrls,
        removedPictures: [],
        spaceDescription: space.description,
        spaceName: space.name,
        location: space.location,
        address: space.address,
        nrOfPax: space.nrOfPax ? space.nrOfPax : 1,
        days: space.bookingHours ? space.bookingHours.days : {},
        perHour: space.ratePerHour,
        perDay: space.ratePerDay,
        perWeek: space.ratePerWeek,
        perMonth: space.ratePerMonth,
        amenityTypeSelectedOption: space.amenitiesType
          ? space.amenitiesType
          : {}
      });
    }
  }

  getSpaceById = spaceID => {
    const { getSpaceById } = this.props;
    getSpaceById(spaceID);
  };

  setType = spaceSelectedOption => {
    if (spaceSelectedOption === "desk") {
      this.setState({ nrOfPax: 1 });
    }
    this.setState({ spaceSelectedOption });
  };

  setAmenities = amenitySelectedOption => {
    this.setState({ amenitySelectedOption });
  };

  setAmenitiesType = amenityTypeSelectedOption => {
    this.setState({ amenityTypeSelectedOption });
  };

  setPictures = pictures => {
    this.setState({ pictures });
  };

  removeUploadedPicture = picture => {
    const { uploadedPictures, removedPictures } = this.state;

    let newUploadedPictures = JSON.parse(JSON.stringify(uploadedPictures));
    let newRemovedPictures = JSON.parse(JSON.stringify(removedPictures));

    let removedPicture = remove(newUploadedPictures, function(uploadedPicture) {
      return picture._id === uploadedPicture._id;
    });

    if (removedPicture.length) {
      newRemovedPictures.push(picture._id);

      this.setState({
        uploadedPictures: newUploadedPictures,
        removedPictures: newRemovedPictures
      });
    }
  };

  setHoursSelected = days => {
    this.setState({ days });
  };

  handleInputChange = e => {
    const { name, value } = e.target;

    if (
      name === "nrOfPax" ||
      name === "perHour" ||
      name === "perDay" ||
      name === "perWeek" ||
      name === "perMonth"
    ) {
      this.setState({ [name]: +value.replace(/[\D+$ ]/g, "") });
    } else this.setState({ [name]: value });
  };

  setLocation = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    e.preventDefault();
  };

  callGeocoder = () => {
    const { address, location } = this.state;
    let fullAddress = `${address} ${location}`;

    Geocode.setApiKey(`${MapsApiKey}&sensor=true`);
    Geocode.fromAddress(fullAddress).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        this.createSpace(lat, lng);
      },
      error => {
        console.error(error);
      }
    );
  };

  createSpace = (lat, long) => {
    const {
      spaceSelectedOption,
      amenitySelectedOption,
      amenityTypeSelectedOption,
      spaceName,
      spaceDescription,
      address,
      nrOfPax,
      pictures,
      uploadedPictures,
      removedPictures,
      days
    } = this.state;
    const { perHour, perDay, perWeek, perMonth, location } = this.state;
    const { addSpace, editIndividualSpace } = this.props;
    const { spaceID } = this.props.match.params;

    const body = {
      type: spaceSelectedOption,
      name: spaceName,
      description: spaceDescription,
      address,
      nrOfPax,
      photosBase64: pictures,
      location,
      ratePerHour: perHour,
      ratePerDay: perDay,
      ratePerWeek: perWeek,
      ratePerMonth: perMonth,
      bookingHours: { days },
      amenities: amenitySelectedOption,
      amenitiesType: amenityTypeSelectedOption,
      removedPhotos: removedPictures,
      lat,
      long
    };

    const editBody = {
      type: spaceSelectedOption,
      name: spaceName,
      description: spaceDescription,
      address,
      nrOfPax,
      location,
      photosBase64: pictures,
      ratePerHour: perHour === 0 ? null : perHour,
      ratePerDay: perDay === 0 ? null : perDay,
      ratePerWeek: perWeek === 0 ? null : perWeek,
      ratePerMonth: perMonth === 0 ? null : perMonth,
      bookingHours: { days },
      amenities: amenitySelectedOption,
      amenitiesType: amenityTypeSelectedOption,
      removedPhotos: removedPictures,
      lat,
      long
    };

    let picturesNo = pictures.length + uploadedPictures.length;
    let fieldsArray = {
      spaceSelectedOption,
      spaceName,
      spaceDescription,
      address,
      nrOfPax,
      location,
      perHour,
      perDay,
      perWeek,
      perMonth
    };

    let incompleteArray = [];
    let rateCount = 0;

    Object.keys(fieldsArray).forEach(field => {
      if (!fieldsArray[field]) {
        if (
          field === "perHour" ||
          field === "perDay" ||
          field === "perWeek" ||
          field === "perMonth"
        ) {
          rateCount++;
        } else incompleteArray.push(field);
        if (rateCount === 4) {
          incompleteArray.push("rate");
        }
        this.setState({ notComplete: true, incomplete: incompleteArray });
      }
    });

    if (incompleteArray.length) {
      this.setState({ notComplete: true });
    } else if (picturesNo < 3) {
      this.setState({ notCompletePictures: true });
    } else {
      if (spaceID) {
        editIndividualSpace({
          putData: editBody,
          spaceId: spaceID
        });
      } else addSpace(body);
      this.setState({ notComplete: false, notCompletePictures: false });
    }
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      editModalOpen: false,
      addCardModal: false,
      deleteModalOpen: false
    });
  };

  removeSpace = () => {
    const { deleteIndividualSpace } = this.props;
    const { spaceID } = this.props.match.params;
    deleteIndividualSpace(spaceID);

    this.setState({ deleteModalOpen: false, deleteCallDone: true });
  };

  render() {
    const {
      spaceSelectedOption,
      spaceDescription,
      notComplete,
      notCompletePictures,
      spaceName,
      location,
      address,
      nrOfPax,
      uploadedPictures
    } = this.state;
    const {
      perHour,
      perDay,
      perWeek,
      perMonth,
      pictures,
      amenitySelectedOption,
      days,
      amenityTypeSelectedOption,
      deleteModalOpen,
      incomplete
    } = this.state;
    const { deleteCallDone } = this.state;
    const {
      createLoading,
      redirect,
      stopRedirect,
      newSpace,
      deleteLoading,
      deleteError
    } = this.props;
    const { spaceID } = this.props.match.params;

    if (createLoading || deleteLoading) {
      return (
        <div className="page-template new-space-page">
          <div className="loader-wrapper">
            <Loader
              type="Ball-Triangle"
              height="100"
              width="100"
              color="#0EA800"
            />
          </div>
        </div>
      );
    }

    if (redirect) {
      stopRedirect();
      if (deleteCallDone) {
        history.push(`/my-deski?type=viewSpace&page=1`);
      } else if (spaceID) {
        history.push(`/space/${spaceID}`);
      } else {
        history.push(`/space/${newSpace._id}`);
      }
    }

    let errorMessage = null;

    if (Object.keys(deleteError).length) {
      let error = JSON.parse(deleteError.message);
      if (error.error.code === "CannotDelete" && deleteCallDone) {
        errorMessage = (
          <div className="validation-message">{error.error.msg}</div>
        );
      }
    }

    let isGroupMeeting = false;
    if (spaceSelectedOption === "groupMeeting") {
      isGroupMeeting = true;
    }

    let title = "Create New Space";
    let buttonText = "Create Space";
    let deleteButton = null;

    if (spaceID) {
      title = "Edit Space";
      buttonText = "Save Changes";
      deleteButton = (
        <PrimaryButton
          text="Delete Space"
          className="footer-button booking red-bg report-btn"
          icon={"ic_delete.svg"}
          onClick={() => this.setState({ deleteModalOpen: true })}
        />
      );
    }

    return (
      <div className="page-template new-space-page">
        <DeleteSpaceModal
          deleteModalOpen={deleteModalOpen}
          closeModal={this.closeModal}
          removeSpace={this.removeSpace}
        />
        <div className="page-wrapper">
          <h1 className="page-title">
            {title}
            {errorMessage}
          </h1>
          <div className="new-space-form">
            <div className="col">
              <SpaceTypes
                setType={this.setType}
                value={spaceSelectedOption}
                isEmpty={incomplete.includes("spaceSelectedOption")}
              />
              <SpaceName
                handleChange={this.handleInputChange}
                value={spaceName}
                isEmpty={incomplete.includes("spaceName")}
              />
              <SpaceDescription
                handleChange={this.handleInputChange}
                nrOfChars={spaceDescription.length}
                value={spaceDescription}
                isEmpty={incomplete.includes("spaceDescription")}
              />
              <div className="form-group">
                <div className="label">
                  Location{" "}
                  <span className="red">
                    {incomplete.includes("location") && "*"}
                  </span>
                </div>
                <SearchInput
                  id="search-input"
                  icon={require("../../../assets/images/ic_location.svg")}
                  placeholder="Add Location"
                  name="location"
                  onBlur={this.setLocation}
                  value={location}
                />
              </div>
              <div className="form-group">
                <div className="label">
                  Full Address{" "}
                  <span className="red">
                    {incomplete.includes("address") && "*"}
                  </span>
                </div>
                <SearchInputAddress
                  id="search-input"
                  icon={require("../../../assets/images/ic_address.svg")}
                  name="address"
                  onBlur={this.setLocation}
                  value={address}
                />
              </div>
              <NoOfPax
                handleChange={this.handleInputChange}
                isDesk={spaceSelectedOption === "desk"}
                value={nrOfPax}
                isEmpty={incomplete.includes("nrOfPax")}
              />
              <SpaceRates
                handleChange={this.handleInputChange}
                perHour={perHour}
                perDay={perDay}
                perWeek={perWeek}
                perMonth={perMonth}
                isEmpty={incomplete.includes("rate")}
              />
            </div>
            <div className="col">
              <SpacePictures
                setPictures={this.setPictures}
                removeUploadedPicture={this.removeUploadedPicture}
                value={pictures}
                uploadedPictures={uploadedPictures}
              />
              <SpaceAmenities
                isGroupMeeting={isGroupMeeting}
                setAmenities={this.setAmenities}
                setAmenitiesType={this.setAmenitiesType}
                value={amenitySelectedOption}
                valueTypes={amenityTypeSelectedOption}
              />
              <BookingHours
                setHoursSelected={this.setHoursSelected}
                value={days}
              />
            </div>
          </div>
        </div>
        <div className="space-footer save-changes">
          <div className="section-wrapper flex-center direction-column">
            <div>
              {notComplete && (
                <div className="validation-message align-center">
                  Please fill in all information.
                </div>
              )}
              {notCompletePictures && (
                <div className="validation-message align-center">
                  You must have at least 3 pictures. Please add more pictures.
                </div>
              )}
            </div>
            <PrimaryButton
              text={buttonText}
              onClick={() => this.callGeocoder()}
            />
            {deleteButton}
          </div>
        </div>
      </div>
    );
  }
}

CreateSpaceScreen.propTypes = {
  addSpace: PropTypes.func.isRequired,
  stopRedirect: PropTypes.func.isRequired,
  getSpaceById: PropTypes.func.isRequired,
  editIndividualSpace: PropTypes.func.isRequired,
  deleteIndividualSpace: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  addSpace,
  stopRedirect,
  getSpaceById,
  editIndividualSpace,
  deleteIndividualSpace
};

const mapStateToProps = state => ({
  createLoading: getCreateSpaceLoading(state),
  newSpace: newSpace(state),
  redirect: redirectHost(state),
  individualSpace: getIndividualSpace(state),
  isLoading: getIsLoading(state),
  deleteLoading: deleteSpaceLoading(state),
  deleteError: deleteError(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSpaceScreen);
