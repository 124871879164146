import React from 'react';
import {
  Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { Map } from 'immutable';
import './App.scss';
import './fonts.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';

import configureStore from './store/configureStore';

import NavBar from './components/NavBar'
import Footer from './components/Footer'
import LoginContainer from './modules/auth/screens/LoginScreen';
import SignupScreen from './modules/auth/screens/SignupScreen';
// import UploadPhotosScreen from './modules/auth/screens/UploadPhotosScreen';
import AddMissingInfoScreen from './modules/auth/screens/AddMissingInfoScreen';
import PrivacyPolicy from './modules/auth/containers/PrivacyPolicy';
import TermsAndConditions from './modules/auth/containers/TermsAndConditions';
import SearchScreen from './modules/search/screens/SearchScreen';
import ResultsScreen from './modules/search/screens/ResultsScreen';
import IndividualSpaceScreen from './modules/individualSpace/screens/IndividualSpaceScreen';
import Bookings from './modules/bookings/screens/BookingsScreen';
import Booking from './modules/bookings/screens/BookingDetailsScreen';
import Profile from './modules/profile/screens/ProfileScreen';
import HostProfile from './modules/profile/screens/HostProfile';
import PosterSpaces from './modules/posterSpaces/screens/PosterSpacesScreen';
import CreateSpace from './modules/spacesManagement/screens/CreateSpaceScreen';
import MyDeski from './modules/myDeski/screens/MyDeski';
import IndividualSpaceScreenPoster from './modules/individualSpacePoster/screens/IndividualSpaceScreenPoster';
import history from './history';
import HomePage from './modules/home/screens/HomePage';
import GlobalLocations from './modules/company/GlobalLocations';
import Careers from './modules/company/Careers';
import FAQ from './modules/company/FAQ';
import Mission from './modules/company/Mission';

const appStore = configureStore(Map({}));

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={
      props => {
        if (localStorage.Current_user) {
          let currUser = JSON.parse(localStorage.Current_user);

          if (!currUser.email || !currUser.phone) {

            return (<Redirect to="/add-missing-info" />);
          } else {

            return (<Component {...props} />);
          }

        } else {

          return (<Redirect to="/login" />);
        }
      }
    }
  />
);

const App = () => (
  <Provider store={appStore}>
    <div className="main-wrapper" >
      <Router history={history}>
        <Route exact component={NavBar} />
      </Router>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/search" component={SearchScreen} />
          <PrivateRoute exact path="/my-deski" component={MyDeski} />
          {/* <PrivateRoute exact path="/space/add" component={CreateSpace} /> */}
          <PrivateRoute exact path="/space/add/:spaceID?" component={CreateSpace} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/profile/host" component={HostProfile} />
          <PrivateRoute exact path="/bookings" component={Bookings} />
          <PrivateRoute exact path="/booking/:bookingId" component={Booking} />
          <PrivateRoute exact path="/booking/host/:bookingId" component={Booking} />
          <PrivateRoute exact path="/space/:spaceID" component={IndividualSpaceScreenPoster} />
          <Route path="/search/:type/:location?/:startDate?/:endDate?/:startTime?/:endTime?/:nrOfGuests?/:interval?" component={ResultsScreen} />
          {/* <Route exact path="/search/:type/:location" component={ResultsScreen} /> */}
          {/* <Route exact path="/search/:type" component={ResultsScreen} /> */}
          {/* <Route exact path="/spaces/:spaceID/:spaceName/:location/:address" component={IndividualSpaceScreen} /> */}
          <Route path="/spaces/:spaceID/:spaceName/:location/:address/:startDate?/:endDate?/:startTime?/:endTime?/:nrOfGuests?/:interval?" component={IndividualSpaceScreen} />
          <Route exact path="/poster/:posterID" component={PosterSpaces} />
          <Route path="/signup" component={SignupScreen} />
          {/* <PrivateRoute path="/upload-photos" component={UploadPhotosScreen} /> */}
          <Route path="/add-missing-info" component={AddMissingInfoScreen} />
          <Route path="/login" component={LoginContainer} />
          <Route path="/login/fromSpace" component={LoginContainer} />
          <Route path="/login/activateHost" component={LoginContainer} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-of-use" component={TermsAndConditions} />
          <Route path="/global" component={GlobalLocations} />
          <Route path="/careers" component={Careers} />
          <Route path="/faq" component={FAQ} />
          <Route path="/mission" component={Mission} />
        </Switch>
      </Router>
    </div>
    <Router history={history}>
      <Route exact component={Footer} />
    </Router>
  </Provider>
);

export default App;
