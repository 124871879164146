import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

import Picker from '../../../components/Picker';
import TimePicker from '../../../components/TimePicker';

import isInclusivelyAfterDay from '../../../util/isInclusivelyAfterDay';
import isBeforeDay from '../../../util/isBeforeDay';

class HourlyPickerWrapper extends Component {

    constructor(props) {
        super(props);

        this.state = ({
            isCalendarVisible: false,
            isTimeTableVisible: false,
            isSDPFocused: false,
        });
    }

    focusChange = (focusedInput) => {
        if (focusedInput.focused) {
            this.setState({ isSDPFocused: focusedInput.focused });
        } else {

            this.setState({ isCalendarVisible: false, isSDPFocused: focusedInput.focused });
        }
    }

    renderDoneButton = () => (
        <div>
            <div
                onClick={() => this.setState({
                    isCalendarVisible: false,
                    isSDPFocused: true
                })}>
                <img src={require('../../../assets/images/ic_close.svg')} className="close-button  x-calendar-button" alt="" />
            </div>
            <div className="calendar-done-button flex-center"
                onClick={() =>
                    this.setState({ isCalendarVisible: false })
                }>
                Done
            </div>
        </div>

    )

    onDateChange = (date) => {
        const { changeDates } = this.props;

        changeDates(date, date);
    }

    onHoursChange = (startHour, endHour) => {
        const { changeHours } = this.props;

        changeHours(startHour, endHour);
    }

    getTimePickerText = () => {
        const { startHour, endHour } = this.props;

        let text = "";

        if (startHour) {
            text += moment(startHour, ["h"]).format('h:mm A');

            if (endHour) {
                text += " - " + moment(endHour, ["h"]).format('h:mm A');
            }
            else {
                text += " - " + moment(startHour + 1, ["h"]).format('h:mm A');
            }
        }

        return text;
    }

    render() {
        const { isCalendarVisible, isTimeTableVisible, isSDPFocused } = this.state;
        const { startDate, startHour, endHour, notComplete } = this.props;

        let date = ''
        if (startDate) {
            date = `${moment(startDate).format('ll')}`;
        }

        return (
            <div className={`calendar-wrapper ${isCalendarVisible && 'calendar-wrapper-opened'}`}>
                {isCalendarVisible ?
                    <SingleDatePicker
                        id="startDate"
                        date={startDate}
                        onDateChange={(date) => this.onDateChange(date)}
                        focused={isSDPFocused}
                        onFocusChange={(focusedInput) => this.focusChange(focusedInput)}
                        verticalHeight={280}
                        showClearDate
                        numberOfMonths={1}
                        keepOpenOnDateSelect
                        customCloseIcon={<span className="clear-calendar">Clear</span>}
                        reopenPickerOnClearDate
                        daySize={40}
                        firstDayOfWeek={1}
                        calendarInfoPosition="bottom"
                        renderCalendarInfo={this.renderDoneButton}
                        displayFormat="D MMM YYYY"
                        // isDayBlocked={this.isDayBlocked}
                        customInputIcon={<span className="from-to-text border-color-green">From: </span>}
                        isOutsideRange={(day) => isBeforeDay(day, moment()) || isInclusivelyAfterDay(day, moment().add(365, 'days'))}
                        readOnly={true}
                    />
                    :
                    <Picker
                        isInvalid={notComplete && !startDate}
                        placeholder="Booking Date"
                        text={date}
                        icon={require('../../../assets/images/ic_calendar.svg')}
                        openIcon
                        onClick={() => this.setState({ isCalendarVisible: true, isSDPFocused: true })}
                    />
                }

                {isTimeTableVisible ?
                    <TimePicker
                        onClose={() => this.setState({ isTimeTableVisible: false })}
                        onDone={() => this.setState({ isTimeTableVisible: false })}
                        getHours={this.onHoursChange}
                        startHour={startHour}
                        endHour={endHour}
                        startDate={startDate}
                    />
                    :
                    <div className="picker-margin">
                        <Picker
                            isInvalid={notComplete && !startHour && !endHour}
                            placeholder="Booking Start & End Time"
                            text={this.getTimePickerText()}
                            icon={require('../../../assets/images/ic_time.svg')}
                            openIcon

                            onClick={() => this.setState({ isTimeTableVisible: true })}
                        />
                    </div>
                }
            </div>
        );
    }
}

HourlyPickerWrapper.propTypes = {
};

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(HourlyPickerWrapper);