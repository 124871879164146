import React, { Component } from 'react';

class UserTypeSwitch extends Component {


    render() {
        const { setPersonal, editMode, setBusiness, userType } = this.props;
        return (
            <div className="field-wrapper">
                <div className="field-name">User Type</div>
                <div className="user-type-wrapper">
                    <label>
                        <input type="radio" name="user-type" onChange={setPersonal} checked={userType === 'individual' || userType === 'personal'} disabled={!editMode} />
                        <span className="radiobtn"></span>
                        individual
              </label>
                    <label>
                        <input type="radio" name="user-type" onChange={setBusiness} checked={userType === 'company' || userType === 'business'} disabled={!editMode} />
                        <span className="radiobtn"></span>
                        company
              </label>
                </div>
            </div>
        )
    }
}

export default UserTypeSwitch;
