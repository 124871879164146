import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import moment from "moment";
import Loader from "react-loader-spinner";
import { padStart, get } from "lodash-es";

import { searchSpaces, createBooking } from "../actions/actions";
import {
  getSearchResults,
  getBookingResponse,
  getIndividualSpace
} from "../reducers/selectors";
import { currentUser } from "../../../modules/auth/reducers/selectors";
import { cardDetails } from "../../profile/reducers/selectors";
import AddCard from "../../profile/components/AddCard";
import ViewCard from "../../profile/components/ViewCard";

import PrimaryButton from "../../../components/PrimaryButton";
import Input from "../../../components/Input";

import { DateType } from "../../../settings";

class PaymentModal extends Component {
  state = {
    notComplete: false,
    modalHeight: 653 + "px",
    nrOfChars: 0,
    isProceedDisabled: false,
    addCardModal: false,
    editCardModal: false
  };

  handleInputChange = e => {
    let { name, value } = e.target;

    this.setState({ [name]: value });
  };

  editCard = () => {
    this.setState({ editCardModal: true });
  };

  addCard = () => {
    this.setState({ addCardModal: true });
  };

  openEditCardModal = type => {
    const { editCardModal, addCardModal } = this.state;

    if (type === "edit") {
      return (
        <ViewCard
          modalIsOpen={type === "edit" ? editCardModal : addCardModal}
          closeModal={this.closeModal}
          name="Edit Payment Details"
        />
      );
    } else
      return (
        <AddCard
          modalIsOpen={type === "edit" ? editCardModal : addCardModal}
          closeModal={this.closeModal}
          name="Add Payment Details"
        />
      );
  };

  closeModal = () => {
    this.setState({ addCardModal: false, editCardModal: false });
  };

  /* getStartHour & getEndHour
    There are 2 cases:
    1. If the start date and end date are the same we should use the startHour and endHour selected by user
    2. If the start date and end date are not the same we should use the start time from start date and end time from end date.
  */
  getStartHour = () => {
    const { startHour, startDate, dateType, individualSpace } = this.props;

    let currStartHour =
      individualSpace.space.bookingHours.days[startDate.isoWeekday()].startTime;

    if (dateType === DateType.HOURLY) {
      currStartHour = startHour;
    }

    return currStartHour;
  };

  getEndHour = () => {
    const {
      endHour,
      startHour,
      endDate,
      dateType,
      individualSpace
    } = this.props;

    let currEndHour = get(
      individualSpace.space.bookingHours.days[endDate.isoWeekday()],
      "endTime",
      0
    );

    if (dateType === DateType.HOURLY) {
      currEndHour = endHour ? endHour : startHour + 1;
    }

    return currEndHour;
  };

  createBookingPayload = () => {
    const { startDate, endDate, guestsNumber, spaceID, dateType } = this.props;
    const { note } = this.state;

    let payload = {
      spaceId: spaceID,
      startDate: moment(startDate).format("X"),
      endDate: moment(endDate).format("X"),
      startTime: this.getStartHour(),
      endTime: this.getEndHour(),
      nrOfGuests: guestsNumber,
      notesFromGuest: note,
      bookingInterval: dateType
    };

    return payload;
  };

  bookSpace = async () => {
    try {
      let bookingPayload = this.createBookingPayload();

      console.log("payload", bookingPayload);

      await this.props.createBooking(bookingPayload);

      this.props.onDone("successful");
    } catch (e) {
      console.log(e);
      let error = JSON.parse(e.message);
      if (error.error.code === "CannotBookOwnSpace")
        this.setState({ [error.error.code]: "You can't book your own space." });
      else this.props.onDone("unsuccessful", error);
    }
  };

  checkAndSubmit = async () => {
    const { currentUser } = this.props;

    if (Object.keys(currentUser).length) {
      if (!!currentUser.saveCreditCard) {
        this.setState({ notComplete: false, isProceedDisabled: true });
        this.bookSpace();
      } else {
        this.setState({ notComplete: true });
      }
    }
  };

  openEditModal = () => {
    this.props.onEdit();
  };

  renderDetails = () => {
    const { currentUser, cardDetails } = this.props;

    const cardName = get(cardDetails, "paymentMethod.name", "-");
    const cardNumber = `XXXX-XXXX-XXXX-${get(
      cardDetails,
      "paymentMethod.last4",
      "XXXX"
    )}`;
    const cardExpMonth = padStart(
      get(cardDetails, "paymentMethod.exp_month", "XX"),
      2,
      "0"
    );
    const cardExpYear = String(
      get(cardDetails, "paymentMethod.exp_year", "XXXX")
    ).slice(2);
    const cardExp = `${cardExpMonth} / ${cardExpYear}`;

    if (!Object.keys(currentUser) && !currentUser.saveCreditCard) return;
    return (
      <div style={{ paddingTop: "10px" }}>
        <div className="justify-between card-name">
          <div>
            <div className="card-info">Name</div>
            <div className="credit-card">{cardName}</div>
          </div>
          <div>
            <img src={require("../../../assets/images/ic_edit.svg")} alt="" />
            <span className="edit" onClick={this.editCard}>
              Edit Card
            </span>
          </div>
        </div>

        <div>
          <div className="card-info">Card Number</div>
          <div className="card-data payment">{cardNumber}</div>
          <div className="card-info">Expiration Date</div>
          <div className="card-data payment">{cardExp}</div>
        </div>
      </div>
    );
  };

  render() {
    const {
      notComplete,
      nrOfChars,
      isProceedDisabled,
      CannotBookOwnSpace,
      cardError,
      note,
      addCardModal,
      editCardModal
    } = this.state;
    const { currentUser, modalIsOpen, closeModal, name } = this.props;

    const customStyles = {
      content: {
        height: this.state.modalHeight
      }
    };

    let notesArea = (
      <div className="note-wrapper payment-input-wrapper">
        <div className="note-header">
          <div>ADD NOTE TO SPACE OWNER</div>
          <div className="nr-of-chars">{nrOfChars}/500 characters</div>
        </div>
        <textarea
          name="note"
          className="textarea"
          maxLength="500"
          spellCheck="false"
          value={note}
          onChange={e => {
            this.handleInputChange(e);
            this.setState({ nrOfChars: e.target.value.length });
          }}
        ></textarea>
      </div>
    );

    const last4 = get(currentUser, "stripe.last4");
    const savedCard = last4 ? `Card ending in ${last4}` : `Saved Card`;

    let editCard = null;

    if (editCardModal) {
      editCard = this.openEditCardModal("edit");
    } else if (addCardModal) {
      editCard = this.openEditCardModal("add");
    }

    let paymentDetails = (
      <Input
        className={`input-style add-payment-btn flex-center payment-btn`}
        readOnly="readonly"
        placeholder="Card Details"
        onClick={this.addCard}
        icon={require("../../../assets/images/ic_add_card.svg")}
      />
    );

    if (currentUser.saveCreditCard) {
      paymentDetails = (
        <Input
          className={`input-style payment-btn`}
          id="payment-input"
          name="cardNumber"
          readOnly="readonly"
          value={savedCard}
          onClick={this.editCard}
        />
      );
    }

    if (isProceedDisabled) {
      return (
        <Modal
          isOpen={modalIsOpen}
          className="payment-modal"
          style={customStyles}
          onRequestClose={closeModal}
          ariaHideApp={false}
        >
          <div className="modal-title white booking">
            Your payment is being processed...
          </div>
          <div className="flex-center direction-column">
            <div className="payment-modal-height flex-center direction-column">
              <Loader
                type="Ball-Triangle"
                height="100"
                width="100"
                color="#0EA800"
              />
            </div>
            <div className="modal-description">
              This can take a while. Please wait.
            </div>
          </div>
        </Modal>
      );
    }

    return (
      <Fragment>
        <Modal
          isOpen={modalIsOpen}
          className="payment-modal"
          style={customStyles}
          onRequestClose={closeModal}
          ariaHideApp={false}
        >
          <div className="modal-title white booking">{name}</div>
          <img
            src={require("../../../assets/images/ic_close.svg")}
            className="close-button booking-close-btn white"
            alt=""
            onClick={closeModal}
          />
          <div className="flex-center direction-column">
            <div className="modal-description">
              Bookings can be cancelled 3 days prior to the check-in date and
              payment will be fully refunded to you.
            </div>
            {Object.keys(currentUser).length && currentUser.saveCreditCard ? (
              this.renderDetails()
            ) : (
              <div className="note-wrapper payment-input-wrapper">
                {paymentDetails}
              </div>
            )}
            {notesArea}
            {notComplete && (
              <span className="validation-message">
                Please add a payment method before continuing.
              </span>
            )}
            {cardError && (
              <span className="validation-message">{cardError}</span>
            )}
            {CannotBookOwnSpace && (
              <span className="validation-message">{CannotBookOwnSpace}</span>
            )}
            <PrimaryButton
              text="Proceed"
              className="top-margin"
              onClick={this.checkAndSubmit}
            />
          </div>
        </Modal>
        {editCard}
      </Fragment>
    );
  }
}

PaymentModal.propTypes = {
  searchSpaces: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  createBooking: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  searchSpaces,
  createBooking
};

const mapStateToProps = state => ({
  searchResults: getSearchResults(state),
  currentUser: currentUser(state),
  bookingResponse: getBookingResponse(state),
  individualSpace: getIndividualSpace(state),
  cardDetails: cardDetails(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentModal);
