import dev from "../config/development";
import staging from "../config/staging";
import prod from "../config/production";

let config = {};

switch (process.env.REACT_APP_STAGE) {
  case "production":
    config = prod;
    break;
  case "staging":
    config = staging;
    break;
  case "develop":
    config = dev;
    break;
  default:
    config = dev;
    break;
}

export default {
  // Add common config values here
  ...config
};
