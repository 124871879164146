import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { Redirect } from "react-router";
import { NavLink } from "react-router-dom";
import history from "../../../history";
import moment from "moment";
import queryString from "query-string";
import Geocode from "react-geocode";
import Loader from "react-loader-spinner";
import MetaTags from "react-meta-tags";
import { Helmet } from "react-helmet";
import { searchSpaces, searchSpaceTypeAndLocation } from "../actions/actions";
import {
  getSearchResults,
  getSearchResultsLoading
} from "../reducers/selectors";
import ResultCard from "../../../components/ResultCard";
import FilterModal from "../../../components/FilterModal";
import { cities, MapsApiKey } from "../../../settings";
import { Mixpanel } from "../../../util/MixPanel";
import { spaces } from "../../../configReduxSetup/reducers/selectors";

const REGULAR_PER_PAGE = 12;
const WINDOW_SIZE_TRASHHOLDS = {
  SMALL_SCREEN: 1100,
  LARGE_SCREEN: 1680
};

class ResultsScreen extends Component {
  constructor(props) {
    super(props);

    this.noOfFeaturedCardsOnRow = this.getNoOfCardsOnRow();

    this.state = {
      sortOpened: false,
      redirectToSearch: false,
      pageNumberFeatured: this.noOfFeaturedCardsOnRow,
      firstIndexRegular: 0,
      lastIndexRegular: 12,
      pageNumber: 1,
      modalOpen: false,
      totalFilters: 0,
      dateType: this.props.match.params.interval,
      formattedLocation: ""
    };
  }

  getNoOfCardsOnRow = () => {
    let windowWidthSize = window.innerWidth;
    let ret = 3;

    if (windowWidthSize < WINDOW_SIZE_TRASHHOLDS.SMALL_SCREEN) {
      ret = 2;
    } else if (windowWidthSize > WINDOW_SIZE_TRASHHOLDS.LARGE_SCREEN) {
      ret = 4;
    }

    return ret;
  };

  getSpaces = async (
    type,
    location,
    startDate,
    endDate,
    startTime,
    endTime,
    nrOfGuests
  ) => {
    const { searchSpaces, searchSpaceTypeAndLocation } = this.props;
    const { dateType } = this.state;

    let query = "";
    const searchQuery = queryString.parse(this.props.location.search);
    let sortByDistance = false;
    let sortByPrice = false;

    if (searchQuery.sortType === "distance") {
      sortByDistance = true;
    }

    if (searchQuery.sortType === "price") {
      sortByPrice = true;
    }

    if (location) {
      Geocode.setApiKey(`${MapsApiKey}&sensor=true`);
      Geocode.fromAddress(location).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location;
          const { formatted_address } = response.results[0];
          this.setState({ formattedLocation: formatted_address });
          if (
            location &&
            type &&
            startDate &&
            endDate &&
            startTime &&
            endTime &&
            nrOfGuests
          ) {
            query = `location=${location}&type=${type}&startDate=${startDate}&endDate=${endDate}&startTime=${startTime}&endTime=${endTime}&nrOfGuests=${nrOfGuests}&${history.location.search.substr(
              1
            )}&lat=${lat}&long=${lng}&sortByDistance=${sortByDistance}&sortByPrice=${sortByPrice}&bookingInterval=${dateType}`;
            searchSpaces(query);
          } else if (type && location) {
            query = `location=${location}&type=${type}&${history.location.search.substr(
              1
            )}&lat=${lat}&long=${lng}&sortByDistance=${sortByDistance}&sortByPrice=${sortByPrice}`;
            searchSpaceTypeAndLocation(query);
          }
        },
        error => {
          console.error(error);
        }
      );
    } else if (type) {
      query = `type=${type}${history.location.search.substr(1) &&
        "&" + history.location.search.substr(1)}&sortByPrice=${sortByPrice}`;
      await searchSpaceTypeAndLocation(query);
    }
  };

  componentDidMount() {
    const {
      type,
      location,
      startDate,
      endDate,
      startTime,
      endTime,
      nrOfGuests
    } = this.props.match.params;
    const { spaces } = this.props;

    let officeType = spaces.find(space => space.url === type).key;

    this.getSpaces(
      officeType,
      location,
      startDate,
      endDate,
      startTime,
      endTime,
      nrOfGuests
    );
  }

  componentDidUpdate(prevProps) {
    const {
      type,
      location,
      startDate,
      endDate,
      startTime,
      endTime,
      nrOfGuests
    } = this.props.match.params;
    const { spaces } = this.props;

    let officeType = spaces.find(space => space.url === type).key;

    if (prevProps.location !== this.props.location) {
      this.getSpaces(
        officeType,
        location,
        startDate,
        endDate,
        startTime,
        endTime,
        nrOfGuests
      );
      this.setState({
        pageNumberFeatured: this.noOfFeaturedCardsOnRow,
        firstIndexRegular: 0,
        lastIndexRegular: 12,
        pageNumber: 1
      });
    }
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ sortOpened: false });
    }
  };

  changePageNumberFeatured = () => {
    const { pageNumberFeatured } = this.state;
    this.setState({
      pageNumberFeatured: pageNumberFeatured + this.noOfFeaturedCardsOnRow
    });
  };

  handlePagination = pageObj => {
    const currentPageNumber = pageObj.selected + 1;

    Mixpanel.track("Spaces - Page Change");

    window.scrollTo(0, 0);

    this.setState({
      firstIndexRegular: REGULAR_PER_PAGE * pageObj.selected,
      lastIndexRegular: REGULAR_PER_PAGE * pageObj.selected + REGULAR_PER_PAGE,
      pageNumber: currentPageNumber
    });
  };

  getMoreFilters = () => {
    this.setState({ modalOpen: true });
    Mixpanel.track("More filters Clicked");
  };

  setSpaceMixpanel = space => {
    Mixpanel.track(`Space opened - ${space}`);
  };

  setBrowseMixpanel = city => {
    Mixpanel.track(`Browse more spaces`, { city });
  };

  cities = [
    "Sydney NSW, Australia",
    "Brisbane QLD, Australia",
    "Canberra ACT, Australia",
    "Hobart TAS, Australia",
    "Melbourne VIC, Australia",
    "Perth WA, Australia",
    "Adelaide SA, Australia",
    "Darwin NT, Australia"
  ];

  sortResults = sortType => {
    let pushedItem = "";
    const searchQuery = queryString.parse(this.props.location.search);

    Mixpanel.track(`Sorted by ${sortType}`);

    if (
      window.location.search.includes("sortType") &&
      searchQuery.sortType !== sortType
    ) {
      searchQuery.sortType = sortType;

      Object.keys(searchQuery).forEach(filter => {
        pushedItem === ""
          ? (pushedItem = pushedItem.concat(`${filter}=${searchQuery[filter]}`))
          : (pushedItem = pushedItem.concat(
              `&${filter}=${searchQuery[filter]}`
            ));
      });
      history.push({
        search: pushedItem
      });
    }

    if (!window.location.search.includes("sortType")) {
      if (Object.keys(searchQuery).length > 0) {
        pushedItem = window.location.search + `&sortType=${sortType}`;
      } else {
        pushedItem = window.location.search + `?sortType=${sortType}`;
      }
      history.push({
        search: pushedItem
      });
    }
  };

  render() {
    const {
      pageNumberFeatured,
      firstIndexRegular,
      lastIndexRegular,
      redirectToSearch,
      modalOpen,
      sortOpened
    } = this.state;
    const { loading } = this.props;
    let sortType = "";

    if (loading) {
      return (
        <div className="loader-wrapper">
          <Loader
            type="Ball-Triangle"
            height="100"
            width="100"
            color="#0EA800"
          />
        </div>
      );
    }

    if (queryString.parse(this.props.location.search).sortType) {
      sortType = queryString.parse(this.props.location.search).sortType;
    }

    if (redirectToSearch) {
      return <Redirect push to={`/`} />;
    }

    const { searchResults } = this.props;
    const {
      searchResults: { featured }
    } = this.props;
    const {
      searchResults: { regular }
    } = this.props;

    const featuredLength =
      searchResults.featured && featured.length ? featured.length : 0;
    const regularSplitLength =
      searchResults.regular && regular.length ? regular.length : 0;
    const pageCount =
      regularSplitLength % REGULAR_PER_PAGE === 0
        ? Math.trunc(regularSplitLength / REGULAR_PER_PAGE)
        : Math.trunc(regularSplitLength / REGULAR_PER_PAGE) + 1;
    const {
      location,
      type,
      startDate,
      endDate,
      startTime,
      endTime,
      nrOfGuests,
      interval
    } = this.props.match.params;
    const startDateNoYear = new Date(startDate * 1000);
    const endDateNoYear = new Date(endDate * 1000);
    const startDateFormatted = moment(startDateNoYear).format("DD MMM");
    const endDateFormatted = moment(endDateNoYear).format("DD MMM");
    const startTimeFormatted = moment(startTime, ["h"]).format("h:mm A");
    const endTimeFormatted = moment(endTime, ["h"]).format("h:mm A");
    const values = queryString.parse(this.props.location.search);
    let date = null;
    let pagination = null;

    if (pageCount > 1) {
      pagination = (
        <ReactPaginate
          pageCount={pageCount}
          containerClassName="flex-center pagination spacing"
          previousLabel={
            <div className="page-switcher flex-center">
              <img
                className="previous label"
                src={require("../../../assets/images/ic_arrow.svg")}
                alt=""
              />
            </div>
          }
          nextLabel={
            <div className="page-switcher flex-center">
              <img
                className="label"
                src={require("../../../assets/images/ic_arrow.svg")}
                alt=""
              />
            </div>
          }
          pageLinkClassName="pagination padding-page"
          previousLinkClassName="pagination"
          nextLinkClassName="pagination"
          breakLabel="..."
          breakClassName="break flex-center"
          breakLinkClassName="pagination"
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={this.handlePagination}
          pageClassName="page flex-center"
          activeClassName="active-page"
        />
      );
    }
    if (startDateFormatted === endDateFormatted) {
      date = startDateFormatted;
    } else {
      date = `${startDateFormatted} - ${endDateFormatted}`;
    }

    let distanceSort = null;
    if (location) {
      distanceSort = (
        <div className="sort-type" onClick={() => this.sortResults("distance")}>
          <div className="sort-type-title">By Distance</div>
          <div className="sort-type-description">
            Sort based on closest to the location entered.
          </div>
        </div>
      );
    }

    let filtersCount = null;

    if (Object.keys(values).length > 0) {
      if (Object.keys(values).length === 1 && values.sortType) {
        filtersCount = null;
      } else {
        filtersCount = (
          <span className="filters-number flex-center">
            {values.sortType
              ? Object.keys(values).length - 1
              : Object.keys(values).length}
          </span>
        );
      }
    }
    let resultsNumber = "";
    if (Object.keys(searchResults).length) {
      resultsNumber =
        searchResults.regular.length + searchResults.featured.length;
    }
    const { spaces } = this.props;

    let metaTag = null;
    let space = spaces.find(space => space.url === type);

    if (location) {
      metaTag = (
        <MetaTags>
          <title>{`${resultsNumber} ${space.name} in ${this.state.formattedLocation} | Deski`}</title>
          <meta
            name="description"
            content={`Find and book ${space.name} in ${this.state.formattedLocation} on Deski for short, medium and long term rent. Flexible spaces and rates to fit your workspace needs.`}
          />
        </MetaTags>
      );
    } else {
      let descriptionMetaContent = `Book ${space.name} on Deski - the easy wasy to find a flexible place to work. See spaces and prices.`;
      let titleMetaContent = `${resultsNumber} ${space.name} for Rent on Deski`;
      switch (type) {
        case "desks-coworking-spaces":
          titleMetaContent = `${resultsNumber} Coworking Spaces and Desks for Rent on Deski`;
          descriptionMetaContent =
            "Book short and long term coworking spaces and hot desks on Deski - the easy wasy to find a flexible place to work. See spaces and prices.";
          break;
        case "office-spaces":
          titleMetaContent = `${resultsNumber} Shared and Private Office Spaces for Rent on Deski`;
          descriptionMetaContent =
            "Book a shared or private office space on Deski - the easy wasy to find a flexible place to work. See spaces and prices.";
          break;
        case "meeting-spaces":
          titleMetaContent = `${resultsNumber} Meeting Spaces and Rooms for Rent on Deski`;
          descriptionMetaContent =
            "Book short term meeting rooms and spaces on Deski - the easy wasy to find a flexible place to work. See spaces and prices.";
          break;
        case "creative-spaces":
          titleMetaContent = `${resultsNumber} Creative Spaces to Work for Rent on Deski`;
          descriptionMetaContent =
            "Book unique and creative spaces to work and meet on Deski - the easy wasy to find a flexible place to work. See spaces and prices.";
          break;
        default:
      }

      metaTag = (
        <MetaTags>
          <title>{titleMetaContent}</title>
          <meta name="description" content={descriptionMetaContent} />
        </MetaTags>
      );
    }

    return (
      <div className="page-template search-results-page">
        <Helmet>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div className="page-wrapper">
          {metaTag}

          {modalOpen && (
            <FilterModal
              activeFilters={values}
              activeRating={values.rating}
              isGroupMeeting={type === "meeting-spaces" ? true : false}
              onCancel={() => this.setState({ modalOpen: false })}
            />
          )}
          <div
            className="back-wrapper"
            onClick={() => this.props.history.push("/search")}
          >
            <img
              src={require("../../../assets/images/ic_back_arrow.svg")}
              className="back-arrow"
              alt=""
            />
            <div className="search-text">Search</div>
          </div>
          <div className="tags-wrapper">
            {location && (
              <div className="search-tag flex-center">
                {this.state.formattedLocation}
              </div>
            )}
            <div className="search-tag flex-center">
              {spaces.find(space => space.url === type).name}
            </div>
            {startDate && <div className="search-tag flex-center">{date}</div>}
            {startTime && (
              <div className="search-tag flex-center">
                {startTimeFormatted} - {endTimeFormatted}
              </div>
            )}
            {nrOfGuests && (
              <div className="search-tag flex-center">{nrOfGuests} Guest</div>
            )}
            <div
              className="search-tag flex-center filter-tag"
              onClick={() => this.getMoreFilters()}
            >
              More Filters &nbsp;
              {filtersCount}
            </div>
          </div>
          <div className="space-between">
            <h1 className="results-header">
              {spaces.find(space => space.url === type).name}
              {location && <span>&nbsp;in&nbsp;</span>}
              {this.state.formattedLocation}
            </h1>
            <div
              className={`sort-button ${sortOpened && "sort-active"}`}
              onClick={() => this.setState({ sortOpened: !sortOpened })}
              ref={this.setWrapperRef}
            >
              <div className={`sort-wrapper `}>
                <div className="sort-text"> Sorted by {sortType}</div>
                <img
                  className={`${sortOpened && "open-icon-reversed"}`}
                  src={require("../../../assets/images/ic_arrow_down.svg")}
                  alt=""
                />
              </div>

              <div className="sort-line" />
              {sortOpened && (
                <div className="sort-type-wrapper">
                  {distanceSort}
                  <div
                    className="sort-type"
                    onClick={() => this.sortResults("price")}
                  >
                    <div className="sort-type-title">By Price</div>
                    <div className="sort-type-description">
                      Sort based on lowest to highest price.
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="results-wrapper">
            {searchResults.featured && featured.length ? (
              <div className="results-category">FEATURED</div>
            ) : null}
            {searchResults.featured && featured.length ? (
              <div className="result-cards">
                {featured.slice(0, pageNumberFeatured).map((space, index) => (
                  <div className="card-wrapper" key={space._id}>
                    <NavLink
                      to={`${
                        startDate
                          ? `/spaces/${space._id}/${space.name
                              .replace(/\W+(?!$)/g, "-")
                              .replace(/\W$/, "")
                              .toLowerCase()}/${encodeURIComponent(
                              space.location
                                .replace(/\W+(?!$)/g, "-")
                                .replace(/\W$/, "")
                                .toLowerCase()
                            )}/${encodeURIComponent(
                              space.address
                                .replace(/\W+(?!$)/g, "-")
                                .replace(/\W$/, "")
                                .toLowerCase()
                            )}/${startDate}/${endDate}/${startTime}/${endTime}/${nrOfGuests}/${interval}`
                          : `/spaces/${space._id}/${space.name
                              .replace(/\W+(?!$)/g, "-")
                              .replace(/\W$/, "")
                              .toLowerCase()}/${encodeURIComponent(
                              space.location
                                .replace(/\W+(?!$)/g, "-")
                                .replace(/\W$/, "")
                                .toLowerCase()
                            )}/${encodeURIComponent(
                              space.address
                                .replace(/\W+(?!$)/g, "-")
                                .replace(/\W$/, "")
                                .toLowerCase()
                            )}`
                      }`}
                      onClick={() => this.setSpaceMixpanel("featured")}
                    >
                      <ResultCard
                        key={space._id}
                        type={space.type}
                        isVerified={space.verified}
                        spaceName={space.name}
                        nrOfReviewers={space.nrOfRaters}
                        rating={space.rating}
                        price={space.price && space.price.totalPrice}
                        ratePerHour={!startDate && space.ratePerHour}
                        ratePerDay={!startDate && space.ratePerDay}
                        ratePerWeek={!startDate && space.ratePerWeek}
                        ratePerMonth={!startDate && space.ratePerMonth}
                        photoUrl={space.photosUrls[0].photoUrl}
                        distance={space.distance && space.distance.toFixed(2)}
                      />
                    </NavLink>
                  </div>
                ))}
              </div>
            ) : null}
            {searchResults.featured &&
            featured.length > this.noOfFeaturedCardsOnRow &&
            pageNumberFeatured <= featuredLength ? (
              <div
                className="view-more"
                onClick={this.changePageNumberFeatured}
              >
                View More
              </div>
            ) : null}
            {searchResults.featured && featured.length ? (
              <div className="separation-line"></div>
            ) : null}
            {searchResults.regular && regular.length ? (
              <div className="results-category">REGULAR SPACE</div>
            ) : null}
            {searchResults.regular && regular.length ? (
              <div className="result-cards">
                {regular
                  .slice(firstIndexRegular, lastIndexRegular)
                  .map((space, index) => (
                    <div className="card-wrapper" key={space._id}>
                      <NavLink
                        to={`${
                          startDate
                            ? `/spaces/${space._id}/${space.name
                                .replace(/\W+(?!$)/g, "-")
                                .replace(/\W$/, "")
                                .toLowerCase()}/${encodeURIComponent(
                                space.location
                                  .replace(/\W+(?!$)/g, "-")
                                  .replace(/\W$/, "")
                                  .toLowerCase()
                              )}/${encodeURIComponent(
                                space.address
                                  .replace(/\W+(?!$)/g, "-")
                                  .replace(/\W$/, "")
                                  .toLowerCase()
                              )}/${startDate}/${endDate}/${startTime}/${endTime}/${nrOfGuests}/${interval}`
                            : `/spaces/${space._id}/${space.name
                                .replace(/\W+(?!$)/g, "-")
                                .replace(/\W$/, "")
                                .toLowerCase()}/${encodeURIComponent(
                                space.location
                                  .replace(/\W+(?!$)/g, "-")
                                  .replace(/\W$/, "")
                                  .toLowerCase()
                              )}/${encodeURIComponent(
                                space.address
                                  .replace(/\W+(?!$)/g, "-")
                                  .replace(/\W$/, "")
                                  .toLowerCase()
                              )}`
                        }`}
                        onClick={() => this.setSpaceMixpanel("regular")}
                      >
                        <ResultCard
                          key={space._id}
                          type={space.type}
                          isVerified={space.verified}
                          spaceName={space.name}
                          nrOfReviewers={space.nrOfRaters}
                          rating={space.rating}
                          price={space.price && space.price.totalPrice}
                          ratePerHour={!startDate && space.ratePerHour}
                          ratePerDay={!startDate && space.ratePerDay}
                          ratePerWeek={!startDate && space.ratePerWeek}
                          ratePerMonth={!startDate && space.ratePerMonth}
                          photoUrl={space.photosUrls[0].photoUrl}
                          distance={space.distance && space.distance.toFixed(2)}
                        />
                      </NavLink>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="results-category">No spaces available.</div>
            )}
          </div>
          {pagination}
          <div className="browse-more-wrapper">
            <div className="results-category">
              BROWSE MORE
              <span style={{ textTransform: "uppercase" }}>
                &nbsp;{spaces.find(space => space.url === type).name}&nbsp;
              </span>
              ACROSS AUSTRALIA
            </div>
            <div className="cities-wrapper">
              {cities.map(city => (
                <div className="city" key={city.name}>
                  <NavLink
                    to={`/search/${type}/${city.name
                      .replace(/\W+(?!$)/g, "-")
                      .replace(/\W$/, "")
                      .toLowerCase()}`}
                    onClick={() => this.setBrowseMixpanel(city.name)}
                  >
                    <img
                      src={require(`../../../assets/images/${city.icon}`)}
                      className="city-photo"
                      alt=""
                    />
                    <div className="city-label">
                      <span className={`city-name`}>
                        {city.name.split(" ", 1)}{" "}
                      </span>
                      <span className={`space-type`}>
                        {spaces.find(space => space.url === type).name}
                      </span>
                    </div>
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ResultsScreen.propTypes = {
  searchSpaces: PropTypes.func.isRequired,
  searchSpaceTypeAndLocation: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  searchSpaces,
  searchSpaceTypeAndLocation
};

const mapStateToProps = state => ({
  searchResults: getSearchResults(state),
  loading: getSearchResultsLoading(state),
  spaces: spaces(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultsScreen);
