import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ShowMoreText from "react-show-more-text";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import Geocode from "react-geocode";
import { currentUser } from "../../../modules/auth/reducers/selectors";
import { MapsApiKey } from "../../../settings";
import {
  amenities,
  groupMeetingTypes
} from "../../../configReduxSetup/reducers/selectors";
import history from "../../../history";
import moment from "moment-timezone";
import Loader from "react-loader-spinner";
import { get } from "lodash-es";

import { getSpaceById } from "../../individualSpace/actions/actions";
import {
  getIndividualSpace,
  getIsLoading
} from "../../individualSpace/reducers/selectors";

import PrimaryButton from "../../../components/PrimaryButton";
import TimeTablePanel from "../../individualSpace/components/TimeTablePanel";

import config from "../../../config/config";
import ReviewComponent from "../../../components/ReviewComponent";
import FeatureModal from "../components/FeatureModal";
import FailModal from "../../../components/FailModal";
import SuccessModal from "../../../components/SuccessModal";
import { Mixpanel } from "../../../util/MixPanel";

class IndividualSpaceScreenPoster extends Component {
  state = {
    thumbs: false,
    width: "50vw",
    isSecondColumnVisible: true,
    featureModalOpen: false
  };

  callGeocoder = address => {
    Geocode.setApiKey(`${MapsApiKey}&sensor=true`);
    Geocode.fromAddress(address).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({ lat, lng });
      },
      error => {
        console.error(error);
      }
    );
  };

  getSpaceById = spaceID => {
    const { getSpaceById } = this.props;

    return new Promise((resolve, reject) => {
      resolve(getSpaceById(spaceID));
    });
  };

  componentDidMount() {
    const { spaceID } = this.props.match.params;

    this.getSpaceById(spaceID).then((res, err) => {
      const { individualSpace } = this.props;
      this.callGeocoder(
        `${individualSpace.space.address} ${individualSpace.space.location}`
      );
    });
    document.title = "Deski";
  }

  componentWillReceiveProps(nextProps) {
    const { spaceID } = nextProps.match.params;

    if (nextProps.location !== this.props.location) {
      this.getSpaceById(spaceID);

      window.scrollTo(0, 0);
    }
  }

  expandCarousel = () => {
    this.setState({
      thumbs: true,
      width: "80vw",
      isSecondColumnVisible: false
    });
  };

  contractCarousel = () => {
    this.setState({
      thumbs: false,
      width: "50vw",
      isSecondColumnVisible: true
    });
  };

  boostSpace = () => {
    this.setState({ featureModalOpen: true });
    Mixpanel.track("Boost Space Clicked");
  };

  showBoostSpace = () => {
    return (
      <div className="boost-wrapper flex-center direction-column">
        <div className="boost-title">WANT TO MAKE YOUR SPACE STAND OUT?</div>
        <div className="boost-description">
          Get noticed more by boosting your space.
        </div>
        <PrimaryButton
          text="Boost Space"
          className="orange-bg report-btn"
          onClick={() => this.boostSpace()}
        />
      </div>
    );
  };

  showFeatured = () => {
    const { individualSpace } = this.props;
    let startDate = "-";
    let endDate = "-";
    const boost = individualSpace.space.boostId;
    if (boost) {
      startDate = moment(boost.startDate)
        .tz(individualSpace.space.timeZone)
        .format("DD MMM YYYY");
      endDate = moment(boost.endDate)
        .tz(individualSpace.space.timeZone)
        .format("DD MMM YYYY");
    }
    return (
      <div className="space-features">
        <div className="results-header individual-amenitie orange">
          FEATURED
        </div>
        <div className="amenitie-text">
          {startDate} - {endDate}
        </div>
      </div>
    );
  };

  renderRating = rating => {
    const ratingArray = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        ratingArray.push(
          <div key={i} className={`rating-stars`}>
            <img
              src={require("../../../assets/images/ic_full_star.svg")}
              alt=""
            />
          </div>
        );
      } else {
        ratingArray.push(
          <div key={i} className={`rating-stars`}>
            <img
              src={require("../../../assets/images/ic_empty_star.svg")}
              alt=""
            />
          </div>
        );
      }
    }
    return ratingArray;
  };

  editSpace = spaceID => {
    history.push(`/space/add/${spaceID}`);
    Mixpanel.track("Edit Space Clicked");
  };

  closeModal = () => {
    this.setState({ featureModalOpen: false });
  };

  getStatus = status => {
    this.setState({ status });
  };

  closeStatusModal = () => {
    const { spaceID } = this.props.match.params;

    history.push(`/space/${spaceID}`);
    this.setState({ status: null });
  };

  render() {
    const { featureModalOpen, status } = this.state;
    const {
      individualSpace,
      isLoading,
      currentUser,
      amenities,
      groupMeetingTypes
    } = this.props;
    const { spaceID } = this.props.match.params;

    if (isLoading) {
      return (
        <div className="loader-wrapper">
          <Loader
            type="Ball-Triangle"
            height="100"
            width="100"
            color="#0EA800"
          />
        </div>
      );
    }

    const { thumbs, width, isSecondColumnVisible } = this.state;
    const MyMapComponent = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          defaultZoom={16}
          defaultCenter={{ lat: this.state.lat, lng: this.state.lng }}
        >
          {props.isMarkerShown && (
            <Marker position={{ lat: this.state.lat, lng: this.state.lng }} />
          )}
        </GoogleMap>
      ))
    );

    let photosContent = [];
    let meetingSpaceTypes = null;
    let boostSection = null;

    if (individualSpace && individualSpace.space) {
      if (individualSpace.space.photosUrls.length > 0) {
        photosContent = individualSpace.space.photosUrls.map(photo => (
          <div key={photo}>
            <img
              src={
                photo.photoUrl
                  ? `${config.api}/files/${photo.photoUrl}`
                  : `/imgs/main_search_bg.jpg`
              }
              alt=""
            />
          </div>
        ));
      }

      if (
        individualSpace.space.type === "groupMeeting" &&
        individualSpace.space.amenitiesType
      ) {
        meetingSpaceTypes = (
          <div className="space-features">
            <div className="results-header individual-amenitie">TYPE</div>
            <ul>
              {Object.keys(individualSpace.space.amenitiesType).map(
                amenitie => (
                  <li key={amenitie} className="amenitie-text">
                    {individualSpace.space.amenitiesType[amenitie] === true &&
                      groupMeetingTypes.find(
                        groupAmenitie => groupAmenitie.key === amenitie
                      ).name}
                  </li>
                )
              )}
            </ul>
          </div>
        );
      }

      if (individualSpace && individualSpace.space.featured) {
        boostSection = this.showFeatured();
      } else boostSection = this.showBoostSpace();
    }

    return (
      <div className="page-template">
        {featureModalOpen && (
          <FeatureModal
            closeModal={this.closeModal}
            featureModalOpen={featureModalOpen}
            spaceId={spaceID}
            closeFeaturedModal={this.closeModal}
            status={this.getStatus}
          />
        )}

        {status === "fail" && (
          <FailModal
            title="Payment error"
            description={`We're sorry but your payment had been unsuccessful. Please check try again.`}
            buttonText="Back"
            onClick={() => this.closeStatusModal()}
            onCancel={() => this.closeStatusModal()}
          />
        )}

        {status === "success" && (
          <SuccessModal
            title="Your space is now featured!"
            description={`A receipt will be sent to your email ${currentUser.email}`}
            buttonText="Ok"
            onClick={() => this.closeStatusModal()}
            onCancel={() => this.closeStatusModal()}
          />
        )}

        {Object.keys(individualSpace).length ? (
          <div>
            <div className="page-wrapper individual-space">
              <div className="individual-content">
                {thumbs && (
                  <img
                    src={require("../../../assets/images/ic_close.svg")}
                    className="close-button big-button"
                    alt=""
                    onClick={this.contractCarousel}
                  />
                )}
                <Carousel
                  className="carousel-wrapper"
                  width={width}
                  showThumbs={thumbs}
                  showStatus={!thumbs}
                  onClickItem={this.expandCarousel}
                  swipeable={true}
                  emulateTouch={true}
                >
                  {photosContent}
                </Carousel>
                <div className="description-wrapper poster">
                  <h1 className="space-name">{individualSpace.space.name}</h1>
                  <div className="space-description">
                    <ShowMoreText
                      lines={5}
                      more={
                        <span className="max-guests">
                          <b>Show more</b>
                        </span>
                      }
                      less={
                        <span className="max-guests">
                          <b>Show less</b>
                        </span>
                      }
                    >
                      <div className="flex-center">
                        {individualSpace.space.description}
                      </div>
                    </ShowMoreText>
                  </div>
                  {boostSection}
                  {individualSpace.space.type !== "desk" && (
                    <div className="space-features">
                      <div className="results-header individual-amenitie">
                        MAX NO. OF PAX
                      </div>
                      <div className="amenitie-text">
                        {individualSpace.space.nrOfPax}
                      </div>
                    </div>
                  )}
                  {individualSpace.space.amenities && (
                    <div>
                      {meetingSpaceTypes}
                      <div className="space-features">
                        <div className="results-header individual-amenitie">
                          AMENITIES
                        </div>
                        <ul>
                          {Object.keys(individualSpace.space.amenities).map(
                            amenitie => (
                              <li key={amenitie} className="amenitie-text">
                                {individualSpace.space.amenities[amenitie] ===
                                  true &&
                                  get(
                                    amenities.find(
                                      singleAmenitie =>
                                        singleAmenitie.key === amenitie
                                    ),
                                    "name",
                                    ""
                                  )}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {isSecondColumnVisible && (
                <div className="individual-sidebar flex-center direction-column">
                  <div className="map-wrapper flex-center direction-column">
                    <div className="address-wrapper">
                      <img
                        src={require("../../../assets/images/ic_location.svg")}
                        alt=""
                      />
                      &nbsp;&nbsp;
                      {individualSpace.space.address}
                    </div>
                    <MyMapComponent
                      isMarkerShown
                      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBOKfqQ4jZtuoQX_dCocGiThiN9tGfbTeE&v=3.exp&libraries=geometry,drawing,places"
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div className="map" />}
                      // containerElement={<div style={{ height: `300px`, width: `25vw` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                    />
                  </div>
                  <div className="hours-title">AVAILABILITY HOURS</div>

                  <div className="hours-wrapper">
                    <TimeTablePanel individualSpace={individualSpace} />
                  </div>

                  <ReviewComponent
                    title="REVIEWS FROM OTHERS"
                    rating={individualSpace.space.rating}
                    ratings={individualSpace.space.ratings}
                    nrOfRaters={individualSpace.space.nrOfRaters}
                  />
                </div>
              )}
            </div>
            <div className="space-footer">
              <div className="section-wrapper sticky-details sticky-mydeski">
                <div className="left-side-footer poster-indiv">
                  <div className="col">
                    <div className="from-to-text">Space ID</div>
                    <div className="card-result-name">
                      {individualSpace.space._id}
                    </div>
                  </div>
                </div>
                <div className="right-side-footer">
                  <PrimaryButton
                    text="Edit Space"
                    className="footer-button gray-bg report-btn"
                    onClick={() => this.editSpace(individualSpace.space._id)}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

IndividualSpaceScreenPoster.propTypes = {
  getSpaceById: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  getSpaceById
};

const mapStateToProps = state => ({
  individualSpace: getIndividualSpace(state),
  isLoading: getIsLoading(state),
  currentUser: currentUser(state),
  amenities: amenities(state),
  groupMeetingTypes: groupMeetingTypes(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndividualSpaceScreenPoster);
