import React, { Component } from 'react';
import WeekDay from './WeekDay';


class BookingHours extends Component {

  state = {
    startTime: null,
    endTime: null,
    days: {},
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.setState({ days: this.props.value })
    }
  }

  weekDays = {
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
    7: "Sun"
  }

  setBookingHours = (startTime, endTime, weekday) => {
    const { days } = this.state;
    let currDay = { startTime, endTime }
    let daysArray = days;

    if (currDay.startTime && currDay.endTime === null) {
      currDay.endTime = currDay.startTime + 1;
    }
    
    daysArray[weekday] = currDay;

    if (currDay.startTime === null && currDay.endTime === null) {
      delete daysArray[weekday];
    }



    this.setState({ days: daysArray })
    this.props.setHoursSelected(daysArray)
  }

  render() {
    const { days } = this.state;

    return (
      <div className="form-group">
        <div className="label">Booking hours</div>
        <div className="hours-wrapper">
          {Object.keys(this.weekDays).map((day) => (
            <WeekDay
              key={day}
              weekday={day}
              startTime={days[day] && days[day].startTime}
              endTime={days[day] && days[day].endTime}
              setBookingHours={this.setBookingHours}
            />
          ))
          }
        </div>
      </div>
    )
  }
}

export default (BookingHours);
