import React, { Component } from 'react';
import { connect } from 'react-redux';

class ProfileSwitch extends Component {


    chooseType = (e) => {
        const { selectedType } = this.props;

        if (selectedType !== e.target.id) {
            this.props.getSelected(e.target.id);
        }
    }

    render() {
        const { selectedType } = this.props;

        return (

            <table className="switch-wrapper">
                <tbody>
                    <tr className="switch-wrapper">
                        <th
                            id="renter"
                            className={`switch-button ${selectedType === 'renter' && 'active'}`}
                            onClick={(e) => this.chooseType(e)}>
                            RENTER
            </th>
                        <th
                            id="host"
                            className={`switch-button ${selectedType === 'host' && 'active'}`}
                            onClick={(e) => this.chooseType(e)}>
                            HOST
          </th>
                    </tr>
                </tbody>
            </table>


        )
    }
}


ProfileSwitch.propTypes = {
};

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSwitch);
