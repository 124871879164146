import React, { Component } from 'react';
import Picker from '../../../components/Picker';
import TimePicker from '../../../components/TimePicker';
import moment from 'moment';


class WeekDay extends Component {

    state = {
        days: {},
        startTime: this.props.startTime,
        endTime: this.props.endTime
    }

    weekDays = {
        1: "Mon",
        2: "Tue",
        3: "Wed",
        4: "Thu",
        5: "Fri",
        6: "Sat",
        7: "Sun"
    }


    getHours = (startTime, endTime) => {
        const { weekday, setBookingHours } = this.props;

        setBookingHours(startTime, endTime, weekday)
    }

    onDone = () => {
        this.setState({ isTimeTableVisible: false })
    }


    render() {
        const { isTimeTableVisible, notComplete } = this.state;
        const { weekday, startTime, endTime } = this.props;

        let picker = (
            <Picker
                isInvalid={notComplete && !startTime && !endTime}
                placeholder="Booking Start & End Time"
                text={(startTime) && `${moment(startTime, ["h"]).format('h:mm A')} - ${endTime ? moment(endTime, ["h"]).format('h:mm A') : moment(startTime + 1, ["h"]).format('h:mm A')}`}
                icon={require('../../../assets/images/ic_time.svg')}
                openIcon
                onClick={() => this.setState({ isTimeTableVisible: true })}
            />
        )

        if (isTimeTableVisible) {
            picker = (
                <TimePicker
                    onClose={() => this.setState({ isTimeTableVisible: false })}
                    onDone={() => this.onDone()}
                    getHours={this.getHours}
                    startHour={startTime}
                    endHour={endTime}
                />
            )
        }

        return (
            <div className="row">
                <span>{this.weekDays[weekday]}</span>
                <div className="hour-range">
                    {picker}
                </div>
            </div>
        )
    }
}

export default (WeekDay);
