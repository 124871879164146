import React, { Component } from 'react';
import PrimaryButton from '../../../components/PrimaryButton';
import { NavLink } from 'react-router-dom';
import { Mixpanel } from '../../../util/MixPanel';

class ListSpaceSection extends Component {


    addMixpanelEvent = () => {
        Mixpanel.track("Activate Account Button Clicked")
    }

    render() {

        return (
            <div className="section-list-space">
                <h2>Want to list your space?</h2>
                <p>Do you have that perfect space? <br />
                    Let us make the match and help you start earning money! <br />
                    Just activate your My Deski (host) account and start listing!</p>
                <NavLink to="/my-deski?type=bookings&page=1" onClick={() => this.addMixpanelEvent()} >
                    <PrimaryButton text="Activate account" className="orange-bg" />
                </NavLink>
            </div >
        )
    }
}

export default ListSpaceSection;