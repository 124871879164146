import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Review from './Review';



const REVIEWS_PER_PAGE = 3;

class ReviewComponent extends Component {

    state = {
        firstIndexRegular: 0,
        lastIndexRegular: 3,
        pageNumber: 1,

    }

    renderRating = (rating) => {
        const ratingArray = []
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                ratingArray.push(
                    <div key={i} className={`rating-stars`}>
                        <img src={require('../assets/images/ic_full_star.svg')} alt="" />
                    </div>
                )
            } else {
                ratingArray.push(
                    <div key={i} className={`rating-stars`}>
                        <img src={require('../assets/images/ic_empty_star.svg')} alt="" />
                    </div>
                )
            }
        }
        return ratingArray;
    }

    handlePagination = (pageObj) => {
        const { firstIndexRegular, lastIndexRegular, pageNumber } = this.state;
        const currentPageNumber = pageObj.selected + 1;

        if (currentPageNumber > pageNumber) {
            this.setState({
                firstIndexRegular: lastIndexRegular,
                lastIndexRegular: lastIndexRegular + REVIEWS_PER_PAGE,
                pageNumber: currentPageNumber
            })
        } else {
            this.setState({
                firstIndexRegular: firstIndexRegular - REVIEWS_PER_PAGE,
                lastIndexRegular: firstIndexRegular,
                pageNumber: currentPageNumber
            })
        }
    };

    render() {
        const { firstIndexRegular, lastIndexRegular } = this.state;
        const { title, rating, ratings, nrOfRaters } = this.props;
        const reviewsSplitLength = ratings.length ? ratings.length : 0;
        let pageCount = Math.trunc(reviewsSplitLength / REVIEWS_PER_PAGE);

        if (reviewsSplitLength % REVIEWS_PER_PAGE !== 0) {
            pageCount = Math.trunc(reviewsSplitLength / REVIEWS_PER_PAGE) + 1
        }

        let review = (
            <div className="review">
                <div className="review-text">No reviews yet.</div>
            </div>
        )

        let pagination = null;

        if (Object.keys(ratings).length) {
            review = (
                <Review
                    firstIndexRegular={firstIndexRegular}
                    lastIndexRegular={lastIndexRegular}
                    ratings={ratings}
                />
            );
            if (pageCount > 1)
                pagination = (
                    <ReactPaginate
                        pageCount={pageCount}
                        containerClassName="flex-center pagination"
                        previousLabel={
                            <div className="page-switcher flex-center">
                                <img
                                    className="previous label"
                                    src={require('../assets/images/ic_arrow.svg')}
                                    alt=""
                                /></div>}
                        nextLabel={
                            <div className="page-switcher flex-center">
                                <img
                                    className="label"
                                    src={require('../assets/images/ic_arrow.svg')}
                                    alt=""
                                />
                            </div>}
                        pageLinkClassName="pagination padding-page"
                        previousLinkClassName="pagination"
                        nextLinkClassName="pagination"
                        breakLabel="..."
                        breakClassName="break flex-center"
                        breakLinkClassName="pagination"
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePagination}
                        pageClassName="page flex-center"
                        activeClassName="active-page"
                    />
                )

        }

        return (

            <div className="reviews-wrapper">
                <div className="reviews-header">
                    <span className="text-link no-link no-underline reviews-title">{title}</span>
                    <div className="rating-wrapper">
                        {this.renderRating(rating)}
                        <span className="review-number">{nrOfRaters}</span>
                    </div>
                </div>
                <div className="reviews-and-pagination">
                    {review}
                    {pagination}
                </div>
            </div>

        )
    }
}


ReviewComponent.propTypes = {
};

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewComponent);









