import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import history from '../../../history';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

import { currentUser } from '../../auth/reducers/selectors';

import ResultCard from '../../../components/ResultCard';

import config from '../../../config/config';
import { Mixpanel } from '../../../util/MixPanel';

class BookingsResults extends Component {


    handlePagination = (pageObj) => {
        const currentPageNumber = pageObj.selected + 1;
        const { type, page } = this.props;

        if (page !== currentPageNumber) {
            history.push({
                pathname: history.location.pathname,
                search: `type=${type}&page=${+currentPageNumber}`
            });
        }
    }

    renderResults = () => {
        const { bookingsPerUser } = this.props;
        return (
            bookingsPerUser.bookings.map((booking) => (
                <div className="card-wrapper" key={booking._id}>
                    <NavLink to={`/booking/host/${booking._id}`} onClick={() => Mixpanel.track("Bookings - opened space")}>
                        <ResultCard
                            isBookingCard
                            type={booking.spaceId.type}
                            spaceName={booking.spaceId.name}
                            startDate={moment(booking.startDate).format('DD MMM YYYY')}
                            endDate={moment(booking.endDate).format('DD MMM YYYY')}
                            startTime={moment(booking.startTime, ["h"]).format('h:mm A')}
                            endTime={moment(booking.endTime, ["h"]).format('h:mm A')}
                            posterPic={booking.renterId && booking.renterId.profilePictureUrl ?
                                booking.renterId.socialLogin ?
                                    booking.renterId.profilePictureUrl :
                                    `${config.api}/files/${booking.renterId.profilePictureUrl}` :
                                require("../../../assets/images/placeholder_profile.svg")}
                            posterName={booking.renterId && `${booking.renterId.firstName} ${booking.renterId.lastName}`}
                            isCanceled={booking.canceled}
                            photoUrl={booking.spaceId.photosUrls[0].photoUrl}
                        />
                    </NavLink>
                </div>
            ))
        )
    }

    render() {
        const { page, elementsPerPage } = this.props;
        const { bookingsPerUser } = this.props;

        let results = (
            <div className="no-bookings">You currently have no bookings</div>
        )
        let pagination = null;
        let pageCount = Math.trunc(bookingsPerUser.count / elementsPerPage) + 1;

        if (bookingsPerUser.count) {

            if ((bookingsPerUser.count % elementsPerPage) === 0) {
                pageCount = Math.trunc(bookingsPerUser.count / elementsPerPage);
            }

            results = this.renderResults();

            if (pageCount > 1)
                pagination = (
                    <ReactPaginate
                        pageCount={pageCount}
                        forcePage={+page - 1}
                        containerClassName="flex-center pagination spacing"
                        previousLabel={
                            <div className="page-switcher flex-center">
                                <img
                                    className="previous label"
                                    src={require('../../../assets/images/ic_arrow.svg')}
                                    alt=""
                                /></div>}
                        nextLabel={
                            <div className="page-switcher flex-center">
                                <img
                                    className="label"
                                    src={require('../../../assets/images/ic_arrow.svg')}
                                    alt=""
                                />
                            </div>}
                        pageLinkClassName="pagination padding-page"
                        previousLinkClassName="pagination"
                        nextLinkClassName="pagination"
                        breakLabel="..."
                        breakClassName="break flex-center"
                        breakLinkClassName="pagination"
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePagination}
                        pageClassName="page flex-center"
                        activeClassName="active-page"
                    />
                )

        }



        return (
            <div>
                {Object.keys(bookingsPerUser.bookings).length &&
                    <div>
                        <div className="result-cards">
                            {results}
                        </div>
                        {pagination}
                    </div>
                }
            </div>
        )
    }
}


BookingsResults.propTypes = {
};

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
    currentUser: currentUser(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingsResults);








