import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Slider from "rc-slider";
import moment from "moment";
import Loader from "react-loader-spinner";
import { padStart, get } from "lodash-es";
import "rc-slider/assets/index.css";

import PrimaryButton from "../../../components/PrimaryButton";
import DailyPickerWrapper from "../../search/components/DailyPickerWrapper";
import { currentUser } from "../../../modules/auth/reducers/selectors";
import Input from "../../../components/Input";
import PriceInfo from "../../bookings/components/PriceInfo";
import AddCard from "../../profile/components/AddCard";
import ViewCard from "../../profile/components/ViewCard";
import { getPriceFeatured, setFeaturedSpace } from "../actions/actions";
import {
  getPrice,
  getIsPriceLoading,
  boostLoading,
  featuredFail
} from "../reducers/selectors";
import { getCardDetailsAction } from "../../profile/actions/actions";
import { cardDetails } from "../../profile/reducers/selectors";
import { Mixpanel } from "../../../util/MixPanel";

class FeatureModal extends Component {
  state = {
    startDate: null,
    endDate: null,
    sliderValue: 4,
    disabledButton: false,
    featureModalOpen: this.props.featureModalOpen,
    addCardModal: false,
    editCardModal: false
  };

  componentDidMount() {
    this.props.getCardDetails();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { startDate, endDate, sliderValue } = this.state;
    const {
      getPriceFeatured,
      boostLoading,
      closeFeaturedModal,
      status,
      featuredFail,
      spaceId
    } = this.props;

    if (startDate !== null && endDate !== null) {
      if (
        prevState.startDate !== startDate ||
        prevState.endDate !== endDate ||
        prevState.sliderValue !== sliderValue
      ) {
        let query = `${spaceId}/${moment(startDate).format("X")}/${moment(
          endDate
        ).format("X")}/${sliderValue}`;
        getPriceFeatured(query);
      }
    }
    if (prevProps.boostLoading !== boostLoading && boostLoading === false) {
      closeFeaturedModal();

      if (featuredFail) {
        status("fail");
      } else {
        status("success");
      }
    }
  }

  onEditCard = () => {
    this.setState({ editCardModal: true });
  };

  onAddCard = () => {
    this.setState({ addCardModal: true });
  };

  openEditCardModal = type => {
    const { editCardModal, addCardModal } = this.state;

    if (type === "edit") {
      return (
        <ViewCard
          modalIsOpen={type === "edit" ? editCardModal : addCardModal}
          closeModal={this.closeModal}
          name="Edit Payment Details"
        />
      );
    } else
      return (
        <AddCard
          modalIsOpen={type === "edit" ? editCardModal : addCardModal}
          closeModal={this.closeModal}
          name="Add Payment Details"
        />
      );
  };

  closeModal = () => {
    this.setState({ addCardModal: false, editCardModal: false });
  };

  createFeaturedPayload = () => {
    const { startDate, endDate, sliderValue } = this.state;

    let payload = {
      startDate: moment(startDate).format("X"),
      endDate: moment(endDate).format("X"),
      radius: sliderValue
    };

    return payload;
  };

  featureSpace = async () => {
    const { setFeaturedSpace } = this.props;
    const { spaceId } = this.props;

    Mixpanel.track("Boost Space Confirmed & Paid");

    this.setState({ disabledButton: true });

    let bookingPayload = this.createFeaturedPayload();

    setFeaturedSpace({ postData: bookingPayload, spaceId });
  };

  changeDates = (startDate, endDate) => {
    this.setState({ startDate, endDate });
  };

  addCard = () => {
    this.setState({ addCardModal: true });
  };

  editCard = () => {
    this.setState({ editModalOpen: true });
  };

  // renderEditCard = () => {
  //   const { editModalOpen } = this.state;
  //   return (
  //     <EditModal
  //       modalIsOpen={editModalOpen}
  //       closeModal={() => this.setState({ editModalOpen: false })}
  //       name="Edit Payment Details"
  //     />
  //   );
  // };

  onSliderChange = value => {
    this.setState({
      sliderValue: value
    });
  };

  render() {
    const { closeModal, currentUser, cardDetails } = this.props;
    const {
      featureModalOpen,
      startDate,
      endDate,
      addCardModal,
      sliderValue,
      disabledButton,
      failModalOpen,
      editCardModal
    } = this.state;
    const { featuredPrice, boostLoading } = this.props;
    let creditCardInfo = null;
    let editModal = null;

    if (boostLoading && !failModalOpen) {
      return (
        <Modal
          ariaHideApp={false}
          isOpen={featureModalOpen}
          // onRequestClose={this.closeModal}
          className="featured-space-modal flex-center direction-column"
          overlayClassName="overlay"
        >
          <div className="flex-center direction-column">
            <Loader
              type="Ball-Triangle"
              height="100"
              width="100"
              color="#0EA800"
            />
          </div>
        </Modal>
      );
    }

    let costInfo = (
      <div>
        <div className="amount total" style={{ width: "100%" }}>
          <div>
            <div className="total-amount-text gray">TOTAL</div>
            <div className="gst-text gray">*GST included</div>
          </div>
          <div className="amount-sum gray">-</div>
        </div>
      </div>
    );

    if (Object.keys(featuredPrice).length && startDate) {
      costInfo = <PriceInfo price={featuredPrice.boostPrice} isBoost={true} />;
    }

    // if (editModalOpen) {
    //   editModal = this.renderEditCard();
    // }

    if (currentUser.saveCreditCard) {
      const cardName = get(cardDetails, "paymentMethod.name", "-");
      const cardNumber = `XXXX-XXXX-XXXX-${get(
        cardDetails,
        "paymentMethod.last4",
        "XXXX"
      )}`;
      const cardExpMonth = padStart(
        get(cardDetails, "paymentMethod.exp_month", "XX"),
        2,
        "0"
      );
      const cardExpYear = String(
        get(cardDetails, "paymentMethod.exp_year", "XXXX")
      ).slice(2);
      const cardExp = `${cardExpMonth} / ${cardExpYear}`;

      creditCardInfo = (
        <div>
          <div className="justify-between card-name">
            <div>
              <div className="card-info gray">Name</div>
              <div className="credit-card gray">{cardName}</div>
            </div>
            <div onClick={this.onEditCard}>
              <img src={require("../../../assets/images/ic_edit.svg")} alt="" />
              <span className="edit gray">Edit Card</span>
            </div>
          </div>

          <div>
            <div className="card-info gray">Card Number</div>
            <div className="card-data payment gray">{cardNumber}</div>
            <div className="card-info gray">Expiration Date</div>
            <div className="card-data payment gray">{`${cardExp}`}</div>
          </div>
        </div>
      );
    } else {
      creditCardInfo = (
        <Input
          className={`input-style add-payment-btn flex-center payment-btn`}
          disabled={true}
          placeholder="Add Card"
          value=""
          onClick={this.onAddCard}
          icon={require("../../../assets/images/ic_add_card.svg")}
        />
      );
    }

    let editCard = null;

    if (editCardModal) {
      editCard = this.openEditCardModal("edit");
    } else if (addCardModal) {
      editCard = this.openEditCardModal("add");
    }

    return (
      <Fragment>
        <Modal
          ariaHideApp={false}
          isOpen={featureModalOpen}
          // onRequestClose={this.closeModal}
          className="featured-space-modal"
          overlayClassName="overlay"
        >
          <AddCard
            modalIsOpen={addCardModal}
            closeModal={() => this.setState({ addCardModal: false })}
            name="Add card"
            updateButtonName="Add"
          />
          {editModal}
          <img
            src={require("../../../assets/images/ic_close.svg")}
            className="close-button filter-close-button"
            alt=""
            onClick={closeModal}
          />
          <div className="flex-center direction-column">
            <div className="code-modal-title delete">Featured Space</div>
            <div className="flex-center direction-column">
              <div className="address-wrapper align-center">
                Increase your reach, by increasing the number of people able to
                see your space.
              </div>
            </div>
            <DailyPickerWrapper
              startDate={startDate}
              endDate={endDate}
              pickerText={"Featured Start & End Date"}
              changeDates={this.changeDates}
              // notComplete={notComplete}
            />

            <div className="field-name">RADIUS</div>

            <Slider
              min={2}
              max={6}
              step={2}
              dots
              value={sliderValue}
              onChange={this.onSliderChange}
            />
            <div className="rc-slider-labels">
              <div className="text-link">2 KM</div>
              <div className="text-link">4 KM</div>
              <div className="text-link">6 KM</div>
            </div>
            <div className="field-name">PAYMENT DETAILS</div>
            {creditCardInfo}

            <div className="cost-wrapper">
              <div className="field-name">COST</div>
              {costInfo}
            </div>
            <PrimaryButton
              text="Confirm & Pay"
              className="top-margin"
              onClick={this.featureSpace}
              disabled={
                (startDate === null && endDate === null) ||
                !currentUser.saveCreditCard ||
                disabledButton
              }
            />
          </div>
        </Modal>
        {editCard}
      </Fragment>
    );
  }
}

FeatureModal.propTypes = {
  getPriceFeatured: PropTypes.func.isRequired,
  setFeaturedSpace: PropTypes.func.isRequired,
  getCardDetails: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  getPriceFeatured,
  setFeaturedSpace,
  getCardDetails: getCardDetailsAction
};

const mapStateToProps = state => ({
  currentUser: currentUser(state),
  featuredPrice: getPrice(state),
  isLoading: getIsPriceLoading(state),
  boostLoading: boostLoading(state),
  featuredFail: featuredFail(state),
  cardDetails: cardDetails(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeatureModal);
