import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { socialLogin, logout } from '../modules/auth/actions/actions';
import { searchSpaceTypeAndLocation } from '../modules/search/actions/actions';
import { currentUser } from '../modules/auth/reducers/selectors';
import { setConfigAction } from '../configReduxSetup/actions/actions';
import { spaces } from '../configReduxSetup/reducers/selectors';
import config from '../config/config';
import { Mixpanel } from '../util/MixPanel';

class NavBar extends Component {
    state = {
        mobileMenu: false,
        isHovering: false,
        searchMenuIsOpen: false,
        menuOpened: false,
    }

    componentDidMount() {
        const { setConfigAction } = this.props;
        setConfigAction();
    }

    closeModal = () => {
        this.setState({ mobileMenu: false });
    }

    logoutUser = () => {
        this.props.logout()
        this.setState({ mobileMenu: false })
    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ isHovering: false, menuOpened: false })
        }
    }

    searchSpaces = async (type) => {
        const { searchSpaceTypeAndLocation } = this.props
        const query = `type=${type}`;

        this.setState({ mobileMenu: false })
        await searchSpaceTypeAndLocation(query)
    }

    showSearchMenu = () => {
        this.setState({ searchMenuIsOpen: true });
        Mixpanel.track("Search spaces Clicked")
    }

    hideSearchMenu = () => {
        this.setState({ searchMenuIsOpen: false });
    }

    setBookingsMixpanel = () => {
        this.setState({ mobileMenu: false });
        Mixpanel.track("Bookings Clicked (Navbar)")
    }

    setMixpanelFAQ = () => {
        this.setState({ mobileMenu: false });
        Mixpanel.track("FAQ Clicked (Navbar)")
    }


    setMixpanelMission = () => {
        this.setState({ mobileMenu: false });
        Mixpanel.track("Mission Clicked (Navbar)")
    }

    setMixpanelProfile = () => {
        this.setState({ mobileMenu: false });
        Mixpanel.track("Profile Clicked (Navbar)")
    }


    setMixpanelLogout = () => {
        this.setState({ mobileMenu: false });
        Mixpanel.track("Logout Clicked (Navbar)")
    }


    setMixpanelLogin = () => {
        this.setState({ mobileMenu: false });
        Mixpanel.track("Login Clicked (Navbar)")
    }

    setMixpanelListSpace = () => {
        this.setState({ mobileMenu: false });
        Mixpanel.track("List your space clicked (Navbar)")
    }

    setMixpanelSearch = (name) => {
        if (name) {
            Mixpanel.track(`Search spaces - ${name}`);
        }
        else {
            Mixpanel.track("Search spaces Clicked");
        }
        this.setState({ mobileMenu: false })
    }

    renderSearchMenu = () => {

        const { searchMenuIsOpen } = this.state;
        const { spaces } = this.props;
        let divColor = "gray";

        if (window.location.pathname.includes('/search')) {
            divColor = "green";
        }
        return (
            <div className={`nav-buttons-wrapper responsive `}>
                <div className="search-menu-wrapper" onClick={() => this.hideSearchMenu()}>
                    <div className={`nav-bar-text ${divColor}`}>Search spaces</div>
                    <img
                        className={`${searchMenuIsOpen && 'open-icon-reversed'}`}
                        src={require('../assets/images/ic_arrow_down.svg')}
                        alt=""
                    />
                </div>
                {spaces.map((space) =>
                    <NavLink key={space.key} to={`/search/${space.url}`} onClick={() => this.setMixpanelSearch(space.name)}>
                        <div className="nav-bar-text small">{space.name}</div>
                    </NavLink>
                )}
                <NavLink to={`/search`} onClick={() => this.setState({ mobileMenu: false })}>
                    <div className="nav-bar-text small">Advanced Search</div>
                </NavLink>
            </div>
        )
    }


    renderSearch = (isSideDrawer) => {

        const { searchMenuIsOpen } = this.state;
        let element = null;
        let divColor = "gray";

        if (window.location.pathname.includes('/search')) {
            divColor = "green";
        }
        if (isSideDrawer) {
            element =
                <div className="search-menu-wrapper" onClick={() => this.showSearchMenu()}>
                    <div
                        style={{ paddingRight: '25px' }}
                        className={`${divColor}`}>Search spaces</div>
                    <img
                        className={`${searchMenuIsOpen && 'open-icon-reversed'}`}
                        src={require('../assets/images/ic_arrow_down.svg')}
                        alt=""
                    />
                </div>
        }
        else {
            element = <NavLink to={`/search`} onClick={() => this.setMixpanelSearch()}>
                <div className={`${divColor}`}>Search spaces</div>
            </NavLink>
        }

        return element

    }

    toggleProfileMenu = () => {
        this.setState({ menuOpened: !this.state.menuOpened })
        Mixpanel.track("Avatar clicked (Navbar)")
    }

    toggleMobileMenu = () => {
        this.setState({ mobileMenu: !this.state.mobileMenu })
    }

    renderProfilePic = () => {
        const { menuOpened } = this.state;
        const { currentUser } = this.props;
        let smallMenu = null;

        if (menuOpened) {
            smallMenu = (
                <div className="profile-menu flex-center direction-column">
                    <div>
                        <NavLink to={`/profile`} onClick={() => this.setMixpanelProfile()}>
                            <div className="profile-menu-text">Profile</div>
                        </NavLink>
                        <div onClick={this.logoutUser} >
                            <NavLink to={`/login`} onClick={() => this.setMixpanelLogout()}>
                                <div className={`profile-menu-text`}>Logout</div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            )
        }

        const profileSrc = currentUser && currentUser.socialLogin && Object.keys(currentUser.socialLogin).length ?
            currentUser.profilePictureUrl :
            `${config.api}/files/${currentUser.profilePictureUrl}`;


        return (
            Object.keys(currentUser).length ?
                <div
                    className="img-uploader flex-center"
                    ref={this.setWrapperRef}
                    onClick={() => this.toggleProfileMenu()}>
                    <div
                        className="small-img"
                        style={{
                            backgroundImage: `url(${currentUser.profilePictureUrl ? profileSrc : require('../assets/images/placeholder_profile.svg')})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center'
                        }}>
                    </div>
                    <img
                        className={`${menuOpened && 'open-icon-reversed'}`}
                        src={require('../assets/images/ic_arrow_down.svg')}
                        alt=""
                    />
                    {smallMenu}
                </div>
                :
                null
        )
    }

    renderButtons = (isSideDrawer) => {
        const { currentUser, spaces } = this.props;
        let profilePic = this.renderProfilePic();
        let loginColor = 'gray';
        let bookingsColor = 'gray';
        let divColor = "gray";
        let deskiColor = "gray";
        let missionColor = "gray";
        let faqColor = "gray";

        if (window.location.pathname === '/' || window.location.pathname.includes('/search')) {
            divColor = "green";
        }
        if (window.location.pathname === '/login') {
            loginColor = "green";
        }
        if (window.location.pathname === '/bookings') {
            bookingsColor = "green";
        }
        if (window.location.pathname === '/my-deski') {
            deskiColor = "green";
        }
        if (window.location.pathname === '/mission') {
            missionColor = "green";
        }
        if (window.location.pathname === '/faq') {
            faqColor = "green";
        }

        let bookings = (
            <NavLink to={`/login`} onClick={() => this.setBookingsMixpanel()}>
                <div className={`nav-bar-text ${bookingsColor}`}>Bookings</div>
            </NavLink>
        )

        if (Object.keys(currentUser).length) {
            bookings = (
                <NavLink to={`/bookings?page=1&type=active`} onClick={() => this.setBookingsMixpanel()}>
                    <div className={`nav-bar-text ${bookingsColor}`}>Bookings</div>
                </NavLink>
            )
        }

        if (Object.keys(currentUser).length && (!currentUser.email || !currentUser.phone)) {
            bookings = (
                <NavLink to={`/add-missing-info`} onClick={() => this.setBookingsMixpanel()}>
                    <div className={`nav-bar-text ${bookingsColor}`}>Bookings</div>
                </NavLink>
            )
        }

        let navbarItem = "List your space";
        if (Object.keys(currentUser).length && currentUser.hostAccount) {
            if (currentUser.hostAccount.active === true)
                navbarItem = "My Deski"
        }

        return (
            <div className={`nav-buttons-wrapper ${isSideDrawer ? 'responsive' : ''}`}>
                <div className={`nav-bar-text ${divColor}`}
                    onMouseEnter={() => !isSideDrawer && this.setState({ isHovering: true })}
                >
                    {this.state.isHovering &&
                        <div className="search-options flex-center direction-column"
                            ref={this.setWrapperRef}
                            onMouseEnter={() => !isSideDrawer && this.setState({ isHovering: true })}
                            onMouseLeave={() => !isSideDrawer && this.setState({ isHovering: false })}>
                            {spaces.map((space) =>
                                <NavLink key={space.key} to={`/search/${space.url}`} onClick={() => this.setMixpanelSearch(space.name)}>
                                    <div className="search-option-text">{space.name}</div>
                                </NavLink>
                            )}
                        </div>}
                    {this.renderSearch(isSideDrawer)}
                </div>
                {bookings}
                <NavLink to={`/my-deski?type=bookings&page=1`} onClick={() => this.setMixpanelListSpace()}>
                    <div className={`nav-bar-text ${deskiColor}`}>{navbarItem}</div>
                </NavLink>
                <NavLink to={`/mission`} onClick={() => this.setMixpanelMission()} target="_blank">
                    <div className={`nav-bar-text ${missionColor}`}>Mission</div>
                </NavLink>
                <NavLink to={`/faq`} onClick={() => this.setMixpanelFAQ()} target="_blank">
                    <div className={`nav-bar-text ${faqColor}`}>FAQ</div>
                </NavLink>
                {!Object.keys(currentUser).length &&
                    <NavLink to={`/login`} onClick={() => this.setMixpanelLogin()}>
                        <div className={`nav-bar-text  ${loginColor}`} >Login</div>
                    </NavLink>
                }

                {!isSideDrawer && profilePic}

            </div>
        )
    }

    setMixpanelLogo = () => {
        Mixpanel.track("Clicked on Deski Logo")
    }

    render() {
        const { searchMenuIsOpen, mobileMenu } = this.state;
        let modalContent = "";
        let profilePic = this.renderProfilePic();

        if (searchMenuIsOpen) {
            modalContent = this.renderSearchMenu()
        } else {
            modalContent = this.renderButtons(true);
        }

        return (
            <div className={`nav-bar flex-center`}>
                <Modal
                    ariaHideApp={false}
                    isOpen={mobileMenu}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    className="drawer-modal"
                    overlayClassName="overlay"
                >
                    <div className="nav-bar-elements-wrapper flex-center responsive full-width">
                        <div className="small-profile-pic">
                            {profilePic}
                        </div>
                        {modalContent}
                        <div className="flex-center direction-column bottom-drawer">
                            <div className="horizontal-line" ></div>
                            <div>
                                <NavLink to={`/terms-of-use`} target="_blank">
                                    <span className="tos-text">Terms Of Service </span>
                                </NavLink>
                                <span className="tos-text"> | </span>
                                <NavLink to={`/privacy-policy`} target="_blank">
                                    <span className="tos-text"> Privacy Policy</span>
                                </NavLink>
                            </div>
                            <div>
                                <a href="https://www.facebook.com/deskiaustralia" target="_blank" rel="noopener noreferrer">
                                    <img src={require('../assets/images/ic_facebook.svg')} className="media-icon" alt="Facebook" />
                                </a>
                                <a href="https://twitter.com/Deski50233800" target="_blank" rel="noopener noreferrer">
                                    <img src={require('../assets/images/ic_twitter.svg')} className="media-icon" alt="Twitter" />
                                </a>
                                <a href="https://www.instagram.com/deskicompany/" target="_blank" rel="noopener noreferrer">
                                    <img src={require('../assets/images/ic_instagram.svg')} className="media-icon" alt="Instagram" />
                                </a>
                            </div>
                            <div>
                                <a href="http://www.deski.com/" target="_blank" rel="noopener noreferrer" className="deski-link">www.deski.com</a>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className="nav-bar-elements-wrapper flex-center">
                    <NavLink to={`/`} onClick={() => this.setMixpanelLogo()}>
                        <img src={require('../assets/images/deski_logo_full.svg')} className="logo-img" alt="Logo" />
                    </NavLink>
                    {this.renderButtons()}
                    <div id="burger-menu" className={mobileMenu ? 'open' : 'closed'} onClick={() => this.toggleMobileMenu()}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        );
    }
}

NavBar.propTypes = {
    socialLogin: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    searchSpaceTypeAndLocation: PropTypes.func.isRequired,
    setConfigAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    socialLogin,
    logout,
    searchSpaceTypeAndLocation,
    setConfigAction
};

const mapStateToProps = (state) => ({
    currentUser: currentUser(state),
    spaces: spaces(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
