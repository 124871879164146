import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ResultCard from '../../../components/ResultCard';
import { Mixpanel } from '../../../util/MixPanel';

class VerifiedSpaces extends Component {

    addMixpanelEvent = (spaceName) => {
        Mixpanel.track("Viewed Top Verified Space", { spaceName })
    }

    render() {
        const { spaces } = this.props;
        let windowWidthSize = window.innerWidth;
        let number = 6;

        if (windowWidthSize < 768) {
            number = 2;
        }

        return (
            <div className="section-verified">
                <div className="section-header">Top verified spaces</div>
                <div className="result-cards">
                    {spaces.spaces.slice(0, number).map((space, index) => (
                        <div className="card-wrapper" key={space._id} onClick={() => this.addMixpanelEvent(space.name)}>
                            <NavLink
                                to={`/spaces/${space._id}/${space.name.replace(/\W+(?!$)/g, '-').replace(/\W$/, '').toLowerCase()}/${encodeURIComponent(space.location.replace(/\W+(?!$)/g, '-').replace(/\W$/, '').toLowerCase())}/${encodeURIComponent(space.address.replace(/\W+(?!$)/g, '-').replace(/\W$/, '').toLowerCase())}`}>
                                <ResultCard
                                    key={space._id}
                                    type={space.type}
                                    isVerified={space.verified}
                                    spaceName={space.name}
                                    nrOfReviewers={space.nrOfRaters}
                                    rating={space.rating}
                                    price={space.price && space.price.totalPrice}
                                    ratePerHour={space.ratePerHour}
                                    ratePerDay={space.ratePerDay}
                                    ratePerWeek={space.ratePerWeek}
                                    ratePerMonth={space.ratePerMonth}
                                    photoUrl={space.photosUrls[0].photoUrl}
                                />
                            </NavLink>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default VerifiedSpaces;