import React, { Component } from "react";
import TextInput from "../../../components/TextInput";

class Earnings extends Component {
  render() {
    const { today, thisWeek, lifetime } = this.props;

    return (
      <div className="form-group">
        <div className="label">EARNINGS</div>
        <div className="rates-wrapper">
          <div className="col deski">
            <TextInput
              id="spaceMax"
              type="number"
              placeholder={`$${today}`}
              disabled={true}
            />
            <span>TODAY</span>
          </div>
          <div className="col deski">
            <TextInput
              id="spaceMax"
              type="number"
              placeholder={`$${thisWeek}`}
              disabled={true}
            />
            <span>THIS WEEK</span>
          </div>
          <div className="col deski">
            <TextInput
              id="spaceMax"
              type="number"
              placeholder={`$${lifetime}`}
              disabled={true}
            />
            <span>LIFETIME</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Earnings;
