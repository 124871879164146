import {
  GET_BOOKINGS,
  GET_BOOKINGS_SUCCESS,
  GET_INDIVIDUAL_BOOKING,
  GET_INDIVIDUAL_BOOKING_SUCCESS,
  CREATE_REVIEW_SPACE,
  CREATE_REVIEW_SPACE_SUCCESS,
  CREATE_REVIEW_RENTER,
  CREATE_REVIEW_RENTER_SUCCESS,
  GET_REVIEW_SPACE,
  GET_REVIEW_SPACE_SUCCESS,
  GET_REVIEW_USER,
  GET_REVIEW_USER_SUCCESS,
  CREATE_REPORT_DISPUTE,
  CREATE_REPORT_DISPUTE_SUCCESS
} from './actionTypes';
import { Booking, Rating } from '../../../api/endpoints';


export function getBookings(payload) {
  return {
    type: GET_BOOKINGS,
    payload,
  };
}

export function getBookingsSuccess(payload) {
  return {
    type: GET_BOOKINGS_SUCCESS,
    payload,
  };
}
export function getBookingById(payload) {
  return {
    type: GET_INDIVIDUAL_BOOKING,
    payload,
  };
}

export function getBookingByIdSuccess(payload) {
  return {
    type: GET_INDIVIDUAL_BOOKING_SUCCESS,
    payload,
  };
}

export function createRatingSpace(payload) {
  return {
    type: CREATE_REVIEW_SPACE,
    payload,
  };
}

export function createRatingForSpaceSuccess(payload) {
  return {
    type: CREATE_REVIEW_SPACE_SUCCESS,
    payload,
  };
}

export function createRatingRenter(payload) {
  return {
    type: CREATE_REVIEW_RENTER,
    payload,
  };
}

export function createRatingForRenterSuccess(payload) {
  return {
    type: CREATE_REVIEW_RENTER_SUCCESS,
    payload,
  };
}

export function getRatingSpace(payload) {
  return {
    type: GET_REVIEW_SPACE,
    payload,
  };
}

export function getRatingForSpaceSuccess(payload) {
  return {
    type: GET_REVIEW_SPACE_SUCCESS,
    payload,
  };
}

export function getRatingUser(payload) {
  return {
    type: GET_REVIEW_USER,
    payload,
  };
}

export function getRatingUserSuccess(payload) {
  return {
    type: GET_REVIEW_USER_SUCCESS,
    payload,
  };
}

export function createReportDispute(payload) {
  return {
    type: CREATE_REPORT_DISPUTE,
    payload,
  };
}

export function createReportDisputeSuccess(payload) {
  return {
    type: CREATE_REPORT_DISPUTE_SUCCESS,
    payload,
  };
}

export function getUsersBookings(payload) {
  return async (dispatch) => {
    dispatch(getBookings(payload));

    const response = await Booking.getBookingsPerUser(payload);

    dispatch(getBookingsSuccess(response));
  };
}


export function getBooking(payload) {
  return async (dispatch) => {
    dispatch(getBookingById(payload));

    const response = await Booking.getIndividualBooking(payload);

    dispatch(getBookingByIdSuccess(response));
  };
}

export function createRatingForSpace(payload) {
  return async (dispatch) => {
    dispatch(createRatingSpace(payload));

    const response = await Rating.createRatingSpace(payload);

    dispatch(createRatingForSpaceSuccess(response));
  };
}

export function createRatingForRenter(payload) {
  return async (dispatch) => {
    dispatch(createRatingRenter(payload));

    const response = await Rating.createRatingRenter(payload);

    dispatch(createRatingForRenterSuccess(response));
  };
}

export function getRatingForSpace(payload) {
  return async (dispatch) => {
    dispatch(getRatingSpace(payload));

    const response = await Rating.getRatingForSpace(payload);

    dispatch(getRatingForSpaceSuccess(response));
  };
}

export function getRatingForUser(payload) {
  return async (dispatch) => {
    dispatch(getRatingUser(payload));

    const response = await Rating.getRatingForUser(payload);

    dispatch(getRatingUserSuccess(response));
  };
}

export function cancelAndRefundBooking(payload) {
  return async (dispatch) => {
    dispatch(getBookingById(payload));

    const response = await Booking.cancelAndRefundBooking(payload);

    dispatch(getBookingByIdSuccess(response));
  };
}

export function cancelAndRefundBookingHost(payload) {
  return async (dispatch) => {
    dispatch(getBookingById(payload));

    const response = await Booking.cancelAndRefundBookingHost(payload);

    dispatch(getBookingByIdSuccess(response));
  };
}

export function sendReportDispute(payload) {
  return async (dispatch) => {
    dispatch(createReportDispute(payload));

    const response = await Booking.createReportDispute(payload);

    dispatch(createReportDisputeSuccess(response));
  };
}