import React, { Component } from 'react';
import Modal from 'react-modal';
import VerificationInput from 'react-verification-input'

import PrimaryButton from '../../../components/PrimaryButton';
import PropTypes from 'prop-types';
import { verifySmsCode, sendSmsCode, saveValidationCode } from '../actions/actions';
import { currentUser, getPhoneNumber } from '../reducers/selectors';
import connect from 'react-redux/es/connect/connect';
import { ResendWaitingTime } from '../../../settings';


class VerificationCodeModal extends Component {
  state = {
    invalidCode: false,
    resendEnabled: null
  }
  componentDidMount() {
    this.setState({ resendEnabled: true });
  }

  verifySmsCode = async () => {
    const code = this.code.value;
    const { phoneNumber, saveValidationCode } = this.props;
    try {
      const response = await this.props.verifySmsCode({ code, phone: phoneNumber });
      if (response.isCodeValid) {
        this.props.onSubmit();
        saveValidationCode(code)
      }
    }
    catch (e) {
      this.setState({ invalidCode: true });
    }
  }

  sendCode = () => {
    const { phoneNumber } = this.props;
    this.props.sendSmsCode({ phone: phoneNumber, forgot: true });
    this.setState({ resendEnabled: false });
    setTimeout(() => {
      this.setState({ resendEnabled: true })
    }, ResendWaitingTime);
  }

  render() {
    const { resendEnabled } = this.state;
    return (
      <Modal
        ariaHideApp={false}
        isOpen={true}
        className="forgot-password-modal"
        overlayClassName="overlay"
      >
        <img src={require('../../../assets/images/ic_close.svg')} className="close-button" alt=""
          onClick={this.props.onCancel} />
        <div className="modal-title">Verification Code</div>
        <div className="flex-center direction-column forgot-password-content-wrapper">
          <div>
            <div className="subtitle modal-subtitle">Please enter the code we sent via SMS.</div>
            <div className="mobile-nr-wrapper">
              <span className="pass-pic-title">Enter verification code</span>
              <VerificationInput
                removeDefaultStyles
                length={4}
                placeholder={''}
                container={{
                  className: 'verifier-container',
                }}
                characters={{
                  className: 'verifier-characters',
                }}
                character={{
                  className: this.state.invalidCode ? 'verifier-character flex-center wrong-code' : 'verifier-character flex-center',
                  classNameSelected: 'character--selected'
                }}
                getInputRef={x => this.code = x}
              />
              <div className="sign-up-button top-margin">
                <span>Didn’t receive your code?</span>
                <button className="send-again-button sign-up-button" onClick={this.sendCode} disabled={!resendEnabled}>
                  Send it again
                  </button>
              </div>
            </div>
          </div>
          <PrimaryButton text="Verify" onClick={this.verifySmsCode} />
        </div>
      </Modal >
    );
  }
}

VerificationCodeModal.propTypes = {
  verifySmsCode: PropTypes.func.isRequired,
  sendSmsCode: PropTypes.func.isRequired,
  saveValidationCode: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  verifySmsCode,
  sendSmsCode,
  saveValidationCode
};

const mapStateToProps = (state) => ({
  currentUser: currentUser(state),
  phoneNumber: getPhoneNumber(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(VerificationCodeModal);